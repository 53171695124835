/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Component } from "react";

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// @material-ui/icons

import Info from "@material-ui/icons/Info";
import Lock from "@material-ui/icons/Lock";
import ColorLens from "@material-ui/icons/ColorLens";
import Ballot from "@material-ui/icons/Ballot";

import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.js";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderHelpSection() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              {/*<h4 style={styles1.cardTitle}>Help Center</h4>*/}
            </CardHeader>
            <CardBody>
              <NavPills
                color="rose"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 5, md: 4 },
                  contentGrid: { xs: 12, sm: 7, md: 8 }
                }}
                tabs={[
                  {
                    tabButton: "Our Mission",
                    tabIcon: Info,
                    tabContent: (
                      <CardBody>
                        <h4>
                          We believe that sustainable development requires
                          strong collaboration among stakeholders in the public
                          and private sector. The current investment pipeline,
                          from the search for new partners, to impact-focused
                          monitoring and assessment of investments, is highly
                          inefficient.
                          <br />
                          <br />
                          HEDERA connect provides a professional networking
                          platform for all stakeholders committed to sustainable
                          development, including: inclusive financial
                          institutions, investors, asset managers, development
                          funds, technical assistance providers, consultants,
                          technology and basic needs providers (SMEs), NGOs, and
                          academia.
                          <br />
                          <br />
                          On HEDERA connect, institutions can create highly
                          customizable search profiles, in order to efficiently
                          find new partners worldwide.
                          <br />
                          <br />
                          Users can interact at the personal and at the
                          institutional level, sharing informations, experience,
                          and achievements with their peers.
                          <br />
                        </h4>
                        <h6>
                          HEDERA connect is developed by{" "}
                          <b>HEDERA Sustainable Solutions</b>. Visit{" "}
                          <a
                            href="https://hedera.online"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            our webpage
                          </a>{" "}
                          for more information.
                        </h6>
                      </CardBody>
                    )
                  },
                  {
                    tabButton: "Advanced Search",
                    tabIcon: ColorLens,
                    tabContent: (
                      <CardBody>
                        <h4>
                          <ul>
                            <li>
                              In the <b>Search</b> page, you can browse the
                              members database by type of institution, working
                              region and sustainability focus (SDGs). You can
                              view matches in a list or in a map. Click on the
                              search results to obtain more information about
                              the institutions.
                            </li>
                            <li>
                              In the <b>Institution Profile</b> tab you can view
                              your personal profile and the details of your
                              institution. If you have admin rights, you are
                              also allowed to edit the profile.
                            </li>
                            <li>
                              Use the <b>Matching Tool</b> page you can
                              personalize your search profile. In the{" "}
                              <b>Set Search Preferences</b> tab you can select
                              the type of institution you are interested in
                              (Financial Institution, Investor, Asset Manager,
                              Technical Assistance, Service or Techology
                              Provider, Academia), and choose among more than 20
                              different filtering options. Results will be
                              displayed in the same page, clicking on the{" "}
                              <b>View Matches</b> tab. Your matchmaking list
                              will be automatically updated as soon as new
                              members join the platform.
                            </li>
                          </ul>
                        </h4>
                      </CardBody>
                    )
                  },
                  {
                    tabButton: "Events",
                    tabIcon: Ballot,
                    tabContent: (
                      <CardBody>
                        <h4>
                          Use the <b>Events</b> page to boost your network and
                          meet your peers at the leading conferences. You will
                          see a list of upcoming events, and you can indicate
                          whether you are planning to participate or not. <br />
                          This feature will allow you to communicate to your
                          peers that you can meet in person without the need of
                          registering or to interact through conference apps.
                        </h4>
                      </CardBody>
                    )
                  },
                  {
                    tabButton: "Privacy Terms",
                    tabIcon: Lock,
                    tabContent: (
                      <CardBody>
                        <h4>
                          By registering and using HEDERA connect, you confirm
                          that you have read our{" "}
                          <a
                            href="http://hedera.online/gdpr/hedera-data-protection-declaration-2020-en.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Data Protection Declaration
                          </a>
                          and that you agree with our{" "}
                          <a
                            href="http://hedera.online/gdpr/hedera-data-protection-declaration-2020-en.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Use
                          </a>
                          .
                        </h4>
                      </CardBody>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return <div>{this.renderHelpSection()}</div>;
  }
}

export default About;
