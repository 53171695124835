/* eslint-disable react/prop-types */
import React, { Component } from "react";
import classNames from "classnames";
import Select from "react-select";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import styles from "assets/jss/material-dashboard-react/customSelectStyle.jsx";

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (values, action) => {
    this.props.onChange && this.props.onChange(values);
  };

  render() {
    const {
      labelText,
      classes,
      multiple,
      items,
      error,
      errorMsg,
      success,
      value,
      id,
      labelProps,
      inputProps
    } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    let defaultValue = undefined;
    if (Array.isArray(value)) {
      defaultValue = value.map(dt => ({
        label: dt,
        value: dt
      }));
    } else if (typeof value !== "object" && value) {
      defaultValue = {
        label: value,
        value: value
      };
    } else if (value && value.label && value.value) {
      defaultValue = value;
    }

    return (
      <div>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {!(inputProps && inputProps.value) ? labelText : ""}
          </InputLabel>
        ) : null}
        <FormControl fullWidth className={classes.selectFormControl} error>
          <Select
            className={classes.select}
            isMulti={multiple}
            options={
              items &&
              items.map(item => ({
                label: item.text || item.label,
                value: item.value
              }))
            }
            defaultValue={defaultValue}
            onChange={this.handleChange}
            placeholder={labelText}
            menuPortalTarget={document.querySelector("body")}
          />
          {error ? (
            <>
              { errorMsg === undefined ? (<Clear className={classes.feedback + " " + classes.labelRootError} />):(<FormHelperText>{errorMsg}</FormHelperText>)}
            </>
          ) : success ? (
            <Check
              className={classes.feedback + " " + classes.labelRootSuccess}
            />
          ) : null}
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(CustomSelect);
