const DATE_FORMAT = "^\\d{4}-\\d{2}-\\d{2}$";
// function that returns true if value is email, false otherwise
export function verifyEmail(value) {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}
// function that verifies if a string has a given length or not
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

export function verifyUrl(value) {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
}

export function validateField(event, type, stateNameEqualTo) {
  let targetValue = null;
  switch (type) {
    case "url":
      targetValue = event.target.value;
      if (!verifyUrl(targetValue)) {
        return { value: targetValue, result: "error" };
      }
      break;
    case "email":
      targetValue = event.target.value;
      if (!verifyEmail(targetValue)) {
        return { value: targetValue, result: "error" };
      }
      break;
    case "length":
      targetValue = event.target.value;
      if (!verifyLength(targetValue, stateNameEqualTo)) {
        return { value: targetValue, result: "error" };
      }
      break;
    case "file":
      if (event.target.files.length === 0) {
        targetValue = event.target.files[0];
        return { value: targetValue, result: "error" };
      }
      break;
    case "select":
      targetValue = event;
      if (targetValue === undefined || targetValue === null) {
        return { value: targetValue, result: "error" };
      }
      break;
    case "text":
      targetValue = event.target.value;
      if (!targetValue) {
        return { value: targetValue, result: "error" };
      }
      break;
    case "date":
      targetValue = event.target.value;
      if (!targetValue.match(DATE_FORMAT)) {
        return { value: targetValue, result: "error" };
      }
      break;
    default:
      targetValue = event.target.value;
      return { value: targetValue, result: "success" };
  }
  return { value: targetValue, result: "success" };
}
