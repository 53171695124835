import React from "react";

import ShowMoreText from "react-show-more-text";
import ReactHtmlParser from 'react-html-parser';

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

//components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import defaultAvatar from "assets/img/default-avatar.png";
import defaultProjectAvatar from "assets/img/HEDERA-blatt.png";
import defaultProjectBanner from "assets/img/project_default_banner.jpeg";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { ProjectPermissions } from "utils/roles";

const styles = {
  card: {
    //border: "solid",
    borderWidth: "thin",
    padding: "5px",
    width: "370px",
    height: "350px",
    margin: "5px"
  },
  cardBanner: {
    flex: "0 0 calc(15vh)",
    height: "120px",
    backgroundColor: "white",
    overflow: "hidden",
    position: "relative"
  },
  bannerImage: {
    width: "-webkit-fill-available",
    height: "80%",
    objectFit: "cover",
  },
  cardAvatar: {
    marginTop: "-60px",
    padding: "15px",
    zIndex: "2",
  },
  cardAvatarImg: {
    width: "50px",
    maxHeight: "50px",
    backgroundColor: "white",
    borderRadius: "40%",
    overflow: "hidden",
    padding: "5px",
    objectFit: "scale-down"
    //border: "solid"
  },
  cardBody: {
    flex: "1 1 auto",
    overflow: "auto",
    fontSize: "0.8rem"
  },
  projectTitle: {
    fontSize: "1.0rem",
    paddingTop: "3px",
    paddingStart: "14px",
    fontWeight: "bold",
    textOverflow: "ellipsis"
  },
};

class ProjectSummary extends React.Component {

  

  handleActionSelection = (e, id, projectData) => {
    switch (e) {
      case "Delete Project":
        this.props.handleDelete && this.props.handleDelete(id);
        break;
      case "Explore Data":
        this.props.handleLinkClickDashboard && this.props.handleLinkClickDashboard(
          projectData.metadata && projectData.metadata.dashboardLink
        );
        break;
      case "Visit Survey":
        this.props.handleSurveyClick && this.props.handleSurveyClick(
          projectData.metadata && projectData.metadata.surveyFormLink
        );
        break;
      case "View Details":
        this.props.handleLinkClick && this.props.handleLinkClick("details", id);
        break;
      case "Edit Project":
        this.props.handleLinkClick && this.props.handleLinkClick("edit", id);
        break;
      default:
        break;
    }
  };

  renderProjectDescription = projectDescription => {
    const { classes } = this.props;
    return (
      <ShowMoreText
        more="Show more"
        less="Show less"
        anchorClass="my-anchor-css-class"
        onClick={this.executeOnClick}
        expanded={false}
      >
        <div className={classes.topicDescriptionContainer}>
          {ReactHtmlParser(projectDescription)}
        </div>
      </ShowMoreText>
    );
  };

  render() {
    const { classes, projectData } = this.props;
    let video_link=projectData.metadata && projectData.metadata["video_banner_link"];
    let projectBanner = defaultProjectBanner;
    if (projectData.banner) {
      projectBanner = projectData.banner
    }


    const actionDropDownList = [];
    if (projectData.metadata) {
      if (projectData.metadata.dashboardLink != null) {
        actionDropDownList.push("Explore Data");
      }
      if (projectData.metadata.surveyFormLink != null) {
        actionDropDownList.push("Visit Survey");
      }
    }

    if (projectData.user_permissions) {
      if (projectData.user_permissions.includes(ProjectPermissions.UPDATE_PROJECT)) {
        actionDropDownList.push("Edit Project");
      }
      if (projectData.user_permissions.includes(ProjectPermissions.DELETE_PROJECT)) {
        actionDropDownList.push("Delete Project");
      }
    }

    return (
      <Card key={`quiz-${projectData.id}`} className={classes.card}>
        <div className={classes.cardBanner}>
        <img
              src={projectBanner}
              alt="..."
              className={classes.bannerImage}
          />
          {/*}  { projectData.banner &&
            <img
              src={projectData.banner}
              alt="..."
              className={classes.bannerImage}
            />
          }*/}
        </div>
        <div className={classes.cardAvatar}>
          <img className={classes.cardAvatarImg}
            src={
              projectData.avatar ? `${projectData.avatar}` : defaultProjectAvatar
            }
            alt="..."
          />
        </div>
        <h4 className={classes.projectTitle}>
          {projectData.short_name?projectData.short_name:projectData.name}
        </h4>
        <CardBody className={classes.cardBody}>
          { this.renderProjectDescription(projectData.description) }
          <div style= {{color: "green", marginTop: "15px"}}>
          {video_link &&
            <a style= {{color: "green", marginTop: "15px"}} key={`projectidx${projectData.id}`} href={video_link} target="_blank" rel="noopener noreferrer">
              Welcome video
            </a>
          }
            {/*<a style= {{color: "green", marginTop: "15px"}} key={`projectidx${projectData.id}`} href="https://hedera.online/en/home/index.html">
            Watch Welcome Video
        </a>*/}
          </div>
        </CardBody>
        <CardFooter className={classes.CardFooter}>
          <Button
            type="button"
            color="primary"
            size="sm"
            key={`project-view-details-${projectData.id}`}
            onClick={e => this.props.handleLinkClick && this.props.handleLinkClick("details", projectData.id)}>
            View
          </Button>
          { projectData.metadata && projectData.metadata.dashboardLink != null && 
            <Button
              type="button"
              color="primary"
              size="sm"
              key={`project-view-dashboard-${projectData.id}`}
              onClick={e => this.props.handleLinkClickDashboard && this.props.handleLinkClickDashboard(
                projectData.metadata && projectData.metadata.dashboardLink
              )}>
                Dashboard
            </Button>
          }
          {/* { actionDropDownList.length > 0 && <CustomDropdown
              key={`project-actions-${projectData.id}`}
              buttonProps={{
                color: buttonColorScheme.infoButton
              }}
              onClick={e => {
                this.handleActionSelection(e, projectData.id, projectData);
              }}
              dropdownList={actionDropDownList}
            />
          } */}
        </CardFooter>
      </Card>
    );
  }
}

export default withStyles(styles)(ProjectSummary);
