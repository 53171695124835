/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from "@material-ui/core/Chip";
import { Input } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select, { components } from "react-select";

import instituteDefaultAvatar from "assets/img/sdg-bg.png"
import Place from "@material-ui/icons/Place";
import Snackbar from "@material-ui/core/Snackbar";
import FollowIcon from "@material-ui/icons/Star";
import UnfollowIcon from "@material-ui/icons/StarOutline";
import InviteIcon from "@material-ui/icons/GroupAdd";
import RequestIcon from "@material-ui/icons/Sms";
import UpdateIcon from "@material-ui/icons/Refresh";

// core components
import Modal from "@material-ui/core/Modal";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ContactMail } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Quote from "components/Typography/Quote.jsx";
import Accordion from "components/Accordion/Accordion.js";
import NavPills from "components/NavPills/NavPills.js";

import CustomForm from "layouts/Registration/WizardSteps/CustomForm.js";
import { FinancialInstituteFormSchema } from "layouts/Registration/WizardSteps/FormSchemas/FinancialInstituteFormSchema.js";
import { InvestorFormSchema } from "layouts/Registration/WizardSteps/FormSchemas/InvestorFormSchema.js";
import { TechnologyProviderFormSchema } from "layouts/Registration/WizardSteps/FormSchemas/TechnologyProviderFormSchema.js";
import { TechnicalAssistanceFormSchema } from "layouts/Registration/WizardSteps/FormSchemas/TechnicalAssistanceFormSchema.js";
import { AcademiaFormSchema } from "layouts/Registration/WizardSteps/FormSchemas/AcademiaFormSchema.js";
import { NGOFormSchema } from "layouts/Registration/WizardSteps/FormSchemas/NGOFormSchema.js";


import defaultAvatar from "assets/img/default-avatar.png";
import { PROFILE_VISIBILITY_CHOICES } from "../../utils/choices";

// sdg icons
import sdg1 from "assets/img/SDGs/E_SDG_Icons-01.jpg";
import sdg2 from "assets/img/SDGs/E_SDG_Icons-02.jpg";
import sdg3 from "assets/img/SDGs/E_SDG_Icons-03.jpg";
import sdg4 from "assets/img/SDGs/E_SDG_Icons-04.jpg";
import sdg5 from "assets/img/SDGs/E_SDG_Icons-05.jpg";
import sdg6 from "assets/img/SDGs/E_SDG_Icons-06.jpg";
import sdg7 from "assets/img/SDGs/E_SDG_Icons-07.jpg";
import sdg8 from "assets/img/SDGs/E_SDG_Icons-08.jpg";
import sdg9 from "assets/img/SDGs/E_SDG_Icons-09.jpg";
import sdg10 from "assets/img/SDGs/E_SDG_Icons-10.jpg";
import sdg11 from "assets/img/SDGs/E_SDG_Icons-11.jpg";
import sdg12 from "assets/img/SDGs/E_SDG_Icons-12.jpg";
import sdg13 from "assets/img/SDGs/E_SDG_Icons-13.jpg";
import sdg14 from "assets/img/SDGs/E_SDG_Icons-14.jpg";
import sdg15 from "assets/img/SDGs/E_SDG_Icons-15.jpg";
import sdg16 from "assets/img/SDGs/E_SDG_Icons-16.jpg";
import sdg17 from "assets/img/SDGs/E_SDG_Icons-17.jpg";

import { getData, updateRoleData } from "services/userDataProvider.js";
import { getData as getInstituteData, updateData, followInstitute } from "services/instituteDataProvider.js";
import { MEDIA_ROOT } from "config.js";
import {
  SDGs,
} from "utils/choices.jsx";
import {
  instituteMemberRoles,
} from "utils/roles.jsx";
import {
  connectionRequest,
  removeConnection
} from "services/userDataProvider.js";

const SDGicons = [
  sdg1,
  sdg2,
  sdg3,
  sdg4,
  sdg5,
  sdg6,
  sdg7,
  sdg8,
  sdg9,
  sdg10,
  sdg11,
  sdg12,
  sdg13,
  sdg14,
  sdg15,
  sdg16,
  sdg17
]

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Poppins", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  sdg: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    "& > *": {
      margin: "1px"
    },
    margin: "20px"
  },
  sdgImage: {
    width: "60px",
    height: "60px",
    maxHeight: "100px",
    overflow: "hidden",
    display: "block"
  },
  imgContainer: {
    maxHeight: "400px",
    borderRadius: "8px",
    //border: "#808080",
    //borderStyle: "solid",
    //boxShadow: "2px 2px #808080"
  },
  imgContent: {
    margin: "auto",
    display: "block",
    height: "100%",
    //width: "100%",
    maxHeight: "100vh",
    maxWidth: "100vw",
    padding: "20px"
  },
  imgContentDefault: {
    margin: "auto",
    display: "block",
    height: "200px",
    //maxHeight: "-webkit-fill-available",
    //width: "100%",
    maxWidth: "100vw",
    padding: "20px"
  },
  memberavatar: {
    width: "100px !important",
    margin: "10px"
  },
  filterContainer: {
    zIndex: "999 !important"
  },
  listTitle: {
    backgroundColor: "#c0c1c2",
    color: "#FFFFFF",
    padding: "5px",
    borderRadius: "5px",
    textAlign: "center",
    fontWeight: "400",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: '"Poppins", sans-serif',
  },
  cardTitle: {
    color: "#3C4858",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontSize: "24px",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    },
  },
  cardBody: {
    fontSize: "16px"
  },
  createInstituteButton: {
    textAlign: "right"
  },
  tableSize: {
    width: "-webkit-fill-available",
    margin: "20px"
  },
  placeStyle: {
    marginBottom: "20px"
  },
  requestButtonStyle: {
    marginTop: "20px"
  },
  requestButtonTextStyle: {
    color: "red",
    fontWeight: "bold"
  },
  toggleButtonStyle: {
    marginRight: "8px"
  },
  toggleStyle: {
    display: "flex"
  },
  chipStyle: {
    marginTop: "8px",
    marginRight: "5px"
  },
  message: {
    marginTop: "20px",
    color: "#6f6262a3",
    textAlign: "center"
  },
  institutetypedetails: {
    margin: "20px"
  },
  instituteDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  instituteFollowContainer: {
    alignSelf: "center"
  },
  membersinfo: {
    width: "100%"
  },
  memberAvatar: {
    width: "80px !important",
    aspectRatio: 1,
    overflow: "hidden",
    //display: "block",
    objectFit: "cover",
    //width: "100%",
    maxWidth: "100vw",
    //height: "100%",
    maxHeight: "100vh",
    borderRadius: "50%"
  },
};

const SDGOptions = SDGs.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const tabColors = [
  "#3c4858",
  "#3c4858",
  "#3c4858",
  "#3c4858",
];

const InstituteOptionsComponents = props => {
  return (
    <div style={{ fontWeight: props.data.isAdmin ? "bold" : null }}>
      <components.Option {...props} />
    </div>
  );
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
    this.state = {
      editable: window.location.href.includes('edit'),
      updatedData: {},
      instituteOptions: [],
      instituteMemberUpdate: {},
      open: false,
      invitationPopup: false
    };
  }

  updateInstituteProfile = e => {
    let type_details = this.formSchema && this.formSchema.schema.data();
    let updatedData = this.state.updatedData;
    if (type_details) {
      updatedData.type_details = type_details;
      this.setState({ updatedData: updatedData });
    }
    updateData(
      this.props.axios,
      this.state.userInstituteId,
      this.state.updatedData
    ).then(data => {
      this.setState({ userInstituteData: data });
    });

    Object.keys(this.state.instituteMemberUpdate).forEach((key) => {
      let instituteData = this.state.instituteMemberUpdate[key];
      instituteData["instituteId"] = key.split('-')[0];
      instituteData["userId"] = key.split('-')[1];
      updateRoleData(this.props.axios, instituteData);
    });
  };

  updateInstitute = (instId, userId, data) => e => {
    let instituteData = data;
    instituteData["instituteId"] = instId;
    instituteData["userId"] = userId;
    updateRoleData(this.props.axios, instituteData);
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let updatedData = this.state.updatedData;
      updatedData["avatarFile"] = file;
      this.setState({ updatedData: updatedData });
    }
  };

  handleSwitchChange = name => e => {
    this.setState({ [name]: e.target.checked });
  }

  handleRoleChange = (instId, userId, field) => e => {
    let data = this.state.instituteMemberUpdate || {};
    let instData = data[`${instId}-${userId}`] || { roles: {} };
    instData["roles"][field] = e.target.checked;
    data[`${instId}-${userId}`] = instData;
    this.setState({ instituteMemberUpdate: data });
  };

  handleFilterSelectionChange = type => (values, action) => {
    // console.log("handleFilterSelectionChange", type, values, action);
    if (type === "profilevisibility") {
      let updatedData = this.state.updatedData;
      let value = values.value;
      updatedData["profile_visibility"] = value;
      this.setState({ "profile_visibility": value, updatedData: updatedData });
    } else if (type === "institute") {
      getInstituteData(this.props.axios, values.value).then(
        data => {
          this.setState({
            userInstituteId: values.value,
            userInstituteData: data,
            updatedData: {}
          });
        }
      );
    } else {
      let updatedData = this.state.updatedData;
      let dt = updatedData;
      let tempArray = type.split(".");
      tempArray.slice(0, -1).forEach(key => {
        if (dt.hasOwnProperty(key)) {
          dt = dt[key];
        } else {
          dt[key] = {};
          dt = dt[key];
        }
      });
      let keyName = tempArray[tempArray.length - 1];

      if (Array.isArray(values)) {
        values = values.map(val => val && val.value);
      } else {
        values = values && values.value;
      }
      dt[keyName] = values;
      this.setState({ updatedData: updatedData });
    }
  }

  onChange = name => e => {
    let updatedData = this.state.updatedData;
    let value = e.target.value;
    updatedData[name] = value;
    this.setState({ [name]: value, updatedData: updatedData });
  };

  followInstitute = instituteId => e => {
    followInstitute(this.props.axios, instituteId, true).then(data => {
      // console.log("followInstitute", data);
      let instituteDetails = this.state.userInstituteData;
      instituteDetails.is_following = true;
      this.setState({ userInstituteData: instituteDetails });
    });
  };

  unfollowInstitute = instituteId => e => {
    followInstitute(this.props.axios, instituteId, false).then(data => {
      // console.log("unfollowInstitute", data);
      let instituteDetails = this.state.userInstituteData;
      instituteDetails.is_following = false;
      this.setState({ userInstituteData: instituteDetails });
    });
  };

  cancelRequest = id => {
    removeConnection(this.props.axios, id).then(data => {
      let message = "Connection request canceled";
      this.handleOpen(message);
    });
  };

  componentDidMount() {
    let instituteId = this.props.match.params && this.props.match.params.instituteId;

    if (instituteId === undefined) { this.props.history.push("/institution"); }

    getData(this.props.axios, this.props.user && this.props.user.user_id).then(data => {
      let instituteOptions = [];
      data && data.roles.forEach(roleData => {
        if (roleData.role !== "Contact" && roleData.role !== "Follower" && roleData.role !== "Admin") {
          instituteOptions.push({
            label: `${roleData.institute_name} - ${roleData.role}`,
            value: roleData.institute
          });
        }
      });

      data && data.roles.forEach(roleData => {
        if (roleData.role === "Admin") {
          instituteOptions.forEach(e => {
            if (e.value === roleData.institute) {
              e["isAdmin"] = roleData.approved;
            }
          })
        }
      });

      getInstituteData(this.props.axios, instituteId).then(
        data => {
          this.setState({
            userInstituteId: instituteId,
            userInstituteData: data,
            updatedData: {}
          });
        }
      );

      this.setState({ ...data, instituteOptions: instituteOptions });
    });
  }

  handleApprovalChange = (roleId) => e => {
    let instData = this.state.userInstituteData;
    let updatedData = this.state.updatedData;
    instData.members.forEach(member => {
      if (member.id === roleId) {
        member.approved = e.target.checked;
      }
    });
    updatedData.members = instData.members;
    this.setState({ updatedData: updatedData });
    console.log("satte", this.state);
  }

  renderToggleRoles(instituteId, userId, field, value) {
    let data = this.state.instituteMemberUpdate || {};
    let instData = data[`${instituteId}-${userId}`] || { roles: {} };
    let state = instData && instData["roles"][field];
    if (state === undefined) {
      state = value;
    }
    return (
      <FormControlLabel
        control={
          <Switch
            checked={state === true}
            onChange={this.handleRoleChange(instituteId, userId, field)}
            value="approved"
            color="primary"
          />
        }
      />
    );
  }

  renderInstituteSelector() {
    const instituteOptions = this.state.instituteOptions;
    const { classes } = this.props;
    return (
      <div className={classes.filterContainer}>
        <Select
          options={instituteOptions}
          components={{ InstituteOptionsComponents }}
          onChange={this.handleFilterSelectionChange("institute")}
          placeholder={<span>Select</span>}
        />
      </div>
    );
  }

  renderEditButton() {
    return (
      this.state.userInstituteData.is_admin &&
      this.state.userInstituteData && <FormControlLabel
        control={
          <Switch
            checked={this.state.editable}
            onChange={this.handleSwitchChange('editable')}
            value="editable"
            color="primary"
          />
        }
        label="Turn on edit mode"
      />
    );
  }

  renderInvitationButton() {
    const { classes } = this.props;
    return (
        <div>
          {
            <Button
              className={classes.instituteFollowButton}
              key={9}
              color="secondary"
              startIcon = {<InviteIcon />}
              onClick={() => this.handleModalOpen() }
              >
                Invite
              </Button>
          }
          <Modal
            style={{ margin: "30px" }}
            open={this.state.invitationPopup}
            onClose={this.handleModalClose}
          >
          <Card
            style={{
              backgroundColor: "white",
              margin: "30px",
              width: "auto"
            }}
          >
            <CardBody>
              <GridContainer justify="center">
              <GridItem xs={12} sm={12}>
              <h4 className={classes.discusionTitle}>
                Invite your colleagues
              </h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
              <CustomInput
                labelText={
                <span>
                  Email <small>(required)</small>
                </span>
                }
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <ContactMail className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
              </GridItem>

              <GridItem
              style={{ marginTop: "30px", display: "flex" }}
              xs={12}
              sm={12}
              md={12}
              >
              <GridItem style={{ textAlign: "end" }} xs={6} sm={6} md={6}>
                <Button
                  type="button"
                  color="rose"
                  onClick={this.handleModalClose}
                >
                  Cancel
                </Button>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  type="button"
                  color="success"
                  onClick={this.handleModalClose}
                >
                  Send
                </Button>
              </GridItem>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          </Modal>
        </div>
    );
  }

  renderInstituteAvatar() {
    const { classes } = this.props;
    const instituteDetails = this.state.userInstituteData;
    return (
      // <CardAvatar project  className={classes.imgContainer}>
       <div className={classes.imgContainer}> {instituteDetails.avatar ? (
          <img
            src={`${MEDIA_ROOT}${instituteDetails.avatar}`}
            alt="..."
            className={classes.imgContent}
          />
        ) : (
          <img
          src={instituteDefaultAvatar}
          alt="..."
          className={classes.imgContentDefault}
        />
        )
        } </div>
      // </CardAvatar>
    );
  }

  renderInstituteDetailsForm(instituteType) {
    switch (instituteType) {
      case "Financial Institution":
        this.formSchema = FinancialInstituteFormSchema;
        break;
      case "Investor/Asset manager":
        this.formSchema = InvestorFormSchema;
        break;
      case "Technology/Service Provider":
        this.formSchema = TechnologyProviderFormSchema;
        break;
      case "NGO":
        this.formSchema = NGOFormSchema;
        break;
      case "Governamental agencies":
        this.formSchema = null;
        break;
      case "Technical assistance providers":
        this.formSchema = TechnicalAssistanceFormSchema;
        break;
      case "Academia":
        this.formSchema = AcademiaFormSchema;
        break;
      default:
    }
    if (this.formSchema != null) {
      let formData = this.state.userInstituteData && this.state.userInstituteData.type_details;
      return <CustomForm formSchema={this.formSchema} data={formData} />;
    } else {
      return null;
    }
  }

  sortMembers = (members, roles) => {
    let users = {};
    let membersList = [];
    members.forEach(userData => {
      let user = users[userData.user.id];
      if (user === undefined) {
        user = userData;
      }
      let existingRoles = user.roles || {};
      existingRoles[userData.role] = userData.approved;
      roles.forEach(roleData => {
        if (roleData.institute === userData.institute && userData["id"] === roleData["user"]) {
          existingRoles[roleData["role"]] = roleData["approved"];
        }
      });
      user.roles = existingRoles;
      users[userData.user.id] = user;
    });
    Object.keys(users).forEach(key => membersList.push(users[key]));
    // console.log("membersList", membersList);
    return membersList;
  }


  renderInstituteTabsEditable() {
    const { classes } = this.props;
    const instituteDetails = this.state.userInstituteData;
    if (instituteDetails !== undefined) {
      return (
        <Card chart className={classes.cardHover}>
          <CardHeader className={classes.cardHeaderHover}>
            {this.renderInstituteAvatar()}
            <div>
              <Input type="file" onChange={this.onFileLoad} />
              <GridItem xs={12} sm={12} md={12}>
                {this.renderEditButton()}

                {this.renderInvitationButton()}
              </GridItem>
            </div>
          </CardHeader>
          <CardBody >
            <NavPills
              tabColors={tabColors}
              vertical={{
                tabsGrid: { xs: 12, sm: 12, md: 12 },
                contentGrid: { xs: 12, sm: 12, md: 12 }
              }}
              tabs={[
                {
                  tabButton: "About the Institute",
                  tabContent: this.renderAboutEditable(this.state.userInstituteData)
                },
                {
                  tabButton: "Discussions",
                },
                {
                  tabButton: "Documents",
                },
              ]}
            />
          </CardBody>
        </Card>
      );
    }
    return null;
  }

  renderAboutEditable(instituteDetails) {
    const { classes } = this.props;
    const members = (instituteDetails && instituteDetails.members && this.sortMembers(instituteDetails.members, this.state.roles)) || [];
    return (
      <div>
        <Accordion
          active={0}
          collapses={[
            {
              title: "Mission & Vision",
              content: (
                <div className={classes.tableSize}>
                  <CustomInput
                    labelText="Institute Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: instituteDetails.name,
                      onChange: this.onChange("name")
                    }}
                  />
                  <CustomInput
                    labelText="Institute Mission"
                    id="mission"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: instituteDetails.mission,
                      onChange: this.onChange("mission")
                    }}
                  />
                  <CustomInput
                    labelText="Institute Vision"
                    id="vision"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: instituteDetails.vision,
                      onChange: this.onChange("vision")
                    }}
                  />
                  <div>
                    {instituteDetails.address && (
                      <>
                        <Place />
                        {instituteDetails.address.city_name},{" "}
                        {instituteDetails.address.country_name}
                      </>
                    )}
                  </div>
                </div>
              )
            },
            {
              title: "Sustainable Development Goals",
              content: (
                <div className={classes.sdg}>
                  <Select
                    isMulti
                    defaultValue={instituteDetails.sustainable_development_goals.map(dt => {
                      return {
                        label: dt,
                        value: dt
                      };
                    })}
                    placeholder={<span>SDG</span>}
                    onChange={this.handleFilterSelectionChange("sustainable_development_goals")}
                    options={SDGOptions}
                    className={classes.filter}
                  />
                </div>
              )
            },
            {
              title: "Basic Information",
              content: (
                <div className={classes.tableSize}>
                  <div className={classes.birthinfo}>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={
                        [
                          <p style={{ fontWeight: "bold" }}>Info</p>,
                          <p style={{ fontWeight: "bold" }}>Details</p>
                        ]}
                      tableData={[
                        [
                          "Short Name",
                          <CustomInput
                            labelText="Short Name"
                            id="short_name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              defaultValue: instituteDetails.short_name,
                              onChange: this.onChange("short_name")
                            }}
                          />
                        ],
                        [
                          "Website",
                          <CustomInput
                            labelText="Website"
                            id="website_url"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              defaultValue: instituteDetails.website_url,
                              onChange: this.onChange("website_url")
                            }}
                          />
                        ],
                        [
                          "Established on",
                          <CustomInput
                            labelText="Established on"
                            id="date_of_establishment"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "date",
                              defaultValue: instituteDetails.date_of_establishment,
                              onChange: this.onChange("date_of_establishment")
                            }}
                          />
                        ],
                        [
                          "Employees:",
                          <CustomInput
                            labelText="Employees"
                            id="total_number_of_staff"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              defaultValue: instituteDetails.total_number_of_staff,
                              onChange: this.onChange("total_number_of_staff")
                            }}
                          />
                        ],
                        [
                          "Profile Visibility",
                          <Select
                            options={[{"label": "Public", "value": "public"}, {"label": "Private", "value": "private"}]}
                            onChange={this.handleFilterSelectionChange("profilevisibility")}
                            placeholder={<span>{instituteDetails.profile_visibility || "Select"}</span>}
                          />
                        ]
                      ]}
                    />
                  </div>
                </div>
              )
            },
            {
              title: "More Details",
              content: (
                <div className={classes.institutetypedetails}>
                  {this.renderInstituteDetailsForm(this.state.updatedData.type || instituteDetails.type)}
                </div>
              )
            },
            {
              title: "Members",
              content: (
                <div className={classes.tableSize}>
                  <div className={classes.membersinfo}>
                    {members.map(member => {
                      let memberRoles = Object.keys(member.roles).filter(role => !["Admin", "Contact"].includes(role));
                      let memberRole = "";
                      if (memberRoles.length > 0) {
                        memberRole = memberRoles[0];
                      }
                      return (
                        <Card style={{padding:"10px"}}>
                          <GridContainer>
                            <GridItem xs={2} sm={4} md={4}>
                              <img
                                className={classes.memberavatar}
                                src={
                                  member.user.avatar
                                    ? `${MEDIA_ROOT}${member.user.avatar}`
                                    : defaultAvatar
                                }
                                alt="..."
                              />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                              <h4>{member.user.name}</h4>
                              {Object.keys(member.roles).join(", ")}
                              {this.renderRequestConnectionButton(member, this.state.id)}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} >
                              <h4 className={classes.listTitle}>Manage Member Roles</h4>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} align="center" style={styles.toggleStyle}>
                              <h4 className={classes.toggleButtonStyle}>Approved(Admin)</h4>
                              {
                                this.renderToggleRoles(
                                  instituteDetails.id,
                                  member.user.id,
                                  "Admin",
                                  (member.roles["Admin"] === undefined) ? false : member.roles["Admin"]
                                )
                              }
                            </GridItem>

                            <GridItem xs={12} sm={4} md={4} align="center" style={styles.toggleStyle}>
                              <h4 className={classes.toggleButtonStyle}>Approved(Contact)</h4>
                              {
                                this.renderToggleRoles(
                                  instituteDetails.id,
                                  member.user.id,
                                  "Contact",
                                  (member.roles["Contact"] === undefined) ? false : member.roles["Contact"]
                                )
                              }
                            </GridItem>

                            <GridItem xs={12} sm={4} md={4} align="center" style={styles.toggleStyle}>
                              <h4 className={classes.toggleButtonStyle}>Approved({memberRole})</h4>
                              {
                                this.renderToggleRoles(
                                  instituteDetails.id,
                                  member.user.id,
                                  memberRole,
                                  (member.roles[memberRole] === undefined) ? false : member.roles[memberRole]
                                )
                              }
                            </GridItem>
                          </GridContainer>
                        </Card>
                      )
                    })}
                  </div>
                </div>
              )
            }
          ]} />
      </div>
    );
  }

  renderRequestConnectionButton(member, userId) {
    const { classes } = this.props;
    if (member.user.id !== userId) {
      return (
        <div className={classes.requestButtonStyle}>
          {member.connection_info &&
            member.connection_info.sent ? (
            <div className={classes.requestButtonTextStyle}>Request already sent</div>
          ) : (
            <Button
              key={member.user.id}
              color="success"
              onClick={() => this.requestButton(member.user.id)}
              >
              Request
            </Button>
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  isMember(members, id) {
    let memberArray = members.filter(e => e["user"]["id"] === id)
    return !(memberArray.length === 0)
  }

  getAccordionContent(instituteDetails, members) {
    const { classes } = this.props;
    let accordionData = [];
    accordionData.push(
      {
        title: 'Mission & Vision',
        content: (
          <div className={classes.placeStyle}>
            {instituteDetails.mission && (
              <Quote text={instituteDetails.mission} author="Mission" />
            )}
            {instituteDetails.vision && (
              <Quote text={instituteDetails.vision} author="Vision" />
            )}
            <div className={classes.placeStyle}>
              {instituteDetails.address && (
                <>
                  <Place />
                  {instituteDetails.address.city_name},{" "}
                  {instituteDetails.address.country_name}
                </>
              )}
            </div>
          </div>
        )
      },
      {
        title: 'Sustainable Development Goals',
        content: (
          <div className={classes.sdg}>
            {instituteDetails.sustainable_development_goals.map(
              (sdg, idx) => {
                return (
                  <img
                    className={classes.sdgImage}
                    src={SDGicons[parseInt(sdg.replace("SDG", "")) - 1]}
                    key={sdg}
                    alt={sdg}
                  />
                );
              }
            )}
          </div>
        )
      },
      {
        title: "Basic Information",
        content: (
          <div className={classes.tableSize}>
            <Table
              //tableHead={[<p style={{ fontWeight: "bold" }}>Info</p>, <p style={{ fontWeight: "bold" }}>Details</p>]}
              tableData={[
                [
                  "Short Name:",
                  instituteDetails.short_name
                ],
                [
                  "Website:",
                  <a href={instituteDetails.website_url}>
                    {instituteDetails.website_url}
                  </a>
                ],
                [
                  "Established on:",
                  instituteDetails.date_of_establishment
                ],
                [
                  "Employees:",
                  instituteDetails.total_number_of_staff
                ],
                [
                  "Legal Status/Type:",
                  instituteDetails.type_details && instituteDetails.type_details.legal_status
                ],
                [
                  "Focus Areas:",
                  instituteDetails.type_details && instituteDetails.type_details.focus_areas && instituteDetails.type_details.focus_areas.map(focusArea => (
                    <Chip
                      // icon={<FaceIcon />}
                      label={focusArea}
                      // onClick={handleClick}
                      // onDelete={handleDelete}
                      variant="outlined"
                    />
                  ))
                ],
                instituteDetails.type_details && instituteDetails.type_details.working_regions ? [
                  "Regions:",
                  instituteDetails.type_details.working_regions.map(region => (
                    <Chip
                      // icon={<FaceIcon />}
                      label={region}
                      // onClick={handleClick}
                      // onDelete={handleDelete}
                      variant="outlined"
                    />
                  ))
                ]:[],
                [
                  "Impact Metrics:",
                  instituteDetails.type_details && instituteDetails.type_details.impact_metrics && instituteDetails.type_details.impact_metrics.map(impactMetric => (
                    <Chip
                      // icon={<FaceIcon />}
                      label={impactMetric}
                      // onClick={handleClick}
                      // onDelete={handleDelete}
                      variant="outlined"
                    />
                  ))
                ],
                [
                  "Profile Visibility:",
                  PROFILE_VISIBILITY_CHOICES[instituteDetails.profile_visibility]
                ]]}
            />
          </div>
        )
      }
    );

    if (this.props.match.url.includes("institutes")) {
      accordionData.push(
        {
          title: "Members",
          content: (
            <div className={classes.membersinfo}>
              <Table
                tableHead={[" ", "Name", "Role/Position", "Connect"]}
                tableData={
                instituteDetails.members &&
                instituteDetails.members.filter(
                  member => member.role !== instituteMemberRoles.Admin
                ).map(member => [
                  <div style={{display: "grid"}}>
                    <img
                      key={member.user.id}
                      className={classes.memberAvatar}
                      src = {
                        member.user.avatar
                        ? `${MEDIA_ROOT}${member.user.avatar}`
                        : defaultAvatar
                      }
                      alt="..."
                    />
                  </div>,
                  <div>
                    <a style={{ marginLeft: "15px", cursor: "pointer" }} href={`/member-profile/${member.user.id}`}>
                      {member.user.name}
                    </a>
                   </div>,
                  member.role,
                  //member.approved ? "Yes" : "No",
                  member.user.id === this.state.id ?
                  (<div> </div>):(
                  member.connection_info &&
                  member.connection_info.sent ? (
                    <div> </div>
                  ) : (
                    <Button
                      key={member.user.id}
                      color="info"
                      startIcon = {<RequestIcon />}
                      onClick={() => this.requestButton(member.user.id)}
                    >
                      Request
                    </Button>
                  ))])
                }
              />
            </div>
          )});
    } else if (this.isMember(members, this.state.id)) {
        accordionData.push(
          {
            title: "Members",
            content: (
              <div className={classes.tableSize}>
                <div className={classes.membersinfo}>
                  {members.map(member => {
                    let memberRoles = Object.keys(member.roles).filter(role => !["Admin", "Contact"].includes(role));
                    let memberRole = "";
                    if (memberRoles.length > 0) {
                      memberRole = memberRoles[0];
                    }
                    let roles = [];
                    roles.push({ role: memberRole, value: member.roles[memberRole] === true ? "Yes" : "No" })
                    roles.push({ role: "Admin", value: member.roles["Admin"] === true ? "Yes" : "No" })
                    roles.push({ role: "Contact", value: member.roles["Contact"] === true ? "Yes" : "No" })
                    return (
                      <Card>
                        <GridContainer>
                          <GridItem xs={12} sm={4} md={4}>
                            <img
                              className={classes.memberavatar}
                              src={
                                member.user.avatar
                                  ? `${MEDIA_ROOT}${member.user.avatar}`
                                  : defaultAvatar
                              }
                              alt="..."
                            />
                          </GridItem>
                          <GridItem xs={12} sm={8} md={8}>
                            <h4>{member.user.name}</h4>
                            <div className={classes.chipStyle}>
                              {
                              roles.map(ele => (
                              <Chip
                                label={ele.role}
                                color={ele.value === "Yes" ? "secondary" : "primary"}
                              variant="outlined"
                            />
                          ))}
                        </div>
                        {this.renderRequestConnectionButton(member, this.state.id)}
                      </GridItem>
                    </GridContainer>
                  </Card>
                )
              })}
            </div>
          </div>
        )
      })
    }
    return accordionData;
  }

  requestButton = id => {
    if (id === this.props.user.user_id) {
      let message = "You cannot send request to yourself";
      this.handleOpen(message);
      return false;
    }
    connectionRequest(this.props.axios, { targetUserId: id }).then(data => {
      let message = "Connection request send";
      this.handleOpen(message);
      window.location.reload();
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = message => {
    this.setState({ open: true, message: message });
  };

  renderAbout(instituteDetails) {
    const members = (instituteDetails && instituteDetails.members && this.sortMembers(instituteDetails.members, this.state.roles)) || [];
    let accordionData = this.getAccordionContent(instituteDetails, members);
    return (
      <div>
        <Accordion
          active={0}
          collapses={
            accordionData.map(e => {
              return {
                title: e.title,
                content: e.content
              }
            })
          }
        />
      </div>
    );
  }

  renderInstituteTabs() {
    const { classes } = this.props;
    const instituteDetails = this.state.userInstituteData;
    const members = (instituteDetails && instituteDetails.members && this.sortMembers(instituteDetails.members, this.state.roles)) || [];
    const isUserMember = this.isMember(members, this.state.id)
    if (instituteDetails !== undefined) {
      return (
        <Card chart className={classes.cardHover}>
          <CardHeader className={classes.cardHeaderHover}>
            {this.renderInstituteAvatar()}
            <div className={classes.instituteDetailsContainer}>
            <h1 style={styles.cardTitleBlack}>{instituteDetails.name}</h1>
            {this.props.match.url.includes("institutes") ?
              (<div className={classes.instituteFollowContainer}>
                {instituteDetails.is_following ? (
                  <Button
                    className={classes.instituteFollowButton}
                    startIcon={<UnfollowIcon />}
                    onClick={this.unfollowInstitute(instituteDetails.id)}
                  >
                    Stop following
                  </Button>
                  ) : (
                  <Button
                    onClick={this.followInstitute(instituteDetails.id)}
                    className={classes.instituteUnFollowButton}
                    startIcon={<FollowIcon />}
                  >
                    Follow Institute
                  </Button>
                  )}
                </div>
                ) : null}
              </div>
              <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                {this.renderEditButton()}
              </GridItem>
                { isUserMember ?
                    <GridItem xs={6} sm={6} md={6} style={{textAlign: 'end', alignItems: 'end'}}>
                      {this.renderInvitationButton()}
                    </GridItem>
                  : null
                }
              </GridContainer>
          </CardHeader>
          <CardBody>
            <NavPills
              tabColors={tabColors}
              vertical={{
                tabsGrid: { xs: 12, sm: 12, md: 12 },
                contentGrid: { xs: 12, sm: 12, md: 12 }
              }}
              tabs={[
                {
                  tabButton: "About the Institute",
                  tabContent: this.renderAbout(this.state.userInstituteData)
                },
                {
                  tabButton: "Projects",
                  tabContent: this.renderProjectDetails()
                }
              ]}
            />
          </CardBody>
        </Card>
      );
    }
  }

  handleModalOpen = e => {
    //e.preventDefault();
    this.setState({ invitationPopup: true });
  };

  handleModalClose = e => {
    e.preventDefault();
    this.setState({ invitationPopup: false });
  };

  renderProjectDetails() {
    if (this.state.userInstituteData.projects
      && this.state.userInstituteData.projects.length > 0) {

      const { classes } = this.props;

      return (
        <Card>
          <div className={classes.tableSize}>
            <div className={classes.birthinfo}>
              <Table
                tableHead={
                  [
                    <p style={{ fontWeight: "bold" }}>Project Name</p>,
                    <p style={{ fontWeight: "bold" }}>Type</p>,
                    <p style={{ fontWeight: "bold" }}>SDGs</p>
                  ]}
                tableData={
                  this.state.userInstituteData.projects.map(projectData => ([
                    <a href={`/projects/${projectData.id}`}>{projectData.name}</a>,
                    projectData.project_type,
                    this.renderSDGs(projectData.sustainable_development_goals)
                  ]))
                }
              />
            </div>
          </div>

        </Card>
      );
    } else {
      return (
        <div style={styles.message}>
          <h3>No Projects Available</h3>
        </div>
      );
    }
  }

  renderSDGs(sdgs) {
    return (
      sdgs.map(element => (
        SDGs[element] !== undefined ?
        <Chip
          style={styles.chipStyle}
          label={SDGs[element]===undefined?"":SDGs[element].text}
        /> :
        null
      )))
  }

  render() {
    const { editable } = this.state;
    const userInstituteData = this.state.userInstituteData;
    if (userInstituteData === undefined) {
      return null;
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {!editable ?
            this.renderInstituteTabs() :
            this.renderInstituteTabsEditable()
          }
        </GridItem>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.open}
          onClose={() => this.handleClose()}
          message={this.state.message}
          autoHideDuration={2000}
          action={<div style={{ background: "green" }} />}
        />
        <GridItem xs={12} sm={12} md={12}>
          {this.state.editable && this.state.userInstituteData &&
            <Button color="info" onClick={this.updateInstituteProfile} startIcon={<UpdateIcon />}>
              Update Profile
        </Button>
          }
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(UserProfile);
