import Main from "layouts/Main/Main.jsx";
import Registration from "../layouts/Registration/Registration";
import SignIn from "layouts/SignIn/SignIn.jsx";
import ResetPassword from "layouts/ResetPassword/ResetPassword.jsx";
import StartPage from "layouts/StartPage/StartPage.jsx";
import EventDetailsPage from "layouts/EventDetails/EventDetails.jsx";
import ErrorPage from "layouts/ErrorPage/ErrorPage.jsx";

const indexRoutes = [
  { path: "/start", component: StartPage },
  { path: "/signin", component: SignIn },
  { path: "/registration", component: Registration },
  { path: "/resetpassword/:uid64/:token", component: ResetPassword },
  { path: "/resetpassword", component: ResetPassword },
  { path: "/error", component: ErrorPage },
  { path: "/events/:eventId", component: EventDetailsPage },
  { path: "/", component: Main }
];

export default indexRoutes;
