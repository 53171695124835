/* eslint-disable */
import React, { useState, Component } from 'react';
import PropTypes from "prop-types";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Footer from "components/Footer/Footer.jsx";

//New File import begin here..
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FindInst from './WizardSteps/FindInst';
import UserDetails from './WizardSteps/UserDetails';
import Summary from './WizardSteps/Summary';
import { signup } from "services/authProvider.js";
import { Alert, AlertTitle } from '@material-ui/lab';
import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';

const url_pattern = /^[^"]+\.[^"]+$/;
const email_pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const Registration = () => {

  let styles = {
    hedera: {
      fontFamily: '"Poppins", sans-serif',
    },
    bottomBox: {
      marginBottom: "15px",
      bottom: 0,
      width: "100%",
      position: 'absolute',
    }
  }

  let useStyles = makeStyles(signinStyle);

  const [formData, setFormData] = useState(
    {
      inst_new: false,
      inst_id: 0,
      inst_name: '',
      inst_type: '',
      inst_sdg: [],
      inst_region: '',
      inst_country: '',
      inst_city: '',
      inst_postalcode: '',
      inst_street: '',
      inst_website: '',
      privacy_policy: false,
    })

  const [userFormData, setUserFormData] = useState(
    {
      inst_role: undefined,
      inst_user_firstname: undefined,
      inst_user_lastname: undefined,
      inst_email: undefined,
      inst_pwd: undefined,
      inst_repwd: undefined
    })
  let steps = ['Institution Data', 'User Details', 'Summary'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <FindInst formData={formData} setFormData={setFormData} />;
      case 1:
        return <UserDetails userFormData={userFormData} setUserFormData={setUserFormData} />;
      case 2:
        return <Summary summaryData={{ instituteFormData: formData, userData: userFormData }} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const onSubmitForm = () => {
    document.body.classList.add("loading-indicator");
    let apiData = {
      first_name: userFormData.inst_user_firstname,
      last_name: userFormData.inst_user_lastname,
      email: userFormData.inst_email,
      password: userFormData.inst_pwd,
      confirm_password: userFormData.inst_repwd,
      create_new_institute: formData.inst_new,
      user_role: userFormData.inst_role
    }

    if (formData.inst_new) {
      apiData = {
        ...apiData,
        instituteData: {
          name: formData.inst_name,
          type: formData.inst_type,
          sustainable_development_goals: formData.inst_sdg,
          address: {
            country: formData.inst_country,
            region: formData.inst_region,
            city: formData.inst_city,
            zip_code: formData.inst_postalcode,
            street: formData.inst_street
          },
          website_url: formData.inst_website
        }
      }
    } else {
      apiData = {
        ...apiData,
        instituteData: null,
        institute: formData.inst_id
      }
    }

    return signup(apiData)
      .then(data => {
        
        document.body.classList.remove("loading-indicator");
        setActiveStep(activeStep + 1);
      }).catch(error => {
        setApiError(true);
        document.body.classList.remove("loading-indicator");
        const data = error.response && error.response.data;
        let messages = "Error Occurred"
        if (typeof data === "object" && Array.isArray(data) === false) {
          Object.keys(data).forEach(key => {
            messages = `${messages}, ${data[key]}`;
          });
        } else if (typeof data === "object") {
          messages = data.join(", ");
        }
        alert(messages);
        throw error;
      });
  }

  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const handleNext = () => {
    const isFormValidated = isValid();
    if (isFormValidated) {
      if (activeStep === 2) {
        onSubmitForm();
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    setIsValid(isFormValidated)

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setApiError(false);
  };

  const goToSignIn = () => {
    window.open("/signin", "_self");
  };

  const [is_valid, setIsValid] = useState(true);
  const [api_error, setApiError] = useState(false);

  const isValid = () => {
        
    switch (activeStep) {
      case 0:
        if (formData.inst_new) {
          if (
            formData.inst_name == null || (formData.inst_name && formData.inst_name.length < 3)
            || formData.inst_type.length === 0
            || formData.inst_sdg.length === 0
            || formData.inst_city == null || (formData.inst_city && formData.inst_city.length < 3)
            || formData.inst_street == null || (formData.inst_street && formData.inst_street.length < 3)
            || formData.inst_region == null || (formData.inst_region && formData.inst_region.length < 3)
            || formData.inst_postalcode == null || (formData.inst_postalcode && formData.inst_postalcode.length < 3)
            || !url_pattern.test(formData.inst_website) || formData.privacy_policy == false)
            return false;
        } else {
          if (formData.inst_id === 0 || (formData.privacy_policy == false || formData.privacy_policy == null)) {
            return false;
          } else {
            return true;
          }
        }
        break;
      case 1:
        if (!email_pattern.test(userFormData.inst_email)
          || userFormData.inst_user_firstname == null || (userFormData.inst_user_firstname && userFormData.inst_user_firstname.length < 3)
          || userFormData.inst_user_lastname == null || (userFormData.inst_user_lastname && userFormData.inst_user_lastname.length < 1)
          || userFormData.inst_pwd == null
          || userFormData.inst_pwd !== userFormData.inst_repwd
          || userFormData.inst_pwd && userFormData.inst_pwd.length <= 8
          || formData.privacy_policy == false) {
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  }

  return (
    <div className={classes.root}>
      <div className={classes.paperContainer}>
        <Paper className={classes.paperSubsection}>
          <div className="logo_name" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
            <img src={require('../../assets/img/hedera-logo.png')} alt="Hedera Logo" width={150} />
          </div>
          <Stepper activeStep={activeStep} className={classes.stepper}  >
            {steps.map((label) => (
              <Step key={label} sx={{ bgcolor: "secondary", backgroundColor: "secondary" }} >
                <StepLabel >{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {is_valid === false ?
              <Alert style={{ ...styles.hedera }} severity="error">
                <AlertTitle style={{ ...styles.hedera }}>Error!</AlertTitle>
                Please revise the entered data.
              </Alert> : null}
            {api_error === true ?
              <Alert style={{ ...styles.hedera }} severity="error">
                <AlertTitle style={{ ...styles.hedera }}>Error!</AlertTitle>
                Invalid Data!
              </Alert> : null}
            {activeStep === steps.length && api_error !== true ? (
              <div>
                <Typography gutterBottom style={{ ...styles.hedera, fontSize: 20 }} >
                  Ready to Go.
                </Typography>
                <Typography style={{ ...styles.hedera, fontSize: 16, color: "#6F4573" }}>
                  Your account has been successfully created. Check your email for confirmation link.
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToSignIn}
                  className={classes.button}
                  disableElevation
                  styles={{ ...styles.hedera, alignSelf: "center" }}
                >
                  Sign In
                </Button>
              </div>
            ) : (
              <div>
                {getStepContent(activeStep)}
                {activeStep === 0 && (
                  <Box sx={{ m: -1.5 }}>
                    <FormControl required error={!formData.privacy_policy}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.privacy_policy}
                            error={!formData.privacy_policy}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                privacy_policy: e.target.checked,
                              })
                            }
                          />
                        }
                        label={
                          <Typography>
                            Confirm that you have read our{" "}
                            <Link href="https://hedera.online/download/gdpr/hedera_privacy_statement-en.pdf" target="_blank">
                              privacy policy
                            </Link>
                          </Typography>
                        }
                      />
                      <Box sx={{ mt: -2 }}>
                        {is_valid == false && formData.privacy_policy == false ? (
                          <FormHelperText style={{ fontFamily: '"Poppins", sans-serif' }}>
                            Please check the box to confirm that you have read our privacy policy
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </FormControl>
                  </Box>
                )}
                <div className={classes.buttons}>
                  <Button variant="outlined" onClick={goToSignIn} className={classes.button} style={{ ...styles.hedera, backgroundColor: "#fff", color: "#000", height: 40, alignItems: "center" }}>
                    Have an account? Sign in
                  </Button>

                  {activeStep !== 0 && (
                    <Button onClick={handleBack} variant="contained" disableElevation className={classes.button} style={{ ...styles.hedera, color: "black" }}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disableElevation
                    styles={styles.hedera}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Paper>
      </div>
      <Footer />
    </div>
  );

}

Registration.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(signinStyle)(Registration);
