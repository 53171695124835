/* eslint-disable react/prop-types */
import React from "react";
import { Map, Popup, TileLayer, CircleMarker } from "react-leaflet";
import L from "leaflet";
import "./leaflet.css";
//import icon from 'images/marker-icon-2x.png';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

/// variable para cargue de icono del marcador
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

//// estilo del container para el mapa
const styles = {
  wrapper: {
    height: "95%",
    width: "100%",
    margin: "0",
    display: "flex"
  },
  map: {
    flex: 1,
    borderRadius: "5px"
  }
};


const Moves = props => {
  return (
    <div style={styles.wrapper}>

      {/* Constructor del mapa */}
      <Map style={styles.map} center={props.center} zoom={props.zoom}>
        {/* Capa con plantilla */}
        <TileLayer url={props.url} />
        {/* Marcador de posicionamiento */}
        {props.dataPoints.length > 0 &&
          props.dataPoints.map((dataPoint, idx) => (
            <CircleMarker
              center={[dataPoint.latitude, dataPoint.longitude]}
              weight={3}
              fillOpacity={0.5}
              color= {props.colors[idx]}
            >
              <Popup>
                <a href={"institutes/" + dataPoint.id}>{dataPoint.name}</a>
                <br />
                {dataPoint.type}
                <br />
                {/*<small>{dataPoint.address.city_name}, {dataPoint.address.country_name} </small>*/}
                <br />
                <a href={dataPoint.url} target='_blank' rel="noopener noreferrer">Homepage</a>

                
              </Popup>
            </CircleMarker>
            
          ))}
      </Map>



    </div>
  );
};

//// Propiedades del mapa
Moves.defaultProps = {
  center: [52.5103175, 13.3275342],
  zoom: 5,
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  
};

export default Moves;
