/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { getContacts, getData } from "services/userDataProvider.js";
import CustomChat from "../../components/Chat/CustomChat";

class ChatPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: false,
      updatedContact: false,
      allContacts: [],
      targetUserId: "",
      userData: {},
      messagePerson: {}
    };
  }

  componentDidMount() {
    getContacts(this.props.axios).then(data => {
      let allContacts = data.results;
      let filterdContact = [];
      filterdContact = allContacts.filter(i => {
        if (i.accepted) {
          // show this contact
          return i;
        } else {
          return null;
        }
      });
      this.setState({
        allContacts: filterdContact,
        updatedContact: true
      });
    });
    getData(this.props.axios, this.props.user.user_id).then(data => {
      this.setState({
        userData: data,
        updated: true
      });
    });
  }

  render() {
    const user = this.props.user;
    // console.log("this.props", this.props);
    const { allContacts, updated, userData, updatedContact } = this.state;
    return (
      updated &&
      updatedContact && (
        <CustomChat
          currentUser={user}
          allContacts={allContacts}
          userAvatar={userData.avatar}
          messagePerson={
            this.props.location.state
              ? this.props.location.state.messagePerson
              : {}
          }
        />
      )
    );
  }
}

export default ChatPage;
