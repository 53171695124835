import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";

import { staticData } from "config.js";

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <span>
        &copy; {1900 + new Date().getYear()}{" "}
        {/*<a href={staticData.company.url} className={classes.a}>*/}
        {staticData.company.name}
        {/*</a>*/}, {staticData.company.tagline}
      </span>
      <div className={classes.left}>
        <List className={classes.list}>
          <ListItem >
            <a href="https://hedera.online" >
              HOME
            </a>
            <a href="/about" >
              ABOUT
            </a>
            <a href="https://hedera.online" >
              IMPRINT
            </a>
          </ListItem>
        </List>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
