import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getAllQuizzes
} from "../../services/quizDataProvider";
import SurveysTable from "../../components/Surveys/SurveysTable";


function useSurveys(axios) {
  const [isLoading, setIsLoading] = useState(false);
  const [surveyList, setSurveyList] = useState([]);

  useEffect(() => {
    setIsLoading(true);    
    // get all quizzes
    getAllQuizzes(axios).then(allProjects => {
      // set the list of available surveys
      const allQuizzes = [];
      allProjects && allProjects.forEach(projectData => {
        const {name, quizzes, user_permissions} = projectData;
        quizzes && quizzes.forEach(quiz => {
          allQuizzes.push({
            ...quiz,
            userPermission: user_permissions,
            projectName: name,
          });
        });
      });
      setSurveyList(allQuizzes);
      setIsLoading(false);
    });
  }, []);
  
  return {
    isLoading,
    surveyList,
    setSurveyList
  };
}

const useStyles = makeStyles({

  wrapper:{
    marginTop:'100px'
  },

  text:{
    width:'100%',
    textAlign:'center'
  }
});


const Surveys = (props) => {
  const { surveyList, isLoading, setSurveyList}=useSurveys(props.axios)
  const classes=useStyles()

  if(isLoading){
    return (
      <div className={classes.wrapper}>
        <h5 className={classes.text}>Loading projects and survey data ...</h5>
      </div>
    )
  }

  return (
    <SurveysTable
      axios={props.axios}
      history={props.history}
      surveyList={surveyList}
      setSurveyList={setSurveyList}
    />
  );
};

export default Surveys;
