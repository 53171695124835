export const instituteMemberRoles = {
    Admin: "Admin"
};

export const ProjectPermissions = {
    VIEW_PROJECT: "VIEW_PROJECT",
    VIEW_PROJECT_DETAILS: "VIEW_PROJECT_DETAILS",
    CREATE_PROJECT: "CREATE_PROJECT",
    UPDATE_PROJECT: "UPDATE_PROJECT",
    DELETE_PROJECT: "DELETE_PROJECT",
    UPDATE_PROJECT_STYLES: "UPDATE_PROJECT_STYLES",

    CREATE_PROJECT_TOPICS: "CREATE_PROJECT_TOPICS",
    UPDATE_PROJECT_TOPICS: "UPDATE_PROJECT_TOPICS",
    VIEW_PROJECT_TOPICS: "VIEW_PROJECT_TOPICS",
    DELETE_PROJECT_TOPICS: "DELETE_PROJECT_TOPICS",

    VIEW_PROJECT_PARTICIPANTS: "VIEW_PROJECT_PARTICIPANTS",
    CREATE_PROJECT_PARTICIPANTS: "CREATE_PROJECT_PARTICIPANTS",
    UPDATE_PROJECT_PARTICIPANTS: "UPDATE_PROJECT_PARTICIPANTS",
    DELETE_PROJECT_PARTICIPANTS: "DELETE_PROJECT_PARTICIPANTS",
    DELETE_PROJECT_ADMIN: "DELETE_PROJECT_ADMIN",

    VIEW_PROJECT_SURVEYS: "VIEW_PROJECT_SURVEYS",
    CREATE_PROJECT_SURVEYS: "CREATE_PROJECT_SURVEYS",
    UPDATE_PROJECT_SURVEYS: "UPDATE_PROJECT_SURVEYS",
    DELETE_PROJECT_SURVEYS: "DELETE_PROJECT_SURVEYS",
    FILL_PROJECT_SURVEYS: "FILL_PROJECT_SURVEYS",
    ACTIVATE_PROJECT_SURVEYS: "ACTIVATE_PROJECT_SURVEYS",
    DEACTIVATE_PROJECT_SURVEYS: "DEACTIVATE_PROJECT_SURVEYS",
    EXPORT_PROJECT_SURVEY_RESULTS: "EXPORT_PROJECT_SURVEY_RESULTS",

    VIEW_PROJECT_DOCUMENTS: "VIEW_PROJECT_DOCUMENTS",
    CREATE_PROJECT_DOCUMENTS: "CREATE_PROJECT_DOCUMENTS",
    UPDATE_PROJECT_DOCUMENTS: "UPDATE_PROJECT_DOCUMENTS",
    DELETE_PROJECT_DOCUMENTS: "DELETE_PROJECT_DOCUMENTS",
    CREATE_PROJECT_PRIVATE_DOCUMENTS: "CREATE_PROJECT_PRIVATE_DOCUMENTS",
    VIEW_PROJECT_PRIVATE_DOCUMENTS: "VIEW_PROJECT_PRIVATE_DOCUMENTS",
    UPDATE_PROJECT_PRIVATE_DOCUMENTS: "UPDATE_PROJECT_PRIVATE_DOCUMENTS",
    DELETE_PROJECT_PRIVATE_DOCUMENTS: "DELETE_PROJECT_PRIVATE_DOCUMENTS",

    VIEW_PROJECT_DISCUSSIONS: "VIEW_PROJECT_DISCUSSIONS",
    CREATE_PROJECT_DISCUSSIONS: "CREATE_PROJECT_DISCUSSIONS",
    UPDATE_PROJECT_DISCUSSIONS: "UPDATE_PROJECT_DISCUSSIONS",
    DELETE_PROJECT_DISCUSSIONS: "DELETE_PROJECT_DISCUSSIONS"
};
