import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccountBalance from "@material-ui/icons/AccountBalance";
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomSelectClassic from "../../../components/CustomSelectClassic/CustomSelect.jsx";
import { searchInstitute } from "services/instituteDataProvider.js";

import {
  SDGs,
  worldCountries,
  WORLD_REGION_CHOICES
} from "utils/choices.jsx";

const url_pattern = /^(https?:\/\/)www\.[^\s"]+\.[^\s"]+$/;

const FindInst = ({ formData, setFormData }) => {

  const { inst_new, inst_name, inst_type, inst_sdg, inst_region, inst_country, inst_city, inst_postalcode, inst_street, inst_website } = formData;

  const onChangeForm = ({ target: { name, value } }) => {
    setFormData(formData => ({ ...formData, [name]: value }))
  }

  const toggleNewInstStatus = (previous) => {
    onChangeForm({ target: { name: 'inst_new', value: !previous } });
  }

  const searchInstituteData = data => {
    let insitituteList = searchInstitute(data).then(
      data =>
        data.results &&
        data.results.map(result => {
          return { text: result.name, value: result.id };
        })
    );
    return insitituteList;
  };


  let [isInstituteSelected, setIsInstituteSelected] = useState(false);
  const changeSelectClassic = (event) => {
    if (event !== undefined && event !== null) {
      onChangeForm({ target: { name: 'inst_id', value: event } });
      setIsInstituteSelected(true)
    } else {
      setIsInstituteSelected(false)
    }
  }

  let styles = {
    hedera: {
      fontFamily: '"Poppins", sans-serif',
      
    },
    hederaFooter: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "16px"
    },
    bottomBox: {
      marginBottom: "15px",
      bottom: 0,
      width: "100%",
      position: 'absolute',
    },
    inputAdornmentIcon: {
      color: "#555"
    },
    inputAdornment: {
      position: "relative"
    }
  }

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography gutterBottom style={{ ...styles.hedera, fontSize: 20 }}>
          {inst_new === true ? "Complete the insitution details" : "Search for your institution"}
        </Typography>
      </Box>
      
      <Box mb={3}>
        <FormControlLabel
          control={
            <Switch
              checked={inst_new}
              onChange={() => toggleNewInstStatus(inst_new)}
              name="switch_new_inst"
              color="primary"
            />
          }
          label="Register a new institution"
        />
      </Box>

      

      {inst_new === true ?
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="new_inst_name"
                name="inst_name"
                value={inst_name}
                fontFamily="Poppins"
                label="Name of the institution"
                fullWidth
                variant="outlined"
                onChange={onChangeForm}
                color="primary"
                margin="normal"
                error={inst_name == null || inst_name.length < 3}
                InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
              />
              <Box mb={2} />
            </Grid>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="select-inst-type" style={{ marginLeft: 10, fontFamily: '"Poppins", sans-serif' }}>
                Type/Legal form of institution
              </InputLabel>
              <Select
                labelId="inst_type"
                id="inst_type"
                name="inst_type"
                value={inst_type}
                onChange={onChangeForm}
                autoWidth
                label="Type of Institution"
                variant="outlined"
                margin="normal"
                color="primary"
                InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
              >
                <MenuItem value="NA"><em>Please select</em></MenuItem>
                <MenuItem value={'Financial Institution'}>Financial Service Provider/ Microfinance institution</MenuItem>
                <MenuItem value={"Investor/Asset manager"}>Impact investor/ Impact investment vehicle</MenuItem>
                <MenuItem value={"Technology/Service Provider"}>Technology/Service Provider</MenuItem>
                <MenuItem value={"NGO"}>NGO/Network</MenuItem>
                <MenuItem value={"Technical Assistance Provider"}>Technical assistance provider, individual consultant, IT company</MenuItem>
                <MenuItem value={"Academia"}>Academia/Research institution</MenuItem>
              </Select>
              <FormHelperText style={{ fontFamily: '"Poppins", sans-serif' }}>Select category that best fits your institution</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="select-inst-sdg" style={{ marginLeft: 10, fontFamily: '"Poppins", sans-serif' }}>
                Sustainable Development Goals
              </InputLabel>
              <Select
                labelId="inst_sdg"
                id="sdg"
                name="inst_sdg"
                value={inst_sdg}
                multiple={true}
                onChange={onChangeForm}
                autoWidth
                label="Sustainable Development Goals"
                variant="outlined"
                color="primary"
                margin="normal"
                InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
              >
                {SDGs.map((count) => {
                  return (
                    <MenuItem value={count.value}>{count.text}</MenuItem>
                  )
                })}
              </Select>

              <FormHelperText style={{ fontFamily: '"Poppins", sans-serif' }}>Select the SDGs related to the work of your institution</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="new_website"
              name="inst_website"
              value={inst_website}
              fontFamily="Poppins"
              label="Website"
              fullWidth
              variant="outlined"
              color="primary"
              onChange={onChangeForm}
              margin="normal"
              error={!url_pattern.test(inst_website)}
              InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
            />
            <FormHelperText style={{ fontFamily: '"Poppins", sans-serif','margin-top':'0' }}>Enter website url as http:// or https://</FormHelperText>
          </Grid>
          <Box mb={2} />
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="select-inst-type" style={{ marginLeft: 10, fontFamily: '"Poppins", sans-serif' }}>
                Region
              </InputLabel>
              <Select
                labelId="inst_region"
                id="region"
                name="inst_region"
                value={inst_region}
                onChange={onChangeForm}
                autoWidth
                label="Region"
                variant="outlined"
                color="primary"
                margin="normal"
              >
                {WORLD_REGION_CHOICES.map((count) => {
                  return (
                    <MenuItem value={count.value}>{count.text}</MenuItem>
                  )
                })}
              </Select>

              <FormHelperText style={{ fontFamily: '"Poppins", sans-serif' }}>Select the working region of institution</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {inst_region === '' || inst_region === undefined
              ?
              null
              :
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel id="select-inst-type" style={{ marginLeft: 10, fontFamily: '"Poppins", sans-serif' }}>
                  Country
                </InputLabel>
                <Select
                  labelId="inst_count"
                  id="inst_country"
                  name={"inst_country"}
                  value={inst_country}
                  onChange={onChangeForm}
                  autoWidth
                  label="Country"
                  variant="outlined"
                  color="primary"
                  margin="normal"
                  InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
                >
                  {worldCountries[inst_region].map((count) => {
                    return (
                      <MenuItem value={count.value}>{count.text}</MenuItem>
                    )
                  })}
                </Select>

                <FormHelperText style={{ fontFamily: '"Poppins", sans-serif' }}>Select the working country of institution</FormHelperText>
              </FormControl>
            }
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="City"
              label="City"
              name="inst_city"
              value={inst_city}
              onChange={onChangeForm}
              fullWidth
              variant="outlined"
              color="primary"
              margin="normal"
              error={inst_city == null || inst_city.length < 3}
              InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
            />
            <Box my={2} />
            <TextField
              id="Street"
              required
              name="inst_street"
              value={inst_street}
              label="Street"
              fullWidth
              color="primary"
              onChange={onChangeForm}
              variant="outlined"
              margin="normal"
              error={inst_street == null || inst_street.length < 3}
              InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
            />
            <Box my={2} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="plz"
                  name="inst_postalcode"
                  value={inst_postalcode}
                  label="Plz / Postal Code"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  margin="normal"
                  error={inst_postalcode == null || inst_postalcode.length < 3}
                  onChange={onChangeForm}
                  InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
                />
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        :
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <CustomSelectClassic
              searchable
              searchMethod={data => searchInstituteData(data)}
              success={isInstituteSelected}
              error={!isInstituteSelected}
              errorMsg={'Select an existing institution or create a new one to continue'}
              labelText={
                <span style= {{fontFamily: '"Poppins", sans-serif' }}>
                  Institute <small>(type to search)</small>
                </span>
              }
              onChange={e => changeSelectClassic(e)}
              InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={styles.inputAdornment}
                  >
                    <AccountBalance className={styles.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />

          </Grid>
        </Grid>
      }


    </React.Fragment>
  );
}

export default FindInst;