import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ProjectPermissions } from "../../utils/roles";
import { updateQuiz } from "../../services/quizDataProvider";

const ActionMenu = ({ history, setSurveyList, quizData, axios }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { name, id, userPermission } = quizData;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (name) => {
    history.push(`/quiz/${name}`);
    handleClose();
  };

  const handleManage = (name) => {
    history.push(`/quiz/manage/${name}`);
    handleClose();
  };

  const handleMarkFinalized = (id) => {
    const { userPermission, projectName, acceptSubmissions, ...rest } =
      quizData;
    rest["accept_submissions"] = false;
    updateQuiz(axios, id, rest).then((data) => {
      setSurveyList((prevQuizzes) => {
        let quizzes = prevQuizzes || [];
        quizzes.forEach((qz) => {
          if (qz.id === data.id) {
            qz.accept_submissions = data.accept_submissions;
          }
        });
        return quizzes;
      });
      handleClose();
    });
  };

 const handleExploreSubmission =(name)=>{
   history.push(`/quiz/explore-submissions/${name}`);
   handleClose();

 }
  const canFillQuiz =
    userPermission &&
    userPermission.includes(ProjectPermissions.FILL_PROJECT_SURVEYS);
  const canDeActivateQuiz =
    userPermission &&
    userPermission.includes(ProjectPermissions.DEACTIVATE_PROJECT_SURVEYS);
  const canActivateQuiz =
    userPermission &&
    userPermission.includes(ProjectPermissions.ACTIVATE_PROJECT_SURVEYS);
  const isManageable =
    canActivateQuiz && canDeActivateQuiz;

  const isExploreSubmission= userPermission && userPermission.includes(ProjectPermissions.EXPORT_PROJECT_SURVEY_RESULTS)
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {quizData.active &&
          (quizData.accept_submissions || canFillQuiz) && (
            <MenuItem onClick={() => handleView(name)}>View</MenuItem>
          )}
        {isManageable && (
          <MenuItem onClick={() => handleManage(name)}>Manage</MenuItem>
        )}
        {/*  TO DO: This needs a refresh of the page after the click */}
        {canDeActivateQuiz && quizData.accept_submissions && (
          <MenuItem onClick={() => handleMarkFinalized(id)}>
            Mark as finalized
          </MenuItem>
        )}
        {
          isExploreSubmission &&(
            <MenuItem onClick={()=>handleExploreSubmission(name)}>
              Explore Submission
            </MenuItem>
          )
        }
      </Menu>
    </div>
  );
};

export default ActionMenu;
