/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";

// icons
import Visibility from "@material-ui/icons/Visibility";
import { VisibilityOff } from "@material-ui/icons";

// core components
import CustomButton from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomForm from "layouts/Registration/WizardSteps/CustomForm.js";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import NavPills from "components/NavPills/NavPills.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FinancialInstituteFormSchema } from "layouts/Search/FormSchemas/FinancialInstituteSearchFormSchema.js";
import { InvestorFormSchema } from "layouts/Search/FormSchemas/InvestorSearchFormSchema.js";
import { TechnologyProviderFormSchema } from "layouts/Search/FormSchemas/TechnologyProviderSearchFormSchema.js";
import { TechnicalAssistanceFormSchema } from "layouts/Search/FormSchemas/TechnicalAssistanceSearchFormSchema.js";
import { AcademiaFormSchema } from "layouts/Search/FormSchemas/AcademiaSearchFormSchema.js";
import { NGOFormSchema } from "layouts/Search/FormSchemas/NGOSearchFormSchema.js";
import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";

import {
  getSearchProfiles,
  updateSearchProfile
} from "services/searchProfileDataProvider.js";
import { getMatchedInstitutes } from "services/searchProfileDataProvider.js";
import {
  followInstitute,
  markAsViewedInstitute
} from "services/instituteDataProvider.js";
import {buttonColorScheme} from "../../assets/jss/material-dashboard-react/layouts/appstyleconfigs";
import Box from "@material-ui/core/Box";

const styles = {
  searchResultsContainer: {
    maxHeight: "500px",
    overflowY: "auto"
  },

  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily:
      "'Josefin Sans','Open Sans', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif'",
    marginBottom: "3px",
    textDecoration: "none"
  },
  accordionWrap: {
    width: '100%',
  },
  tableText: {
    color: "black"
  },
  notFoundText: {
    marginTop: "50px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400
  },
  notFoundLink: {
    font: "inherit",
    color: "#9c27b0",
    cursor: "pointer",
    margin: 0
  },
  statusLabel: {
    color: "#FFF",
    fontWeight: "bolder"
  },
  loaderClass: {
    margin: "auto",
    width: "50%",
    padding: "10px",
    justifyContent: "center"
  }
};

const INSTITUTE_TYPES = {
  FINANCIAL_INSTITUTION: {
    name: "Financial Institution",
    label: "Rural Banks, Microfinance institution, Cooperatives"
  },
  INVESTOR_ASSET_MANAGER: {
    name: "Investor/Asset manager",
    label: "Funds, Development Banks, Commercial Banks, Corporations"
  },
  TECHNOLOGY_SERVICE_PROVIDER: {
    name: "Technology/Service Provider",
    label: "Companies (SMEs) providing (e.g.) energy or water technologies"
  },
  NGO: {
    name: "NGO",
    label:
      "Non-governamental organizations, networks, international initiatives"
  },
  TECHNICAL_ASSISTANCE_CONSULTANT: {
    name: "Technical assistance/Consultant",
    label: "Consulting companies, Multilateral organizations, Rating Agencies"
  },
  ACADEMIA: {
    name: "Academia",
    label: "Research Institutes, Universities, R&D Departments"
  }
};

class FilterAndMatch extends Component {
  constructor(props) {
    super(props);
  this.state = {
    institutes: {},
    matches: [],
    checkedFollow: false,
    checkedNewMatches: false,
    sortByRelevance: false,
    isLoading: true
  };
}

  navPillsRef = React.createRef();

  // MATCH PAGE
  followInstitute = (idx, instituteId) => e => {
    // console.log("followInstitute", idx, instituteId);
    followInstitute(this.props.axios, instituteId, true).then(data => {
      // console.log("followInstitute", data);
      let institutes = this.state.matches;
      institutes[idx].is_following = true;
      this.setState({ matches: institutes });
    });
  };

  unfollowInstitute = (idx, instituteId) => e => {
    followInstitute(this.props.axios, instituteId, false).then(data => {
      let institutes = this.state.matches;
      institutes[idx].is_following = false;
      this.setState({ matches: institutes });
    });
  };

  markAsViewedInstitute = (idx, instituteId, viewed) => () => {
    markAsViewedInstitute(this.props.axios, instituteId, viewed).then(() => {
      let institutes = this.state.matches;
      institutes[idx].is_viewed = viewed;
      this.setState({ matches: institutes });
    });
  };

  changeSwitch(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked
    });
  }

  // FILTER PAGE
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    let inputData = {};
    Object.keys(this.state.institutes).forEach(key => {
      if (this.state.institutes[key] === true) {
        inputData[key] =
          this.formSchemas[key] && this.formSchemas[key].schema.data();
      }
    });
    updateSearchProfile(this.props.axios, inputData)
      .then(() => {
        this.setState({ successMessage: "Success" });
        getMatchedInstitutes(this.props.axios).then(data => {
          let institutes = [];
          data.forEach(dt => {
            institutes.push({
              ...dt,
              is_viewed: Math.random() < 0.5
            });
          });
          this.setState({ matches: institutes });
        });
      })
      .catch(() => {
        this.setState({ errorMessage: "Failed" });
      });
  };

  checkBoxUpdated = name => e => {
    let institutes = this.state.institutes;
    institutes[name] = e.target.checked;
    this.setState({ institutes: institutes });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getSearchProfiles(this.props.axios, this.props.user.user_id).then(data => {
      let searchSettings = {};
      let institutes = {};
      data.forEach(dt => {
        searchSettings[dt.institute_type] = dt;
        institutes[dt.institute_type] = true;
      });
      this.setState({ profiles: searchSettings, institutes: institutes });
    });

    getMatchedInstitutes(this.props.axios).then(data => {
      this.setState({ isLoading: false });
      let institutes = [];
      data.forEach(dt => {
        institutes.push({
          ...dt,
          // todo remove after is_viewed come from backend
          is_viewed: Math.random() < 0.5,
          is_following: Math.random() < 0.5,
          relevant_index: Math.floor(Math.random() * (100 - 1)) + 1
        });
      });
      this.setState({ matches: institutes });
    });
  }

  renderInstituteForm(instituteType) {
    let formSchema = null;
    switch (instituteType) {
      case INSTITUTE_TYPES.FINANCIAL_INSTITUTION.name:
        formSchema = FinancialInstituteFormSchema;
        break;
      case INSTITUTE_TYPES.INVESTOR_ASSET_MANAGER.name:
        formSchema = InvestorFormSchema;
        break;
      case INSTITUTE_TYPES.TECHNOLOGY_SERVICE_PROVIDER.name:
        formSchema = TechnologyProviderFormSchema;
        break;
      case INSTITUTE_TYPES.NGO.name:
        formSchema = NGOFormSchema;
        break;
      case INSTITUTE_TYPES.TECHNICAL_ASSISTANCE_CONSULTANT.name:
        formSchema = TechnicalAssistanceFormSchema;
        break;
      case INSTITUTE_TYPES.ACADEMIA.name:
        formSchema = AcademiaFormSchema;
        break;
      default:
        formSchema = null;
    }
    if (formSchema != null) {
      let schemas = this.formSchemas || {};
      schemas[instituteType] = formSchema;
      this.formSchemas = schemas;
      return (
        <CustomForm
          formSchema={formSchema}
          formData={
            this.state.profiles[instituteType] &&
            this.state.profiles[instituteType].options
          }
        />
      );
    } else {
      return "Invalid Institute type";
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              {/*<h4 style={styles1.cardTitle}>Help Center</h4>*/}
            </CardHeader>
            <CardBody>
              <NavPills
                ref={this.navPillsRef}
                color="warning"
                vertical={{
                  tabsGrid: { xs: 12, sm: 12, d: 12 },
                  contentGrid: { xs: 12, sm: 12, md: 12 }
                }}
                tabs={[
                  {
                    tabButton: "View Matches",
                    //tabIcon: Extension,
                    tabContent: (
                      <CardBody>{this.renderMatchedInstitutes()}</CardBody>
                    )
                  },
                  {
                    tabButton: "Set search preferences",
                    //tabIcon: SettingsApplications,
                    tabContent: (
                      <CardBody>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                          <Box marginRight={{ sm: '5px'  }}>
                            <h4>
                              Select the type of institution you are interested in
                              panel below. Depending on the cathegory, additional
                              filters will appear.
                              <br />
                              Save your search profile by clicking the{" "}
                              <b>UPDATE SEARCH PROFILE</b> button.
                            </h4>
                          </Box>
                          <Box marginLeft={{ sm: '5px' }} marginBottom="15px">
                            <CustomButton
                              contained
                              color={buttonColorScheme.createButton}
                              fullwidth
                              className={classes.submit}
                              onClick={this.submitForm}
                            >
                              Update Search Profile
                            </CustomButton>
                          </Box>
                        </Box>
                        {this.renderFilter()}
                      </CardBody>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderFilter() {
    //const { classes } = this.props;
    return (
      <div>
        <div style={styles.accordionWrap}>
          {Object.keys(INSTITUTE_TYPES).map((key, index) => {
            const instituteType = INSTITUTE_TYPES[key].name;
            const isChecked = this.state.institutes[instituteType] === true;

            return (
              <Accordion expanded={isChecked} key={key}>
                <AccordionSummary
                  aria-label="Expand"
                  aria-controls={`accordion-item-${index}`}
                  id={`accordion-item-header-${index}`}
                >
                  <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={this.checkBoxUpdated(instituteType)}
                      />
                    }
                    label={
                      <h5 style={styles.tableText}>
                        {INSTITUTE_TYPES[key].label}
                      </h5>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {isChecked ? (
                    this.renderInstituteForm(instituteType)
                  ) : (
                    <GridItem xs={12} sm={12} md={12} lg={12} />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    );
  }

  /// TODO: This function is not (yet?) used
  renderMatchesStatus(institute) {
    return institute.is_viewed ? (
      <Chip
        style={{ ...styles.statusLabel, backgroundColor: "#FF9800" }}
        label={"Viewed"}
      />
    ) : (
      <Chip
        style={{ ...styles.statusLabel, backgroundColor: "#1E8449" }}
        label={"New"}
      />
    );
  }

  showLoader() {
    return (
      <GridContainer style={styles.loaderClass}>
        <CircularProgress />
      </GridContainer>
    )
  }

  renderExtraButtons(idx, instituteId, is_viewed) {
    return (
      <Tooltip
        id="tooltip-top"
        title={`Mark As ${is_viewed ? "New" : "Viewed"}`}
        placement="bottom"
      >
        <Button
          color="transparent"
          simple
          justIcon
          onClick={() =>
            this.markAsViewedInstitute(idx, instituteId, !is_viewed)()
          }
        >
          {is_viewed ? <VisibilityOff /> : <Visibility />}
        </Button>
      </Tooltip>
    );
  }

  renderNotFoundText() {
    return (
      <div style={styles.notFoundText}>
        No matches found.
        <h4
          style={styles.notFoundLink}
          onClick={() => this.navPillsRef.current.setActiveTab(1)}
        >
          Set your profile to find new matches.
        </h4>
      </div>
    );
  }

  getFilteredMatches() {
    const {
      matches,
      checkedFollow,
      checkedNewMatches,
      sortByRelevance
    } = this.state;
    return matches
      .filter(instData => (checkedFollow ? instData.is_following : instData))
      .filter(instData => (checkedNewMatches ? !instData.is_viewed : instData))
      .sort(
        (a, b) => (sortByRelevance ? b.relevant_index - a.relevant_index : 0)
      );
  }

  renderFilterPanel() {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              onChange={event => this.changeSwitch(event)}
              checked={this.state.checkedNewMatches}
              name="checkedNewMatches"
              color="primary"
            />
          }
          label="New matches"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={event => this.changeSwitch(event)}
              checked={this.state.checkedFollow}
              name="checkedFollow"
              color="primary"
            />
          }
          label="Following"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={event => this.changeSwitch(event)}
              checked={this.state.sortByRelevance}
              name="sortByRelevance"
              color="primary"
            />
          }
          label="Sort by Relevance"
        />
      </FormGroup>
    );
  }

  renderMatchedInstitutes() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const { matches } = this.state;

    return (
      <React.Fragment>
        {this.renderFilterPanel()}

        {this.state.isLoading ? this.showLoader() : null}
        {!this.state.isLoading ? matches.length < 1 && this.renderNotFoundText() : null}

        <div style={styles.searchResultsContainer}>
          <GridContainer>
            {!this.state.isLoading ?
              this.getFilteredMatches().map((instData, idx) => (
                <InstituteSummary
                  key={`institute-${idx}`}
                  instituteData={instData}
                  followInstitute={this.followInstitute(idx, instData.id)}
                  unfollowInstitute={this.unfollowInstitute(idx, instData.id)}
                  renderExtraButtons={() =>
                    this.renderExtraButtons(idx, instData.id, instData.is_viewed)
                  }
                  isInstituteList={false}
                  renderStatus={() => this.renderMatchesStatus(instData)}
                />
              )) : null}
          </GridContainer>
        </div>
      </React.Fragment>
    );
  }
}

FilterAndMatch.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterAndMatch);
