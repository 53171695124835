import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import defaultProjectAvatar from "assets/img/HEDERA-blatt.png";
import { getProjectsList } from "../../../services/projectDataProvider";
import { withAxios } from "containers/authenticated.js";
import ConfigurationModal from './ConfigurationModal';

const styles = {
  tableContainer: {
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    objectFit: "contain",
  },
  tableRow:{
    cursor:'pointer'
  }
};

class ImportConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: {},
      projects: [],
    };
  }

  componentDidMount() {
    this.fetchProjects();
  }

  fetchProjects = () => {
    getProjectsList(this.props.axios).then((data) => {
      this.setState({ projects: data.results });
    });
  };

  handleRowClick = (project) => {
    this.setState({ selectedRow: project });
  };

  handleClose = () => {
    this.setState({ selectedRow: {} });
  };

  isValidated() {
    return true;
  }
  sendState() {
    return this.state;
  }
 
  
  render() {
    const { classes, allStates } = this.props;
    const { projects, selectedRow } = this.state;

    return (
      <>
      <div>
        Select a project to import an existing configuration (project custom styling, project banner)
      </div>
      <br/>
        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left" style={{fontWeight : "800"}}>Project Name</TableCell>
                <TableCell align="right" style={{fontWeight : "800"}}>Project Type </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => {
                const imgUrl = project.avatar ? project.avatar : defaultProjectAvatar;
                const { name, id, project_type } = project;
                return (
                  <TableRow className={classes.tableRow} onClick={() => this.handleRowClick(project)} key={id}>
                    <TableCell align="left" component="th" scope="row">
                      <img className={classes.image} src={imgUrl} alt={name} />
                    </TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="right">{project_type || 'None'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfigurationModal currentProject={allStates.project} project={selectedRow} handleClose={this.handleClose} />
      </>
    );
  }


}

export default withAxios(withStyles(styles)(ImportConfiguration));
