import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";

import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";
import { getMatchedInstitutes } from "services/searchProfileDataProvider.js";
import { followInstitute } from "services/instituteDataProvider.js";

const style = {};

class MatchedInstitutes extends React.Component {
  state = {
    institutes: []
  };

  followInstitute = (idx, instituteId) => e => {
    // console.log("followInstitute", idx, instituteId);
    followInstitute(this.props.axios, instituteId, true).then(data => {
      // console.log("followInstitute", data);
      let institutes = this.state.institutes;
      institutes[idx].is_following = true;
      this.setState({ institutes: institutes });
    });
  };

  unfollowInstitute = (idx, instituteId) => e => {
    followInstitute(this.props.axios, instituteId, false).then(data => {
      // console.log("unfollowInstitute", data);
      let institutes = this.state.institutes;
      institutes[idx].is_following = false;
      this.setState({ institutes: institutes });
    });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getMatchedInstitutes(this.props.axios).then(data => {
      let institutes = [];
      data.forEach(dt => {
        institutes.push(dt);
      });
      this.setState({ institutes: institutes });
    });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const { institutes } = this.state;

    return (
      <GridContainer>
        {institutes.map((instData, idx) => {
          return (
            <InstituteSummary
              key={`institute-${idx}`}
              instituteData={instData}
              followInstitute={this.followInstitute(idx, instData.id)}
              unfollowInstitute={this.unfollowInstitute(idx, instData.id)}
            />
          );
        })}
      </GridContainer>
    );
  }
}

MatchedInstitutes.propTypes = {
  classes: PropTypes.object.isRequired,
  axios: PropTypes.object.isRequired
};

export default withStyles(style)(MatchedInstitutes);
