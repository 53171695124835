/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import NavPills from "components/NavPills/NavPills.js";
import Chip from "@material-ui/core/Chip";
//import MembersMap from "components/MembersMap/Me"
import Button from "components/CustomButtons/Button.jsx";
import { Input } from "@material-ui/core";
// @material-ui/icons
import GridItem from "components/Grid/GridItem.jsx";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";

import defaultAvatar from "assets/img/default-avatar.png";
import AddIcon from "@material-ui/icons/Add";
import Domain from "@material-ui/icons/Domain";
import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";
import { buttonColorScheme } from "../../../src/assets/jss/material-dashboard-react/layouts/appstyleconfigs.jsx"

import { MEDIA_ROOT } from "config.js";
import { getUserInstitutes } from "services/userInstituteDataProvider.js";
import { getData, updateData } from "services/userDataProvider.js";
import CustomSwitcher from "../../components/CustomSwitcher/CustomSwitcher";
import InstitutionsTableView from "../../components/InstitutionsTableView/InstitutionsTableView";

const profileStyles = {
  userProfileAvatarImg:{
    position: "relative",
    top: "5%",
    transform: "translateY(30%)",
    zIndex: "3",
    left: "10px"
  },
  // User profile tab
  userProfileCardBody:{
    flex: "0 0 calc(70vh)",
    position: "relative",
    zIndex: "1",
    marginTop: "85px"
  },
  userDatacardProfile: {
    marginTop: "50px"
  },
  userProfileContainer: {
    width: "100%"
  },
  // User profile data
  userDataCardTitle: {
    color: "#FFFFFF",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none"
  },
  userProfileInfo: {
    textAlign: "left",
    fontSize: "18pt",
    minHeight: "36px",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  userProfileInputContainer: {
    fontSize: "14pt",
    width: "85%",
    display: "flex",
    alignItems: "baseline",
  },
  userProfileInput: {
    textAlign: "left",
    fontSize: "14pt",
    marginLeft: "20px",
    minWidth: "200px",
    width: "-webkit-fill-available"
  },
  userProfileLabel: {
    fontSize: "14pt",
    color: "black"
  },
  updateProfileButton: {
    color: "black",
    bordeRadius: "0.2rem"
  },
  // institute list tab
  cardInstituteProfile: {
    color: "#3C4858",
    marginTop: "30px",
    minHeight: "auto",
    fontWeight: "500",
    fontSize: "18px",
    marginBottom: "10px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    },
  },
  instituteListContainer: {
    zIndex: "999 !important",
    marginTop: "30px"
  },
  tableSwitcherWrap: {
    marginBottom: "30px"
  },
  instituteCardBody: {
    fontSize: "16px"
  },

  // +++
  /*MuiGridItem: {
    margin: "10px",
    padding: "10px"
  },*/

};

const tabColors = [
  "#3c4858",
  "#3c4858",
  "#3c4858",
  "#3c4858",
];

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedData: {},
      instituteOptions: [],
      isInstitutionsTableView: false,
    };
  }

  updateProfile = e => {
    updateData(
      this.props.axios,
      this.props.user.user_id,
      this.state.updatedData
    );
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let updatedData = this.state.updatedData;
      updatedData["avatarFile"] = file;
      this.setState({ updatedData: updatedData });
    }
  };

  onUserInfoChange = name => e => {
    let updatedData = this.state.updatedData;
    updatedData[name] = e.target.value;
    this.setState({ [name]: e.target.value, updatedData: updatedData });
  };

  handleFilterSelectionChange = type => (values, action) => {
    // console.log("handleFilterSelectionChange", type, values, action);
    if (type === "profile_visibility") {
      let updatedData = this.state.updatedData;
      let value = values.value;
      updatedData["profile_visibility"] = value;
      this.setState({ "profile_visibility": value, updatedData: updatedData });
    }
  }

  componentDidMount() {
    this.props.user &&
      getData(this.props.axios, this.props.user.user_id).then(data => {
        if (data && data.is_guest !== false) {
          // this.props.history.push("/registration");
          // ToDo: Show it in modal
          console.log("Error: Profile verification pending.",);
        }

        let instituteOptions = [];
          data &&
            data.roles.forEach(roleData => {
              if (
                roleData.role !== "Contact" &&
                roleData.role !== "Follower" &&
                roleData.role !== "Admin"
              ) {
                let instituteData = {
                  name: roleData.institute_name,
                  id: roleData.institute,
                  avatar: roleData.institute_avatar,
                  currentUsersRoles: {}
                };
                instituteOptions.push(instituteData);
              }
            });

          data &&
            data.roles.forEach(roleData => {
              instituteOptions.forEach(instituteData => {
                if (instituteData.id === roleData.institute) {
                  if (roleData.role === "Admin") {
                    instituteData["isAdmin"] = roleData.approved;
                  }
                  instituteData["currentUsersRoles"][roleData.role] = roleData.approved;
                }
              });
            });

        this.setState({ userData: data, instituteOptions: instituteOptions });
      });

    this.props.axios &&
      getUserInstitutes(this.props.axios).then(data => {
        let userInstitutes = [];
        let followingInstitutes = [];
        data &&
          Object.keys(data).forEach(key => {
            if (key !== "Follower") {
              userInstitutes = data[key].map(instData => {
                return { userRole: key, institute: instData };
              });
            } else {
              followingInstitutes = data[key].map(instData => {
                return { userRole: key, institute: instData };
              });
            }
          });

        this.setState({
          userInstitutes: userInstitutes,
          followingInstitutes: followingInstitutes,
        });
      });
  }

  getTabList() {
    let tabs = [
      {
        tabButton: "User Data",
        tabContent: this.renderUserData()
      },
      {
        tabButton: "Institutions",
        tabContent: this.renderInstituteList()
      }
    ]
    return tabs;
  }

  /* USER PROFILE DATA TAB */
  renderUserData() {
    const { classes } = this.props;
    const userData = this.state.userData || {};
    let profileVisibilityOptions={
      "public": "Public",
      "private": "Private"
    }
    return (
      <Card profile style={profileStyles.userDatacardProfile}>
          <CardHeader color="info">
            <h4 style={profileStyles.userDataCardTitle}>
              {userData.first_name} {userData.last_name}
            </h4>
          </CardHeader>
          <CardBody>
            <GridItem
              xs={12}
              sm={12}
              md={12}
            >
              <table className={classes.userProfileContainer}>
                <tr className={classes.userProfileInfo}>
                  <td>
                  <label className={classes.userProfileLabel}>
                      First Name
                  </label>
                  </td>
                  <td className={classes.userProfileInputContainer}>
                    <Input
                      className={classes.userProfileInput}
                      placeholder={userData.first_name}
                      type="text"
                      onChange={this.onUserInfoChange("first_name")} />
                  </td>
                </tr>
                <tr className={classes.userProfileInfo}>
                  <td>
                  <label className={classes.userProfileLabel}>
                      Last Name
                  </label>
                  </td>
                  <td className={classes.userProfileInputContainer}>
                    <Input
                        className={classes.userProfileInput}
                        type="text"
                        placeholder={userData.last_name}
                        onChange={this.onUserInfoChange("last_name")} />
                  </td>
                </tr>
                <tr className={classes.userProfileInfo}>
                  <td>
                  <label className={classes.userProfileLabel}>
                      Profile Visibility
                  </label>
                  </td>
                  <td className={classes.userProfileInputContainer}>
                    <Select
                      className={classes.userProfileInput}
                      options={[
                        {"label": "Public", "value": "public"},
                        {"label": "Private", "value": "private"}
                      ]}
                      onChange={this.handleFilterSelectionChange("profile_visibility")}
                      placeholder={<span>{profileVisibilityOptions[userData.profile_visibility] || "Select"}</span>}
                    />
                  </td>
                </tr>
                <tr className={classes.userProfileInfo}>
                  <td >
                    <label className={classes.userProfileLabel}>
                      Profile Picture
                    </label>
                  </td>
                  <td className={classes.userProfileInputContainer}>
                    <Input type="file" onChange={this.onFileLoad} />
                  </td>
                </tr>
                <tr className={classes.userProfileInfo}>
                  <td>
                  <Button
                      style={profileStyles.updateProfileButton}
                      color="primary"
                      size="small"
                      onClick={this.updateProfile}
                    >
                      Update Profile
                    </Button>
                  </td>
                  <td className={classes.userProfileInputContainer}>
                  </td>
                </tr>
              </table>
            </GridItem>
          </CardBody>
        </Card>
    );
  }
  /* END USER PROFILE DATA TAB */

  handleChangeTableView = (event, checked) => {
    this.setState({ isInstitutionsTableView: checked })
  }

  renderInstitutionsTable() {
    if (!Array.isArray(this.state.userInstitutes || !this.state.instituteOptions)) {
      return null
    }

    const preparedData = this.state.instituteOptions.map((item) => {
      const { institute: foundInstitute } = this.state.userInstitutes.find(({ institute }) => institute.id === item.id) || {}

      return {
        ...item,
        type: foundInstitute && foundInstitute.type,
        country: foundInstitute && foundInstitute.address && foundInstitute.address.country_name,
      }
    })

    return (
      <InstitutionsTableView
        institutionsData={preparedData}
        renderRoles={(dataItem) => this.renderAdminView(dataItem)}
        history={this.props.history}
      />
    )
  }

  renderInstitutionsList() {
    return (
      <GridContainer >
        {this.state.instituteOptions.map((instData, idx) => (
          <InstituteSummary
            key={`institute-${idx}`}
            instituteData={instData}
            isInstituteList={true}
            renderAdminView={() => this.renderAdminView(instData)}
          />
        ))}
      </GridContainer>
    )
  }

  /* INSTITUTE TAB */
  renderInstituteSelector() {
    const { classes } = this.props;

    if (!this.state.instituteOptions || !this.state.instituteOptions.length) {
      return null
    }

    return (
      <div className={classes.instituteListContainer}>
        <div className={classes.tableSwitcherWrap}>
          <CustomSwitcher
            label='Table View'
            labelPlacement='start'
            checked={this.state.isInstitutionsTableView}
            onChange={this.handleChangeTableView}
          />
        </div>

        {this.state.isInstitutionsTableView
          ? this.renderInstitutionsTable(this.props.history, this.props.axios)
          : this.renderInstitutionsList()
        }
      </div>
    );
  }

  renderAdminView(institute) {
    const {classes} = this.props;
    return institute.currentUsersRoles && (
      <div className={classes.instituteUserRoles}>
        {Object.keys(institute.currentUsersRoles).map(roleName => {
          const roleStatus = institute.currentUsersRoles[roleName] ? "Approved" : "Unapproved";
          return (
            <Tooltip
              key={`${institute.id}-${roleName}`}
              title={`The role is ${roleStatus}`}
              placement="bottom"
              classes={{ tooltip: this.props.classes.tooltip }}
            >
              <Chip
                color={institute.currentUsersRoles[roleName] ? "success" : "warning"}
                variant="outlined"
                size="small"
                label={roleName}
              />
            </Tooltip>
          );
        })}
      </div>
    );
  }

  renderInstituteList() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardInstituteProfile}>
            <CardHeader icon>
              <CardIcon color="danger">
                <Domain />
              </CardIcon>
              <h4>
                Your Institutions
              </h4>
            </CardHeader>
            <CardBody className={classes.instituteCardBody}>
              <h5>
                Select below from the list of your institutions. If you are
                registered as administrator, you will be able to switch to
                editable mode.
              </h5>
              <Button color={ buttonColorScheme.createButton } onClick={() => window.open("/create-institute")} startIcon = {<AddIcon />}>
                Create new institution
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderInstituteSelector()}
        </GridItem>
      </GridContainer>
    );
  }
  /* END INSTITUTE TAB */


  renderUserDetails() {
    const { classes } = this.props;
    const userData = this.state.userData || {};
    //let activeTab =
    //  this.props.match.params && this.props.match.params.activeTab;
    //let activeTabIdx = tabNames.indexOf(activeTab);
    //if (activeTabIdx < 0) {
    //  activeTabIdx = 0;
    //}
    return (
      <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            key={`userProfile-${this.props.user_id}`}
          >
            <Card>
              <CardAvatar profile className = {classes.userProfileAvatarImg}>
                <a href="#avatar" onClick={e => e.preventDefault()}>
                  <img
                    src={
                      userData.avatar
                        ? `${MEDIA_ROOT}${userData.avatar}`
                        : defaultAvatar
                    }
                    alt="avatar"
                  />
                </a>
              </CardAvatar>
            <CardBody className = {classes.userProfileCardBody}>
              <br />
              <NavPills
                color={"info"}
                tabColors={tabColors}
                vertical={{
                  tabsGrid: { xs: 12, sm: 12, md: 12 },
                  contentGrid: { xs: 12, sm: 12, md: 12 }
                }}
                tabs={this.getTabList()}
              />

              {/*<NavPills
                active={activeTabIdx}
                tabColors={tabColors}
                color={stylingInfo.navTabsColor ? stylingInfo.navTabsColor : "info"}
                vertical={{
                  tabsGrid: { xs: 12, sm: 12, md: 12 },
                  contentGrid: { xs: 12, sm: 12, md: 12 }
                }}
                tabs={this.getTabList(projectDetails)}
              />*/}
            </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }


  render() {
    return (
      <div>
        {this.renderUserDetails()}
      </div>
    );
  }
}

export default withStyles(profileStyles)(Profile);
