import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import SendIcon from "@material-ui/icons/Send";
import { Done, DoneAll } from "@material-ui/icons";
import moment from "moment";
import WebSocketInstance from "../../services/WebSocket";

import "./Chat.css";

const styles = {
  paperContainer: {
    backgroundImage: "#FFFFFF",
    backgroundSize: "cover",
    boxShadow: "rgb(221, 221, 221) 1px 1px 1px 1px"
  },
  tick: {
    display: "inline-flex"
  },
  date: {
    fontSize: "12px"
  }
};

var lastMessageId = undefined;

class SetChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      connectionInitiated: false,
      messages: [],
      message: "",
      targetUserId: props.targetUser.id
    };
    this.setNewChat(props);
  }

  setNewChat = props => {
    lastMessageId = undefined;
    this.socketInitializor = setInterval(
      this.initiateConnection(props.targetUser.id),
      100
    );
    this.waitForSocketConnection(() => {
      WebSocketInstance.initChatUser(props.targetUser.id);
      WebSocketInstance.addCallbacks(
        this.setMessages.bind(this),
        this.addMessage.bind(this)
      );
      this.fetchMessageInterval(props.targetUser.id);
    });
  };

  initiateConnection = userId => {
    if (userId !== undefined) {
      WebSocketInstance.connect(userId);
      clearInterval(this.socketInitializor);
    }
  };

  fetchMessageInterval = targetId => {
    setInterval(function() {
      WebSocketInstance.fetchMessages(
        targetId,
        lastMessageId && parseInt(lastMessageId)
      );
    }, 3000);
  };

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(function() {
      // Check if websocket state is OPEN
      if (WebSocketInstance.state() === 1) {
        // console.log("Connection is made");
        callback();
        return;
      } else {
        // console.log("wait for connection...");
        component.waitForSocketConnection(callback);
      }
    }, 500); // wait 500 milisecond for the connection...
  }

  componentDidMount() {
    this.setState({ message: "" });
  }

  componentWillUnmount() {
    this.messagesEnd = null;
  }

  componentDidUpdate() {
    if (this.messagesEnd.scrollTop === 0) {
      this.scrollToBottom("bottom_scroll");
    } else {
      this.scrollToBottom(this.messagesEnd.scrollTop);
    }
  }

  UNSAFE_componentWillReceiveProps = async props => {
    if (props.targetUser.id !== this.state.targetUserId) {
      this.setState({ messages: [] });
      await WebSocketInstance.closeServer();
      this.setNewChat(props);
      this.setState({ targetUserId: props.targetUser.id });
    }
  };

  scrollToBottom = currentScroll => {
    const chat = this.messagesEnd;
    const scrollHeight = chat.scrollHeight;
    const height = chat.clientHeight;
    const maxScrollTop = scrollHeight - height;
    chat.scrollTop =
      currentScroll === "bottom_scroll" ? maxScrollTop : currentScroll;
  };
  // scrollToBottom = () => {
  //   this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  // };

  addMessage(message) {
    if (message.id !== undefined) {
      lastMessageId = message.id;
      let allMessages = this.state.messages;
      allMessages.push(message);
      this.setState({ messages: allMessages });
      let lastMessage = message;
      let messageDate = moment(lastMessage.created_at).format(`ss`);
      let currentDate = moment().format(`ss`);
      if (
        Math.sign(currentDate - messageDate) !== -1 &&
        currentDate - messageDate < 5
      ) {
        this.scrollToBottom("bottom_scroll");
      }
    }
  }

  setMessages(messages) {
    messages.reverse();
    let oldMessages = this.state.messages;
    let allMessages = messages;
    if (messages.length > 0) {
      lastMessageId = messages[messages.length - 1].id;
      if (oldMessages.length > 0) {
        allMessages = [...oldMessages, ...messages];
      }
      this.setState({ messages: allMessages });
      let lastMessage = messages[messages.length - 1];
      let messageDate = moment(messages[messages.length - 1].created_at).format(
        `ss`
      );
      let currentDate = moment().format(`ss`);
      if (
        Math.sign(currentDate - messageDate) !== -1 &&
        currentDate - messageDate < 5
      ) {
        this.scrollToBottom("bottom_scroll");
      }
      if (lastMessage.author.includes(this.props.targetUser.first_name))
        WebSocketInstance.messageSeen(messages[messages.length - 1].id);
    }
  }

  messageChangeHandler = event => {
    this.setState({
      message: event.target.value
    });
  };

  sendMessageHandler = (e, message) => {
    const messageObject = {
      from: this.props.targetUser.id,
      message: message
    };
    WebSocketInstance.newChatMessage(messageObject);
    this.setState({
      message: ""
    });
    e.preventDefault();
  };

  handleDown = e => {
    if (e.key === "Enter") {
      this.sendMessageHandler(e, this.state.message, this.props);
    }
  };

  renderMessages = messages => {
    const targetUser = this.props.targetUser;
    return messages.map(
      message =>
        typeof message == "object" ? (
          <ListItem key={message.id}>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <div
                  className={
                    message.author.includes(targetUser.first_name)
                      ? "leftAlignBox"
                      : "rightAlignBox"
                  }
                >
                  <ListItemText
                    align={
                      message.author.includes(targetUser.first_name)
                        ? "left"
                        : "right"
                    }
                    primary={message.content}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <ListItemText
                    align={
                      message.author.includes(targetUser.first_name)
                        ? "left"
                        : "right"
                    }
                  >
                    <div style={styles.tick}>
                      <span style={styles.date}>
                        {moment(message.created_at).format(`Do MMM, hh:mm a`)}
                      </span>

                      {message.author.includes(
                        targetUser.first_name
                      ) ? null : message.seen ? (
                        <DoneAll color="primary" />
                      ) : (
                        <Done />
                      )}
                    </div>
                  </ListItemText>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        ) : null
    );
  };

  render() {
    const messages = this.state.messages;
    return (
      <Grid item xs={this.props.screenSize}>
        <Paper style={styles.paperContainer}>
          <List
            className={this.props.className}
            ref={el => {
              this.messagesEnd = el;
            }}
          >
            {messages && this.renderMessages(messages)}
          </List>
        </Paper>

        <Divider />
        <Grid container style={{ padding: "20px", alignItems: "center" }}>
          <Grid item xs={11}>
            <TextField
              id="outlined-basic-email"
              label="Send Message"
              rows={4}
              multiline
              variant="outlined"
              fullWidth
              onChange={this.messageChangeHandler}
              value={this.state.message}
              onKeyDown={e => {
                this.handleDown(e);
              }}
            />
          </Grid>
          <Grid xs={1} align="right">
            <Fab
            style={{marginLeft: "5px"}}
              color="primary"
              aria-label="add"
              onClick={e =>
                this.sendMessageHandler(e, this.state.message, this.props)
              }
            >
              <SendIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SetChat;
