/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";
import moment from "moment";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";
import { getQuizDetails, updateQuiz} from "../../services/quizDataProvider";

import styles from "./quizStyles.jsx";
import CustomSwitcher from "../../components/CustomSwitcher/CustomSwitcher.jsx";
import DeleteIcon from "@material-ui/icons/Delete";
import QuizExportButton from "./QuizExportButton";

class QuizManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuiz: props.match.params.quizName,
      quizDetails: null
    };
  }

  componentDidMount() {
    if (
      this.state.activeQuiz && this.state.activeQuiz !== ":quizName"
    ) {
      getQuizDetails(this.props.axios, this.state.activeQuiz).then(data =>
        this.setState({ quizDetails: data}, () => {
          this.setState({ allMandatoryQuestionsAnswered: this.checkIfMandatoryQuestionsAreAnswered() })
        })
      );
    }
  }

  checkIfMandatoryQuestionsAreAnswered() {
    // console.log("checkIfMandatoryQuestionsAreAnswered", this.state);
    let allAnswered = true;
    let variablesDict = {};
    let questions = this.state.quizDetails && this.state.quizDetails.questions;
    let answerData = this.state.quizDetails && this.state.quizDetails.answers;
    
    if (answerData.length === 0) {
      return false;
    }
    
    questions && questions.forEach((question, idx) => {
      let answer = answerData && answerData[idx];
      if (answer == null) {
        answer = question.answer;
      }

      if (question.required === true) {
        allAnswered = allAnswered && (answer !== null)
      }

      // Check the visibility conditions for the questions
      if (question.variable_name != null && question.variable_name.length > 0) {
        // console.log("question has variable name answer: ", question, "answer", answer);
        answer = this.extractAnswerData(question, answer && answer.answer_details);
        variablesDict[question.variable_name] = answer;
      }
    });
    //console.log("checkIfMandatoryQuestionsAreAnswered allAnswered", allAnswered);
    // console.log("checkIfMandatoryQuestionsAreAnswered variablesDict", variablesDict);
    return allAnswered;
  }

  extractAnswerData(question, answer) {
    if (answer != null && typeof answer === "object") {
      answer = answer.answer;
      if (question.question_type === "single-select") {
        answer = Object.keys(answer).find(key => answer[key] === true);
      } else if (question.question_type === "multi-select") {
        answer = Object.keys(answer).filter(key => answer[key] === true);
      } else if (typeof answer === "object") {
        let theAnswer = null;
        Object.keys(answer).forEach(key => {
          theAnswer = answer[key];
        });
        answer = theAnswer;
      }
    }
    // console.log("answer", question, answer);
    return answer;
  }

  toggleQuizSubmissions = (name) => {

    const { quizDetails } = this.state;
    const quizData = {
      [name]:!quizDetails[name]
    };
    updateQuiz(this.props.axios, quizDetails.id, quizData).then(data => {
      quizDetails[name] = data[name];
      this.setState({quizDetails: quizDetails});
    });
  }

  deleteSurvey=async()=>{
    //const { quizDetails } = this.state;
    if (window.confirm("Are you sure that you want to delete this survey?") === true) {
      // TODO call network
      // await deleteQuiz(this.props.axios, quizDetails.id)
    }

  }

  render() {
    const { classes } = this.props;
    const { quizDetails } = this.state;
    if (quizDetails == null)
      return `Loading survey data...`;

    return (
      <Card className={classes.quizInfoCard}>
        <CardHeader>
          <div className={classes.quizActionsContainer}>
          <h4 className={classes.quizName}>
            Survey data: {quizDetails.name}
          </h4>
          <div className={classes.buttonWrap}>
            <Button
              type="button"
              onClick={ e => { window.location = `/quiz/${this.state.activeQuiz}`}}
            >
              BACK TO SURVEY
            </Button>

            <QuizExportButton quizDetails={this.state.quizDetails} />
          </div>
          </div>


        </CardHeader>
        <CardBody>
          <div className={classes.quizDetailsTableContainer}>
            <Table
              tableHeaderColor="primary"
              tableData={[
                [<p style={{ fontWeight: "bold" }}>Description</p>, quizDetails.description],
                [<p style={{ fontWeight: "bold" }}>Survey type</p>, quizDetails.type],
                [<p style={{ fontWeight: "bold" }}>Project ID</p>, quizDetails.project],
                [<p style={{ fontWeight: "bold" }}>Accessible to users</p>,
                <div className={classes.switcherWrap}>
                <CustomSwitcher
                 checked={quizDetails.active}
                 onChange={()=>this.toggleQuizSubmissions('active')} />
                </div>
              ],
                [<p style={{ fontWeight: "bold" }}>Ongoing (accepting submissions)</p>,
                <div className={classes.switcherWrap}>
                 <CustomSwitcher
                 checked={quizDetails.accept_submissions}
                 onChange={()=>this.toggleQuizSubmissions('accept_submissions')} />
                 </div> 
                ]
                 ,
                [<p style={{ fontWeight: "bold" }}>Updated at</p>, moment(quizDetails.updated_at).format("LLLL")],
                [<p style={{ fontWeight: "bold" }}>Number of questions</p>, quizDetails.questions.length],
                [<p style={{ fontWeight: "bold" }}>Number of answers (from all users)</p>, quizDetails.answers.length],
                [<p style={{ fontWeight: "bold" }}>All mandatory questions answered</p>, this.state.allMandatoryQuestionsAnswered ? "Yes": "No"]
              ]}
            />
          </div>
        </CardBody>
        <CardFooter>
          <div className={classes.buttonDelete}>
          <Button color='danger' onClick={this.deleteSurvey}>
            <DeleteIcon fontSize="large" aria-label="delete" />
            Delete
          </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default withStyles(styles)(QuizManagement);
