import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Calendar as CalendarComponent,
  momentLocalizer
} from "react-big-calendar";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {
  getUserCalendar,
  createEvent,
  deleteEvent,
  getEventDetails
} from "../../services/calendarDataProvider";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const eventColors = event => {
  var backgroundColor = "event-";
  event.color
    ? (backgroundColor = backgroundColor + event.color)
    : (backgroundColor = backgroundColor + "default");
  return {
    className: backgroundColor
  };
};

const localizer = momentLocalizer(moment);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      sessions: []
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    let fromTime = moment()
      .subtract(10, "days")
      .utc()
      .format();
    let toTime = moment()
      .add(10, "days")
      .utc()
      .format();

    // eslint-disable-next-line react/prop-types
    getUserCalendar(this.props.axios, fromTime, toTime).then(data => {
      let allSessions = [];
      data.forEach(calendarData => {
        calendarData.occurences.forEach(session => {
          return allSessions.push(session);
        });
      });
      this.setState({ sessions: allSessions });
    });
  }

  handleClick = (name, idx) => e => {
    if (name === "cancel") {
      deleteEvent(this.props.axios, idx).then(e => {
        let sessions = this.state.sessions.filter(
          session => idx !== session.event.id
        );
        this.setState({ sessions: sessions, selectedEvent: undefined });
      });
    } else if (name === "details") {
      getEventDetails(this.props.axios, idx);
    } else if (name === "back") {
      this.setState({ selectedEvent: undefined });
    }
  };

  selectedEvent = event => {
    this.setState({ selectedEvent: event });
  };

  setAlert(alert) {
    this.setState({ alert: alert });
  }

  hideAlert = () => {
    this.setAlert(null);
  };

  addNewEventAlert = slotInfo => {
    const { classes } = this.props;
    this.setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Enter session title"
        onConfirm={e => this.addNewEvent(e, slotInfo)}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      />
    );
  };

  addNewEvent = (e, slotInfo) => {
    let eventData = {
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    };
    let newEvents = this.state.sessions;
    // eslint-disable-next-line react/prop-types
    createEvent(this.props.axios, eventData).then(data => {
      // console.log("created event", data);
      newEvents.push({
        event: data,
        start: slotInfo.start,
        end: slotInfo.end
      });
      this.setAlert(null);
    });
    this.setState({ sessions: newEvents });
  };

  renderSession(sessionData, showCancel) {
    const { classes } = this.props;
    if (sessionData.event === undefined) {
      return null;
    }
    return (
      <Card>
        <CardHeader color="primary" stats icon>
          <p className={classes.cardCategory}>{sessionData.event.title}</p>
          <h3 className={classes.cardTitle}>
            {moment(sessionData.start).fromNow()}
          </h3>
        </CardHeader>
        <CardBody>
          <h4>
            Advisor: <span>Advisor</span>
          </h4>
          <h4>
            Starts At:{" "}
            <span>
              {moment(sessionData.start).format("MMMM Do YYYY, hh:mm a")}
            </span>
            {" to "}
            <span>{moment(sessionData.end).format("hh:mm a")}</span>
          </h4>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            onClick={this.handleClick("back", sessionData.event.id)}
          >
            Back
          </Button>
          <Button
            color="primary"
            disabled={!showCancel}
            onClick={this.handleClick("cancel", sessionData.event.id)}
          >
            Cancel Session
          </Button>
        </CardFooter>
      </Card>
    );
  }

  rendarCalendar() {
    const calendarEvents = this.state.sessions.map(session => {
      return {
        event: session.event,
        title: session.event.title,
        start: new Date(session.event.start),
        end: new Date(session.event.end),
        color: "rose"
      };
    });
    return (
      <Card>
        <CardBody calendar>
          <CalendarComponent
            selectable
            localizer={localizer}
            events={calendarEvents}
            defaultView="week"
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={event => this.selectedEvent(event)}
            onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
            eventPropGetter={eventColors}
          />
        </CardBody>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <div>
        <Heading
          textAlign="center"
          title="Your Sessions Calendar"
          category={
            <span>
              The calendar also shows free slots from your advisor, for you to
              book sessions.
            </span>
          }
        />
        {this.state.alert}
        <GridContainer justify="center">
          {this.state.selectedEvent !== undefined ? (
            <GridItem xs={12} sm={12} md={10}>
              {this.renderSession(this.state.selectedEvent, true)}
            </GridItem>
          ) : (
            <GridItem xs={12} sm={12} md={10}>
              {this.rendarCalendar()}
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Calendar);
