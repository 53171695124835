import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";

import Button from "components/CustomButtons/Button.jsx";

import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography
} from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { MEDIA_ROOT } from "config.js";

const styles = {
  root: {
    width: "100%",
    // backgroundColor: theme.palette.background.paper
  },
  fonts: {
    fontWeight: "bold"
  },
  inline: {
    display: "inline"
  },
  listItemText: {
    fontSize: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "inset 5px 5px 5px #ccc"
  },
  buttonStyle: {
    margin: "10px"
  }
};

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComment: ""
    };
  }

  updateComment = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let comment = this.state.currentComment;
      const { onComment } = { ...this.props };
      this.setState({
        currentComment: ""
      });
      
      onComment(comment);
    }
  }

  change = e => {
    this.setState({
      currentComment: e.target.value
    });
   };

  sendComment = e => {
    let comment = this.state.currentComment;
    const { onComment } = { ...this.props };
    this.setState({
      currentComment: ""
    });
    e.preventDefault();
    onComment(comment);
}

  render() {
    const { classes, comments, user } = { ...this.props };

    return (
      <List className={classes.root}>
        {comments.map(comment => {
          return (
            <React.Fragment key={comment.id}>
              <ListItem key={comment.id} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="avatar" src={`${MEDIA_ROOT}${comment.author.avatar}`} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography className={classes.fonts}>
                      {comment.text}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {comment.author.email}
                      </Typography>
                      {` - ${moment(comment.created_at).format("LLLL")}`}
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
        {user && <React.Fragment>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="avatar" src={`${MEDIA_ROOT}${user.avatar}`} />
            </ListItemAvatar>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={
                <CustomInput
                  style={
                    { margin: "0px", padding: "5px" }
                  }
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.currentComment,
                    disableUnderline: true,
                    onChange: this.change,
                    onKeyDown: this.updateComment
                  }}
                />
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {user.email}
                  </Typography>
                </>
              }
            />
            <Button
              className={classes.buttonStyle}
              color="success"
              onClick={this.sendComment}> Send </Button>
          </ListItem>
          <Divider />
        </React.Fragment>}
      </List>
    );
  }
}

export default withStyles(styles)(Comment);