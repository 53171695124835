import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";

const ActionMenu = ({ institutionId, history, allowedToEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (id) => {
    history.push(`/institutes/${id}`);
    handleClose();
  };

  const handleEdit = (id) => {
    history.push(`/institutes/${id}/edit`);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleView(institutionId)}>View</MenuItem>
        {allowedToEdit && <MenuItem onClick={() => handleEdit(institutionId)}>Edit</MenuItem>}
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  institutionId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  allowedToEdit: PropTypes.bool.isRequired,
};


export default ActionMenu;
