import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ActionMenu = ({ id, history, isEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (id) => {
    history.push(`/projects/${id}`);
    handleClose();
  };

  const handleEdit = (id) => {
    history.push(`/projects/${id}/edit`);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleView(id)}>View</MenuItem>
        {isEdit && <MenuItem onClick={() => handleEdit(id)}>Edit</MenuItem>}
      </Menu>
    </div>
  );
};

export default ActionMenu;
