/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Warning from "components/Typography/Warning.jsx";

import Quiz from "views/Quiz/Quiz.jsx";

import { getQuizes } from "../../services/quizDataProvider";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class QuizList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuiz: props.match.params.quizName,
      quizes: []
    };
  }

  componentDidMount() {
    if (
      this.state.activeQuiz === ":quizName" ||
      this.state.activeQuiz === "all"
    ) {
      getQuizes(this.props.axios).then(data =>
        this.setState({ quizes: data.results })
      );
    }
  }

  handleLinkClick = quizName => e => {
    e.preventDefault();
    this.setState({ activeQuiz: quizName });
  };

  renderQuizList() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    let quizs = this.state.quizes ? this.state.quizes.length : 0;

    let rows = [];
    for (let i = 0; i < Math.ceil(quizs / 2); i += 1) {
      rows.push(
        <GridContainer key={`quiz-container-${i}`}>
          {[0, 1].map(idx => {
            let quizData = this.state.quizes && this.state.quizes[i * 2 + idx];
            // console.log("quizData", quizData);
            if (quizData) {
              return (
                <GridItem xs={12} sm={12} md={6} key={`quiz-${quizData.id}`}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="warning">
                        <Icon>content_copy</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>{quizData.name}</p>
                      <h3 className={classes.cardTitle}>
                        {quizData.percentage_completion &&
                          quizData.percentage_completion.toFixed(1)}
                        % <small>completed</small>
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <p>{quizData.description}</p>
                      {!quizData.accessible ? (
                        <Warning>Survey locked</Warning>
                      ) : null}
                    </CardBody>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Button
                          type="button"
                          color="primary"
                          onClick={this.handleLinkClick(quizData.name)}
                          disabled={!quizData.accessible}
                        >
                          Fill the module {quizData.name}
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              );
            }
            return null;
          })}
        </GridContainer>
      );
    }
    return <div>{rows}</div>;
  }

  renderQuiz() {
    return (
      <Quiz
        history={this.props.history}
        axios={this.props.axios}
        name={this.state.activeQuiz}
        description={"test"}
      />
    );
  }

  render() {
    if (this.state.activeQuiz === "all") {
      return this.renderQuizList();
    } else if (this.state.activeQuiz !== ":quizName") {
      return this.renderQuiz();
    }
    return this.renderQuizList();
  }
}

export default withStyles(styles)(QuizList);
