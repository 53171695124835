import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center"
    }
  }
}));

export default function Paginate(props) {
  const classes = useStyles();
  const { getPage, pageCount } = props;

  const pageClicked = page => {
    getPage(page);
  };
  return (
    <div className={classes.root}>
      <Pagination
        count={pageCount}
        showFirstButton
        showLastButton
        size="large"
        shape="rounded"
        color="primary"
        onChange={(e, page) => pageClicked(page)}
      />
    </div>
  );
}
