/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Datetime from "react-datetime";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
// import Check from "@material-ui/icons/Check";
// import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Input } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import Edit from "@material-ui/icons/Edit";
import Save from "@material-ui/icons/Save";
// @material-ui/icons
import Delete from "@material-ui/icons/Delete";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { MEDIA_ROOT } from "../../config";
import { updateQuestion } from "../../services/quizDataProvider";

const QuestionStyles = {
  questionAndActionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  questionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "12px",
    width: "100%"
  },
  questionActionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  questionText: {
    fontSize: "16px",
    color: "rgb(137 71 72)",
    width: "100%"
  },
  questionTextEditable: {
    fontSize: "16px",
    color: "rgb(137 71 72)",
    width: "100%"
  },
  requiredQuestionMark: {
    color: "red"
  },
  questionInfoContainer: {
    marginTop: "5px",
    color: "#2E4053",
    fontSize: "12px",
    backgroundColor: "#EAEDED",
    padding: "8px"
  },
  answerOptionsContainer: {
    padding: " 0 0 3px 35px"
  },
  questionVideoLink: {
    paddingLeft: "10px"
  },
  checkboxInputContainer: {
    padding: "10px 0 0 0",
  },
  checkboxInput: {
    padding: "10px 0 0 0",
    height: "20px"
  },
  textInputContainer: {
    padding: "10px 0 8px 0",
    margin: "0"
  },
};

class Question extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionData: {}
    };
  }

  componentDidMount() {
    this.setState({questionData: this.props.data});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({questionData: this.props.data});
    }
  }

  handleChange = (questionType, name, inputType) => event => {
    let value = null;
    let currentState = this.state;
    const questionData = currentState.questionData;
    delete currentState["questionData"];
    if (inputType === "checkbox") {
      value = event.target.checked;
    } else if (
      inputType === "date" ||
      inputType === "time" ||
      inputType === "datetime"
      ) {
        value = event;
      } else if (inputType === "integer") {
        value = parseInt(event.target.value);
      } else {
        value = event.target.value;
      }
      
    // console.log("handleChange", questionType, name, inputType, value, event);

    if (questionType.includes("single-select")) {
      let currentValue = currentState[[name]];
      Object.keys(currentState).forEach(key => {
        currentState[key] = false;
      });
      // if it is already selected, de-select it
      currentState[[name]] = value !== currentValue;
    } else {
      currentState[[name]] = value;
    }

    if (inputType === "file") {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        currentState[[name]] = file;
      }
    }

    if (inputType === "remove-file") {
      currentState[[name]] = '';
    }

    const newState = {
      ...currentState,
      questionData: questionData
    };
    this.setState(newState, () => {
      if (this.props.onChange) {
        this.props.onChange(currentState);
      }
    });
  };

  updateQuestion = e => {
    // const { description, metadata, question_id, question, question_type, options, required } = this.props.data;
    const { question_id, question } = this.props.data;
    const questionData = this.state.questionData;
    questionData["id"] = question_id;
    questionData["question"] = this.state.updatedQuestionText || question;
    updateQuestion(this.props.axios, question_id, questionData).then(data => {
      this.setState({editMode: false, questionData: questionData});
    });
  };

  renderQuestionText(question, required, description, info, video_link, questionStyles) {
    const { classes } = this.props;
    const editMode = this.state.editMode === true;
    if (editMode) {
      return (
        <div class="questionAndActionsContainer">
          <div className={classes.questionActionsContainer}>
            <Tooltip
              id="tooltip-top"
              title="Update question text"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Save"
                className={classes.saveActionButton}
                onClick={e => this.updateQuestion(question)}
              >
                <Save
                  className={
                    classes.saveActionButton + " " + classes.save
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Cancel"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Cancel"
                className={classes.cancelActionButton}
                onClick={e => this.setState({editMode: false})}
              >
                <Cancel
                  className={
                    classes.saveActionButton + " " + classes.save
                  }
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.questionContainer}>
            <div className={classes.questionTextEditable} style={questionStyles}>
              {required === true ? <span className={classes.requiredQuestionMark}>*</span> : null}
              <textarea defaultValue={question} onInput={e => this.setState({updatedQuestionText: e.target.value})} style={{width: "100%"}}/>
            </div>
            {description || info ?
              <div className={classes.questionInfoContainer}>
                <InfoIcon />
                {description ? description : null}
                {info ? info : null}
                {video_link &&
                  <a className={classes.questionVideoLink} href={video_link} target="_blank" rel="noopener noreferrer">
                    Video Link
                  </a>
                }
              </div>
              : null
            }
          </div>
        </div>
    );
    }
      return (
        <div className={classes.questionAndActionsContainer}>
          {this.props.editable ? (
            <div className={classes.questionActionsContainer}>
              <Tooltip
                id="tooltip-top"
                title="Edit question text"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Edit"
                  className={classes.editActionButton}
                  onClick={e => this.setState({editMode: true})}
                >
                  <Edit
                    className={
                      classes.editActionButton + " " + classes.edit
                    }
                  />
                </IconButton>
              </Tooltip>
          </div>
          ): (null)}
        <div className={classes.questionContainer}>
          <div className={classes.questionText} style={questionStyles}>
            {required === true ? <span className={classes.requiredQuestionMark}>*</span> : null}
            {question}
          </div>
          {description || info ?
            <div className={classes.questionInfoContainer}>
              <InfoIcon />
              {description ? description : null}
              {info ? info : null}
              {video_link &&
                <a className={classes.questionVideoLink} href={video_link} target="_blank" rel="noopener noreferrer">
                  Video Link
                </a>
              }
            </div>
            : null
          }
        </div>
      </div>
    );
  }

  render() {
    const { classes, className, answer, isTable } = this.props;
    const { description, metadata, question_id, question, question_type, options, required } = this.state.questionData;
    let visual_appearance = this.state.questionData && this.state.questionData.visual_appearance;
    const { info, video_link } = metadata || {};
    const questionStyles = {
    };
    //let visual_appearance = question.visual_appearance;
    if (visual_appearance != null && visual_appearance.length > 0) {
      if (visual_appearance === "header") {
        questionStyles["color"] = "white";
        questionStyles["background"] = "#2C3E50";
        questionStyles["padding"] = "12px";
        questionStyles["fontSize"] = "16px";
        questionStyles["fontWeight"] = "bold";
        questionStyles["borderRadius"] = "5px";
        questionStyles["lineHeight"] = "1.6rem";
      } else if (visual_appearance === "text") {
        questionStyles["color"] = "black";
        questionStyles["fontSize"] = "14px";
      } else if (visual_appearance === "text-bold") {
        questionStyles["color"] = "black";
        questionStyles["fontSize"] = "16px";
        questionStyles["fontWeight"] = "800";
      } else if (visual_appearance === "warning") {
        questionStyles["color"] = "white";
        questionStyles["background"] = "#CB4335";
        questionStyles["padding"] = "12px";
        questionStyles["fontSize"] = "16px";
        questionStyles["borderRadius"] = "5px";
        questionStyles["lineHeight"] = "2rem";
        questionStyles["textAlign"] = "center";
      }
      else {
        try {
          visual_appearance = JSON.parse(visual_appearance);
          Object.keys(visual_appearance).forEach(key => {
            questionStyles[key] = visual_appearance[key];
          });
        } catch {
          console.log("ERROR: Invalid style for question.", visual_appearance);
        }
      }
    }
    // console.log("visual_appearance", visual_appearance);
    return (
      <div className={className}>
        {this.renderQuestionText(question, required, description, info, video_link, questionStyles)}
        {question_type && question_type.toLowerCase() !== "note" && <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormGroup className={isTable ? classes.answerOptionsTable : classes.answerOptionsContainer}>
              {options.map((option, idx) => {
                let isChecked = (this.state[option.name] === true) || (answer && answer[option.name] === true);
                if (question_type.toLowerCase() === "single-select" && option.type === "boolean") {
                  return (
                    <div className={classes.checkboxInputContainer}>
                      <FormControlLabel
                        className={classes.checkboxInput}
                        componentsProps={{
                          className: classes.checkboxInput
                        }}
                        key={`question-${question_id}-${idx}`}
                        control={
                          <Radio
                            checked={isChecked}
                            onClick={this.handleChange(
                              question_type,
                              option.name,
                              "checkbox"
                            )}
                            value={option.name}
                          />
                        }
                        label={option.name}
                      />
                    </div>
                  );
                } else if (option.type === "boolean") {
                  return (
                    <div className={classes.checkboxInputContainer}>
                      <FormControlLabel
                        className={classes.checkboxInput}
                        componentsProps={{
                          className: classes.checkboxInput
                        }}
                        key={`question-${question_id}-${idx}`}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={this.handleChange(
                              question_type,
                              option.name,
                              "checkbox"
                            )}
                            value={option.name}
                          />
                        }
                        label={option.name}
                      />
                    </div>
                  );
                } else if (option.type === "boolean-with-text") {
                  return (
                    <div>
                      <FormControlLabel
                        className={classes.checkboxInputContainer}
                        key={`question-${question_id}-${idx}`}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={this.handleChange(
                              question_type,
                              option.name,
                              "checkbox"
                            )}
                            value={option.name}
                          />
                        }
                        label={option.name}
                      />
                      {this.state[option.name] ? (
                        <CustomInput
                          key={`question-${question_id}-${idx}`}
                          labelText={option.name}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.textInputContainer
                          }}
                          inputProps={{
                            value: answer && answer["-data"],
                            onChange: this.handleChange(
                              question_type,
                              `${option.name}-data`,
                              "text"
                            )
                          }}
                        />
                      ) : null}
                    </div>
                  );
                } else if (option.type === "text") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textInputContainer
                      }}
                      inputProps={{
                        type: "textarea",
                        value: answer && answer["-data"],
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "text"
                        )
                      }}
                    />
                  );
                } else if (option.type === "email") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textInputContainer
                      }}
                      inputProps={{
                        type: "email",
                        value: answer && answer["-data"],
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "email"
                        )
                      }}
                    />
                  );
                } else if (option.type === "number") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textInputContainer
                      }}
                      inputProps={{
                        type: "number",
                        value: answer && answer["-data"],
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "number"
                        )
                      }}
                    />
                  );
                } else if (option.type === "integer") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textInputContainer
                      }}
                      inputProps={{
                        type: "number",
                        value: answer && answer["-data"],
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "integer"
                        )
                      }}
                    />
                  );
                } else if (option.type === "date") {
                  return (
                    <FormControl fullWidth className={classes.dateInputContainer}>
                      <Datetime
                        timeFormat={false}
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        value={answer && answer["-data"] && new Date(answer["-data"])}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "date"
                        )}
                      />
                    </FormControl>
                  );
                } else if (option.type === "time") {
                  return (
                    <FormControl fullWidth className={classes.timeInputContainer}>
                      <Datetime
                        dateFormat={false}
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        value={answer && answer["-data"] && new Date(answer["-data"])}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "time"
                        )}
                      />
                    </FormControl>
                  );
                } else if (option.type === "datetime") {
                  return (
                    <FormControl fullWidth className={classes.datetimeInputContainer}>
                      <Datetime
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        value={answer && answer["-data"] && new Date(answer["-data"])}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "datetime"
                        )}
                      />
                    </FormControl>
                  );
                } else if (option.type === "file") {
                  return (
                    <FormControl fullWidth>
                      {
                        answer && answer["-file"] && 
                        <div>
                          <a href={`${MEDIA_ROOT}/media/${answer["-file"].replaceAll(" ", "_")}`}><strong>{answer["-file"]}</strong></a>
                          <IconButton
                            color="inherit"
                            onClick={this.handleChange(
                              question_type,
                              `${option.name}-data`,
                              "remove-file"
                            )}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      }
                      <Input
                        type="file"
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "file"
                        )} />
                    </FormControl>
                  );
                }
                return null;
              })}
            </FormGroup>
          </GridItem>
        </GridContainer>
      }
      </div>
    );
  }
}

export default withStyles(QuestionStyles)(Question);
