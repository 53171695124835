/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Footer from "components/Footer/Footer.jsx";
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { login } from "services/authProvider.js";

import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    };
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    login(this.state.username, this.state.password)
      .then(() => {
        let url = window.location.href;
        let nextUrl = url.split("?next=")[1]
        if (nextUrl) {
          window.location = nextUrl;
        }
        else {
          this.props.history.push("/projects");
        }
      })
      .catch(() => {
        this.setState({ errorMessage: "Invalid username or password" });
      });
  };

  createUser = e => {
    e.preventDefault();
    window.open("registration", "_self");
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <div className={classes.paperContainer}>
          <CssBaseline />

          <div className="logo_name" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>

          </div>
          <Box my={3} />


          <Paper elevation={3} style={{ padding: 40, maxWidth: 600, alignSelf: "center", margin: "auto" }}>
            <Typography className={classes.hedera} style={{ textAlign: "center", fontSize: 30 }} >
              <img src={require('../../assets/img/hedera-logo.png')} alt="Hedera Logo" width={150} />
            </Typography>
            <Box my={3} />
            <Grid item margin="normal">

              <TextField
                variant="outlined"
                margin="normal"
                required
                color="secondary"
                fullWidth
                id="username"
                label="Username (Email)"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={this.handleChange("username")}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                color="secondary"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange("password")}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{<Link className={classes.hederaFooter} style={{ color: "#49494a", fontWeight: "bold" }} href="resetpassword" variant="body2">
                    Forgot?
                  </Link>}</InputAdornment>,
                }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="secondary" />}
                label="Remember me"
              />
              {this.state.errorMessage ? (
                <SnackbarContent
                  message={this.state.errorMessage}
                  color="danger"
                />
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                size="large"
                //color="primary"
                onClick={this.submitForm}
                className={classes.registrationButtonStyle}
              >
                Sign In
              </Button>

              <Grid container>
                <Grid item style={{ alignSelf: "center", alignItems: "center", textAlign: "center", width: "100%" }}>
                  <Link className={classes.hederaFooter} style={{ textAlign: "center", color: "#49494a" }} href="registration" variant="body2">
                    {"Don't have an account? Sign up"}
                  </Link>
                </Grid>
              </Grid>

            </Grid>
          </Paper>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(signinStyle)(SignIn);
