import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import defaultAvatar from "assets/img/default-avatar.png";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import DeleteButton from "@material-ui/icons/Delete";
import { MEDIA_ROOT } from "config.js";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TablePagination
} from "@material-ui/core";
import { addRole, removeUser } from "../../services/projectDataProvider";
import { fetchChatContact } from "../../services/contactDataProvider";
import Button from "components/CustomButtons/Button.jsx";

import ProjectRoles from "./projectRoles";
import { buttonColorScheme } from "../../assets/jss/material-dashboard-react/layouts/appstyleconfigs";

import {
  connectionRequest,
} from "services/userDataProvider.js";

const ProjectRolesList = Object.keys(ProjectRoles).map(
  key => ProjectRoles[key]
);

const styles = {
  tableHeaderStyle: {
    width: "20%",
    fontFamily: '"Poppins", serif',
    fontWeight: "bolder",
    color: "black",
    fontSize: "1rem"
  },
  searchTableHeader: {
    width: "20%",
    fontFamily: '"Poppins", serif',
    fontWeight: "bolder",
    color: "black",
    fontSize: "1rem"
  },
  tableCellStyle: {
    fontSize: "0.8rem"
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "80px"
  },
  avatarImg: {
    aspectRatio: 1,
    overflow: "hidden",
    //display: "block",
    objectFit: "cover",
    width: "100%",
    maxWidth: "100vw",
    height: "100%",
    maxHeight: "100vh",
    borderRadius: "50%"
  }
};

class ParticipantsList extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
    this.state = {
      pageNumber: 0,
      pageSize: this.props.pageSize || 10,
      participants: this.props.participants || [],
      id: this.props.projectId
    };
  }

  componentDidMount = async () => {
    const isEditable = this.props.editable === true;
    let chatContact = await fetchChatContact(this.props);
    this.setState({ chatContact: chatContact });
    let participants = this.state.participants;

    if (!isEditable) {
      let participantKeys = {};
      let idsToDelete = [];
      participants && participants.forEach((p, idx) => {
        const userId = p.user.id;
        if (participantKeys[userId] == null) {
          participantKeys[userId] = idx;
        } else {
          idsToDelete.push(idx);
          let existingVal = participants[participantKeys[userId]];
          existingVal.role.name = `${ProjectRoles[existingVal.role.name]}, ${ProjectRoles[p.role.name]}`
        }
      });
      participants = participants.filter((p, idx) => !idsToDelete.includes(idx));
    }
    participants.forEach(obj => { obj["message"] = chatContact.some(o => o.id === obj.user.id) });
    this.setState({ participants: participants });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ pageNumber: newPage });
    event.preventDefault();
  };

  handleChangeRowsPerPage = (event, newRowsPerPage) => {
    this.setState({ pageSize: newRowsPerPage }, () => {
      console.log("handleChangeRowsPerPage", newRowsPerPage);
    });
    event.preventDefault();
  };

  renderTableHeader = editable => {
    const showDelete = this.props.showDelete === true;
    return (
      <TableRow>
       <TableCell style={styles.tableHeaderStyle}>Participant</TableCell>
        {/* <TableCell style={styles.tableHeaderStyle}>Institution</TableCell> */}
        <TableCell style={styles.tableHeaderStyle}>Type</TableCell> 
        <TableCell style={styles.tableHeaderStyle}>Project Role</TableCell>
        {editable && showDelete? (
          <TableCell style={styles.tableHeaderStyle}>Delete user</TableCell>
        ) : null}
      </TableRow>
    );
  };

  renderUsers = (participant, editable) => {
    const { classes } = this.props;
    const showDelete = this.props.showDelete === true;
    const showUpdate = this.props.showUpdate === true;
    return (
      <TableRow key={`participant-row-${participant.id}`}>
        <TableCell>
          <a
            href={`/member-profile/${participant.user.id}`}
            className={classes.avatarContainer}
          >
            <img
              className={classes.avatarImg}
              src={
                participant.user.avatar
                  ? `${MEDIA_ROOT}${participant.user.avatar}`
                  : defaultAvatar
              }
              alt="..."
            />
          </a>
        </TableCell>
        <TableCell>
          <span style={{padding: "20px"}}>
              {`${participant.user.first_name}  ${participant.user.last_name}`}
            </span>
        </TableCell>
        {editable && showUpdate? (
          <TableCell>
            <CustomDropdown
              key={`participants-user-actions-${participant.id}`}
              buttonProps={{
                color: buttonColorScheme.actionButton
              }}
              buttonText={ProjectRoles[participant.role.name]}
              onClick={event => {
                this.handleRoleSelectionChange(
                  event,
                  participant.id,
                  participant.user.id
                );
              }}
              dropdownList={ProjectRolesList}
            />
          </TableCell>
        ) : (
          <TableCell>
            { ProjectRoles[participant.role.name] ? ProjectRoles[participant.role.name] : participant.role.name}
          </TableCell>
        )}
        {editable && showDelete? (
          <TableCell>
            <DeleteButton
              id={`participant-remove-${participant.id}`}
              onClick={() =>
                this.deleteUser(participant.user.id, participant.role.name)
              }
            />
          </TableCell>
        ) : null}
        <TableCell>
          {(participant["message"] === true) ? 
          (<Button 
            onClick={() => this.handleActionSelection(participant)} 
            color="primary" 
            size="sm"> Message
          </Button>) : 
          (<Button 
            onClick={() => this.handleActionSelection(participant)} 
            color="success" 
            size="sm"> Request Connection
          </Button>)}
        </TableCell>
      </TableRow>
    );
  };

  handleActionSelection (participant) { 
    if (participant["message"] === true)  {
      /*this.props.history.push({
      pathname: "/chat",
      state: { messagePerson: participant["user"] }
    })*/
    window.open("/chat", "_self")
  } else  {
      if (participant.id === this.props.user.user_id) {
        let message = "You cannot send request to yourself";
        window.alert(message);
        return false;
      } 
      else {
        connectionRequest(this.props.axios, { targetUserId: participant["user"]["id"] }).then(data => {
        let message = "Connection request sent";
        window.alert(message);
        window.location.reload();
      });
    }
  }
  }

  renderInstitute = (participant, editable) => {
    const { classes } = this.props;
    const showDelete = this.props.showDelete === true;
    const showUpdate = this.props.showUpdate === true;
    return (
      <TableRow>
        <TableCell>
          <a
            href="#avatar"
            className={classes.avatarContainer}
            onClick={e => e.preventDefault()}>
            <img
              className={classes.avatarImg}
              src={
                participant.institute.avatar
                  ? `${MEDIA_ROOT}${participant.institute.avatar}`
                  : defaultAvatar
              }
              alt="..."
            />
            {participant.institute.name}
          </a>
          <span style={{padding: "15px"}}>Institution</span>
        </TableCell>
        {/* <TableCell>
          <h6 style={styles.tableCellStyle}>
            {participant.institute.name !== "" ? (
              <a href={`/institutions/${participant.institute.id}`}>
                {`${participant.institute.name}`}
              </a>
            ) : (
              "--"
            )}
          </h6>
        </TableCell> */}
        <TableCell>Institution</TableCell>
        {editable && showUpdate? (
          <TableCell>
            <CustomDropdown
              key={`participants-institute-actions-${participant.id}`}
              buttonProps={{
                color: "default"
              }}
              buttonText={ProjectRoles[participant.role.name]}
              onClick={e => {
                this.handleRoleSelectionChange(
                  e,
                  participant.id,
                  participant.id
                );
              }}
              dropdownList={ProjectRolesList}
            />
          </TableCell>
        ) : (
          <TableCell>
            {participant.role.name ? ProjectRoles[participant.role.name] : "--"}
          </TableCell>
        )}
        {editable && showDelete? (
          <TableCell>
            <DeleteButton
              id={`institute-remove-${participant.id}`}
              onClick={() =>
                this.deleteUser(participant.id, participant.role.name)
              }
            />
          </TableCell>
        ) : null}
      </TableRow>
    );
  };

  addUser(participantId, userId, role) {
    let userDetails = {
      projectId: this.state.id,
      roleName: role,
      userId: userId,
      participantId: participantId
    };
    addRole(this.props.axios, userDetails).then(data => {
      this.setState({ participants: data });
    });
  }

  deleteUser(userId, role) {
    let userDetails = {
      projectId: this.state.id,
      roleName: role,
      userId: userId
    };
    removeUser(this.props.axios, userDetails).then(data => {
      this.setState({ participants: data });
    });
  }

  handleRoleSelectionChange(newRole, participantId, userId) {
    const selectedRole = Object.keys(ProjectRoles).find(
      key => ProjectRoles[key] === newRole
    );
    if (selectedRole) {
      this.addUser(participantId, userId, selectedRole);
    }
  }

  render() {
    const { classes, editable } = this.props;
    const stylingInfo = this.props.stylingInfo || {};
    if (this.state.participants <= 0) {
      return null;
    }
    return (
      <div className={classes.participantsContainer}>
        <TableContainer className={classes.container} style={stylingInfo ? stylingInfo : null}>
          <Table stickyHeader aria-label="sticky table">
            {/*<TableHead style={stylingInfo.header ? stylingInfo.header : null}>{this.renderTableHeader(editable)}</TableHead>*/}
            <TableBody style={stylingInfo.body ? stylingInfo.body : null}>
              {this.state.participants
                .slice(
                  this.state.pageNumber * this.state.pageSize,
                  this.state.pageNumber * this.state.pageSize +
                    this.state.pageSize
                )
                .map(participant => {
                  return participant.institute.id === undefined
                    ? this.renderUsers(participant, editable)
                    : this.renderInstitute(participant, editable);
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={this.state.participants.length}
          rowsPerPage={this.state.pageSize}
          page={this.state.pageNumber}
          onChangePage={this.handleChangePage}
          rowsPerPageOptions={[10]}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ParticipantsList);
