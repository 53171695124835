import {
  instituteTypes,
  SDGs,
  worldCountries,
  WORLD_REGION_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
      required: true
    },
    short_name: {
      type: "string",
      title: "Short Name"
    },
    type: {
      type: "string",
      title: "Type of Insitution",
      options: instituteTypes,
      required: true
    },
    sustainable_development_goals: {
      type: "string",
      title: "Sustainable Development Goals",
      options: SDGs,
      required: true
    },
    region: {
      type: "string",
      title: "Region",
      required: true,
      options: WORLD_REGION_CHOICES
    },
    country: {
      type: "string",
      title: "Country",
      required: true,
      dependsOn: "region",
      options: worldCountries
    },
    city: {
      type: "string",
      title: "City",
      required: true
    },
    zip_code: {
      type: "string",
      title: "Postal Code",
      required: true
    },
    street: {
      type: "string",
      title: "Street Name",
      required: true
    },
    website_url: {
      type: "url",
      title: "Website (http://)",
      required: true
    },
    date_of_establishment: {
      type: "date",
      title: "Date of Establishment"
    },
    total_number_of_staff: {
      type: "number",
      title: "Total Number Of Employees"
    },
    vision: {
      type: "string",
      title: "Institute vision"
    },
    mission: {
      type: "string",
      title: "Institute mission"
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      name: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter name"
      },
    }
  },
  {
    container: "griditem",
    items: {
      short_name: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter short name"
      },
      type: {
        "ui:widget": "select",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please select type of insitution"
      }
    }
  },
  {
    container: "griditem",
    items: {
      sustainable_development_goals: {
        "ui:widget": "multiselect",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please select sustainable development goals"
      }
    }
  },
  {
    container: "griditem",
    items: {
      region: {
        "ui:widget": "select",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please select region"
      },
      country: {
        "ui:widget": "select",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter country"
      }
    }
  },
  {
    container: "griditem",
    items: {
      city: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter city"
      },
      zip_code: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter postal code"
      }
    }
  },
  {
    container: "griditem",
    items: {
      street: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter street name"
      }
    }
  },
  {
    container: "griditem",
    items: {
      website_url: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter website url"
      }
    }
  },
  {
    container: "griditem",
    items: {
      date_of_establishment: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter date  of establishment"
      },
      total_number_of_staff: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter total number of employees"
      }
    }
  },
  {
    container: "griditem",
    items: {
      mission: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter institute mission"
      }
    }
  },
  {
    container: "griditem",
    items: {
      vision: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter institute vision"
      }
    }
  }
];

export const InstituteFromSchema = {
  schema: schema,
  uiSchema: uiSchema
};
