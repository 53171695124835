import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import defaultAvatar from "assets/img/default-avatar.png";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import Hidden from "@material-ui/core/Hidden";

//icons
import Contacts from "@material-ui/icons/Contacts";

import SetChat from "./SetChat";
import { MEDIA_ROOT } from "config.js";
import Drawer from "@material-ui/core/Drawer";
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  contactList: {
    background: "#eeeeee"
  },
  chatSection: {
    width: "100%",
    height: "auto"
  },
  headBG: {
    backgroundColor: "#e0e0e0"
  },
  borderRight500: {
    background: "#eeeeee",
    borderRight: "3px solid #e0e0e0"
  },
  messageArea: {
    height: "100vh",
    overflowY: "auto"
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: "#1e8449" //deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: "#243743" //deepPurple[500],
  },
  root: {
    "&$selected": {
      backgroundColor: "#d2d4ec"
    }
  },
  selected: {
    color: "#220b50"
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.3em"
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(26,65,119,.92)"
      // outline: "1px solid slategrey",
    }
  },
  bgContainer: {
    backgroundImage: "#FFFFFF",
    backgroundSize: "cover",
    boxShadow: "rgb(221, 221, 221) 1px 1px 1px 1px"
  },
  informationContainer: {
    width: "100%",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center"
  },
  text1: {
    fontSize: "60px",
    fontWeight: "600",
    color: "#3c4858",
    lineHeight: "85px"
  },
  text2: {
    fontSize: "1.55rem",
    // fontWeight: "600",
    color: "#3c4858",
    lineHeight: "40px"
  },
  cardTitle: {
    color: "#3c4858",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "400",
    fontSize: "20px",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  chatHeader: {
    background: "#414841eb",
    color: "white",
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center"
  },
  contacts: {
    display: "flex",
    paddingRight: "10px",
    fontWeight: "400",
    cursor: "pointer"
  },
  contactsListDrawer: {},
  targetUserHeader: {
    borderRadius: "5px",
    background: "rgb(81,86,80)",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    fontFamily: '"Poppins", sans-serif',
  }
}));

const Chat = props => {
  const classes = useStyles();
  const userName = props.currentUser.username;
  const userAvatar = props.userAvatar;
  let fetchContacts = props.allContacts;
  const [allContacts, setAllContacts] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const [targetUser, setTargetUser] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const contactPerson = person => {
    let contact =
      person.from.id === props.currentUser.user_id ? person.to : person.from;
    return contact;
  };
  const switchChange = () => {
    setChecked(!checked);
  };

  React.useEffect(
    () => {
      // setAllContacts(props.allContacts);
      let filterContact = !searchValue
        ? fetchContacts
        : fetchContacts.filter(function (i) {
          let customContact = contactPerson(i);
          if (
            customContact.first_name.toLowerCase().includes(searchValue) ||
            customContact.last_name.toLowerCase().includes(searchValue)
          ) {
            return i;
          } else {
            return null;
          }
        });
      setAllContacts(filterContact);
      if (Object.keys(props.messagePerson).length) {
        handleListItemClick(props.messagePerson);
      }
    },
    [searchValue]
  );

  const handleListItemClick = itm => {
    setSelectedIndex(itm.id);
    setTargetUser(itm);
    setChecked(false);
  };

  const displayTargetUser = targetUser => {
    return (
      <List className={classes.targetUserHeader}>
        <ListItem button key="RemySharp">
          <ListItemIcon>
            <Avatar
              // alt="Remy Sharp"
              src={targetUser.avatar ? `${MEDIA_ROOT}${targetUser.avatar}` : defaultAvatar}
            >
              {targetUser.first_name.charAt(0).toUpperCase() + " " + targetUser.last_name.charAt(0).toUpperCase()}
            </Avatar>
          </ListItemIcon>
          <ListItemText style={{fontFamily: "'Poppins'"}}>
            {targetUser.first_name + " " + targetUser.last_name}
          </ListItemText>
        </ListItem>
      </List>);
  }

  const handleSearch = e => {
    let value = e.target.value;
    setSearchValue(value);
    e.preventDefault();
  };

  const contactList = allContacts.map((itm, idx) => {
    let customContact = contactPerson(itm);
    let targetUserName = customContact.first_name
      ? `${customContact.first_name.charAt(0).toUpperCase() +
      customContact.first_name.slice(1)} ${customContact.last_name
        .charAt(0)
        .toUpperCase() + customContact.last_name.slice(1)}`
      : customContact.username.charAt(0).toUpperCase() +
      customContact.username.slice(1);
    return (
      <ListItem
        button
        key={customContact.id}
        selected={selectedIndex === customContact.id}
        classes={{ root: classes.root, selected: classes.selected }}
        onClick={() => handleListItemClick(customContact)}
      >
        <ListItemIcon>
          <Avatar
            alt={targetUserName}
            src={
              customContact.avatar == null
                ? ""
                : `${MEDIA_ROOT}${customContact.avatar}`
            }
            className={classes.purple}
          />
        </ListItemIcon>
        <ListItemText style={{fontFamily: "'Poppins'"}}>{targetUserName}</ListItemText>
        {/* <ListItemText secondary="online" align="right" /> */}
      </ListItem>
    );
  });

  const user = (
    <List>
      <ListItem button key="RemySharp">
        <ListItemIcon>
          <Avatar
            // alt="Remy Sharp"
            src={userAvatar ? `${MEDIA_ROOT}${userAvatar}` : null}
            className={classes.orange}
          >
            {userName.charAt(0).toUpperCase()}
          </Avatar>
        </ListItemIcon>
        {/*<ListItemText>
          {userName.charAt(0).toUpperCase() + userName.slice(1)}
      </ListItemText>*/}
      </ListItem>
    </List>
  );

  return (
    <GridContainer component={Paper} className={classes.chatSection}>
      <GridItem lg={3} md={4} style={{background: "#eeeeee", border: "solid", borderRadius: "10px"}}>
        <Hidden smDown implementation="css">
          {user}
          <Divider />
          <div style={{ padding: "10px" }}>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={e => handleSearch(e)}
              value={searchValue}
            />
          </div>
          <Divider />
          <List style={{ height: "100%", overflow: "auto" }}>
            {contactList}
          </List>
        </Hidden>

        <Hidden mdUp implementation="css">
          <Drawer
            className={classes.contactsListDrawer}
            anchor={"left"}
            open={checked}
            onClose={() => setChecked(false)}
          >
            <Grid item>
              {user}
              <Divider />
              <Grid item xs={12} style={{ padding: "10px" }}>
                <TextField
                  id="outlined-basic-email"
                  label="Search"
                  variant="outlined"
                  fullWidth
                  onChange={e => handleSearch(e)}
                  value={searchValue}
                />
              </Grid>
              <Divider />
              <List style={{ overflow: "auto" }}>
                {contactList}
              </List>
            </Grid>
          </Drawer>
        </Hidden>
      </GridItem>
      {Object.keys(targetUser).length ? (
        <GridItem xs={12} md={8} sm={12} lg={9}>
          <Hidden smDown implementation="css">
            {displayTargetUser(targetUser)}
          </Hidden>
          <Hidden mdUp implementation="css">
            <div className={classes.chatHeader}>
              {displayTargetUser(targetUser)}
              <div onClick={() => switchChange()} className={classes.contacts}>
                <Contacts />{" "}
                <span style={{ paddingLeft: "5px" }}>Contacts</span>
              </div>
            </div>
          </Hidden>
          <SetChat
            className={classes.messageArea}
            targetUser={targetUser}
            screenSize={12}
          />
        </GridItem>
      ) : (
        <GridItem xs={12} md={8} sm={12} lg={9}>
          <Hidden smDown implementation="css">
            <Paper className={classes.bgContainer}>
              <Grid className={classes.informationContainer}>
                <p className={classes.text2}>
                Select a contact to start
                </p>
              </Grid>
            </Paper>
          </Hidden>
          <Hidden mdUp implementation="css">
            <div className={classes.chatHeader}>
              {user}
              <div onClick={() => switchChange()} className={classes.contacts}>
                <Contacts />{" "}
                <span style={{ paddingLeft: "5px" }}>Contacts</span>
              </div>
            </div>
            <Paper className={classes.bgContainer}>
              <Grid className={classes.informationContainer}>
                <p className={classes.text1}>
                  Welcome To Hedera
              <br />
              Connect!
            </p>
                <p className={classes.text2}>
                  Please Add Your <br />
              Contacts in Conversation
            </p>
              </Grid>
            </Paper>
          </Hidden>
        </GridItem>
      )}
    </GridContainer>
  );
};

Chat.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default Chat;