/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert } from '@material-ui/lab';
// core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Question from "components/Question/Question.jsx";
import { getQuestions, submitAnswer, updateQuizStyle } from "../../services/quizDataProvider";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Switch from '@material-ui/core/Switch';

import { ProjectPermissions } from "utils/roles";
import QuizSettings from "./QuizSettings.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { getProjectDetails } from "../../services/projectDataProvider";

const styles = {
  question: {
    marginTop: "10px"
  },
  quizHeaderCard: {
    //backgroundColor: "#894747"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none"
  },
  quizActionsContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  quizActions: {
    display: "flex",
    justifyContent: "space-between"
  },
  quizSectionInfo: {
    color: "black",
    fontSize: "26px"
  },
  quizName: {
    color: "aliceblue",
    fontSize: "16px"
  },
  quizInfo: {
    display: "block",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  quizQuestionsInfo: {
    color: "aliceblue",
    fontSize: "14px"
  },
  quizCompletionInfo: {
      //color: "white",
      fontSize: "14px"
  },
  tableGroupQuestion: {
    border: "1px solid",
    color: "#dcdbd8",
    minWidth: "180px",
  },
  quizTable: {
    marginTop: "15px",
  },
  quizSectionsStepper: {
    display: "flex",
    justifyContent: "flex-start",
    overflow: "scroll",
    alignItems: "center"
  },
  quizSectionStepLabel: {
    width: "fit-content" 
  },
  stepIcon: {color: "red",},
  done: { color: "blue !important" },
  active: { color: "green !important"},
  fail: { color: "red !important" },
  selectQuiz: {
    width: "250px",
    "margin-left": "auto",
    "padding-right": "20px",
  },
};

class Quiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answerData: [],
      showAlert: false,
      alertType: "error",
      alertMessage: "",
      skipped: new Set(),
      activeStep: 0,
      quizRows: [],
      quizDefault: null,
    };
  }

  extractAnswerData(question, answer) {
    if (answer != null && typeof answer === "object") {
      answer = answer.answer;
      if (question.question_type === "single-select") {
        answer = Object.keys(answer).find(key => answer[key] === true);
      } else if (question.question_type === "multi-select") {
        answer = Object.keys(answer).filter(key => answer[key] === true);
      } else if (typeof answer === "object") {
        let theAnswer = null;
        Object.keys(answer).forEach(key => {
          theAnswer = answer[key];
        });
        answer = theAnswer;
      }
    }
    //console.log("answer", question.question, question.question_type, question.answer);
    return answer;
  }

  checkIfMandatoryQuestionsAreAnswered() {
    let allAnswered = true;
    let variablesDict = {};
    this.state.questions.forEach((question, idx) => {
      let answer = this.state.answerData && this.state.answerData[idx];
      if (answer == null) {
        answer = question.answer;
      }

      if (question.required === true) {
        allAnswered = allAnswered && (answer !== null)
      }

      // Check the visibility conditions for the questions
      if (question.variable_name != null && question.variable_name.length > 0) {
        // console.log("question has variable name answer: ", question, "answer", answer);
        answer = this.extractAnswerData(question, answer);
        variablesDict[question.variable_name] = answer;
      }
    });
    return {
      allMandatoryQuestionsAnswered: allAnswered,
      variablesDict: variablesDict
    };
  }

  checkQuestionVisibilityCondition(questionIdx) {
    const questions = this.state.questions;
    const variablesDict = this.state.variablesDict || {};
    if (questions == null || questions.length === 0) {
      return true;
    }
    const question = questions[questionIdx];
    let visibilityCondition = question.visibility_condition;
    if (
      visibilityCondition == null
        ||
      visibilityCondition.length === 0
    ) {
        return true;
    }
    //console.log(" checkQuestionVisibilityCondition variablesDict", variablesDict);
    //console.log(" checkQuestionVisibilityCondition visibilityCondition", questionIdx,question);
    Object.keys(variablesDict).sort((a, b) => b.length - a.length).forEach(key => {
      let val = variablesDict[key];
      val = this.unpackToString(val);
      const key_regex = new RegExp('\\b' + key + '\\b', 'g');
      visibilityCondition = visibilityCondition.replaceAll(key_regex, val);
    });
    
    let result = false;
    try {
      // eslint-disable-next-line
    result = eval(visibilityCondition);
    } catch {
      console.log("Error eval visibilityCondition: ", visibilityCondition, " for question: ", question.variable_name);
    };
    return result === true;
  }

  unpackToString(data) {
    let resultStr = '';
    if (data === null) {
      resultStr = data;  // Handle the case where data is null
    } else if (!isNaN(data)) {  // Check if the string can be converted to a number
      resultStr += ` ${Number(data)}`;  // Unpack as a number
    } else if (typeof data === "string") {
      resultStr += ` "${data}"`;
    } else if (typeof data === "object") {
      resultStr += JSON.stringify(data);
    } else {
      resultStr = data;
    }
    return resultStr;
  }

  calculateRequiredCompletionPercentage(currentSection) {
    const questions = this.state.questions;
    let totalVisibleRequiredQuestions = 0;
    let totalAnsweredQuestions = 0;
    
    questions.forEach((question, idx) => {
      if (this.checkQuestionVisibilityCondition(idx) && question.required && question.section_name === currentSection) {
        totalVisibleRequiredQuestions += 1;
        let answer = this.state.answerData && this.state.answerData[idx];
        if (answer == null) {
          answer = question.answer;
        }
        
        answer = this.extractAnswerData(question, answer);
        // console.log("extractAnswerData", question, answer);
        if (question.question_type === "multi-select") {
          if (answer && answer.length > 0) {
            totalAnsweredQuestions += 1;
          }
        } else {
          if (answer != null && answer !== '') {
            totalAnsweredQuestions += 1;
          }
        }
      }
    });
    return {
      'total': totalVisibleRequiredQuestions,
      'answered': totalAnsweredQuestions
    }
  }

  calculateQuizCompletion() {
    
    const questions = this.state.questions;
    let totalVisibleQuestions = 0;
    let totalAnsweredQuestions = 0;

    
    questions.forEach((question, idx) => {

      // check if question is visible
      if (this.checkQuestionVisibilityCondition(idx)) {
        let questionAnswerOptions = question.options.filter(option => option.type !== "mapping");      
        // check if a question is of type note:
        if (questionAnswerOptions && questionAnswerOptions.length > 0) {
          totalVisibleQuestions += 1;
          
          let answer = this.state.answerData && this.state.answerData[idx];

          if (answer == null) {
            answer = question.answer;
          }
          answer = this.extractAnswerData(question, answer);
          if (question.question_type === "multi-select") {
            if (answer && answer.length > 0) {
            totalAnsweredQuestions += 1;
            }
          } else {
            if (answer != null && answer !== '') {
              totalAnsweredQuestions += 1;
            }
          
          }
        }
      }
      
    });
    //console.log('calc',totalAnsweredQuestions,totalVisibleQuestions,comp);
    let percentage = totalVisibleQuestions > 0 ? (totalAnsweredQuestions * 100 / totalVisibleQuestions).toFixed(1): 0;
    return {'total': totalVisibleQuestions, 'answered': totalAnsweredQuestions, 'percentage': percentage};
  }

  /*
  calculateQuizCompletionPercentage() {
    const questions = this.state.questions;
    let totalVisibleQuestions = 0;
    let totalAnsweredQuestions = 0;
    questions.forEach((question, idx) => {
      if (this.checkQuestionVisibilityCondition(idx)) {
        
        let questionAnswerOptions = question.options.filter(option => option.type !== "mapping");
        let questionHasValidAnswerOptions = questionAnswerOptions && questionAnswerOptions.length > 0;
        //console.log(question.question, question.options);
        let answer = this.state.answerData && this.state.answerData[idx];
        if (answer == null) {
          answer = question.answer;
        }
        answer = this.extractAnswerData(question, answer);
        // console.log("extractAnswerData", answer, typeof answer);
        if (!questionHasValidAnswerOptions) {
          totalVisibleQuestions += 1;
          // this is done to handle question type "note".  These are treated as single/multi select, but without answer mapping
          if (questionAnswerOptions.length>0) {totalAnsweredQuestions += 1;}
        } else if (question.question_type === "multi-select") {
          if (answer && answer.length > 0) {
            totalAnsweredQuestions += 1;
          }
        } else {
          if (answer != null && answer !== '') {
            totalAnsweredQuestions += 1;
          }
        }
      }
    });
    //let comp = totalVisibleQuestions > 0 ? (totalAnsweredQuestions * 100 / totalVisibleQuestions).toFixed(2): 0;
    //console.log('calc',totalAnsweredQuestions,totalVisibleQuestions,comp);
    return totalVisibleQuestions > 0 ? (totalAnsweredQuestions * 100 / totalVisibleQuestions).toFixed(2): 0;
  }
  */

  answerUpdated = questionIndex => data => {
    const questions = this.state.questions;
    let answers = this.state.answerData;
    let answerData = answers[questionIndex] || {};
    answerData["question"] = questions[questionIndex];
    answerData["answer"] = data;
    answers[questionIndex] = answerData;
    // update answers
    this.setState({
        ...this.state, answerData: answers,
        ...this.checkIfMandatoryQuestionsAreAnswered()
    },
    // update quiz overall statistics 
    /*() => {
      this.setState({ 
        completionPercentage: this.calculateQuizCompletion().percentage, //this.calculateQuizCompletionPercentage(), 
        totalVisible: this.calculateQuizCompletion().total,
        totalAnswered: this.calculateQuizCompletion().answered 
      });
    }*/
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleClick = name => event => {
    let nextIndex = this.state.activeStep || 0;
    let payload = {
      "answers": this.state.answerData && this.state.answerData.map(answerDt => {
        return {
          answer: answerDt.answer,
          question: {
            question_id: answerDt.question && answerDt.question.question_id
          }
        };
      }),
      "completionPercentage": this.calculateQuizCompletion().percentage, //this.state.completionPercentage,
      "totalVisible": this.calculateQuizCompletion().total, //this.state.totalVisible,
      "totalAnswered": this.calculateQuizCompletion().answered //this.state.totalAnswered
    };
    if (name === "exit") {
      setTimeout(() => {
        if (this.state.projectDetails && this.state.projectDetails.id) {
          window.location = `/projects/${this.state.projectDetails.id}/surveys`;
        } else {
          window.location = `/projects/`;
        }
      }, 100);
    } else if (name === "finish") {
      submitAnswer(this.props.axios, payload).then(data => {
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: "Submitted answers successfully! Redirecting back to the project!"
        }, () => {
          setTimeout(() => {
            if (this.state.projectDetails && this.state.projectDetails.id) {
              window.location = `/projects/${this.state.projectDetails.id}/surveys`;
            } else {
              window.location = `/projects/`;
            }
          }, 3000);
        });
      }).catch(error => {
        console.log("Error submitting the answers", error);
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Failed to submit the answers!"
        });
      });
    } else if ((name === "save" || name === "save&next") && this.state.answerData) {
      submitAnswer(this.props.axios, payload).then(data => {
        //console.log("data", data);
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: "Saved answers successfully!"
        }, () => {
          setTimeout(() => {
            this.setState({
              showAlert: false
            });
          }, 3000);
        });
      }).catch(error => {
        console.log("Error saving the answers", error);
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Failed to submit the answers!"
        });
      });
    }
    if (name === "last" && nextIndex > 0) {
      nextIndex = nextIndex - 1;
      this.handleBack();
    } else if ((name === "next" || name === "save&next")  && nextIndex < this.state.totalSections) {
      const anchor = document.getElementById("sectionInfo");
      if (anchor) {
          anchor.scrollIntoView({ behavior: 'instant' });
        }
      
      nextIndex = nextIndex + 1;
    } else if (name === "start") {
      nextIndex = 0;
      this.handleNext();
    }
    if (this.state.index !== nextIndex) {
      this.setState({ activeStep: nextIndex });
    }
  };

  handleSettingsUpdate = settings => {
    if (settings.styling_info) {
      this.setState({stylingInfo: settings.styling_info, settings: settings});
    }
  };

  updateQuizSettings = e => {
    const stylingInfo = this.state.stylingInfo;
    if (stylingInfo) {
      updateQuizStyle(this.props.axios, this.state.form_id, stylingInfo).then(data => {
        // this.setState({ ...data });
        // console.log("Updated quiz style", data);
      });
    }
  };

  isStepOptional = (step) => {
    return false;
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  };

  handleNext = () => {
    let newSkipped = this.state.skipped;
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }

    this.setState({
      activeStep: ((prevActiveStep) => prevActiveStep + 1),
      skipped: newSkipped
    })
  };

  handleBack = () => {
    this.setState({
      activeStep: ((prevActiveStep) => prevActiveStep - 1)
    })
  };

  handleReset = () => {
    this.setState({activeStep: 0});
  };

  componentDidMount() {
    getQuestions(this.props.axios, this.props.name).then(data => {
      const questions = data && data.results;
      const projectDetails = data && data.project_details;
      const userPermissions = data && data.user_permissions;
      if (questions != null) {
        const groupedQuestions = this.groupQuestionsBySection(questions);
        this.setState({
          form_id: data.form_id,
          questions: questions,
          groupedQuestions: groupedQuestions,
          totalSections: Object.keys(groupedQuestions).length,
          projectDetails: projectDetails,
          userPermissions: userPermissions,
          quizActive: data && data.form_active,
          acceptSubmissions:  data && data.accept_submissions,
          stylingInfo: (data.form_metadata && data.form_metadata.styling_info) || {}
        },
        () => this.setState({...this.checkIfMandatoryQuestionsAreAnswered()},
          () => this.setState({ 
            completionPercentage: this.calculateQuizCompletion().percentage, //this.calculateQuizCompletionPercentage(),
            totalVisible: this.calculateQuizCompletion().total,
            totalAnswered: this.calculateQuizCompletion().answered}))
        );
      }
      this.getProjectDetails();
    });
    
    let quizName = this.props.name;
    quizName = decodeURIComponent(quizName);
    this.setState({ quizDefault: quizName });
  }
  
  getProjectDetails = () => {
    getProjectDetails(this.props.axios, this.state.projectDetails.id).then(
      (data) => {
        let allSurveys = data.quizzes;
        this.setState({ quizRows: allSurveys });
      }
    );
  };

  groupQuestionsBySection(questions) {
    let groups = {};

    questions.forEach((question, idx) => {
      const section = question.section_name;
      
      let existingQuestions = groups[section];
      if (existingQuestions != null) {
        existingQuestions.push({question: question, idx: idx});
      } else {
        groups[section] = [{question: question, idx: idx}];
      }
    });

    return groups;
  }


  renderSectionQuestions(questions) {
    const {classes} = this.props;
    const tableGroupedQuestions = {};

    let questionsList = [];
    let questionsListIdxTracker = 0
    let tableIdxTracker = {};

    const userPermissions = this.state.userPermissions;
    const userCanEditQuestions = userPermissions && userPermissions.includes(ProjectPermissions.UPDATE_PROJECT_SURVEYS);

    questions.forEach(questionData => {
      const questionMetadata = questionData.question.metadata || {};
      const {table_group} = questionMetadata;

      // console.log("table_group", table_group, questionData);
      if (table_group === undefined || table_group === null || table_group.length === 0) {
        questionsList.push(questionData);
        questionsListIdxTracker += 1;
      } else {
        // table_group have table name and row name
        let tempTable = table_group.split(' ');
        let tableName = tempTable[0];
        let rowName = '0';
        if (tempTable.length > 0) {
          rowName = tempTable[1];
        }

        // store the questions index for the table
        if (tableIdxTracker[tableName] === undefined) {
          tableIdxTracker[tableName] = questionsListIdxTracker;
          questionsListIdxTracker += 1;
        }

        let existingRows = tableGroupedQuestions[tableName] || {};
        let existingQuestionsForRow = existingRows[rowName] || [];

        existingQuestionsForRow.push({question: questionData.question, idx: questionData.idx});
        existingRows[rowName] = existingQuestionsForRow;
        tableGroupedQuestions[tableName] = existingRows;
        questionsList[tableIdxTracker[tableName]] = Object.keys(existingRows).map(key => existingRows[key]);
      }
    });

    return questionsList.map(questionsData => {
      if (Array.isArray(questionsData)) {
        return (
          <table className={classes.quizTable}>
            {questionsData.map(questionRow => {
              return (
                <tr>
                  {questionRow.map(qData => {
                    return (
                      <td className={classes.tableGroupQuestion}>{this.renderQuestion(qData, true)}</td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        );
      }
      return this.renderQuestion(questionsData, false, userCanEditQuestions);
    });
  }

  renderQuestion(questionData, isTable=false, isEditable) {
    const {classes} = this.props;
    const {question, idx} = questionData;
    let answerData = this.state.answerData[idx];
    if (answerData == null) {
      answerData = question['answer'];
    }
    let questionVisible = true;
    if (this.state.variablesDict !== null) {
      questionVisible = this.checkQuestionVisibilityCondition(idx);
    }
    if (questionVisible !== true) return null;
    return (
      <Question
        className={classes.question}
        axios={this.props.axios}
        isTable={isTable}
        key={`question-${idx}`}
        data={question}
        answer={answerData && answerData.answer}
        onChange={this.answerUpdated(idx)}
        editable={isEditable}
      />
    );
  }

  renderEditStylesButton() {
    const userPermissions = this.state.userPermissions;
    const stylingInfo = this.state.stylingInfo || {};
    const actionButtonColor = stylingInfo.primaryActionButtonColor || "primary";
    return (
      userPermissions && 
      userPermissions.includes(ProjectPermissions.UPDATE_PROJECT_STYLES) && 
      <FormControlLabel
        control={
          <Switch
            checked={this.state.showSettings === true}
            onChange={e => this.setState({showSettings: !this.state.showSettings})}
            value="editable"
            color={actionButtonColor}
          />
        }
        label="Change quiz settings"
      />
    );
  }

  quizChanges(e) {
    let quizName = e.label;
    this.props.history.push(`/quiz/${quizName}`);
    window.location.reload();
  }
  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, className } = this.props;
    const questions = this.state.questions;
    const stylingInfo = this.state.stylingInfo || {};
    const userPermissions = this.state.userPermissions;

    if (questions == null) return null;

    const groupedQuestions = this.state.groupedQuestions;
    let sections = Object.keys(groupedQuestions);
    const currentSectionIdx = this.state.activeStep || 0;
    const currentSection = sections[currentSectionIdx];
    const progressPercentage = currentSectionIdx * 100 / sections.length;
    let quizName = this.props.name;
    quizName = decodeURIComponent(quizName);

    let requiredCheck = this.calculateRequiredCompletionPercentage(currentSection);
    const totalRequired = requiredCheck.total;
    const requiredAnswered = requiredCheck.answered;
    let percentageRequired = requiredCheck.answered * 100 / requiredCheck.total;

    let finalQuizRows = [];
    let quizs = this.state.quizRows ? this.state.quizRows.length : 0;

    for (let i = 0; i < this.state.quizRows.length; i++) {
      finalQuizRows.push({
        label: this.state.quizRows[i].name,
        value: this.state.quizRows[i].name,
      });
    }

    return (
      <div>
        <Card className={className} style={stylingInfo.quizPage ? stylingInfo.quizPage : null}>
        {quizs ? (
            <div style={styles.selectQuiz}>
              <CustomSelect
                value={this.state.quizDefault}
                items={finalQuizRows}
                onChange={(e) => this.quizChanges(e)}
              />
            </div>
          ) : null}
          <CardHeader className={classes.quizHeaderCard} style={stylingInfo.quizHeader ? stylingInfo.quizHeader : null}>
            {currentSection &&
              <h4 id="sectionInfo" className={classes.quizSectionInfo} style={stylingInfo.quizHeader ? stylingInfo.quizHeader : null}>
                Section:  {currentSection}
              </h4>
            }
            <p className={classes.quizHeaderCard}>
              Form: {quizName}
            </p>
            { !this.state.quizActive && 
              <p className={classes.quizHeaderCard}>
                <h6>Deactivated</h6>
              </p>
            }
            { !this.state.acceptSubmissions && 
              <p className={classes.quizHeaderCard}>
                <h6>Not accepting submissions anymore!</h6>
              </p>
            }
            <div className={classes.quizInfo}>
              <div className={classes.quizCompletionInfo}>
                {/*<h5>
                  {this.state.completionPercentage < 100.0
                    ? `Total answers: ${this.state.totalAnswered}  (${this.state.completionPercentage} % of ${this.state.totalVisible} currently visible questions)`
                    : `Completed`
                  }
                </h5>
                */}
                <h5>
                  {`Answered ${requiredAnswered} of ${totalRequired} required questions in this section`}
                </h5>
                { this.state.completionPercentage < 100.0 &&
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow={percentageRequired} aria-valuemin="0" aria-valuemax="100" style={{width: `${percentageRequired}%`}}>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className={classes.actionsContainer}>
              {this.renderEditStylesButton()}
            </div>
          </CardHeader>
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="primary" variant="determinate" value={progressPercentage} />
          </Box>
          <Stepper activeStep={this.state.activeStep} className={classes.quizSectionsStepper}>
            {sections.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              labelProps.style = styles.stepLabelStyle
              labelProps.label = styles.stepLabelStyle
              if (this.isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  className={classes.quizSectionsStep}
                  key={label}
                  {...stepProps}
                  onClick={() => this.setState({ activeStep: index })}>
                  <StepLabel 
                    className={classes.quizSectionStepLabel}
                    StepIconProps={{
                      classes: { 
                      root: classes.stepIcon,
                      completed: classes.done,
                      active: classes.active,
                      error: classes.fail
                    }
                }}>{label}</StepLabel> 
                </Step>
              );
            })}
          </Stepper>
          <CardBody style={stylingInfo.tabContent ? stylingInfo.tabContent : null}>
            { this.renderSectionQuestions(groupedQuestions[currentSection]) }
          </CardBody>
          <CardFooter>
            <GridContainer>
              <GridItem xl={12} lg={12} sm={12} md={12}>
                { this.state.showAlert &&
                  <Alert severity={this.state.alertType} sx={{ width: '100%' }}>
                    {this.state.alertMessage}
                  </Alert>
                }
              </GridItem>
              <GridItem xl={12} lg={12} sm={12} md={12}>
                <div className={classes.quizActionsContainer}>
                  <div className={classes.quizActions}>
                    <Button
                      color="secondary"
                      disabled={currentSectionIdx <= 0}
                      onClick={this.handleClick("start")}
                    >
                      Go to Start
                    </Button>
                    </div>
                    <div>
                    <Button
                      color="secondary"
                      disabled={currentSectionIdx <= 0}
                      onClick={this.handleClick("last")}
                    >
                      Previous page
                    </Button>
                    {questions && currentSectionIdx < this.state.totalSections - 1 ? (
                      this.state.quizActive && this.state.acceptSubmissions
                        ?
                          <Button color="secondary" onClick={this.handleClick("save&next")}>
                            Next page
                          </Button>
                        :
                          <Button color="primary" onClick={this.handleClick("next")}>
                            Next
                          </Button>
                    ) : (
                      <Button color="primary" onClick={this.handleClick("save")} disabled={!(this.state.quizActive && this.state.acceptSubmissions)}>
                        Save Answers
                      </Button>
                    )}
                    </div>
                    <div className={classes.quizActions}>
                      { this.state.quizActive && this.state.acceptSubmissions ?
                          <Button
                              color="secondary"
                              onClick={this.handleClick("finish")}>
                            Save & Exit
                          </Button>
                        :
                          <Button
                              color="success"
                              onClick={this.handleClick("exit")}>
                            Exit
                          </Button>
                      }
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
        {
          userPermissions && 
          userPermissions.includes(ProjectPermissions.UPDATE_PROJECT_STYLES) && 
          this.state.showSettings &&
          <QuizSettings
            axios={this.props.axios}
            settings={this.state.stylingInfo}
            onUpdate={this.handleSettingsUpdate}
            onSubmit={this.updateQuizSettings}
            onClose={e => this.setState({showSettings: false})}
          />
        }
      </div>
    );
  }
}

export default withStyles(styles)(Quiz);
