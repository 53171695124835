/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Wizard from "components/Wizard/Wizard.jsx";
import ProjectForm from "./WizardSteps/ProjectForm.js";
import LastStep from "./WizardSteps/LastStep.js";
import TopicStep from "./WizardSteps/TopicStep.js";
import ImportConfiguration from './WizardSteps/ImportConfiguration.jsx'
// import {
//   getProjectDetails,
//   createComment
// } from "../../services/projectDataProvider";

// import { MEDIA_ROOT } from "config.js";

const styles = {};

class CreateProject extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.content} style={{ marginTop: "-10px" }}>
          <div className={classes.container}>
            <Wizard
              validate
              steps={[
                {
                  stepName: "Project Details",
                  stepComponent: ProjectForm,
                  stepId: "project"
                },
                {
                  stepName: "Import configuration",
                  stepComponent: ImportConfiguration,
                  stepId: "importConfiguration"
                },
                {
                  stepName: "Topics",
                  stepComponent: TopicStep,
                  stepId: "topics"
                },
                {
                  stepName: "Finish",
                  stepComponent: LastStep,
                  stepId: "finish"
                }
              ]}
              title="New project"
              subtitle=""
              finishButtonClick={e => this.props.history.push("/projects")}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreateProject);
