import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";

import { withAxios } from "containers/authenticated.js";
import { completeRegistration } from "services/userDataProvider.js";
import { updateData } from "services/instituteDataProvider.js";

import CustomForm from "./CustomForm.js";
import { FinancialInstituteFormSchema } from "./FormSchemas/FinancialInstituteFormSchema.js";
import { InvestorFormSchema } from "./FormSchemas/InvestorFormSchema.js";
import { TechnologyProviderFormSchema } from "./FormSchemas/TechnologyProviderFormSchema.js";
import { TechnicalAssistanceFormSchema } from "./FormSchemas/TechnicalAssistanceFormSchema.js";
import { AcademiaFormSchema } from "./FormSchemas/AcademiaFormSchema.js";
import { NGOFormSchema } from "./FormSchemas/NGOFormSchema.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class InstituteDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.formSchema = null;
  }
  sendState() {
    return this.state;
  }

  nextButtonClick() {
    if (this.formSchema === null) {
      return completeRegistration(
        this.props.axios,
        this.props.user.user_id
      ).then(data => true);
    }
    const instituteData = {
      type_details: this.formSchema && this.formSchema.schema.data()
    };
    return updateData(this.props.axios, this.instituteId, instituteData).then(
      data => {
        completeRegistration(this.props.axios, this.props.user.user_id).then(
          data => true
        );
      }
    ).catch(error => {
      console.log("error updating institute details", error);
      alert("Something went wrong!");
    });
  }

  fixInputState(inputState) {
    if (this.state[inputState] !== "success") {
      this.setState({ [inputState]: "error" });
    }
  }

  isValidated() {
    if (this.formSchema === null) {
      return true;
    }
    return this.formSchema && this.formSchema.schema.validate();
  }

  renderInstituteForm(instituteId, instituteType) {
    this.instituteId = instituteId;
    switch (instituteType) {
      case "Financial Institution":
        this.formSchema = FinancialInstituteFormSchema;
        break;
      case "Investor/Asset manager":
        this.formSchema = InvestorFormSchema;
        break;
      case "Technology/Service Provider":
        this.formSchema = TechnologyProviderFormSchema;
        break;
      case "NGO":
        this.formSchema = NGOFormSchema;
        break;
      case "Governamental agencies":
        this.formSchema = null;
        break;
      case "Technical assistance providers":
        this.formSchema = TechnicalAssistanceFormSchema;
        break;
      case "Academia":
        this.formSchema = AcademiaFormSchema;
        break;
      default:
      //this.formSchema = null;
      //   this.instituteId = 42;
    }
    if (this.formSchema != null) {
      return <CustomForm formSchema={this.formSchema} />;
    } else {
      return "No details needed.";
    }
  }

  render() {
    // const { classes } = this.props;
    const { instituteId, instituteType } = this.props.allStates.institute || {};
    return (
      <GridContainer justify="center">
        {this.renderInstituteForm(instituteId, instituteType)}
      </GridContainer>
    );
  }
}

InstituteDetailsForm.propTypes = {
  classes: PropTypes.object
};

export default withAxios(withStyles(style)(InstituteDetailsForm));
