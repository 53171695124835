import logo from "assets/img/hedera-logo.png";
import logoSmall from "assets/img/HEDERA-blatt.png";
//import companyLogo from "assets/img/logo.png";
let apiEnv = process.env.REACT_APP_API_ENV || "test";

export const staticData = {
  MadeWithLove: {
    orgTitle: "HEDERA",
    orgLink: "https://hedera.online/",
    tagline: "Developed by "
  },
  company: {
    name: "HEDERA",
    logoSmall: logoSmall,
    logo: logo,
    logoText: "Hedera Connect",
    logoURL: logo,
    url: "https://hedera.online/",
    tagline: "Digital solutions for sustainable development",
  }
};

export const SENTRY_DSN = process.env.SENTRY_DSN;

// export const API_ROOT = "http://localhost:8001";
// export const MEDIA_ROOT = "http://localhost:8001";
// export const WEB_SOCKET_API_PATH = "ws://localhost:8001/ws/chat";

export const API_ROOT = `https://h-connect-api.${apiEnv}.hedera.online`;
export const MEDIA_ROOT = `https://h-connect-api.${apiEnv}.hedera.online`;
export const WEB_SOCKET_API_PATH = `wss://h-connect-api.${apiEnv}.hedera.online/ws/chat`;
export const FORM_PATH = `https://forms.prod.hedera.online/forms/`
