import React, { Fragment } from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import PostAddIcon from "@material-ui/icons/PostAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { withAxios, getAccessToken } from "containers/authenticated.js";
import { logout } from "services/authProvider.js";
import {
  getNotifications,
  readNotification,
} from "services/userDataProvider.js";
import { buttonColorScheme } from "../../assets/jss/material-dashboard-react/layouts/appstyleconfigs";

class HeaderLinks extends React.Component {
  state = {
    notifications: false,
    user: false,
    userNotifications: [],
    interval: "",
  };

  componentDidMount() {
    this.fetchNotification();
    let setNotification = setInterval(() => this.fetchNotification(), 10000);
    this.setState({ interval: setNotification });
  }

  fetchNotification = () => {
    let token = getAccessToken();
    if (!token) {
      clearInterval(this.state.interval);
    } else {
      getNotifications(token).then((data) =>
        this.setState({ userNotifications: data.results }),
      );
    }
  };

  handleToggle = (name) => () => {
    this.setState((state) => ({ [name]: !state.open }));
  };

  handleNotificationsToggle() {
    this.handleToggle("notifications")();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  readNotificationExecute(notificationId) {
    readNotification(this.props.axios, notificationId).then(
      this.fetchNotification,
    );
  }

  readAllNotifications() {
    Promise.all(
      this.state.userNotifications.map(({ id }) =>
        readNotification(this.props.axios, id),
      ),
    ).then(() => {
      this.handleNotificationClose(null)({});
      this.fetchNotification();
    });
  }

  handleNotificationClose = (notificationId, data) => (event) => {
    this.setState({ notifications: false, user: false });
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (notificationId) {
      readNotification(this.props.axios, notificationId).then(() => {
        this.fetchNotification();
      });
    }
    if (data) {
      const jsonString = data.replaceAll("'", '"');
      var jsonData = JSON.parse(jsonString);
      this.props.history.push(jsonData.link);
    }
  };

  handleClose = (notificationId) => (event) => {
    this.setState({ notifications: false, user: false });
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (notificationId) {
      readNotification(this.props.axios, notificationId).then((data) => {
        //to read notification
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  logout = (event) => {
    logout();
    this.props.history.push("/start");
  };

  renderNotificationIcon(contentType) {
    switch (contentType) {
      case 42:
        return <PostAddIcon />;
      // case ???:
      //   return <PersonIcon />
      // case ???:
      //   return <BusinessIcon />
      default:
        return <PostAddIcon />;
    }
  }

  render() {
        const { classes } = this.props;
    const { notifications, user } = this.state;
        return (
      <div>
        <div className={classes.manager}>
          <Button
            ref={(node) => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={notifications ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={() => this.handleNotificationsToggle()}
            className={classes.buttonLink}
          >
            <Notifications className={classes.icons} />
            {this.state.userNotifications &&
            this.state.userNotifications.length > 0 ? (
              <span className={classes.notifications}>
                {this.state.userNotifications.length}
              </span>
            ) : null}
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Notifications
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={notifications}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !notifications }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={this.handleNotificationClose(null)}
                  >
                    <List
                      className={`${classes.notificationsListContainer} ${!this.state.userNotifications.length ? classes.overflowHidden : ""}`}
                    >
                      <Box
                        display="flex"
                        marginBottom="8px"
                        justifyContent={
                          this.state.userNotifications.length
                            ? "space-between"
                            : "flex-end"
                        }
                        alignItems="center"
                        paddingX="16px"
                      >
                        {!!this.state.userNotifications.length && (
                          <div>
                            <Button
                              color={buttonColorScheme.infoButton}
                              size="sm"
                              onClick={() => this.readAllNotifications()}
                            >
                              <VisibilityIcon />
                              Mark all as viewed
                            </Button>
                          </div>
                        )}
                        <IconButton
                          onClick={this.handleNotificationClose(null)}
                          component="span"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Divider variant="fullWidth" component="li" />
                      {this.state.userNotifications.length ? (
                        this.state.userNotifications.map((notif, idx) => {
                          return (
                            <Fragment key={notif.id}>
                              {!!idx && (
                                <Divider variant="fullWidth" component="li" />
                              )}
                              <ListItem
                                alignItems="center"
                                style={{
                                  backgroundColor: "rgba(60, 72, 88, 0.1)",
                                  marginTop: "3px", // Additional top margin
                                  marginBottom: "3px", // Additional bottom margin
                                  marginRight: "3px",
                                  marginLeft: "3px",
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    style={{ backgroundColor: "#1E8449" }}
                                  >
                                    {this.renderNotificationIcon(
                                      notif.actor_content_type,
                                    )}
                                  </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                  primaryTypographyProps={{
                                    style: {
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      fontFamily: '"Poppins"', // Font family
                                      fontSize: "0.8rem",
                                    },
                                  }}
                                  primary={
                                    <Link
                                      style={{ cursor: "pointer" }}
                                      onClick={this.handleNotificationClose(
                                        notif.id,
                                        notif.data,
                                      )}
                                      color="inherit"
                                    >
                                      {notif.description}
                                    </Link>
                                  }
                                />
                                <IconButton
                                  onClick={() =>
                                    this.readNotificationExecute(notif.id)
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </ListItem>
                            </Fragment>
                          );
                        })
                      ) : (
                        <Box marginBottom="8px" marginTop="8px" paddingX="16px">
                          <h6>You do not have new notifications</h6>
                        </Box>
                      )}
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        <div className={classes.manager}>
          <Button
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={user ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle("user")}
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Account
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={user}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !user }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose(null)}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.logout}
                        className={classes.dropdownItem}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(withAxios(HeaderLinks));
