
/*
* Registation Theme
*/
export const registrationColorScheme = {
  backgroundColor: "#7e2f6d",
  textColor: '#fff',
  buttonBackgroundColor: '#358DC2',
  buttonColor: '#fff'
}

const appStyle = theme => ({
  "@global": {
    body: {
      //backgroundColor: theme.palette.common.white
      backgroundColor: "#fff",
      //backgroundImage: "url(" + image + ")"
    }
  },
  footer: {
    color: "#eeeeee",
    textAlign: "left",
    margin: theme.spacing(1),
    fontSize: "10px",
    link: {
      color: "eeeeee"
    }
  },
  subtitle: {
    textAlign: "center",
    margin: theme.spacing(1),
    fontSize: "20px"
  },
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: '"Poppins", sans-serif',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  logo: {
    objectPosition: "50% 50%",
    width: "250px",
    objectFit: "cover"
  },
  logoSmall: {
    objectPosition: "50% 50%",
    width: "100px",
    objectFit: "cover",
    marginBottom: "80px"
  },
  hedera: {
    fontFamily: '"Poppins", sans-serif',
  },
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    color: "primary",
    fontFamily: '"Poppins", sans-serif',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: "primary",
  },
  button: {
    margin: theme.spacing(3, 3, 2),
    fontFamily: '"Poppins", sans-serif',
    fontSize: "16px",
    backgroundColor: registrationColorScheme.buttonBackgroundColor,
    color: registrationColorScheme.buttonColor
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: registrationColorScheme.backgroundColor,
    color: registrationColorScheme.textColor,
    minHeight: "100vh"
    //height: 'calc(100vh)' adding this creates issues in the second step
  },
  paperSubsection: {
    height: 'fit-content',
    maxWidth: "90%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },

  },
  paperContainer: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  registrationButtonStyle: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: '"Poppins", sans-serif',
    fontSize: "16px",
    backgroundColor: registrationColorScheme.buttonBackgroundColor,
    color: registrationColorScheme.buttonColor
  },
  hederaFooter: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: "16px"
  },
  bottomBox: {
    marginBottom: "15px",
    bottom:0,
    width:"100%",
    position: 'absolute',
  },
});

export default appStyle;
