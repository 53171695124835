/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import NotListedLocation from "@material-ui/icons/NotListedLocation";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";
import { buttonColorScheme } from "../../../src/assets/jss/material-dashboard-react/layouts/appstyleconfigs.jsx"

import { getData } from "services/userDataProvider.js";

const styles = {
  filterContainer: {
    zIndex: "999 !important"
  },
  cardTitle: {
    color: "#3C4858",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontSize: "24px",
    fontFamily:
      "'Josefin Sans','Open Sans', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif'",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardBody: {
    fontSize: "16px"
  },
  createInstituteButton: {
    textAlign: "right"
  },
  statusLabel: {
    color: "#FFF",
    fontWeight: "bolder"
  }
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
    this.state = {
      instituteOptions: []
    };
  }

  componentDidMount() {
    getData(this.props.axios, this.props.user && this.props.user.user_id).then(
      data => {
        let instituteOptions = [];
        data &&
          data.roles.forEach(roleData => {
            if (
              roleData.role !== "Contact" &&
              roleData.role !== "Follower" &&
              roleData.role !== "Admin"
            ) {
              let instituteData = {
                name: roleData.institute_name,
                id: roleData.institute,
                avatar: roleData.institute_avatar,
                currentUsersRoles: {}
              };
              instituteOptions.push(instituteData);
            }
          });

        data &&
          data.roles.forEach(roleData => {
            instituteOptions.forEach(instituteData => {
              if (instituteData.id === roleData.institute) {
                if (roleData.role === "Admin") {
                  instituteData["isAdmin"] = roleData.approved;
                }
                instituteData["currentUsersRoles"][roleData.role] = roleData.approved;
              }
            });
          });

        this.setState({ ...data, instituteOptions: instituteOptions });
      }
    );
  }

  renderAdminView(institute) {
    const {classes} = this.props;
    return institute.currentUsersRoles && (
      <div className={classes.instituteUserRoles}>
        {Object.keys(institute.currentUsersRoles).map(roleName => {
          const roleStatus = institute.currentUsersRoles[roleName] ? "Approved" : "Unapproved";
          return (
            <Tooltip
              key={`${institute.id}-${roleName}`}
              title={`The role is ${roleStatus}`}
              placement="bottom"
              classes={{ tooltip: this.props.classes.tooltip }}
            >
              <Chip
                color={institute.currentUsersRoles[roleName] ? "success" : "warning"}
                variant="outlined"
                size="small"
                label={roleName}
              />
            </Tooltip>
          );
        })}
      </div>
    );
  }

  renderInstituteSelector() {
    const instituteOptions = this.state.instituteOptions;
    const { classes } = this.props;
    return (
      <div className={classes.filterContainer}>
        <GridContainer>
          {instituteOptions.map((instData, idx) => (
            <InstituteSummary
              key={`institute-${idx}`}
              instituteData={instData}
              isInstituteList={true}
              renderAdminView={() => this.renderAdminView(instData)}
            />
          ))}
        </GridContainer>
      </div>
    );
  }

  renderCreateInstituteButton() {
    const { classes } = this.props;
    return (
      <div className={classes.createInstituteButton}>
        <span>
          <Tooltip
            id="tooltip-top"
            title="Create New Institute"
            placement="bottom"
            classes={{ tooltip: this.props.classes.tooltip }}
          >
            <Button
              color = {buttonColorScheme.createButton}
              onClick={() => window.open("/create-institute")}
            >
              <AddIcon fontSize="40px" aria-label="add" />
              Add Institution
            </Button>
          </Tooltip>
        </span>
      </div>
    );
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="danger">
                <NotListedLocation />
              </CardIcon>
              <h4 style={styles.cardTitle}>
                View and Edit Institution Profile
              </h4>
            </CardHeader>
            <CardBody>
              <h5 style={styles.cardBody}>
                Select below from the list of your institutions. If you are
                registered as administrator, you will be able to switch to
                editable mode.
              </h5>
              {this.renderCreateInstituteButton()}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderInstituteSelector()}
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(UserProfile);
