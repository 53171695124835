import React from "react";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import formStyles from "assets/jss/material-dashboard-react/views/regularFormsStyle.jsx";

const InlineCheckBox = props => {

  const handleToggle = async value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    await setChecked(newChecked);
    props.showClickedItem(newChecked);
  };
  const { classes, label, checkBox, defaultChecked } = props;
  const [ checked, setChecked ] = React.useState(defaultChecked || []);

  const allCheckBox = checkBox.map((itm, idx) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            tabIndex={-1}
            onClick={() => handleToggle(itm.key)}
            checked={checked.indexOf(itm.key) !== -1 ? true : false}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        }
        classes={{
          label: classes.label,
          root: classes.labelRoot
        }}
        label={itm.label}
        key={itm.key}
      />
    );
  });
  return (
    <GridContainer>
      <GridItem>
        <FormLabel
          className={
            classes.labelHorizontal + " " + classes.labelHorizontalRadioCheckbox
          }
        >
          {label}
        </FormLabel>
      </GridItem>
      <GridItem>
        <div className={classes.inlineChecks}>{allCheckBox}</div>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(formStyles)(InlineCheckBox);
