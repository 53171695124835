/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import AccountBalance from "@material-ui/icons/AccountBalance";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import { validateField } from "utils/fieldVarification.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (props.formSchema && props.formSchema.schema) {
      props.formSchema.schema.validate = this.validate.bind(this);
      props.formSchema.schema.data = this.data.bind(this);
    }
  }

  validate() {
    // eslint-disable-next-line react/prop-types
    const { formSchema } = this.props;
    const properties = formSchema.schema.properties;
    let allValid = true;
    let inputState = {};
    Object.keys(properties).forEach(key => {
      let property = properties[key];
      if (property.required === true) {
        let dependsOn = property.dependsOn;
        if (
          dependsOn !== undefined &&
          property.options[this.state[dependsOn]] === undefined
        ) {
          inputState[`${key}State`] = "success";
        } else if (this.state[`${key}State`] !== "success") {
          allValid = false;
          inputState[`${key}State`] = "error";
        }
      }
    });
    this.setState({ ...inputState });
    return allValid;
  }

  data() {
    let data = {};
    const { formSchema } = this.props;
    const properties = formSchema.schema.properties;
    Object.keys(properties).forEach(key => {
      data[key] = this.state[key];
    });
    return data;
  }

  change(event, stateName, type, stateNameEqualTo) {
    let result = validateField(event, type, stateNameEqualTo);
    let value = result.value;
    if (Array.isArray(result.value)) {
      value = value.map(dt => dt.value);
    } else if (value && value.value !== undefined) {
      value = value.value;
    }
    this.setState({
      [stateName]: value,
      [stateName + "State"]: result.result
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { formData } = props;
    if (state.initiated !== true && typeof formData === "object") {
      Object.keys(formData).forEach(key => {
        state[key] = formData[key];
        state[`${key}State`] = "success";
      });
      state["initiated"] = true;
    }
    return state;
  }

  renderTextBox(itemName, itemUISchema) {
    // console.log("itemName", itemName, itemUISchema)
    const { classes, formSchema } = this.props;
    const schema = formSchema.schema.properties[itemName];
    const inputState = this.state[`${itemName}State`];
    const inputValue = this.state[itemName];
    let endAdornment = null;
    if (itemUISchema["icon"] !== undefined) {
      endAdornment = (
        <InputAdornment position="end" className={classes.inputAdornment}>
          <AccountBalance className={classes.inputAdornmentIcon} />
        </InputAdornment>
      );
    }
    return (
      <CustomInput
        success={inputState === "success"}
        error={inputState === "error"}
        errorMsg={itemUISchema['ui:errorMsg']}
        labelText={
          <span>
            {schema.title}
            <small>({schema.required ? "required" : "optional"})</small>
          </span>
        }
        id={`input${itemName}`}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: schema.type,
          value: inputValue,
          onChange: event => this.change(event, itemName, schema.type),
          endAdornment: endAdornment
        }}
      />
    );
  }

  renderSelect(itemName, itemUISchema, multi) {
    const { formSchema } = this.props;
    const { data } = this.props;
    const schema = formSchema.schema.properties[itemName];
    const inputState = this.state[`${itemName}State`];
    const inputValue = this.state[itemName] || (data && data[itemName]);
    const dependsOn = schema.dependsOn;
    let options = schema.options || [];
    if (dependsOn !== undefined) {
      options = options[this.state[dependsOn]];
    }
    if (options === undefined) {
      return null;
    }
    return (
      <CustomSelect
        multiple={multi}
        success={inputState === "success"}
        error={inputState === "error"}
        errorMsg={itemUISchema['ui:errorMsg']}
        labelText={
          <span>
            {schema.title}
            <small>({schema.required ? "required" : "optional"})</small>
          </span>
        }
        items={options}
        value={inputValue}
        onChange={e => this.change(e, itemName, "select")}
      />
    );
  }

  renderItem(itemName, itemUISchema) {
    const widget = itemUISchema["ui:widget"];
    switch (widget) {
      case "text":
        return this.renderTextBox(itemName, itemUISchema);
      case "select":
        return this.renderSelect(itemName, itemUISchema);
      case "multiselect":
        return this.renderSelect(itemName, itemUISchema, true);
      default:
        break;
    }
    return null;
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, formSchema } = this.props;
    const { schema, uiSchema } = formSchema;

    if (uiSchema === undefined || uiSchema === null) {
      return null;
    }

    return (
      <GridContainer justify="center">
        {schema.title && (
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>{schema.title}</h4>
          </GridItem>
        )}
        {uiSchema.map(uiData => {
          const { container, items } = uiData;
          const itemNames = Object.keys(items);
          const length = 12 / itemNames.length;
          return itemNames.map((itemName, idx) => {
            if (container === "griditem") {
              return (
                <GridItem
                  xs={length}
                  sm={length}
                  md={length}
                  lg={length}
                  key={`griditem-${itemName}-${idx}`}
                >
                  {this.renderItem(itemName, items[itemName])}
                </GridItem>
              );
            }
            return null;
          });
        })}
      </GridContainer>
    );
  }
}

CustomForm.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(CustomForm);
