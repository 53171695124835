import { API_ROOT } from "config.js";

const EVENT_DETAILS_ENDPOINT = `${API_ROOT}/api/events/`;

export async function search(axios, name) {
  let url = `${EVENT_DETAILS_ENDPOINT}`;
  if (name) {
    url = `${EVENT_DETAILS_ENDPOINT}?name=${name}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getEvents(axios, fromTime, toTime) {
  let url = `${EVENT_DETAILS_ENDPOINT}`;
  if (fromTime && toTime) {
    url = `${url}?fromTime=${fromTime}&&toTime=${toTime}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getEventDetails(axios, eventId) {
  let url = `${EVENT_DETAILS_ENDPOINT}${eventId}`;
  let response = undefined;
  if (axios !== undefined) {
    response = await axios.get(url);
  } else {
    response = await fetch(url);
    return response.json();
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateEvent(axios, eventId, eventData) {
  let payload = eventData;
  if (eventData.avatarFile) {
    payload = new FormData();
    payload.append("file", eventData.avatarFile);
    delete eventData["avatarFile"];

    const json = JSON.stringify(eventData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.put(
    `${EVENT_DETAILS_ENDPOINT}${eventId}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
