import {
  INSTITUTE_LEGAL_STATUS_CHOICES,
  INSTITUTE_REGULATED_CHOICES,
  FOCUS_AREA_CHOICES,
  FINANCIAL_SERVICE_CHOICES,
  FUNDING_INSTRUMENT_CHOICES,
  BASIC_SERVICE_CHOICES,
  IMPACT_METRIC_CHOICES,
  TRUE_FALSE_CHOICES,
  SDGs,
  WORLD_REGION_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    legal_status: {
      type: "string",
      title: "Legal Status ",
      options: INSTITUTE_LEGAL_STATUS_CHOICES["FinancialInstitute"]
    },
    regulated: {
      type: "string",
      title: "Regulated ",
      options: INSTITUTE_REGULATED_CHOICES["FinancialInstitute"]
    },
    focus_areas: {
      type: "string",
      title: "Focus Areas ",
      options: FOCUS_AREA_CHOICES
    },
    services_offered: {
      type: "string",
      title: "Services Offered ",
      options: FINANCIAL_SERVICE_CHOICES
    },
    non_financial_programs: {
      type: "string",
      title: "Non financial programs ",
      options: BASIC_SERVICE_CHOICES
    },
    main_funding_sources: {
      type: "string",
      title: "Current Main Funding Instrument ",
      options: FUNDING_INSTRUMENT_CHOICES["FinancialInstitute"]
    },
    funding_needed: {
      type: "string",
      title: "Funding Instrument Needed (if any) ",
      options: FUNDING_INSTRUMENT_CHOICES["FinancialInstitute"]
    },
    savings: {
      type: "string",
      title: "Savings ",
      options: TRUE_FALSE_CHOICES
    },
    impact_metrics: {
      type: "string",
      title: "Relevant Impact Metrics ",
      options: IMPACT_METRIC_CHOICES
    },
    sustainable_development_goals: {
      type: "string",
      title: "Sustainable Development Goals",
      options: SDGs
    },
    region: {
      type: "string",
      title: "Region",
      options: WORLD_REGION_CHOICES
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      region: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      sustainable_development_goals: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      legal_status: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      regulated: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      services_offered: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      focus_areas: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      non_financial_programs: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      savings: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      main_funding_sources: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      funding_needed: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      impact_metrics: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  }
];

export const FinancialInstituteFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
