
import TextField from "@material-ui/core/TextField";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = {
  textField: {
    width: "100%",
  },

  cssLabel: {
    color: "grey"
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "rgb(243, 197,69)"
    }
  },
  cssFocused: {
    "&$cssFocused": {
      color: "grey"
    }
  },
  notchedOutline: {
    borderWidth: "1px"
  },
  formControlLabel:{
    margin:0,
    width:'100%',
    display:'flex',
    alignItems:'flex-start',
    color:'#000'
  }
};

const CustomTextArea=({value, onChange, label, variant='outlined', error})=>{
  return(
    <FormControlLabel
      id={label}
      label={label}
      labelPlacement='top'
      style={styles.formControlLabel}
      control={
        <TextField
          error={error}
          fullWidth
          value={value}
          onChange={onChange}
          multiline
          className={styles.textField}
          variant={variant}
          InputLabelProps={{
            classes: {
              root: styles.cssLabel,
              focused: styles.cssFocused
            }
          }}
          InputProps={{
            classes: {
              root: styles.cssOutlinedInput,
              focused: styles.cssFocused,
              notchedOutline: styles.notchedOutline
            }
          }}
        />
      }
    />
  )
}

export default CustomTextArea
