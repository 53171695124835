import { API_ROOT } from "config.js";

const USER_FORM_LIST_ENDPOINT = `${API_ROOT}/api/quiz/userforms/`;
const QUIZ_RESULTS_ENDPOINT = `${API_ROOT}/api/quiz/results/`;
const QUIZ_LIST_ENDPOINT = `${API_ROOT}/api/quiz/`;
const ANSWER_DETAILS_ENDPOINT = `${API_ROOT}/api/quiz/answers/`;
const QUIZ_DETAILS_ENDPOINT = `${API_ROOT}/api/quiz/questions/`;

export async function getUserForms(axios) {
  const response = await axios.get(`${USER_FORM_LIST_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getAllQuizzes(axios) {
  const response = await axios.get(`${QUIZ_LIST_ENDPOINT}all_projects`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getQuizes(axios) {
  const response = await axios.get(`${QUIZ_LIST_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getQuizDetails(axios, quizName) {
  const response = await axios.get(`${QUIZ_LIST_ENDPOINT}?form=${quizName}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getQuestions(axios, quizName) {
  const response = await axios.get(`${QUIZ_DETAILS_ENDPOINT}?form=${quizName}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateQuestion(axios, questionId, questionData) {
  const response = await axios.put(
    `${QUIZ_DETAILS_ENDPOINT}${questionId}/`,
    questionData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}


export async function getData(axios, question_id) {
  const response = await axios.get(`${QUIZ_DETAILS_ENDPOINT}${question_id}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function submitAnswer(axios, answerData) {
  let payload = new FormData();
  let anyFileFound = false;
  let answers = answerData.answers;
  answers && answers.forEach(answerDt => {
    const isFile = (typeof answerDt.answer["-data"] === "object") && (typeof answerDt.answer["-data"].name) === 'string';
    if (answerDt.answer != null && answerDt.question != null && isFile) {
      const fileData = answerDt.answer['-data'];
      if (fileData && typeof fileData.name == 'string') {
        delete answerDt["answer"]['-data'];
        answerDt["answer"]['-file'] = fileData.name;
        payload.append("file", fileData);
        anyFileFound = true;
      }
    }
  });
  if (anyFileFound) {
    const json = JSON.stringify(answerData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  } else {
    payload = answerData;
  }

  const response = await axios.post(`${ANSWER_DETAILS_ENDPOINT}`, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getResults(axios, quizName) {
  const response = await axios.get(`${QUIZ_RESULTS_ENDPOINT}?form=${quizName}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateQuiz(axios, quizName, quizData) {
  const response = await axios.put(
    `${QUIZ_LIST_ENDPOINT}${quizName}/`,
    quizData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function deleteQuiz(axios, quizName){
  const response = await axios.delete( `${QUIZ_LIST_ENDPOINT}${quizName}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
}

export async function updateQuizStyle(axios, quizName, styleData) {
  const response = await axios.put(
    `${QUIZ_LIST_ENDPOINT}${quizName}/styling_info/`,
    styleData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function createQuiz(axios, projectId, data) {
  let payload = new FormData();
  Object.keys(data).forEach(key=>(
    payload.append(key, data[key])
  ))

  if (data.file) {
    payload.append("projectId", projectId);
  }
  const resp = await axios.post(
    `${QUIZ_LIST_ENDPOINT}`,
    payload
  );
  const success = resp.status >= 200 && resp.status < 300;
  return {success: success, data: resp.data};
}
