import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TextField from "@material-ui/core/TextField";
import Table from "components/Table/Table.jsx";

import { instituteTypes, SDGs, WORLD_REGION_CHOICES } from "utils/choices.jsx";

import { searchUsers, updateRoleData } from "services/userDataProvider.js";

const dashboardStyle = {
  filter: {
    marginBottom: "10px"
  }
};

const instituteTypeOptions = instituteTypes.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const SDGOptions = SDGs.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const regionOptions = WORLD_REGION_CHOICES.map(dt => ({
  label: dt.text,
  value: dt.value
}));

class Search extends React.Component {
  state = {
    searchUsers: [],
    searchData: {},
    updatedData: {}
  };

  updateInstitute = (instId, userId) => e => {
    let instituteData = this.state.updatedData[`${instId}-${userId}`];
    instituteData["instituteId"] = instId;
    instituteData["userId"] = userId;
    updateRoleData(this.props.axios, instituteData);
  };

  handleAdminChange = instId => e => {
    let updatedData = this.state.updatedData || {};
    let instData = updatedData[instId] || {};
    instData["admin"] = e.target.value;
    updatedData[instId] = instData;
    this.setState({ updatedData: updatedData });
  };

  handleSwitchChange = (instId, userId, field) => e => {
    let updatedData = this.state.updatedData || {};
    let instData = updatedData[`${instId}-${userId}`] || { roles: {} };
    instData["roles"][field] = e.target.checked;
    updatedData[`${instId}-${userId}`] = instData;
    this.setState({ updatedData: updatedData });
  };

  handleChange = e => {
    let val = e.target.value;
    let searchData = this.state.searchData;
    searchData.searchTerm = val;
    this.setState(
      {
        searchData: searchData
      },
      () => {
        searchUsers(this.props.axios, this.state.searchData).then(data => {
          let users = [];
          data &&
            data.forEach(dt => {
              users.push(dt);
            });
          this.setState({
            searchUsers: users
          });
        });
      }
    );
  };

  handleFilterSelectionChange = type => (values, action) => {
    let searchData = this.state.searchData;
    let instituteDetails = searchData.instituteDetails || {};
    instituteDetails[type] = values && values.map(dt => dt.value);
    searchData.instituteDetails = instituteDetails;
    this.setState(
      {
        searchData: searchData
      },
      () => {
        searchUsers(this.props.axios, this.state.searchData).then(data => {
          let users = [];
          data &&
            data.forEach(dt => {
              users.push(dt);
            });
          this.setState({
            searchUsers: users
          });
        });
      }
    );
  };

  renderToggleButton(instituteId, userId, field, value) {
    let updatedData = this.state.updatedData || {};
    let instData = updatedData[`${instituteId}-${userId}`] || { roles: {} };
    let state = instData && instData["roles"][field];
    if (state === undefined) {
      state = value;
    }
    return (
      <FormControlLabel
        control={
          <Switch
            checked={state === true}
            onChange={this.handleSwitchChange(instituteId, userId, field)}
            value="approved"
            color="primary"
          />
        }
      />
    );
  }

  groupUserDataByInstitute(users) {
    let rolesDict = {};
    users.forEach(userData => {
      let userRoles = rolesDict[userData.id] || {};
      userData.roles.forEach(roleData => {
        if (roleData.role !== "Follower") {
          let existingData = userRoles[roleData.institute] || {};
          if (existingData["instituteDetails"] === undefined) {
            existingData["instituteDetails"] = {
              id: roleData.institute,
              name: roleData.institute_name
            };
          }
          if (roleData.role === "Admin" || roleData.role === "Contact") {
            let adminRoles = existingData["adminRoles"] || {};
            adminRoles[roleData.role] = roleData.approved || false;
            existingData["adminRoles"] = adminRoles;
          } else {
            let otherRoles = existingData["otherRoles"] || {};
            otherRoles[roleData.role] = roleData.approved || false;
            existingData["otherRoles"] = otherRoles;
          }
          userRoles[roleData.institute] = existingData;
        }
        rolesDict[userData.id] = userRoles;
      });
    });

    let allUsers = [];
    users.forEach(userData => {
      let userDataTemp = JSON.parse(JSON.stringify(userData));
      delete userDataTemp.roles;
      const userInstitutes = rolesDict[userData.id];
      if (userInstitutes != null) {
        Object.keys(userInstitutes).forEach(instituteId => {
          let instituteData = userInstitutes[instituteId];
          let userDataTemp1 = JSON.parse(JSON.stringify(userDataTemp));
          userDataTemp1.instituteDetails = instituteData.instituteDetails;
          delete instituteData.instituteDetails;
          let userRoles = instituteData.otherRoles || { "": false };
          const adminRoles = instituteData.adminRoles || {};
          userDataTemp1.roles = {};
          Object.keys(userRoles).forEach(roleName => {
            userDataTemp1.instituteDetails.role = roleName;
            userDataTemp1.roles = {
              [roleName]: userRoles[roleName],
              ...adminRoles
            };
          });
          allUsers.push(userDataTemp1);
        });
      }
    });
    return allUsers;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    let allUsers = this.groupUserDataByInstitute(this.state.searchUsers);

    //console.log("allUsers", allUsers);

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SearchIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form className={classes.form}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="searct-term"
                        label="Search Institute or User"
                        name="search"
                        autoFocus
                        onChange={this.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Select
                        isMulti
                        options={instituteTypeOptions}
                        onChange={this.handleFilterSelectionChange(
                          "instituteType"
                        )}
                        placeholder={<span>Type</span>}
                        className={classes.filter}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Select
                        isMulti
                        placeholder={<span>SDG</span>}
                        onChange={this.handleFilterSelectionChange("sdg")}
                        options={SDGOptions}
                        className={classes.filter}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Select
                        isMulti
                        placeholder={<span>Region</span>}
                        onChange={this.handleFilterSelectionChange("region")}
                        options={regionOptions}
                        className={classes.filter}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Id",
                    "username",
                    "Name",
                    "Institute",
                    "Role",
                    "Admin",
                    "Contact Person",
                    "Approved"
                  ]}
                  tableData={Object.keys(allUsers).map(key => {
                    let userData = allUsers[key];
                    return [
                      userData.id,
                      userData.username,
                      `${userData.last_name} ${userData.first_name}`,
                      `${userData.instituteDetails.id} | ${
                        userData.instituteDetails.name
                      }`,
                      userData.instituteDetails.role,
                      this.renderToggleButton(
                        userData.instituteDetails.id,
                        userData.id,
                        "Admin",
                        userData.roles["Admin"]
                      ),
                      this.renderToggleButton(
                        userData.instituteDetails.id,
                        userData.id,
                        "Contact",
                        userData.roles["Contact"]
                      ),
                      this.renderToggleButton(
                        userData.instituteDetails.id,
                        userData.id,
                        userData.instituteDetails.role,
                        userData.roles[userData.instituteDetails.role]
                      ),
                      <Button
                        key={`button-${userData.id}-${
                          userData.instituteDetails.id
                        }`}
                        color="info"
                        onClick={this.updateInstitute(
                          userData.instituteDetails.id,
                          userData.id
                        )}
                        disabled={
                          this.state.updatedData[
                            `${userData.instituteDetails.id}-${userData.id}`
                          ] === undefined
                        }
                      >
                        Update
                      </Button>
                    ];
                  })}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  axios: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Search);
