import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import {MEDIA_ROOT} from "../../config";
import defaultLogo from "assets/img/sdg-bg.png";
import ActionMenu from "./ActionMenu";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const useStyles = makeStyles({
  tableContainer:{
    overflow:'auto'
  },
  table: {
    minWidth: 650,
  },
  members: {
    display: 'flex'
  },
  image:{
    width:"50px",
    height:'50px',
    borderRadius:'8px',
    objectFit:'contain'
  },
});

const InstitutionsTableView = ({ institutionsData, renderRoles, history }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Avatar/Logo</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Roles</TableCell>
            <TableCell align="left">Members</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {institutionsData.map((item) => {
            const { avatar, name, type, country, id, currentUsersRoles } = item

            const allowedToEdit = Object.keys(currentUsersRoles).includes('Admin')

            return (
              <TableRow>
                <TableCell align='left' component="th" scope="row">
                  <img
                    className={classes.image}
                    src={avatar
                      ? `${MEDIA_ROOT}${avatar}`
                      : defaultLogo}
                    alt={name} />
                </TableCell>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="left">
                  {type}
                </TableCell>
                <TableCell align="left">{country}</TableCell>
                <TableCell align="left">
                  {renderRoles(item)}
                </TableCell>
                <TableCell align="left">
                  <div className={classes.members}>
                    <AvatarGroup max={4}>
                      <Avatar alt="Remy Sharp" src={defaultLogo} />
                      <Avatar alt="Travis Howard" src={defaultLogo} />
                      <Avatar alt="Cindy Baker" src={defaultLogo} />
                      <Avatar alt="Agnes Walker" src={defaultLogo} />
                      <Avatar alt="Trevor Henderson" src={defaultLogo} />
                    </AvatarGroup>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <ActionMenu institutionId={id} history={history} allowedToEdit={allowedToEdit}/>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InstitutionsTableView.propTypes = {
  institutionsData: PropTypes.array.isRequired,
  renderRoles: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default InstitutionsTableView;
