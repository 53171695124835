import React, { Component } from "react";
import Select from "react-select";
// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";


import withStyles from "@material-ui/core/styles/withStyles";


const styles = {
  settingsContainer: {
    padding: "10px 0 10px 10px",
  },
  settingsSeparator: {
    marginTop: "0px",
    marginBottom: "10px"
  },
  settingsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  settingsTitle: {
    fontSize: "larger",
    paddingBottom: "10px"
  },
  inputWithLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWithSelectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  selectInput: {
    width: "50%",
    margin: "0px 9px 10px 0px"
  },
  textInput: {
    margin: "-3px 10px 10px 10px"
  },
  settingsActionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    padding: "0 10px 0 0"
  }
};

const colorSelectionOptions = [
  { label: "Primary", value: "primary" },
  { label: "Secondary", value: "secondary" },
  { label: "Info", value: "info" },
  { label: "Warning", value: "warning" },
  { label: "Danger", value: "danger" },
  { label: "Error", value: "error" },
];

// sample settings
// {
//   "styling_info": {
//     "quizPage": {
//       "background": "#ffffff"
//     },
//     "quizHeader": {
//       "background": "red",
//       "font-size": "20px",
//       "color": "blue",
//       "font-family": "cursive"
//     },
//     "quizUpdateInfo": {
//       "font-size": "15px",
//       "color": "brown"
//     },
//     "navTabsColor": "info",
//     "infoTitleColor": "info",
//     "infoActionButtonColor": "info",
//     "primaryActionButtonColor": "primary",
//     "secondaryActionButtonColor": "secondary",
//     "labelStyle": {
//       "color": "black",
//       "font-family": "cursive"
//     },
//     "anchorStyle": {
//       "color": "#9c27b0"
//     },
//     "tabTitle": {
//       "background": "#00acc1",
//       "color": "blue",
//       "font-family": "cursive"
//     },
//     "tabContent": {
//       "background": "#ffffff",
//       "font-family": "cursive"
//     },
//     "surveyCard": {
//       "background": "#ffffff"
//     },
//     "participantsTable": {
//       "border": "solid",
//       "border-width": "1px"
//     },
//     "documentsTable": {
//       "border": "solid",
//       "border-width": "1px"
//     }
//   }
// }

class QuizSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      stylingData: {
        quizBackground: null,
        quizHeaderBackground: null,
        quizFontFamily: null,
        quizTitleFontSize: null,
        quizTitleFontFamily: null,
        quizTitleFontColor: null,
        quizLabelFontSize: null,
        quizLabelFontFamily: null,
        quizLabelFontColor: null,
        quizNavPillColor: null,
        quizTabBackground: null,
        quizTabFontSize: null,
        quizTabFontFamily: null,
        quizTabFontColor: null,
        infoTitleColor: null,
        primaryActionButtonColor: null,
        secondaryActionButtonColor: null,
        infoActionButtonColor: null,
      }
    };
  }

  toggleDrawer = drawerState => {
    this.setState({drawerOpen: drawerState});
  };

  getUpdatedStylingInfo = () => {
    const stylingData = this.state.stylingData || {};
    return {
      "quizPage": {
        "background": stylingData.quizBackground
      },
      "quizHeader": {
        "background": stylingData.quizHeaderBackground,
        "font-size": stylingData.quizTitleFontSize,
        "color": stylingData.quizTitleFontColor,
        "font-family": stylingData.quizTitleFontFamily
      },
      "quizUpdateInfo": {
        "font-size": stylingData.quizLabelFontSize,
        "color": stylingData.quizLabelFontColor,
        "font-family": stylingData.quizLabelFontFamily,
      },
      "navTabsColor": stylingData.quizNavPillColor,
      "infoTitleColor": stylingData.infoTitleColor,
      "infoActionButtonColor": stylingData.infoActionButtonColor,
      "primaryActionButtonColor": stylingData.primaryActionButtonColor,
      "secondaryActionButtonColor": stylingData.secondaryActionButtonColor,
      "labelStyle": {
        "font-size": stylingData.quizLabelFontSize,
        "color": stylingData.quizLabelFontColor,
        "font-family": stylingData.quizLabelFontFamily,
      },
      "anchorStyle": {
        "color": "#9c27b0"
      },
      "tabTitle": {
        "background": stylingData.quizTabBackground,
        "color": stylingData.quizTabFontColor,
        "font-size": stylingData.quizTabFontSize,
        "font-family": stylingData.quizTabFontFamily
      },
      "tabContent": {
        "background": stylingData.quizBackground,
        "font-family": stylingData.quizFontFamily
      },
      "surveyCard": {
        "background": stylingData.quizBackground,
      },
      "participantsTable": {
        "border": "solid",
        "border-width": "1px"
      },
      "documentsTable": {
        "border": "solid",
        "border-width": "1px"
      }
    };
  };

  parseStylingInfo = (settings) => {
    const stylingInfo = settings;

    if (stylingInfo) {
      const stylingData = {
        quizBackground: stylingInfo.quizPage && stylingInfo.quizPage.background,
        quizHeaderBackground: stylingInfo.quizHeader && stylingInfo.quizHeader.background,
        quizFontFamily: stylingInfo.tabContent && stylingInfo.tabContent["font-family"],
        quizTitleFontSize: stylingInfo.quizHeader && stylingInfo.quizHeader["font-size"],
        quizTitleFontFamily: stylingInfo.quizHeader && stylingInfo.quizHeader["font-family"],
        quizTitleFontColor: stylingInfo.quizHeader && stylingInfo.quizHeader["color"],
        quizLabelFontSize: stylingInfo.labelStyle && stylingInfo.labelStyle["font-size"],
        quizLabelFontFamily: stylingInfo.labelStyle && stylingInfo.labelStyle["font-family"],
        quizLabelFontColor: stylingInfo.labelStyle && stylingInfo.labelStyle["color"],
        quizTabBackground: stylingInfo.tabTitle && stylingInfo.tabTitle.background,
        quizTabFontSize: stylingInfo.tabTitle && stylingInfo.tabTitle["font-size"],
        quizTabFontFamily: stylingInfo.tabTitle && stylingInfo.tabTitle["font-family"],
        quizTabFontColor: stylingInfo.tabTitle && stylingInfo.tabTitle["color"],
        quizNavPillColor: stylingInfo.navTabsColor,
        infoTitleColor: stylingInfo.infoTitleColor,
        infoActionButtonColor: stylingInfo.infoActionButtonColor,
        primaryActionButtonColor: stylingInfo.primaryActionButtonColor,
        secondaryActionButtonColor: stylingInfo.secondaryActionButtonColor
      };
      this.setState({stylingData: stylingData}, () => console.log("parsed styling", this.state.stylingData));
    }
  };

  updateStyleData = styleName => e => {
    const stylingData = this.state.stylingData || {};
    stylingData[styleName] = e.target.value;
    this.setState({stylingData: stylingData}, () => {
      const settings = this.props.settings || {};
      settings.styling_info = this.getUpdatedStylingInfo();
      this.props.onUpdate && this.props.onUpdate(settings);
    });
  };

  updateStyleDataOnSelection = styleName => e => {
    const selectedValue = e.value;
    if (selectedValue) {
      const stylingData = this.state.stylingData || {};
      stylingData[styleName] = selectedValue;
      this.setState({stylingData: stylingData}, () => {
        const settings = this.props.settings || {};
        settings.styling_info = this.getUpdatedStylingInfo();
        this.props.onUpdate && this.props.onUpdate(settings);
      });
    }
  };

  submitStyleData = e => {
    console.log("updateStyleDataOnSelection", e);
    this.props.onSubmit && this.props.onSubmit(e);
  }

  componentDidMount() {
    const settings = this.props.settings;
    if (settings) {
      this.parseStylingInfo(settings);
    }
  }

  closeSettings = e => {
    this.toggleDrawer(false);
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        anchor={"right"}
        variant="persistent"
        open={this.state.drawerOpen}
        onClose={e => this.toggleDrawer(false)}
      >
        <div className={classes.settingsContainer}>
          <div className={classes.settingsHeader}>
            <h4>Quiz Settings</h4>
            <IconButton
              aria-label="Close"
              className={classes.drawerCloseButton}
              onClick={this.closeSettings}
            >
              <Close />
            </IconButton>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Quiz
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithLabelContainer}>
              <label>Background</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizBackground")}
                value={this.state.stylingData && this.state.stylingData.quizBackground}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizFontFamily")}
                value={this.state.stylingData && this.state.stylingData.quizFontFamily}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Quiz title
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithLabelContainer}>
              <label>Quiz Header Background</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizHeaderBackground")}
                value={this.state.stylingData && this.state.stylingData.quizHeaderBackground}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font size</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTitleFontSize")}
                value={this.state.stylingData && this.state.stylingData.quizTitleFontSize}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTitleFontFamily")}
                value={this.state.stylingData && this.state.stylingData.quizTitleFontFamily}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font color</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTitleFontColor")}
                value={this.state.stylingData && this.state.stylingData.quizTitleFontColor}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Quiz label
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithLabelContainer}>
              <label>Font size</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizLabelFontSize")}
                value={this.state.stylingData && this.state.stylingData.quizLabelFontSize}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizLabelFontFamily")}
                value={this.state.stylingData && this.state.stylingData.quizLabelFontFamily}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font color</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizLabelFontColor")}
                value={this.state.stylingData && this.state.stylingData.quizLabelFontColor}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Quiz tabs
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithSelectContainer}>
              <label>Nav Pill</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                value={this.state.stylingData && this.state.stylingData.quizNavPillColor}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("quizNavPillColor")}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font size</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTabFontSize")}
                value={this.state.stylingData && this.state.stylingData.quizTabFontSize}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTabFontFamily")}
                value={this.state.stylingData && this.state.stylingData.quizTabFontFamily}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font color</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTabFontColor")}
                value={this.state.stylingData && this.state.stylingData.quizTabFontColor}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Background</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("quizTabBackground")}
                value={this.state.stylingData && this.state.stylingData.quizTabBackground}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Other
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithSelectContainer}>
              <label>Info title</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("infoTitleColor")}
              />
            </div>
            <div className={classes.inputWithSelectContainer}>
              <label>Primary action</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("primaryActionButtonColor")}
              />
            </div>
            <div className={classes.inputWithSelectContainer}>
              <label>Secondary action</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("secondaryActionButtonColor")}
              />
            </div>
            <div className={classes.inputWithSelectContainer}>
              <label>Info action</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("infoActionButtonColor")}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsActionContainer }>
            <Button
              color="primary"
              onClick={this.submitStyleData}
            >
              Update Settings
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }
}
    
export default withStyles(styles)(QuizSettings);
