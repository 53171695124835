import React, { Component } from "react";
// @material-ui/core components
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";

import defaultAvatar from "assets/img/default-avatar.png";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import InlineCheckBox from "components/InlineCheckBox/InlineCheckBox";

import Button from "components/CustomButtons/Button.jsx";
import { MEDIA_ROOT } from "config.js";
import {
  Avatar,
  Divider,
  Paper,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import { addRole } from "../../services/projectDataProvider";
import { searchApi } from "../../services/searchProvider";

import ProjectRoles from "./projectRoles";

const ProjectRolesList = Object.keys(ProjectRoles).map(
  key => ProjectRoles[key]
);

const searchOptions = [
  { key: "institute", label: "Institute" },
  { key: "members", label: "Members" }
];

const styles = {
  paperContainer: {
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
    padding: "10px"
  },
  searchContainer: {
    padding: "0 4px 0 4px"
  },
  searchResultsContainer: {
    width: "calc(60vh)",
    overflow: "scroll",
    height: "calc(60vh)"
  },
  modalActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 4px 0 4px"
  },
  memberDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  memberDetails: {},
  searchSeparator: {
    marginTop: "0px",
    marginBottom: "10px"
  },
};

function getModalStyle() {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    margin: "auto",
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}
class AddParticipants extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
    this.state = {
      searchTerm: "",
      lookFor: ["institute", "members"],
      id: this.props.projectId,
      institutes: [],
      members: [],
      pageNumber: 0,
      pageSize: 10
    };
  }

  handleModalClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  handleRoleSelectionChange(newRole, userType, userId) {
    const selectedRole = Object.keys(ProjectRoles).find(
      key => ProjectRoles[key] === newRole
    );
    if (selectedRole) {
      this.addUser(userId, userType, selectedRole);
    }
  }

  addUser(userId, userType, role) {
    let userDetails = {
      projectId: this.props.projectId,
      roleName: role
    };
    if (userType === "user") {
      userDetails["userId"] = userId;
    } else if (userType === "institute") {
      userDetails["instituteId"] = userId;
    }
    addRole(this.props.axios, userDetails).then(data => {
      this.setState({ participants: data });
    });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ pageNumber: newPage });
    event.preventDefault();
  };

  handleSwitchChange = searchTypes => {
    this.setState(
      { lookFor: searchTypes, users: [] },
      () => {
        if (this.state.searchTerm !== "") {
          this.searchCall();
        }
      }
    );
  };

  handleSearchTermChange(e) {
    let val = e.target.value;
    this.setState({ searchTerm: val }, () => {
      this.searchCall();
    });
  }

  getPayload() {
    const {
      pageNumber,
      pageSize,
      type,
      lookFor,
      searchTerm: name
    } = this.state;
    let payload = {
      name,
      type,
      lookFor,
      pageNumber,
      pageSize
    };
    return payload;
  }

  searchCall = () => {
    let payload = this.getPayload();
    searchApi(this.props.axios, payload)
      .then(data => {
        let searchResult = data.results;
        this.setState({
          searchDataAvailable: true,
          pageCount: data.total,
          pageSize: data.pageSize,
          institutes: searchResult.institutes,
          members: searchResult.members
        });
      })
      .catch(e => {
        this.setState({
          searchDataAvailable: false,
          pageNumber: 0,
          pageSize: this.state.users.length,
          users: this.state.users
        });
      });
  };

  renderInstitute(institute) {
    const { classes } = this.props;
    return (
      <div key={`institute-${institute.id}`}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={institute.name}
              src={
                institute.avatar
                  ? `${MEDIA_ROOT}${institute.avatar}`
                  : defaultAvatar
              }
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <a href={`/institutes/${institute.id}`}>{`${
                institute.name
              }`}</a>
            }
            secondary={
              <React.Fragment>
                <div className={classes.memberDetailsContainer}>
                  <div className={classes.memberDetails}>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {institute.short_name}
                    </Typography>
                    <div className={classes.moreDetails}>
                      {institute.mission ? institute.mission : institute.vision}
                    </div>
                  </div>
                  <CustomDropdown
                    buttonProps={{
                      color: "default"
                    }}
                    buttonText="Select Role"
                    onClick={event => {
                      this.handleRoleSelectionChange(event, "institute", institute.id);
                    }}
                    dropdownList={ProjectRolesList}
                  />
                </div>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  renderMember(member) {
    const { classes } = this.props;
    return (
      <div key={`member-${member.id}`}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={member.name}
              src={
                member.avatar
                  ? `${MEDIA_ROOT}${member.avatar}`
                  : defaultAvatar
              }
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <a href={`/member-profile/${member.id}`}>{`${
                member.first_name
              } ${member.last_name}`}</a>
            }
            secondary={
              <React.Fragment>
                <div className={classes.memberDetailsContainer}>
                  <div className={classes.memberDetails}>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {member.email}
                    </Typography>
                    <div className={classes.moreDetails}>
                      {member.city}
                    </div>
                  </div>
                  <CustomDropdown
                    buttonProps={{
                      color: "default"
                    }}
                    buttonText="Select Role"
                    onClick={event => {
                      this.handleRoleSelectionChange(event, "user", member.id);
                    }}
                    dropdownList={ProjectRolesList}
                  />
                </div>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.active === true}
        onClose={this.handleModalClose}
        style={{ margin: "30px" }}
      >
        <Paper className={classes.paperContainer} style={getModalStyle()}>
          <GridContainer className={classes.searchContainer}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="search-user"
                label="Search Institutes or Members"
                name="search"
                onChange={e => this.handleSearchTermChange(e)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <InlineCheckBox
                label="Search includes"
                checkBox={searchOptions}
                defaultChecked={searchOptions.map(searchOption => searchOption.key)}
                showClickedItem={items => this.handleSwitchChange(items)}
              />
              <hr className={classes.searchSeparator}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <List
                className={classes.searchResultsContainer}
                sx={{ bgcolor: "background.paper" }}
              >
                {this.state.institutes && this.state.institutes.map(institute => this.renderInstitute(institute))}
                {this.state.members && this.state.members.map(member => this.renderMember(member))}
              </List>
            </GridItem>
          </GridContainer>
          <div className={classes.modalActionsContainer}>
            <Button color="default" onClick={this.handleModalClose}>
              Close
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddParticipants);
