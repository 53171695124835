const projectStyles = theme => ({
  typo: {
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Poppins", "sans-serif"',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  sdg: {
    display: "block",
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    "& > *": {
      margin: "3px"
    }
  },
  sdgImage: {
    width: "60px",
    height: "60px",
    maxHeight: "100px",
    overflow: "visible"
    //display: "block"
  },
  imgContainer: {
    width: "100%",
    overflow: "hidden",
    display: "block",
    textAlign: "center"
  },
  img: {
    width: "auto",
    maxHeight: "400px",
    verticalAlign: "middle",
    horizontalAlign: "middle",
    border: "#FFFFFF",
    borderRadius: "5px"
  },
  memberAvatar: {
    width: "80px !important"
  },
  filterContainer: {
    zIndex: "999 !important"
  },
  listTitle: {
    backgroundColor: "#34495E",
    color: "#FFFFFF",
    padding: "5px",
    borderRadius: "5px",
    textAlign: "center",
    fontWeight: "400",
    marginTop: "30px",
    fontFamily: '"Poppins", sans-serif'
  },
  projectDescription: {
    padding: "5px",
    borderRadius: "5px",
    textAlign: "left",
    fontWeight: "400",
    marginTop: "10px"
  },
  cardTitleBlack: {
    color: "#273746",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "600",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    width: "fit-content"
  },
  createrInfo: {
    color: "#273746",
    marginTop: "15px",
    textAlign: "right"
  },
  itemsList: {
    display: "flex",
    flexDirection: "column"
  },
  createDiscussion: {
    display: "flex",
    flexDirection: "column"
  },
  createDiscussionIcon: {
    alignSelf: "flex-end"
  },
  discussionTitle: {
    color: "#273746",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontSize: "30px",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "bold",
    textAlign: "center"
  },
  modalButtons: {
    display: "flex",
    flexDirection: "inherit",
    justifyContent: "center"
  },
  select: {
    marginTop: "38px",
    marginBottom: "40px",
    display: "blocks"
  },
  buttonStyle: {
    direction: "rtl",
    marginTop: "30px",
    textAlign: "end",
    backgroundColor: "yellow"
  },
  surveyButton: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    borderRadius: "30pt"
  },
  textField: {
    width: "100%",
    marginTop: "20px"
  },

  cssLabel: {
    color: "grey"
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "rgb(243, 197,69)"
    }
  },

  cssFocused: {
    "&$cssFocused": {
      color: "grey"
    }
  },

  notchedOutline: {
    borderWidth: "1px"
    //borderColor: 'grey !important'
  },
  createTopicStyle: {
    marginTop: "10px",
    textAlign: "end"
  },
  deleteTopicStyle: {
    marginTop: "10px",
    textAlign: "end",
    display: "flex",
    flexDirection: "column"
  },
  discussionDescription: {
    width: "100%"
  },
  editButtonStyle: {
    display: "block",
    direction: "rtl",
    paddingRight: "20px"
  },
  root: {
    width: "100%",
    fontFamily: "'Poppins', sans-serif",
    overflow: "visible"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "visible",
    width: "100%",
    fontFamily: "'Poppins', sans-serif",
    minHeight: "400px"
  },
  tableHeader: {
    width: "20%",
    fontFamily: '"Poppins", serif',
    fontWeight: "bolder",
    color: "black",
    fontSize: "1rem"
  },
  tableCell: {
    fontSize: "0.8rem"
  },
  imgcontainer: {
    maxHeight: "600px",
  },
  imgContent: {
    margin: "auto",
    display: "block",
    maxHeight: "100%",
    maxWidth: "100%"
  },
  videoContent: {
    margin: "auto",
    display: "block",
    width: "100%",
  },
  projectDetailsFooter: {
    display: "inline-table"
  },
  projectActionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "column"
  },
  projectInfoCard: {
    boxShadow: "none"
  },
  projectNameInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  projectNameInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  projectUpdateInfo: {
    color: "#273746",
    textAlign: "right"
  },
  topicTitleContainer: {
    backgroundColor: "gainsboro"
  },
  topicTitle: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "3px",
    fontFamily: "unset"
  },
  quizListContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    padding: "10px 0px 0px 0px",
    columnGap: "10px"
  },
  quizCard: {
    marginTop: "25px",
    width: "auto",
    alignItems: "center",
    marginBottom: "0px",
    border: "2px solid #D6DBDF"
  },
  quizCardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  quizCardHeader: {
    alignItems: "center",
    marginRight: "3pt",
    marginLeft: "3pt",
    marginTop: "3pt"
  },
  quizTitle: {
    fontSize: "1.5rem",
    paddingTop: "0px",
    color: "#3C4858",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "800",
    //fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    },
    maxWidth: "fit-content"
  },
  quizDetails: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "10px"
  },
  quizDescription: {
    fontSize: "medium",
  },
  quizUpdateInfoTitle: {
    fontWeight: "800"
  },
  quizProgressContainer: {
    //alignItems: "center",
  },
  quizProgress: {
    width: "100px !important",
    height: "50px !important",
    marginBottom: "10px"
  },
  quizCategory: {
    color: "#999999",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  quizActionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    width: "-webkit-fill-available"
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "baseline",
  },
  participantActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 0 8px 0"
  },
  uploadSurveyError: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px"
  },
  quizCreationModalElements: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 10px",
    maxHeight: "500px",
    overflow: "scroll"
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "baseline"
  },
  flexContainerEnd: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "baseline"
  },
  projectAvatarActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "10px",
    width: "fit-content", // Set the width to fit-content
  },
  projectAvatarAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    columnGap: "10px",
    width: "100%",
  },
  cardHeaderWrap:{
    display:'flex',
    alignItems:'center'
  },
  iconSurvey:{
    marginBottom:'3px',
    marginRight:'8px'
  },
  formFields:{
    display:'flex', 
    flexDirection:'column',
    gap:'8px',
    alignItems:'flex-start'
  },
  imageFileWrap:{
    display:'flex', 
    flexDirection:'column',
    gap:'10px',
    alignItems:'flex-start'
  }
});

export default projectStyles;
