import axios from "axios";
import { API_ROOT } from "config.js";

import { setAccessToken, removeAccessToken } from "containers/authenticated.js";

const AUTHORIZATION_ENDPOINT = `${API_ROOT}/api-token-auth/`;
const SIGNUP_ENDPOINT = `${API_ROOT}/auth/registration/`;
const PASSWORD_RESET_ENDPOINT = `${API_ROOT}/auth/password/reset/`;
// const AUTHORIZATION_ENDPOINT = `${API_ROOT}/auth/login/`;

export function login(username, password) {
  return axios
    .post(
      AUTHORIZATION_ENDPOINT,
      {
        username: username,
        password: password
      },
      { withCredentials: true }
    )
    .then(response => {
      const data = response.data;
      setAccessToken(data);
      return data;
    });
}

export async function signup(userData) {
  let payload = userData;
  if (userData.avatarFile) {
    payload = new FormData();
    payload.append("file", userData.avatarFile);
    delete userData["avatarFile"];

    const json = JSON.stringify(userData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(
    `${SIGNUP_ENDPOINT}`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export function verifyLogin() {
  return axios
    .get(AUTHORIZATION_ENDPOINT, {
      withCredentials: true
    })
    .then(response => {
      const data = response.data;
      setAccessToken(data);
      return true;
    })
    .catch(error => {
      // window.location = "/start";
      console.log("verifyLogin error", error);
      return false;
    });
}

export async function logout() {
  removeAccessToken();
  return axios.delete(AUTHORIZATION_ENDPOINT, {
    withCredentials: true
  });
}

export async function forgotPassword(email) {
  const response = await axios.post(PASSWORD_RESET_ENDPOINT, {
    email: email
  });
  const data = response.data;
  setAccessToken(data);
  return data;
}

export async function resetPassword(uid, token, password, confirmPassword) {
  return axios.post(`${PASSWORD_RESET_ENDPOINT}confirm/`, {
    uid: uid,
    token: token,
    new_password1: password,
    new_password2: confirmPassword
  }).then(response => {
      setAccessToken(response.data);
      return {
        success: true,
        data: response.data
      };
    })
    .catch(error => {
      return {
        success: false,
        data: error.response.data.new_password2[0]
      };
    });
}
