/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// core components
import PrimaryText from "components/Typography/Primary.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Accordion from "components/Accordion/Accordion.js";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core";
import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Input } from "@material-ui/core";
import Comment from "components/Comment/Comment.jsx";
import Info from "components/Typography/Info.jsx";
import { getData } from "services/userDataProvider.js";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ContactMail } from "@material-ui/icons";
import { buttonColorScheme } from "../../../src/assets/jss/material-dashboard-react/layouts/appstyleconfigs.jsx"

import {
  getProjectDetails,
  postTopic,
  updateProject,
  createDiscussion,
  createComment,
  updateTopic,
  deleteTopic,
  deleteDiscussion,
  postDocument
} from "../../services/projectDataProvider";

import { MEDIA_ROOT } from "config.js";
import Select from "react-select";
import {
  SDGs,
  WORLD_REGION_CHOICES,
  BASIC_SERVICE_CHOICES,
  projectTypes
} from "utils/choices.jsx";
import ParticipantsList from "./ParticipantsList";
import AddParticipants from "./AddParticipants";
import { ProjectPermissions } from "utils/roles";
import projectStyles from "./projectStyles";
//import BannerList from "./BannerList";

const SDGOptions = SDGs.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const RegionChoices = WORLD_REGION_CHOICES.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const FocusAreaChoices = BASIC_SERVICE_CHOICES.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const ProjectTypeChoices = projectTypes.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const permissions = [
  { label: "PUBLIC", value: "PUBLIC" },
  { label: "MEMBERS ONLY", value: "MEMBERS_ONLY" },
  { label: "ADMIN ONLY", value: "ADMIN_ONLY" }
];
class EditProject extends Component {
  constructor(props) {
    super(props);
    this.formSchema = null;
    this.state = {
      editable: false,
      updatedData: {},
      instituteOptions: [],
      addParticipantsModalActive: false,
      isDataDoc: false
    };
  }

  componentDidMount = async () => {
    let projectId =
      this.props.match.params && this.props.match.params.projectId;
    if (projectId === undefined) {
      this.props.history.push("/projects");
    }
    getProjectDetails(this.props.axios, projectId).then(data => {
      this.setState({ ...data });
      this.setState({ users: this.state.participants });
    });
    this.props.user &&
      getData(this.props.axios, this.props.user.user_id).then(data => {
        this.setState({ userData: data, openPopUp: false });
      });
  };

  onComment = discussionId => comment => {
    createComment(this.props.axios, discussionId, comment).then(data => {
      let projectDetails = this.state;
      let discussions = projectDetails.discussions.map(discussion => {
        if (discussion.id === data.id) {
          return data;
        }
        return discussion;
      });
      this.setState({ discussions: discussions });
    });
  };

  renderProjectAvatar(projectDetails) {
    const { classes } = this.props;
    const videoBannerLink = projectDetails && projectDetails.metadata && projectDetails.metadata["video_banner_link"];
    let projectBanner = projectDetails && projectDetails.banner;
    if (projectBanner == null) {
      projectBanner = projectDetails.avatar;
    }
  
    return (
      <div>
        {projectBanner ? (
          <CardAvatar project className={classes.imgcontainer}>
          {videoBannerLink ? (
            <video id="project-video-banner" controls playsinline autoPlay={true} muted loop type="video/mp4" className={classes.videoContent}>
              <source src={videoBannerLink}></source>
              Your browser does not support the video.
            </video>
          ) : (
            projectBanner && <a href="#avatar" onClick={e => e.preventDefault()}>
              <img
                className={classes.imgContent}
                src={projectBanner && projectBanner.includes("http") ? projectBanner : `${MEDIA_ROOT}${projectBanner}`}
                alt="..." />
            </a>
          )}
        </CardAvatar>
        ) : (
          <div className={classes.typo}>
            <h1>{projectDetails.name}</h1>
          </div>
        )
        }
        <div className={classes.projectAvatarActions}>
          <div className={classes.projectAvatarAction}>
            <label>Avatar</label>
            <Input type="file" onChange={this.onFileLoad("avatarFile")}/>
          </div>
          <div className={classes.projectAvatarAction}>
            <label>Banner</label>
            <div className={classes.imageFileWrap}>
             <Input type="file" onChange={this.onFileLoad("bannerFile")} />
             {/*<BannerList  handleState={this.setState.bind(this)}  />*/}
            </div>
          </div>
          <div className={classes.projectAvatarAction}>
            <label>Banner Video Link</label>
            <Input type="url" defaultValue={videoBannerLink} onChange={this.bannerVideoLinkUpdate} />
          </div>
        </div>
      </div>
    );
  }

  onChange = name => e => {
    let updatedData = this.state.updatedData;
    let value = e.target ?  e.target.value : e;
    updatedData[name] = value;
    this.setState({ [name]: value, updatedData: updatedData });
  };

  onFileLoad = fileType => e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let updatedData = this.state.updatedData;
      updatedData[fileType] = file;
            this.setState({ updatedData: updatedData });
    }
  };

  bannerVideoLinkUpdate = e => {
    let updatedData = this.state.updatedData || {};
    updatedData["videoBannerLink"] = e.target.value || '';
    this.setState({ updatedData: updatedData });
  };

  finishEditing = e => {
    this.props.history.push(`/projects/${this.state.id}`);
  };

  updateProjectDetails = e => {
    let projectData = this.state.updatedData;
    projectData["id"] = this.state.id;
    updateProject(this.props.axios, projectData).then(data => {
      this.setState({ ...data });
      this.setState({ users: this.state.participants });
    });
  };

  updateTopicList = e => {
    let topicList = this.state.updatedData.topics;
    let topics = this.state.topics;
    topicList.forEach(e => {
      e["project"] = this.state.id;
      updateTopic(this.props.axios, e).then(data => {
        let topicIndex = topics.findIndex(e => e.id === data.id);
        topics[topicIndex] = data;
      });
    });
    this.setState({ topics: topics });
  };

  handleModalClose = e => {
    e.preventDefault();
    this.setState({
      openDiscussionPopUp: false,
      openTopicPopUp: false,
      openDocumentPopUp: false,
      isDataDoc: false
    });
  };

  handleModalOpen = modalType => e => {
    e.preventDefault();
    switch (modalType) {
      case "Topic":
        this.setState({
          openTopicPopUp: true,
          openDiscussionPopUp: false,
          openDocumentPopUp: false
        });
        break;
      case "Document":
        this.setState({
          openTopicPopUp: false,
          openDiscussionPopUp: false,
          openDocumentPopUp: true
        });
        break;
      case "Discussion":
        this.setState({
          openTopicPopUp: false,
          openDiscussionPopUp: true,
          openDocumentPopUp: false
        });
        break;
      default:
        break;
    }
  };

  activateAddParticipantsModal = e => {
    this.setState({
      addParticipantsModalActive: true
    });
  };

  renderProjectDetails() {
    const { classes } = this.props;
    const projectDetails = this.state;
    if (projectDetails.name === undefined) {
      return null;
    } else {
      return (
        <Card chart className={classes.cardHover}>
          <CardHeader className={classes.cardHeaderHover}>
            {this.renderProjectAvatar(projectDetails)}
          </CardHeader>
          <CardFooter className={classes.projectDetailsFooter}>
            {this.renderUpdateButton()}
          </CardFooter>
          <CardBody>
            <br />
            <NavPills
              color="warning"
              vertical={{
                tabsGrid: { xs: 12, sm: 12, md: 12 },
                contentGrid: { xs: 12, sm: 12, md: 12 }
              }}
              tabs={[
                {
                  tabButton: "About Project",
                  tabContent: this.renderAbout(projectDetails)
                },
                {
                  tabButton: "Manage Topics",
                  tabContent: this.renderEditableTopics(projectDetails)
                },
                {
                  tabButton: "Discussions",
                  tabContent: this.renderDiscussions(
                    projectDetails.discussions
                  )
                },
                {
                  tabButton: "Documents",
                  tabContent: this.renderDocuments(projectDetails.documents)
                },
                {
                  tabButton: "Participants",
                  tabContent: this.showParticipantsTable()
                }
              ]}
            />
          </CardBody>
        </Card>
      );
    }
  }

  renderDiscussions = discussions => {
    return (
      <div>
        {this.renderCreateDiscussionButton()}
        <Accordion
          collapses={discussions.map(discussion => {
            return {
              title: discussion.name,
              content: this.renderDiscussion(discussion)
            };
          })}
        />
      </div>
    );
  };

  renderDiscussion(discussion) {
    const { classes } = this.props;
    return (
      <div key={discussion.id} className={classes.discussionDescription}>
        <Info>{discussion.description}</Info>
        <Comment
          comments={discussion.comments || []}
          onComment={this.onComment(discussion.id)}
          user={this.state.userData}
        />
        {this.renderDeleteDiscussionButton(discussion.id)}
      </div>
    );
  }

  createNewTopic = e => {
    let topicData = this.state.newTopicData || {};
    let topicArray = this.state.topics || [];
    topicData["project"] = this.state.id || 0;

    postTopic(this.props.axios, topicData).then(data => {
      topicArray.push(data);
      this.setState({ topics: topicArray, openTopicPopUp: false });
    });
  };

  deleteTopicDetails = id => e => {
    let topicList = this.state.topics || [];
    let index = topicList.findIndex(x => x.id === id);
    topicList.splice(index, 1);

    deleteTopic(this.props.axios, id).then(data => {
      this.setState({ state: this.state });
    });
  };

  deleteDiscussionDetails = id => e => {
    let discussionList = this.state.discussions || [];
    let index = discussionList.findIndex(x => x.id === id);
    discussionList.splice(index, 1);

    deleteDiscussion(this.props.axios, id).then(data => {
      this.setState({ state: this.state });
    });
  };

  renderEditableTopics(projectDetails) {
    let projectTopics = projectDetails.topics;
    return (
      <div>
        {this.renderCreateTopicButton()}
        {projectTopics.map(topic => this.renderIndividualTopics(topic))}
        {this.renderUpdateTopicButton()}
      </div>
    );
  }

  renderUpdateTopicButton() {
    const projectDetails = this.state;
    const { classes } = this.props;
    const can_update = projectDetails.user_permissions &&
                        projectDetails.user_permissions.includes(ProjectPermissions.DELETE_PROJECT_TOPICS);
    if (can_update) {
      return (this.state.updatedData.topics &&
        <div className={classes.projectActionsContainer}>
          <Button
            size="sm"
            color={buttonColorScheme.createButton}
            onClick={this.updateTopicList}>
            Update Topics
          </Button>
        </div>
      );
    }
  }

  topicUpdate = (id, fieldName) => e => {
    if (id === -1) {
      let topicData = this.state.newTopicData || {};
      topicData[fieldName] = e.target.value;
      this.setState({ newTopicData: topicData });
    } else {
      let updatedData = this.state.updatedData || {};
      let topicList = this.state.updatedData.topics || [];
      if (topicList.size > 0) {
        let selectedIndex = topicList.findIndex(x => x.id === id);
        topicList[selectedIndex][fieldName] = e.target.value;
        updatedData["topics"] = topicList;
        this.setState({ updatedData: updatedData });
      } else {
        topicList = this.state.topics;
        let selectedIndex = topicList.findIndex(x => x.id === id);
        topicList[selectedIndex][fieldName] = e.target ? e.target.value : e;
        updatedData["topics"] = topicList;
        this.setState({ updatedData: updatedData });
      }
    }
  };

  discussionUpdate = fieldName => e => {
    let discussionData = this.state.newDiscussionData || {};
    if (fieldName === "topicId") {
      discussionData["topic"] = e.value;
    } else {
      discussionData[fieldName] = e.target.value;
    }
    this.setState({ newDiscussionData: discussionData });
  };

  documentUpdate = fieldName => e => {
    let documentData = this.state.newDocumentData || {};
    if (fieldName === "document") {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        documentData["attachedFile"] = file;
      }
    } else if (fieldName === "permissions") {
      documentData[fieldName] = e.value;
    } else if (fieldName === "isDataDoc") {
      documentData["isDataDoc"] = e.target.checked;
      this.setState({ isDataDoc: e.target.checked });
    }
    else {
      documentData[fieldName] = e.target.value;
    }
    this.setState({ newDocumentData: documentData });
  };

  uploadDocument = projectData => e => {
    let documentData = this.state.newDocumentData;
    documentData["project"] = projectData;
    postDocument(this.props.axios, documentData).then(data => {
      let documents = this.state.document || [];
      documents.push(data);
      this.setState({ documents: documents, openDocumentPopUp: false, isDataDoc: false });
    });
  };

  createNewDiscussion = projectId => e => {
    let discussionData = this.state.newDiscussionData;
    if (this.state.showTopicList) discussionData["project"] = null;
    else {
      discussionData["project"] = projectId;
      discussionData["topic"] = null;
    }

    createDiscussion(this.props.axios, discussionData).then(data => {
      let discussions = this.state.discussions || [];
      discussions.push(data);
      this.setState({ discussions: discussions, openDiscussionPopUp: false });
    });
  };

  handleDiscussionSwitch = e => {
    this.setState({ showTopicList: e.target.checked });
  };

  renderCreateDocumentsButton = e => {
    const projectDetails = this.state;
    const { classes } = this.props;
    const { openDocumentPopUp } = this.state;
    return (
      <div className={classes.createTopicStyle}>
        {
          projectDetails.user_permissions &&
          (
            projectDetails.user_permissions.includes(ProjectPermissions.CREATE_PROJECT_DOCUMENTS) ||
            projectDetails.user_permissions.includes(ProjectPermissions.CREATE_PROJECT_PRIVATE_DOCUMENTS)
          ) &&
          <Button color={buttonColorScheme.createButton} onClick={this.handleModalOpen("Document")}>
            <AddIcon fontSize="48px" aria-label="add" />
            Upload New Document
          </Button>
        }

        <Modal
          style={{ margin: "30px" }}
          open={openDocumentPopUp}
          onClose={this.handleModalClose}
        >
          <Card
            style={{ backgroundColor: "white", margin: "30px", width: "auto" }}
          >
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                  <h4 className={classes.discusionTitle}>
                    Upload New Document
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <Input
                    type="file"
                    onChange={this.documentUpdate("document")}
                    style={{ marginTop: "20px", width: "100%" }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <CustomInput
                    labelText={
                      <span>
                        Title <small>(required)</small>
                      </span>
                    }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.documentUpdate("name"),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        >
                          <ContactMail className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <TextField
                    id="description"
                    label="Description"
                    rows={4}
                    multiline
                    className={classes.textField}
                    onChange={this.documentUpdate("description")}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <div className={classes.select}>
                    <Select
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: "grey",
                          primary: "rgb(243, 197, 63)"
                        }
                      })}
                      onChange={this.documentUpdate("permissions")}
                      placeholder={<span>Permissions</span>}
                      options={permissions}
                      className={classes.filter}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isDataDoc}
                          onChange={this.documentUpdate("isDataDoc")}
                        />
                      }
                      label={
                        <PrimaryText>Send CSV as Data-File?</PrimaryText>
                      }
                    />
                  </div>
                </GridItem>

                <GridItem
                  style={{ marginTop: "30px", display: "flex" }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <GridItem style={{ textAlign: "end" }} xs={6} sm={6} md={6}>
                    <Button
                      type="button"
                      color="rose"
                      onClick={this.handleModalClose}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      type="button"
                      color="success"
                      onClick={this.uploadDocument(this.state.id)}
                    >
                      Create
                    </Button>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  };

  showParticipantsTable = () => {
    const projectDetails = this.state;
    const { classes } = this.props;
    const canDeleteParticipants = projectDetails.user_permissions &&
                                    projectDetails.user_permissions.includes(ProjectPermissions.DELETE_PROJECT_PARTICIPANTS);
    const canUpdateParticipants = projectDetails.user_permissions &&
                                    projectDetails.user_permissions.includes(ProjectPermissions.UPDATE_PROJECT_PARTICIPANTS);
    return (
      <div className={classes.participantsContainer}>
        {
          projectDetails.user_permissions &&
          projectDetails.user_permissions.includes(ProjectPermissions.CREATE_PROJECT_PARTICIPANTS) &&
          <div className={classes.participantActionsContainer}>
            <Button color="info" onClick={e => this.activateAddParticipantsModal(e)}>
              Add participants
            </Button>
          </div>
        }
        <div>
          <AddParticipants
            projectId={this.state.id}
            axios={this.props.axios}
            active={this.state.addParticipantsModalActive === true}
            onClose={e => {this.refreshProjectDetails(this.state.id)}}
          />
          <ParticipantsList
            showUpdate={canUpdateParticipants}
            showDelete={canDeleteParticipants}
            participants={this.state.participants}
            projectId={this.state.id}
            axios={this.props.axios}
            editable={true}
          />
        </div>
      </div>
    );
  };

  refreshProjectDetails(projectId) {
    getProjectDetails(this.props.axios, projectId).then(data => {
      this.setState({ ...data });
    });
    this.setState({addParticipantsModalActive: false});
  }

  renderCreateDiscussionButton = e => {
    const projectDetails = this.state;
    const { classes } = this.props;
    const { openDiscussionPopUp } = this.state;
    return (
      <div className={classes.createTopicStyle}>
        {
          projectDetails.user_permissions &&
          projectDetails.user_permissions.includes(ProjectPermissions.CREATE_PROJECT_DISCUSSIONS) &&
          <Button color="primary" onClick={this.handleModalOpen("Discussion")}>
            <AddIcon fontSize="48px" aria-label="add" />
            Create New Discussion
          </Button>
        }

        <Modal
          style={{ margin: "30px" }}
          open={openDiscussionPopUp}
          onClose={this.handleModalClose}
        >
          <Card
            style={{ backgroundColor: "white", margin: "30px", width: "auto" }}
          >
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                  <h4 className={classes.discusionTitle}>
                    Create New Discussion
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <CustomInput
                    labelText={
                      <span>
                        Title <small>(required)</small>
                      </span>
                    }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.discussionUpdate("name"),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        >
                          <ContactMail className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                  <TextField
                    id="description"
                    label="Description"
                    rows={4}
                    multiline
                    className={classes.textField}
                    onChange={this.discussionUpdate("description")}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                  />
                </GridItem>
                {this.state.showTopicList ? (
                  <GridItem xs={12} sm={12} md={12} lg={10}>
                    <div className={classes.select}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "grey",
                            primary: "rgb(243, 197, 63)"
                          }
                        })}
                        onChange={this.discussionUpdate("topicId")}
                        placeholder={<span>Topics</span>}
                        options={this.state.topics.map(element => {
                          return { label: element.name, value: element.id };
                        })}
                        className={classes.filter}
                      />
                    </div>
                  </GridItem>
                ) : null}

                <GridItem align="right" xs={12} sm={12} md={12} lg={10}>
                  <FormControlLabel
                    label="Create Topic Discussion"
                    control={
                      <Switch
                        checked={this.state.showTopicList}
                        onChange={this.handleDiscussionSwitch}
                        value="approved"
                        color="primary"
                      />
                    }
                  />
                </GridItem>

                <GridItem
                  style={{ marginTop: "30px", display: "flex" }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <GridItem style={{ textAlign: "end" }} xs={6} sm={6} md={6}>
                    <Button
                      type="button"
                      color="rose"
                      onClick={this.handleModalClose}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      type="button"
                      color="success"
                      onClick={this.createNewDiscussion(this.state.id)}
                    >
                      Create
                    </Button>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  };

  renderCreateTopicButton = e => {
    const projectDetails = this.state;
    const { classes } = this.props;
    const can_create_topic = projectDetails.user_permissions &&
                              projectDetails.user_permissions.includes(ProjectPermissions.CREATE_PROJECT_TOPICS)
    if (can_create_topic) {
      return (
        <div className={classes.createTopicStyle}>
          <Button color="primary" onClick={this.handleModalOpen("Topic")}>
            <AddIcon fontSize="48px" aria-label="add" />
            Create New Topic
          </Button>
          {this.renderTopicModal()}
        </div>
      );
    }
  };

  renderTopicModal() {
    const { classes } = this.props;
    const { openTopicPopUp } = this.state;
    return (
      <Modal
        style={{ margin: "30px" }}
        open={openTopicPopUp}
        onClose={this.handleModalClose}
      >
        <Card
          style={{ backgroundColor: "white", margin: "30px", width: "auto" }}
        >
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12}>
                <h4 className={classes.discusionTitle}>Create a New Topic</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <CustomInput
                  labelText={
                    <span>
                      Title <small>(required)</small>
                    </span>
                  }
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: this.topicUpdate(-1, "name"),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <ContactMail className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <TextField
                  id="description"
                  label="Description (required)"
                  rows={4}
                  multiline
                  className={classes.textField}
                  onChange={this.topicUpdate(-1, "description")}
                  variant="outlined"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
              </GridItem>
              <GridItem
                style={{ marginTop: "30px", display: "flex", justifyContent: "space-between", flexWrap: "column" }}
                xs={12}
                sm={12}
                md={12}
              >
                <Button
                  type="button"
                  color="success"
                  onClick={this.createNewTopic}
                  style={{ marginLeft: "90px"}}
                >
                  Create
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={this.handleModalClose}
                  style={{ marginRight: "90px"}}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Modal>
    );
  }

  renderIndividualTopics(topic) {
    const { classes } = this.props;
    return (
      <Card
        key={topic.id}
        style={{
          border: "1px solid lightgrey",
          padding: "10px",
          boxShadow: "4px 10px #efefef"
        }}
      >
        <CardBody>
          <CustomInput
            labelText="Topic Name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              defaultValue: topic.name,
              onChange: this.topicUpdate(topic.id, "name")
            }}
          />
          {/* <TextField
            label="Description"
            rows={4}
            multiline
            className={classes.textField}
            defaultValue={topic.description}
            onChange={this.topicUpdate(topic.id, "description")}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused
              }
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              }
            }}
          /> */}
          <ReactQuill
            className={classes.topicDescriptionContainer}
            theme="snow"
            value={topic.description}
            onChange={this.topicUpdate(topic.id, "description")}
          />
        </CardBody>
        <CardFooter>
          <div style={projectStyles.projectUpdateInfo}>
            <label>Last Update:</label>
            <span>{" "}</span>
            <span>{moment(topic.updated_at).format("MMMM Do YYYY")}</span>
          </div>
          {this.renderDeleteButton(topic.id)}
        </CardFooter>
      </Card>
    );
  }

  renderDeleteButton = id => {
    const projectDetails = this.state;
    const { classes } = this.props;
    const canDelete = projectDetails.user_permissions && projectDetails.user_permissions.includes(ProjectPermissions.DELETE_PROJECT_TOPICS);

    if (!canDelete) return null;

    return (
      <div className={classes.deleteTopicStyle}>
        <span>
          <Tooltip
            id="tooltip-top"
            title="Delete Project"
            placement="bottom"
            classes={{ tooltip: this.props.classes && this.props.classes.tooltip }}
          >
            <Button
              color="danger"
              size="sm"
              onClick={this.deleteTopicDetails(id)}
            >
              <DeleteIcon fontSize="48px" aria-label="delete" />
              Delete Topic
            </Button>
          </Tooltip>
        </span>
      </div>
    );
  };

  renderDeleteDiscussionButton = id => {
    const projectDetails = this.state;
    const { classes } = this.props;
    const canDelete = projectDetails.user_permissions &&
                        projectDetails.user_permissions.includes(ProjectPermissions.DELETE_PROJECT_DISCUSSIONS);
    if (canDelete) {
      return (
        <div className={classes.deleteTopicStyle}>
          <span>
            <Tooltip
              id="tooltip-top"
              title="Delete Discussion"
              placement="bottom"
              classes={{ tooltip: this.props.classes && this.props.classes.tooltip }}
            >
              <Button
                color="danger"
                size="sm"
                onClick={this.deleteDiscussionDetails(id)}
              >
                <DeleteIcon fontSize="48px" aria-label="delete" />
                Delete Discussion
              </Button>
            </Tooltip>
          </span>
        </div>
      );
    }
  };

  renderDocuments(documents) {
    const { classes } = this.props;
    return (
      <div className={classes.documentsContainer}>
        {this.renderCreateDocumentsButton()}
        <TableContainer className={classes.documentsTableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>Document</TableCell>
                <TableCell className={classes.tableHeader}>Details</TableCell>
                <TableCell className={classes.tableHeader}>Last update</TableCell>
                <TableCell className={classes.tableHeader}>Download link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((document, idx) => {
                return (
                  <TableRow>
                    <TableCell className={classes.tableCell}>{idx+1}</TableCell>
                    <TableCell className={classes.tableCell}>{document.name}</TableCell>
                    <TableCell className={classes.tableCell}>{document.description}</TableCell>
                    <TableCell className={classes.tableCell}>{moment(document.updated_at).format("MMMM Do YYYY")}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <a key={idx} href={`${MEDIA_ROOT}${document.file}`}>download</a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  renderBasicInfo(projectDetails) {
    const { classes } = this.props;
    return (
      <div style={{ width: "100%", margin: "8px" }}>
        <CustomInput
          labelText="Project Name"
          id="name"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            defaultValue: projectDetails.name,
            onChange: this.onChange("name")
          }}
        />
        <div>
          <label>Project Visibility</label>
          <ReactQuill
            className={classes.projectDescriptionContainer}
            theme="snow"
            value={projectDetails.description}
            onChange={this.onChange("description")}
          />
        </div>
        <div>
          <label>Project Visibility</label>
          <Select
            options={[{"label": "Public", "value": "public"}, {"label": "Private", "value": "private"}]}
            onChange={this.handleFilterSelectionChange("profilevisibility")}
            placeholder={<span>{projectDetails.profile_visibility || "Select"}</span>}
          />
        </div>
      </div>
    );
  }

  renderAbout(projectDetails) {
    const { classes } = this.props;
    return (
      <div>
        <Accordion
          active={0}
          expanded={true}
          collapses={[
            {
              title: "Basic Info",
              content: this.renderBasicInfo(projectDetails)
            },
            {
              title: "Project SDGs",
              content: this.renderSelect(
                "sustainable_development_goals",
                "SDG",
                SDGOptions,
                projectDetails.sustainable_development_goals
              )
            },
            {
              title: "Type of Project",
              content: this.renderTypeOfProject(
                "project_type",
                "Type of Project",
                ProjectTypeChoices,
                projectDetails.project_type
              )
            },
            {
              title: "Focus Areas",
              content: this.renderSelect(
                "focus_areas",
                "Focus Areas",
                FocusAreaChoices,
                projectDetails.focus_areas
              )
            },
            {
              title: "Regions",
              content: this.renderSelect(
                "regions",
                "Regions",
                RegionChoices,
                projectDetails.regions
              )
            }
          ]}
        />
        <div className={classes.createrInfo}>
          <h4>
            <small>
            Created by
            <a href={`/members/${projectDetails.created_by.id}`}>{` ${
              projectDetails.created_by.email
            } `}</a>
            {`at ${moment(projectDetails.created_at).format("MMMM Do YYYY")}`}{" "}
            </small>
          </h4>
        </div>
      </div>
    );
  }

  renderUpdateButton = e => {
    const { classes } = this.props;
    return (
      <div className={classes.projectActionsContainer}>
        <Button size="sm" color={buttonColorScheme.createButton} onClick={this.updateProjectDetails}>
          Update Project
        </Button>
        <Button size="sm" color={buttonColorScheme.infoButton} onClick={this.finishEditing}>
          Finish Editing
        </Button>
      </div>
    );
  };

  handleFilterSelectionChange = type => (values, action) => {
    if (type === "profilevisibility") {
      let updatedData = this.state.updatedData;
      let value = values.value;
      updatedData["profile_visibility"] = value;
      this.setState({ "profile_visibility": value, updatedData: updatedData });
    } else {
      let updatedData = this.state.updatedData;
      let dt = updatedData;
      let tempArray = type.split(".");
      tempArray.slice(0, -1).forEach(key => {
        if (dt.hasOwnProperty(key)) {
          dt = dt[key];
        } else {
          dt[key] = {};
          dt = dt[key];
        }
      });
      let keyName = tempArray[tempArray.length - 1];

      if (Array.isArray(values)) {
        values = values.map(val => val && val.value);
      } else {
        values = values && values.value;
      }
      dt[keyName] = values;
      this.setState({ updatedData: updatedData });
    }
  };

  getListItems = (options, displayList) => {
    let tempOptions = [];
    options.forEach(e => {
      displayList.forEach(dt => {
        if (dt["value"] === e) {
          tempOptions.push(dt);
        }
      });
    });
    return tempOptions;
  };

  renderTypeOfProject = (fieldName, placeholder, displayList, defaultValue) => {
    const { classes } = this.props;
    defaultValue = displayList.find(dl => dl.value === defaultValue)
    return (
      <div className={classes.sdg}>
        <Select
          defaultValue={defaultValue}
          placeholder={<span>{placeholder}</span>}
          onChange={this.handleFilterSelectionChange(fieldName)}
          options={displayList}
          className={classes.filter}
        />
      </div>
    );
  };

  renderSelect = (fieldName, placeholder, displayList, defaultValue) => {
    const { classes } = this.props;
    /*let defaultValues = [];
    displayList.forEach(dl => {
      if (defaultValue.includes(dl.value)) {
        defaultValues.push(dl);
      }
    });*/

    // Check if defaultValue is null or undefined, and set defaultValues accordingly
    let defaultValues = defaultValue
    ? displayList.filter(dl => defaultValue.includes(dl.value))
    : [];

    return (
      <div className={classes.sdg}>
        <Select
          isMulti
          defaultValue={defaultValues}
          placeholder={<span>{placeholder}</span>}
          onChange={this.handleFilterSelectionChange(fieldName)}
          options={displayList}
          className={classes.filter}
        />
      </div>
    );
  };

  render() {
    return this.renderProjectDetails();
  }
}

export default withStyles(projectStyles)(EditProject);
