import React, { Component } from "react";
import Select from "react-select";
// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";


import withStyles from "@material-ui/core/styles/withStyles";

import {updateProjectStyle} from "../../services/projectDataProvider";

const styles = {
  settingsContainer: {
    padding: "10px 0 10px 10px",
  },
  settingsSeparator: {
    marginTop: "0px",
    marginBottom: "10px"
  },
  settingsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  settingsTitle: {
    fontSize: "larger",
    paddingBottom: "10px"
  },
  inputWithLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputWithSelectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  selectInput: {
    width: "50%",
    margin: "0px 9px 10px 0px"
  },
  textInput: {
    margin: "-3px 10px 10px 10px"
  },
  settingsActionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    padding: "0 10px 0 0"
  }
};

const colorSelectionOptions = [
  { label: "Primary", value: "primary" },
  { label: "Secondary", value: "secondary" },
  { label: "Info", value: "info" },
  { label: "Warning", value: "warning" },
  { label: "Danger", value: "danger" },
  { label: "Error", value: "error" },
];

// sample settings
// {
//   "styling_info": {
//     "projectPage": {
//       "background": "#ffffff"
//     },
//     "projectAvatar": {
//       "background": "red"
//     },
//     "projectName": {
//       "font-size": "20px",
//       "color": "blue",
//       "font-family": "cursive"
//     },
//     "projectUpdateInfo": {
//       "font-size": "15px",
//       "color": "brown"
//     },
//     "navTabsColor": "info",
//     "infoTitleColor": "info",
//     "infoActionButtonColor": "info",
//     "primaryActionButtonColor": "primary",
//     "secondaryActionButtonColor": "secondary",
//     "labelStyle": {
//       "color": "black",
//       "font-family": "cursive"
//     },
//     "anchorStyle": {
//       "color": "#9c27b0"
//     },
//     "tabTitle": {
//       "background": "#00acc1",
//       "color": "blue",
//       "font-family": "cursive"
//     },
//     "tabContent": {
//       "background": "#ffffff",
//       "font-family": "cursive"
//     },
//     "surveyCard": {
//       "background": "#ffffff"
//     },
//     "participantsTable": {
//       "border": "solid",
//       "border-width": "1px"
//     },
//     "documentsTable": {
//       "border": "solid",
//       "border-width": "1px"
//     }
//   }
// }

class ProjectSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      settings: {},
      stylingData: {
        projectBackground: null,
        projectAvatarBackground: null,
        projectFontFamily: null,
        projectTitleFontSize: null,
        projectTitleFontFamily: null,
        projectTitleFontColor: null,
        projectLabelFontSize: null,
        projectLabelFontFamily: null,
        projectLabelFontColor: null,
        projectNavPillColor: null,
        projectTabBackground: null,
        projectTabFontSize: null,
        projectTabFontFamily: null,
        projectTabFontColor: null,
        infoTitleColor: null,
        primaryActionButtonColor: null,
        secondaryActionButtonColor: null,
        infoActionButtonColor: null,
      }
    };
  }

  toggleDrawer = drawerState => {
    this.setState({drawerOpen: drawerState});
  };

  getUpdatedStylingInfo = () => {
    const stylingData = this.state.stylingData || {};
    return {
      "projectPage": {
        "background": stylingData.projectBackground
      },
      "projectAvatar": {
        "background": stylingData.projectAvatarBackground
      },
      "projectName": {
        "font-size": stylingData.projectTitleFontSize,
        "color": stylingData.projectTitleFontColor,
        "font-family": stylingData.projectTitleFontFamily
      },
      "projectUpdateInfo": {
        "font-size": stylingData.projectLabelFontSize,
        "color": stylingData.projectLabelFontColor,
        "font-family": stylingData.projectLabelFontFamily,
      },
      "navTabsColor": stylingData.projectNavPillColor,
      "infoTitleColor": stylingData.infoTitleColor,
      "infoActionButtonColor": stylingData.infoActionButtonColor,
      "primaryActionButtonColor": stylingData.primaryActionButtonColor,
      "secondaryActionButtonColor": stylingData.secondaryActionButtonColor,
      "labelStyle": {
        "font-size": stylingData.projectLabelFontSize,
        "color": stylingData.projectLabelFontColor,
        "font-family": stylingData.projectLabelFontFamily,
      },
      "anchorStyle": {
        "color": "#9c27b0"
      },
      "tabTitle": {
        "background": stylingData.projectTabBackground,
        "color": stylingData.projectTabFontColor,
        "font-size": stylingData.projectTabFontSize,
        "font-family": stylingData.projectTabFontFamily
      },
      "tabContent": {
        "background": stylingData.projectBackground,
        "font-family": stylingData.projectFontFamily
      },
      "surveyCard": {
        "background": stylingData.projectBackground,
      },
      "participantsTable": {
        "border": "solid",
        "border-width": "1px"
      },
      "documentsTable": {
        "border": "solid",
        "border-width": "1px"
      }
    };
  };

  parseStylingInfo = (settings) => {
    const stylingInfo = settings.styling_info;

    if (stylingInfo) {
      const stylingData = {
        projectBackground: stylingInfo.projectPage && stylingInfo.projectPage.background,
        projectAvatarBackground: stylingInfo.projectAvatar && stylingInfo.projectAvatar.background,
        projectFontFamily: stylingInfo.tabContent && stylingInfo.tabContent["font-family"],
        projectTitleFontSize: stylingInfo.projectName && stylingInfo.projectName["font-size"],
        projectTitleFontFamily: stylingInfo.projectName && stylingInfo.projectName["font-family"],
        projectTitleFontColor: stylingInfo.projectName && stylingInfo.projectName["color"],
        projectLabelFontSize: stylingInfo.labelStyle && stylingInfo.labelStyle["font-size"],
        projectLabelFontFamily: stylingInfo.labelStyle && stylingInfo.labelStyle["font-family"],
        projectLabelFontColor: stylingInfo.labelStyle && stylingInfo.labelStyle["color"],
        projectTabBackground: stylingInfo.tabTitle && stylingInfo.tabTitle.background,
        projectTabFontSize: stylingInfo.tabTitle && stylingInfo.tabTitle["font-size"],
        projectTabFontFamily: stylingInfo.tabTitle && stylingInfo.tabTitle["font-family"],
        projectTabFontColor: stylingInfo.tabTitle && stylingInfo.tabTitle["color"],
        projectNavPillColor: stylingInfo.navTabsColor,
        infoTitleColor: stylingInfo.infoTitleColor,
        infoActionButtonColor: stylingInfo.infoActionButtonColor,
        primaryActionButtonColor: stylingInfo.primaryActionButtonColor,
        secondaryActionButtonColor: stylingInfo.secondaryActionButtonColor
      };
      this.setState({stylingData: stylingData}, () => console.log("parsed styling", this.state.stylingData));
    }
  };

  updateStyleData = styleName => e => {
    const stylingData = this.state.stylingData || {};
    stylingData[styleName] = e.target.value;
    this.setState({stylingData: stylingData}, () => {
      const settings = this.props.settings || {};
      settings.styling_info = this.getUpdatedStylingInfo();
      this.props.onUpdate && this.props.onUpdate(settings);
    });
  };

  updateStyleDataOnSelection = styleName => e => {
    console.log("updateStyleDataOnSelection", styleName, e);
    const selectedValue = e.value;
    if (selectedValue) {
      const stylingData = this.state.stylingData || {};
      stylingData[styleName] = selectedValue;
      this.setState({stylingData: stylingData}, () => {
        const settings = this.props.settings || {};
        settings.styling_info = this.getUpdatedStylingInfo();
        this.props.onUpdate && this.props.onUpdate(settings);
      });
    }
  };

  componentDidMount() {
    const settings = this.props.settings;
    if (settings) {
      this.parseStylingInfo(settings);
    }
  }

  updateProjectSettings = e => {
    const settings = this.props.settings;
    if (settings) {
      const stylingInfo = settings.styling_info;
      if (stylingInfo) {
        updateProjectStyle(this.props.axios, this.props.projectId, stylingInfo).then(data => {
          // this.setState({ ...data });
          console.log("Updated project style", data);
        });
      }
    }
  };

  closeSettings = e => {
    this.toggleDrawer(false);
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        anchor={"right"}
        variant="persistent"
        open={this.state.drawerOpen}
        onClose={this.closeSettings}
      >
        <div className={classes.settingsContainer}>
          <div className={classes.settingsHeader}>
            <h4>Project Settings</h4>
            <IconButton
              aria-label="Close"
              className={classes.drawerCloseButton}
              onClick={this.closeSettings}
            >
              <Close />
            </IconButton>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Project
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithLabelContainer}>
              <label>Background</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectBackground")}
                value={this.state.stylingData && this.state.stylingData.projectBackground}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Avatar Background</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectAvatarBackground")}
                value={this.state.stylingData && this.state.stylingData.projectAvatarBackground}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectFontFamily")}
                value={this.state.stylingData && this.state.stylingData.projectFontFamily}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Project title
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithLabelContainer}>
              <label>Font size</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTitleFontSize")}
                value={this.state.stylingData && this.state.stylingData.projectTitleFontSize}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTitleFontFamily")}
                value={this.state.stylingData && this.state.stylingData.projectTitleFontFamily}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font color</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTitleFontColor")}
                value={this.state.stylingData && this.state.stylingData.projectTitleFontColor}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Project label
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithLabelContainer}>
              <label>Font size</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectLabelFontSize")}
                value={this.state.stylingData && this.state.stylingData.projectLabelFontSize}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectLabelFontFamily")}
                value={this.state.stylingData && this.state.stylingData.projectLabelFontFamily}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font color</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectLabelFontColor")}
                value={this.state.stylingData && this.state.stylingData.projectLabelFontColor}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Project tabs
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithSelectContainer}>
              <label>Nav Pill</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                value={this.state.stylingData && this.state.stylingData.projectNavPillColor}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("projectNavPillColor")}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font size</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTabFontSize")}
                value={this.state.stylingData && this.state.stylingData.projectTabFontSize}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font family</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTabFontFamily")}
                value={this.state.stylingData && this.state.stylingData.projectTabFontFamily}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Font color</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTabFontColor")}
                value={this.state.stylingData && this.state.stylingData.projectTabFontColor}
              />
            </div>
            <div className={classes.inputWithLabelContainer}>
              <label>Background</label>
              <input
                type="text"
                className={classes.textInput}
                onChange={this.updateStyleData("projectTabBackground")}
                value={this.state.stylingData && this.state.stylingData.projectTabBackground}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsTitle}>
            Other
          </div>
          <div className={classes.styleInputsContainer}>
            <div className={classes.inputWithSelectContainer}>
              <label>Info title</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("infoTitleColor")}
              />
            </div>
            <div className={classes.inputWithSelectContainer}>
              <label>Primary action</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("primaryActionButtonColor")}
              />
            </div>
            <div className={classes.inputWithSelectContainer}>
              <label>Secondary action</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("secondaryActionButtonColor")}
              />
            </div>
            <div className={classes.inputWithSelectContainer}>
              <label>Info action</label>
              <Select
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "rgb(243, 197, 63)"
                  }
                })}
                placeholder={<span>Color</span>}
                options={colorSelectionOptions}
                className={classes.selectInput}
                onChange={this.updateStyleDataOnSelection("infoActionButtonColor")}
              />
            </div>
          </div>
          <hr className={classes.settingsSeparator}/>

          <div className={classes.settingsActionContainer }>
            <Button
              color="primary"
              onClick={this.updateProjectSettings}
            >
              Update Settings
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }
}
    
export default withStyles(styles)(ProjectSettings);
