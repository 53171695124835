/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Wizard from "components/Wizard/Wizard.jsx";

import InstituteForm from "../../layouts/Registration/WizardSteps/InstituteForm.js";
import InstituteDetailsForm from "../../layouts/Registration/WizardSteps/InstituteDetailsForm.js";
import InstituteCreationDone from "../../layouts/Registration/WizardSteps/InstituteCreationDone.jsx";


import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

const styles = {
  hedera: {
    fontFamily: "'Josefin Sans','Open Sans', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif'",
    fontWeight: "800"
  }
}

let registrationRoutes = [];

class CreateNewInstitute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    /*if (navigator.platform.indexOf("Win") > -1 && mainPanelRef.current) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }*/
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <Wizard
        validate
        steps={[
          { stepName: "Institution", stepComponent: InstituteForm, stepId: "institute" },
          { stepName: "Institution details", stepComponent: InstituteDetailsForm, stepId: "institutedetails" },
          { stepName: "Completed", stepComponent: InstituteCreationDone, stepId: "finish" },
        ]}
        title="Create New Institute"
        subtitle="Please fill in your Institute information"
        finishButtonClick={e => this.props.history.push("/dashboard")}
      />
    );
  }
}

CreateNewInstitute.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(CreateNewInstitute);
