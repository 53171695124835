/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// import Icon from "@material-ui/core/Icon";
//import moment from "moment";

import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import {
  ReactTabulator,
  DateEditor,
} from "react-tabulator";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { getQuizDetails, updateQuiz } from "../../services/quizDataProvider";

import styles from "./quizStyles.jsx";
import { getQuestionComplete } from "../../utils/quiz";
import QuizExportButton from "./QuizExportButton";

const options = {
  height: '600px'
};

const editableColumns = [
  { title: "Question Id", field: "questionId", headerFilter: "input" },
  { title: "Question", field: "question", headerFilter: "input" },
  { title: "Answered By", field: "user", headerFilter: "input" },
  {
    title: "Answered On",
    field: "createdAt",
    headerFilter: "input",
    editor: DateEditor,
    editorParams: { format: "MM/DD/YYYY" },
  },
  { title: "Answer", field: "answer", headerFilter: "input", editor: "input" },
];

class ExploreQuizSubmissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuiz: props.match.params.quizName,
      quizDetails: null,
    };
  }

  componentDidMount() {
    if (this.state.activeQuiz && this.state.activeQuiz !== ":quizName") {
      getQuizDetails(this.props.axios, this.state.activeQuiz).then((data) =>
        this.setState({ quizDetails: data }, () => {
          this.setState({
            allMandatoryQuestionsAnswered: this.checkIfMandatoryQuestionsAreAnswered(),
          });
        })
      );
    }
  }

  checkIfMandatoryQuestionsAreAnswered() {
    // console.log("checkIfMandatoryQuestionsAreAnswered", this.state);
    let allAnswered = true;
    let variablesDict = {};
    let questions = this.state.quizDetails && this.state.quizDetails.questions;
    let answerData = this.state.quizDetails && this.state.quizDetails.answers;
    questions &&
      questions.forEach((question, idx) => {
        let answer = answerData && answerData[idx];
        if (answer == null) {
          answer = question.answer;
        }

        if (question.required === true) {
          allAnswered = allAnswered && answer !== null;
        }

        // Check the visibility conditions for the questions
        if (
          question.variable_name != null &&
          question.variable_name.length > 0
        ) {
          // console.log("question has variable name answer: ", question, "answer", answer);
          answer = this.extractAnswerData(
            question,
            answer && answer.answer_details
          );
          variablesDict[question.variable_name] = answer;
        }
      });
    // console.log("checkIfMandatoryQuestionsAreAnswered allAnswered", allAnswered);
    // console.log("checkIfMandatoryQuestionsAreAnswered variablesDict", variablesDict);
    return allAnswered;
  }

  extractAnswerData(question, answer) {
    if (answer != null && typeof answer === "object") {
      answer = answer.answer;
      if (question.question_type === "single-select") {
        // console.log("answer - single-select", answer);
        answer = Object.keys(answer).find((key) => answer[key] === true);
      } else if (question.question_type === "multi-select") {
        answer = Object.keys(answer).filter((key) => answer[key] === true);
      } else if (typeof answer === "object") {
        let theAnswer = null;
        Object.keys(answer).forEach((key) => {
          theAnswer = answer[key];
        });
        answer = theAnswer;
      }
    }
    // console.log("answer", question, answer);
    return answer;
  }

  toggleQuizSubmissions = (e) => {
    const { quizDetails } = this.state;
    const quizData = {
      accept_submissions: !quizDetails.accept_submissions,
    };
    updateQuiz(this.props.axios, quizDetails.id, quizData).then((data) => {
      quizDetails.accept_submissions = data.accept_submissions;
      this.setState({ quizDetails: quizDetails });
    });
  };

  extractData() {
    const { quizDetails } = this.state;
    const data = [];
    let questions = quizDetails.questions || [];
    let answers = quizDetails.answers || [];
    let questionsIds = questions.map((question) => question.question_id).sort();
    let questionsDict = {};
    questions.forEach((question) => {
      questionsDict[question.question_id] = {
        question: question.question,
        variable_name: question.variable_name ? question.variable_name : "",
        parent_ids: question.parent_ids,
      };
    });

    let users = answers.map((answer) => answer.user__email).sort();

    let answersDict = {};
    answers.forEach((answer) => {
      let answerData = answer.answer_details;
      if (answerData && typeof answerData.answer === "object") {
        answerData = answerData.answer;
        let answerKeys = Object.keys(answerData);
        if (answerKeys.includes("-data")) {
          answerData = answerData["-data"];
        } else if (answerKeys.includes("-file")) {
          answerData = `FILE: ${answerData["-file"]}`;
        } else {
          answerData = Object.keys(answerData)
            .filter((key) => answerData[key] === true)
            .join(",");
        }
      }
      answersDict[`${answer.question__id}-${answer.user__email}`] = {
        question__id: answer.question__id,
        answer: answerData,
        createdAt: answer.created_at,
      };
    });

    // console.log("questionsDict", questionsDict);
    // console.log("answersDict", answersDict);

    questionsIds.forEach((qId) => {
      users.forEach((user) => {
        let item = {};
        let answer = answersDict[`${qId}-${user}`];
        if (answer !== undefined) {
          item['questionId'] = qId;
          item['question'] = getQuestionComplete(questionsDict, qId);
          item['user'] =  user;
          item['answer'] = answer == null ? "" : answer["answer"];
          item['createdAt'] = answer == null ? "" : answer["createdAt"];
          data.push(item);
        }
      });
    });
    return data;
  }

  render() {
    const { classes } = this.props;
    const { quizDetails } = this.state;
    if (quizDetails == null) return `Loading Quiz Data...`;

    const data = this.extractData();

    return (
      <Card className={classes.quizInfoCard}>
        <CardHeader className={classes.quizHeaderContainer}>
          <h4 className={classes.quizName}>{quizDetails.name}</h4>
        </CardHeader>
        <CardBody>
          <div className={classes.quizDetailsTableContainer}>
            <ReactTabulator
              columns={editableColumns}
              data={data}
              cellEdited={(cell) => console.log("cellEdited", cell)}
              dataChanged={(newData) => console.log("dataChanged", newData)}
              options={options}
            />
          </div>
        </CardBody>
        <CardFooter>
          <div className={classes.quizActionsContainer}>
            <Button
              type="button"
              // color="secondary"
              onClick={(e) => {
                window.location = `/quiz/${this.state.activeQuiz}`;
              }}
              // disabled={!quizData.accessible}
            >
              Show
            </Button>
            <Button
              type="button"
              color={quizDetails.accept_submissions ? "danger" : "success"}
              onClick={(e) => this.toggleQuizSubmissions(e)}
              // disabled={!quizData.accessible}
            >
              {quizDetails.accept_submissions
                ? "Stop Accepting Submissions"
                : "Start Accepting Submissions"}
            </Button>
            <QuizExportButton quizDetails={this.state.quizDetails}/>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default withStyles(styles)(ExploreQuizSubmissions);
