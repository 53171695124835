import React, { useImperativeHandle } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
const NavPills = React.forwardRef((props, ref) => {
  const [active, setActive] = React.useState(props.active);
  const handleChange = (event, active) => {
    setActive(active);
  };
  const handleChangeIndex = index => {
    setActive(index);
  };
  const { tabs, direction, color, vertical, horizontal, alignCenter, tabColors} = props;
  const tabColorstyle  = {}
  if (tabColors!==undefined)
  {
    tabColors.forEach(element => {
      tabColorstyle["customColor"+element] = {  
        color: 'white !important',
        "&,&:hover": {
          color: whiteColor,
          backgroundColor: element,
          boxShadow:
            "0 4px 20px 0px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 7px 10px -5px rgba(" +
            hexToRgb(successColor[0]) +
            ", 0.4)"
        }
      }
    })
  }
  const navPillsStyle = theme => ({
    ...tabColorstyle,
    root: {
      marginTop: "20px",
      paddingLeft: "0",
      marginBottom: "0",
      overflow: "visible !important"
    },
    flexContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexWrap: "wrap"
      }
    },
    displayNone: {
      display: "none !important"
    },
    fixed: {
      overflow: "visible !important"
    },
    horizontalDisplay: {
      display: "block"
    },
    verticalDisplay: {
      display: "flex",
      justifyContent: "space-evenly"
    },
    pills: {
      float: "left",
      position: "relative",
      display: "block",
      padding: "10px 15px",
      color: grayColor[6],
      height: "auto",
      opacity: "1",
      margin: "0 5px",
      fontSize: "12px",
      fontFamily: '"Poppins", sans-serif',
      maxWidth: "100%",
      minWidth: "100px",
      minHeight: "48px",
      textAlign: "center",
      transition: "all .3s",
      fontWeight: "500",
      lineHeight: "24px",
      borderRadius: "15px",
      textTransform: "uppercase",
      letterSpacing: "initial"
    },
    pillsWithIcons: {
      borderRadius: "4px"
    },
    tabIcon: {
      width: "30px",
      height: "30px",
      display: "block",
      margin: "15px 0 !important"
    },
    horizontalPills: {
      width: "100%",
      float: "none !important",
      "& + button": {
        margin: "10px 0"
      }
    },
    labelContainer: {
      padding: "0!important",
      color: "inherit"
    },
    label: {
      lineHeight: "24px",
      textTransform: "uppercase",
      fontSize: "12px",
      fontWeight: "500",
      position: "relative",
      display: "block",
      color: "inherit"
    },
    contentWrapper: {
      marginTop: "20px"
    },
    primary: {
      "&,&:hover": {
        color: whiteColor,
        backgroundColor: primaryColor[0],
        boxShadow:
          "0 4px 20px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.14), 0 7px 10px -5px rgba(" +
          hexToRgb(primaryColor[0]) +
          ", 0.4)"
      }
    },
    info: {
      "&,&:hover": {
        color: whiteColor,
        backgroundColor: infoColor[0],
        boxShadow:
          "0 4px 20px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.14), 0 7px 10px -5px rgba(" +
          hexToRgb(successColor[0]) +
          ", 0.4)"
      }
    },
    success: {
      "&,&:hover": {
        color: whiteColor,
        backgroundColor: successColor[0],
        boxShadow:
          "0 2px 2px 0 rgba(" +
          hexToRgb(successColor[0]) +
          ", 0.14), 0 3px 1px -2px rgba(" +
          hexToRgb(successColor[0]) +
          ", 0.2), 0 1px 5px 0 rgba(" +
          hexToRgb(successColor[0]) +
          ", 0.12)"
      }
    },
    warning: {
      "&,&:hover": {
        color: whiteColor,
        backgroundColor: warningColor[0],
        boxShadow:
          "0 4px 20px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.14), 0 7px 10px -5px rgba(" +
          hexToRgb(warningColor[0]) +
          ", 0.4)"
      }
    },
    danger: {
      "&,&:hover": {
        color: whiteColor,
        backgroundColor: dangerColor[0],
        boxShadow:
          "0 4px 20px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.14), 0 7px 10px -5px rgba(" +
          hexToRgb(warningColor[0]) +
          ", 0.4)"
      }
    },
    rose: {
      "&,&:hover": {
        color: whiteColor,
        backgroundColor: roseColor[0],
        boxShadow:
          "0 4px 20px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.14), 0 7px 10px -5px rgba(" +
          hexToRgb(roseColor[0]) +
          ", 0.4)"
      }
    },
    alignCenter: {
      alignItems: "center",
      justifyContent: "center"
    }
  });
  const useStyles = makeStyles(navPillsStyle);
  const classes = useStyles();
  
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
    [classes.verticalDisplay]: vertical !== undefined
  });
  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone
      }}
      value={active}
      onChange={handleChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon !== undefined) {
          icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined
        });
        return (
          <Tab
            label={prop.tabButton}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: tabColors===undefined?classes[color]:classes["customColor"+tabColors[key]]
            }}
          />
        );
      })}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      <SwipeableViews
        axis={direction === "rtl" ? "x-reverse" : "x"}
        index={active}
        onChangeIndex={handleChangeIndex}
        style={{ overflowY: "hidden" }}
      >
        {tabs.map((prop, key) => {
          return (
            <div className={classes.tabContent} key={key}>
              {prop.tabContent}
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );

  useImperativeHandle(ref, () => ({
    setActiveTab(index) {
      setActive(index);
    }
  }));

  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
});

export default NavPills;

NavPills.defaultProps = {
  active: 0,
  color: "primary"
};

NavPills.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node
    })
  ).isRequired,
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool
};
