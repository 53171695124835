import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

const Summary = (summaryData) => {
  const classes = useStyles();

  const { instituteFormData, userData } = summaryData["summaryData"];

  let styles = {
    hedera: {
      fontFamily: '"Poppins", sans-serif',
    },
    hederaFooter: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "16px"
    },
    bottomBox: {
      marginBottom: "15px",
      bottom: 0,
      width: "100%",
      position: 'absolute',
    }

  }

  return (
    <React.Fragment>
      <Typography gutterBottom style={{ ...styles.hedera, fontSize: 20 }}>
        Summary
      </Typography>

      <Grid container spacing={2}>
        {instituteFormData["inst_new"] === true ?
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom className={classes.title} style={{ ...styles.hedera, fontSize: 20, fontWeight: 200, marginBottom: 10, color: "#8a8a8a" }}>
              Institution
            </Typography>
            <Typography gutterBottom style={{ ...styles.hedera, fontSize: 16, fontWeight: 100, marginBottom: 2 }}>{instituteFormData["inst_name"]}</Typography>
            <Typography gutterBottom style={{ ...styles.hedera, fontSize: 16, fontWeight: 100 }}>{instituteFormData["inst_region"]}</Typography>
            <Typography gutterBottom style={{ ...styles.hedera, fontSize: 16, fontWeight: 100 }}>{instituteFormData["inst_street"]}, {instituteFormData["inst_city"]}, {instituteFormData["inst_country"]}</Typography>
          </Grid>
          : null
        }
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography gutterBottom className={classes.title} style={{ ...styles.hedera, fontSize: 20, fontWeight: 100, marginBottom: 10, color: "#8a8a8a" }}>
            User Details
          </Typography>
          <Grid container>
            <React.Fragment>
              <Grid item xs={12}>
                <Typography gutterBottom style={{ ...styles.hedera, fontSize: 16, fontWeight: 100, marginBottom: 1 }}>Role: {userData["inst_role"]}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom style={{ ...styles.hedera, fontSize: 16, fontWeight: 100, marginBottom: 1 }}>Name: {userData["inst_user_firstname"]} {userData["inst_user_lastname"]}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom style={{ ...styles.hedera, fontSize: 16, fontWeight: 100, marginBottom: 1 }}>Username (email): {userData["inst_email"]}</Typography>
              </Grid>

            </React.Fragment>

          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Summary;