import {
  INSTITUTE_LEGAL_STATUS_CHOICES,
  BASIC_SERVICE_CHOICES,
  IMPACT_METRIC_CHOICES,
  WORLD_REGION_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    legal_status: {
      type: "string",
      title: "Type",
      required: true,
      options: INSTITUTE_LEGAL_STATUS_CHOICES["TechnologyProvider"]
    },
    regions: {
      type: "string",
      title: "Region of Intervention",
      options: WORLD_REGION_CHOICES,
      required: true
    },
    focus_areas: {
      type: "string",
      title: "Focus Areas",
      options: BASIC_SERVICE_CHOICES,
      required: true
    },
    impact_metrics: {
      type: "string",
      title: "Relevant Impact Metrics",
      options: IMPACT_METRIC_CHOICES,
      required: false
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      legal_status: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      },
      focus_areas: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      regions: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      impact_metrics: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  }
];

export const TechnologyProviderFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
