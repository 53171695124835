/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import PostAdd from "@material-ui/icons/PostAdd";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Heading from "components/Heading/Heading.jsx";

import ProjectSummary from "components/ProjectSummary/ProjectSummary.jsx";

import {
  getProjectsList,
  deleteProject,
  getDashboardConfigUrl
} from "../../services/projectDataProvider";
import { buttonColorScheme } from "../../../src/assets/jss/material-dashboard-react/layouts/appstyleconfigs.jsx";
import CustomSwitcher from "../../components/CustomSwitcher/CustomSwitcher.jsx";
import ProjectTableView from "../../components/ProjectTableView/ProjectTableView.jsx";

const styles = {
  card:{
    marginTop: "30px"
  },
  cardTitle: {
    color: "#3C4858",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontSize: "24px",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    },
  },
  cardBody: {
    fontSize: "16px"
  },
  projectsListPage: {
    marginTop: "-30px"
  },
  projectsListHeader: {
    margin: "43px 0 8px 0",
    textAlign: "center"
  },
  projectsListContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexGrow: "1"
  },
  projectDashboard: {
    height: "100vh",
    //width: "-webkit-fill-available",
    width: "100%"
  },
  projectView:{
    overflow:'auto'
  },
  switchWrap:{
    marginBottom:'16px'
  }
};

class ProjectsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDashboard: null,
      activeProject: props.match.params.projectId || "all",
      isTableView:false,
      projects: []
    };
    this.handleChangeTableView = this.handleChangeTableView.bind(this);
  }

  componentDidMount() {
    if (
      this.state.activeProject === ":projectId" ||
      this.state.activeProject === "all"
    ) {
      getProjectsList(this.props.axios).then(data =>
        this.setState({ projects: data.results })
      );
    }
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  header = () => {
    const { classes } = this.props;
    return (
      <div className={classes.projectsListHeader}>
        <span>
          <Tooltip
            id="tooltip-top"
            title="Create New Project"
            placement="bottom"
            classes={{ tooltip: this.props.classes.tooltip }}
          >
              <Button color={ buttonColorScheme.infoButton } onClick={() => window.open("/create-project")}>
              <AddIcon fontSize="48px" aria-label="add" />
            </Button>
          </Tooltip>
        </span>
      </div>
    );
  };

  handleDelete = (projectId) => {
    if (window.confirm("Are you sure you want to delete the project?") === true) {
      deleteProject(this.props.axios, projectId).then(data =>
        getProjectsList(this.props.axios).then(data =>
          this.setState({ projects: data.results })
        )
      );
    }
  };

    handleChangeTableView(event, checked){
    this.setState({isTableView:checked})
  }


  handleLinkClick = (action, projectId) => {
    this.setState({
      action: action,
      activeProject: projectId,
      activeDashboard: null
    });
  };

  handleLinkClickDashboard = (dashboardLink) => {
    this.props.isProjectDashboard(true);
    this.setState({
      activeProject: null,
      activeDashboard: dashboardLink
    });
  };

  showList = () => {
    this.setState({ activeDashboard: null, activeProject: null });
  };

  handleSurveyClick = (surveyLink) => {
    this.props.history.push(`/survey/${surveyLink}`);
  };

  renderProjectsList() {
    let projects = this.state.projects ? this.state.projects.length : 0;
    if (projects > 0) { 
      return this.state.projects.map(project => 
        <ProjectSummary
          key={project.id}
          projectData={project}
          handleDelete={this.handleDelete}
          handleLinkClickDashboard={this.handleLinkClickDashboard}
          handleSurveyClick={this.handleSurveyClick}
          handleLinkClick={this.handleLinkClick}
        />
      );
    } else {
      return <Heading textAlign="center" title={"No Projects Available"} />;
    }
  }

  renderProjectTable(history, axios) {
    let projects = this.state.projects ? this.state.projects.length : 0;
    if(!projects){
      return <Heading textAlign="center" title={"No Projects Available"} />;
    }

    return <ProjectTableView projects={this.state.projects} history={history} axios={axios} />
  }

  renderDashboard(dashboardLink) {
    const { classes } = this.props;
    return (
      <GridContainer className={classes.projectDashboardContainer}>
        <GridItem xs={12} sm={12} md={12}>
          <iframe
            className={classes.projectDashboard}
            src={`${dashboardLink}?load=${getDashboardConfigUrl(dashboardLink)}`}
            title="project dashboard"
          />
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.activeDashboard !== null && this.state.activeProject !== "all") {
      return this.renderDashboard(this.state.activeDashboard);
    } else if (
      this.state.activeProject !== "all" && 
      this.state.activeProject !== ":projectId" &&
      this.state.activeProject !== null
    ) {
      if (this.state.action === "edit") {
        this.props.history.push(`/projects/${this.state.activeProject}/edit/`);
      } else {
        this.props.history.push(`/projects/${this.state.activeProject}`);
      }
    }
    return (
      <div className={classes.projectsListPage}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <Card style={styles.card}>
            <CardHeader icon>
              <CardIcon color="danger">
                <PostAdd />
              </CardIcon>
              <h4 style={styles.cardTitle}>
                Your Projects
              </h4>
            </CardHeader>
            <CardBody>
              <h5 style={styles.cardBody}>
                Projects allows you to collaborate with other users and create interactive pages. 
                You can use projects for creating web surveys, visualizing dashboards from data collection campaigns, or monitor investment KPIs.
              </h5>
              <Button color={ buttonColorScheme.createButton } onClick={() => window.open("/create-project")}>
                <AddIcon fontSize="medium" aria-label="add" /> Create new project
              </Button>
            </CardBody>
          </Card>
          </GridItem>
          <GridItem style={styles.switchWrap}>
            <CustomSwitcher
              label='Table View'
              labelPlacement='start'
              checked={this.state.isTableView}
              onChange={this.handleChangeTableView}
            />
          </GridItem>
          <GridItem style={this.state.isTableView ? styles.projectView : null} xl={12} lg={12} sm={12} md={12}>
            <div className={classes.projectsListContainer}>
              {this.state.isTableView 
              ? this.renderProjectTable(this.props.history, this.props.axios)
              :this.renderProjectsList()
              }
            </div>
          </GridItem>
        </GridContainer>
       {/*  <div className={classes.projectsListContainer}>
          {this.header()}
          {this.renderProjectsList()}
        </div> */}
      </div>
    );
  }
}

export default withStyles(styles)(ProjectsList);
