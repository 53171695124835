import {escapeCSVText, exportToCSV, exportToExcel} from "./export";
import moment from "moment/moment";

export function prepareAllAnswersDict(quizDetails) {
  let questions = quizDetails.questions || [];
  let answers = quizDetails.answers || [];

  let questionsIds = questions.map(question => question.question_id).sort();
  let questionsDict = {};
  questions.forEach(question => {
    questionsDict[question.question_id] = {
      "question": question.question,
      "variable_name": question.variable_name ? question.variable_name : '',
      "parent_ids": question.parent_ids
    }
  });

  let users = answers.map(answer => answer.user__email).sort();

  let answersDict = {};
  answers.forEach(answer => {
    let answerData = answer.answer_details;
    if (answerData && typeof answerData.answer === "object") {
      answerData = answerData.answer;
      let answerKeys = Object.keys(answerData);
      if (answerKeys.includes("-data")) {
        answerData = answerData["-data"];
      } else if (answerKeys.includes("-file")) {
        answerData = `FILE: ${answerData["-file"]}`;
      } else {
        answerData = Object.keys(answerData).filter(key => answerData[key] === true).join(',');
      }
    }
    answersDict[`${answer.question__id}-${answer.user__email}`] = {
      "answer": answerData,
      "createdAt": answer.created_at
    };
  });

  return {
    questionsIds,
    questionsDict,
    answersDict,
    users
  }
}

export function prepareLatestAnswersDict(quizDetails) {
  let questions = quizDetails.questions || [];
  let answers = quizDetails.answers || [];

  let questionsIds = questions.map(question => question.question_id).sort();

  let questionsDict = {};

  questions.forEach(question => {
    questionsDict[question.question_id] = {
      "question": question.question,
      "variable_name": question.variable_name ? question.variable_name : '',
      "parent_ids": question.parent_ids
    }
  });

  let answersDict = {};
  answers.forEach(answer => {
    const qId = answer.question__id;
    let skip = false;
    if (answersDict[qId] && answersDict[qId].createdAt) {
      let lastTime = moment(answersDict[qId]["createdAt"]);
      let newTime = moment(answer.created_at);
      if (newTime.diff(lastTime) < 0) {
        skip = true;
      }
    }
    if (!skip) {
      let answerData = answer.answer_details;
      if (answerData && typeof answerData.answer === "object") {
        answerData = answerData.answer;
        let answerKeys = Object.keys(answerData);
        if (answerKeys.includes("-data")) {
          answerData = answerData["-data"];
        } else if (answerKeys.includes("-file")) {
          answerData = `FILE: ${answerData["-file"]}`;
        } else {
          answerData = Object.keys(answerData).filter(key => answerData[key] === true).join(',');
        }
      }
      answersDict[qId] = {
        "answer": answerData,
        "createdAt": answer.created_at,
        "user": answer.user__email
      };
    }
  });

  return {
    questionsIds,
    questionsDict,
    answersDict
  }
}

export function getQuestionComplete(questionsDict, questionId, alreadyCoveredParentIds = []) {
  if (questionsDict[questionId] == null) return null;

  let parent_ids = questionsDict[questionId].parent_ids;
  if (parent_ids == null || parent_ids.length === 0) return questionsDict[questionId].question;

  let questionText = parent_ids.map(parent_id => {
    if (alreadyCoveredParentIds.includes(parent_id)) return '';
    alreadyCoveredParentIds.push(parent_id);
    return getQuestionComplete(questionsDict, parent_id, alreadyCoveredParentIds);
  });
  questionText = questionText.join(' ');
  if (questionsDict[questionId].question == null || questionsDict[questionId].question === '') {
    return questionText;
  }
  return questionText + ' (' + questionsDict[questionId].question + ') ';
}

function prepareAllQuizData(quizDetails) {
  const {
    questionsIds,
    questionsDict,
    answersDict,
    users
  } = prepareAllAnswersDict(quizDetails)

  const headers = ['Question', 'API Name', 'User Email', 'Answer', 'Created At']
  const result = [headers]

  questionsIds.forEach(qId => {
    users.forEach(user => {
      let answer = answersDict[`${qId}-${user}`];
      if (answer !== undefined) {
        result.push([
          getQuestionComplete(questionsDict, qId),
          questionsDict[qId].variable_name,
          user,
          answer == null ? '' : answer["answer"],
          answer == null ? '' : answer["createdAt"]
        ])
      }
    });
  });

  return result
}

function prepareLatestQuizData(quizDetails, withoutHeaders) {
  const {
    questionsIds,
    questionsDict,
    answersDict
  } = prepareLatestAnswersDict(quizDetails)

  const headers = ['Question', 'API Name', 'User Email', 'Answer', 'Created At']
  const result = withoutHeaders ? [] : [headers]

  questionsIds.forEach(qId => {
    let answer = answersDict[qId];
    if (answer !== undefined) {
      result.push([
        getQuestionComplete(questionsDict, qId),
        questionsDict[qId].variable_name,
        answer == null ? '' : escapeCSVText(answer["user"]),
        answer == null ? '' : escapeCSVText(answer["answer"]),
        answer == null ? '' : answer["createdAt"]
      ])
    }
  });

  return result
}

export function downloadExcelAllResults(quizDetails) {
  exportToExcel(quizDetails.name, prepareAllQuizData(quizDetails));
}

const allQuizzesFileName = 'Data'

function combineLatestQuizData(quizDetailsList) {
  return quizDetailsList.reduce((result, quizDetails, index) => {
    return [...result, ...prepareLatestQuizData(quizDetails, !!index)]
  }, [])
}

export function downloadExcelLatestSingleFile(quizDetailsList) {
  exportToExcel(allQuizzesFileName, combineLatestQuizData(quizDetailsList));
}

export function downloadExcelLatestMultipleFiles(quizDetailsList) {
  quizDetailsList.forEach((quizDetails) => {
    exportToExcel(quizDetails.name, prepareLatestQuizData(quizDetails));
  })
}

export function downloadExcelLatestResults(quizDetails) {
  exportToExcel(quizDetails.name, prepareLatestQuizData(quizDetails));
}

export function downloadCsvAllResults(quizDetails) {
  exportToCSV(quizDetails.name, prepareAllQuizData(quizDetails));
}

export function downloadCsvLatestSingleFile(quizDetailsList) {
  exportToCSV(allQuizzesFileName, combineLatestQuizData(quizDetailsList));
}

export function downloadCsvLatestMultipleFiles(quizDetailsList) {
  quizDetailsList.forEach((quizDetails) => {
    exportToCSV(quizDetails.name, prepareLatestQuizData(quizDetails));
  })
}

export function downloadCsvLatestResults(quizDetails) {
  exportToCSV(quizDetails.name, prepareLatestQuizData(quizDetails));
}

