import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import L from "leaflet";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TextField from "@material-ui/core/TextField";

// icons
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { instituteTypes, SDGs, WORLD_REGION_CHOICES } from "utils/choices.jsx";

import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";
import { search, followInstitute } from "services/instituteDataProvider.js";
import { getInstituteCoordinates } from "../../services/instituteDataProvider.js";
import MapLeft from "../../components/Map/MapLeft.jsx";

const dashboardStyle = {
  filter: {
    marginBottom: "10px"
  }
};

const instituteTypeOptions = instituteTypes.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const SDGOptions = SDGs.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const regionOptions = WORLD_REGION_CHOICES.map(dt => ({
  label: dt.text,
  value: dt.value
}));

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

class MapSearch extends React.Component {
  state = {
    institutes: [],
    filteredData: [],
    dataPoints: []
  };

  componentDidMount() {
    // get all coordinates to fill markers
    getInstituteCoordinates(this.props.axios).then(data => {
      let dataPoints = [];
      data.forEach(dt => {
        if (dt.address && dt.address.latitude && dt.address.longitude) {
          dt["description"] = `
          <a href="institutes/${dt.id}" target='_blank'>${dt["name"]}</a>
          <br>
          ${dt.type}
          <br>
          <small>${dt.address.city_name},${dt.address.country_name} </small>
          <br>
          <a href="${dt.website_url}" target='_blank'>Homepage</a>
          `;

          // change marker color according to type
          if (dt.type === instituteTypes[0].value) {
            dt.color = instituteTypes[0].color;
          } else if (dt.type === instituteTypes[1].value) {
            dt.color = instituteTypes[1].color;
          } else if (dt.type === instituteTypes[2].value) {
            dt.color = instituteTypes[2].color;
          } else if (dt.type === instituteTypes[3].value) {
            dt.color = instituteTypes[3].color;
          } else if (dt.type === instituteTypes[4].value) {
            dt.color = instituteTypes[4].color;
          } else if (dt.type === instituteTypes[5].value) {
            dt.color = instituteTypes[5].color;
          } else dt.color = "black";

          dataPoints.push(dt);
        }
      });

      this.setState({ dataPoints: dataPoints });
    });
  }

  handleChange = e => {
    let val = e.target.value;
    this.setState({ searchTerm: val }, () => {
      search(this.props.axios, val).then(data => {
        let institutes = [];
        data.results &&
          data.results.forEach(dt => {
            institutes.push(dt);
          });
        this.setState({
          institutes: institutes,
          filteredData: institutes,
          searchDataAvailable: true
        });
      });
    });
  };

  handleFilterSelectionChange = type => (values, action) => {
    if (this.state.searchDataAvailable !== true) {
      search(this.props.axios, " ").then(data => {
        let institutes = [];
        data.results &&
          data.results.forEach(dt => {
            institutes.push(dt);
          });
        this.setState({
          institutes: institutes,
          filteredData: institutes,
          searchDataAvailable: true
        });
      });
    }
    let filteredData = this.state.institutes;
    let filterVals = values || [];
    filterVals = filterVals.map(dt => dt.value);
    this.setState({ [`filter${type}`]: filterVals }, () => {
      filteredData = this.filterInstituteType(
        this.state.filterinstituteType || [],
        filteredData
      );
      filteredData = this.filterSDGs(this.state.filtersdg || [], filteredData);
      filteredData = this.filterRegions(
        this.state.filterregion || [],
        filteredData
      );
      this.setState({ filteredData: filteredData });
    });
  };

  filterInstituteType(filterValues, source) {
    // console.log("filterInstituteType", values, source);
    if (filterValues.length > 0) {
      let filteredResults = this.state.institutes.filter(instituteData => {
        const target = instituteData.type;
        return filterValues.filter(dt => dt === target).length > 0;
      });
      // console.log("filteredResults", filteredResults);
      return filteredResults;
    }
    return source;
  }

  filterSDGs(filterValues, source) {
    if (filterValues.length > 0) {
      // console.log("values", filterValues);
      let filteredResults = this.state.institutes.filter(instituteData => {
        const target = instituteData.sustainable_development_goals;
        // console.log("target", target);
        return (
          filterValues.filter(dt => {
            return target.filter(tval => tval === dt).length > 0;
          }).length > 0
        );
      });
      // console.log("filteredResults", filteredResults);
      return filteredResults;
    }
    return source;
  }

  filterRegions(filterValues, source) {
    if (filterValues.length > 0) {
      // console.log("values", filterValues);
      let filteredResults = this.state.institutes.filter(instituteData => {
        const target = instituteData.address.region_name;
        // console.log("target", target);
        return filterValues.filter(dt => dt === target).length > 0;
      });
      // console.log("filteredResults", filteredResults);
      return filteredResults;
    }
    return source;
  }

  followInstitute = (idx, instituteId) => e => {
    followInstitute(this.props.axios, instituteId, true).then(data => {
      // console.log("followInstitute", data);
      let institutes = this.state.institutes;
      institutes[idx].is_following = true;
      this.setState({ institutes: institutes });
    });
  };

  unfollowInstitute = (idx, instituteId) => e => {
    followInstitute(this.props.axios, instituteId, false).then(data => {
      // console.log("unfollowInstitute", data);
      let institutes = this.state.institutes;
      institutes[idx].is_following = false;
      this.setState({ institutes: institutes });
    });
  };

  renderInstitutes() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const institutes = this.state.filteredData;

    return (
      <div>
        {/*<GridContainer>
          
          {this.renderInstitutesMap(this.state.filteredData)}
        </GridContainer>
       */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SearchIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form className={classes.form}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="searct-term"
                        label="Search Institute"
                        name="search"
                        autoFocus
                        onChange={this.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Select
                        isMulti
                        options={instituteTypeOptions}
                        onChange={this.handleFilterSelectionChange(
                          "instituteType"
                        )}
                        placeholder={<span>Type</span>}
                        className={classes.filter}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Select
                        isMulti
                        placeholder={<span>SDG</span>}
                        onChange={this.handleFilterSelectionChange("sdg")}
                        options={SDGOptions}
                        className={classes.filter}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Select
                        isMulti
                        placeholder={<span>Region</span>}
                        onChange={this.handleFilterSelectionChange("region")}
                        options={regionOptions}
                        className={classes.filter}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
          {institutes.map((instData, idx) => {
            return (
              <InstituteSummary
                key={`institute-${idx}`}
                instituteData={instData}
                followInstitute={this.followInstitute(idx, instData.id)}
                unfollowInstitute={this.unfollowInstitute(idx, instData.id)}
              />
            );
          })}
        </GridContainer>
      </div>
    );
  }

  renderInstitutesMap() {
    let center = [52.5103175, 13.3275342];
    let dataPoints = [];
    let allInstitutes = this.state.filteredData || [];
    allInstitutes = allInstitutes.concat(this.state.userInstitutes || []);
    allInstitutes.forEach(inst => {
      inst.address.latitude &&
        inst.address.longitude &&
        dataPoints.push({
          latitude: inst.address.latitude,
          longitude: inst.address.longitude,
          name: inst.name,
          url: inst.website_url
        });
      center = [
        (center[0] + inst.address.latitude) / 2,
        (center[1] + inst.address.longitude) / 2
      ];
    });
    return (
      <GridItem xs={12} sm={12} md={6}>
        <MapLeft center={center} dataPoints={dataPoints} zoom={2} />
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>
          {/* Personal profile */}
          {this.renderInstitutes()}

          {/* Map profile */}
          {this.renderInstitutesMap()}
        </GridContainer>
      </div>
    );
  }
}

MapSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  axios: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MapSearch);
