import { API_ROOT } from "config.js";

const USER_DETAILS_ENDPOINT = `${API_ROOT}/api/user/institutes/`;

export async function getUserInstitutes(axios) {
  let url = `${USER_DETAILS_ENDPOINT}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
