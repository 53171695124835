import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import AccountBalance from "@material-ui/icons/AccountBalance";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomSelectClassic from "components/CustomSelectClassic/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { validateField } from "utils/fieldVarification.jsx";
import { userRoles } from "utils/choices.jsx";
import { withAxios } from "containers/authenticated.js";
import { create } from "services/instituteDataProvider.js";
import { search as searchInstitute } from "services/instituteDataProvider.js";

import CustomForm from "./CustomForm.js";
import { InstituteFromSchema } from "./FormSchemas/InstituteBase.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  filterContainer: {
    zIndex: "9999 !important"
  }
};

class InstituteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  sendState() {
    return this.state;
  }

  searchInstitute = data => {
    return searchInstitute(this.props.axios, data).then(
      data =>
        data.results &&
        data.results.map(result => {
          return { text: result.name, value: result.id };
        })
    );
  };

  changeSelectClassic(event, stateName, type, stateNameEqualTo) {
    let result = validateField(event, type, stateNameEqualTo);
    this.setState({
      [stateName]: result.value,
      [stateName + "State"]: result.result
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    let result = validateField(event, type, stateNameEqualTo);
    let value = result.value;
    if (Array.isArray(result.value)) {
      value = value.map(dt => dt.value);
    } else if (value.value !== undefined) {
      value = value.value;
    }
    this.setState({
      [stateName]: value,
      [stateName + "State"]: result.result
    });
  }

  nextButtonClick() {
    let instituteData = null;
    if (this.state.createNewInstitute) {
      instituteData = InstituteFromSchema.schema.data();
    }
    return create(this.props.axios, this.props.user.user_id, {
      create_new_institute: this.state.createNewInstitute,
      institute: this.state.instituteName,
      instituteData: instituteData && {
        name: instituteData.name,
        short_name: instituteData.short_name,
        type: instituteData.type,
        sustainable_development_goals:
          instituteData.sustainable_development_goals,
        address: {
          country: instituteData.country,
          region: instituteData.region,
          city: instituteData.city,
          zip_code: instituteData.zip_code,
          street: instituteData.street
        },
        website_url: instituteData.website_url,
        date_of_establishment: instituteData.date_of_establishment,
        total_number_of_staff: instituteData.total_number_of_staff,
        vision: instituteData.vision,
        mission: instituteData.mission
      },
      user_role: this.state.userRole
    }).then(data => {
      let instituteId = data.id;
      this.setState({
        instituteId: instituteId,
        instituteType: instituteData && instituteData.type
      });
    });
  }

  fixInputState(inputState) {
    if (this.state[inputState] !== "success") {
      this.setState({ [inputState]: "error" });
    }
  }

  isValidated() {
    if (this.state.createNewInstitute !== true) {
      if (
        this.state.instituteNameState === "success" &&
        this.state.userRoleState === "success"
      ) {
        return true;
      }
    } else if (
      InstituteFromSchema.schema.validate() &&
      this.state.userRoleState === "success"
    ) {
      return true;
    }
    this.fixInputState("instituteNameState");
    this.fixInputState("userRoleState");
    return false;
  }

  renderInstituteForm() {
    return <CustomForm formSchema={InstituteFromSchema} />;
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Add your Institute Information</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.createNewInstitute}
                onChange={e => {
                  this.setState({ createNewInstitute: e.target.checked });
                }}
                value={"Create New Institute"}
              />
            }
            label={"Create New Institute"}
          />
        </GridItem>
        {this.state.createNewInstitute ? (
          this.renderInstituteForm()
        ) : (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomSelectClassic
              searchable
              searchMethod={data => this.searchInstitute(data)}
              success={this.state.instituteNameState === "success"}
              error={this.state.instituteNameState === "error"}
              errorMsg="Please enter a valid institute name."
              labelText={
                <span>
                  Institute <small>(type to search)</small>
                </span>
              }
              onChange={e =>
                this.changeSelectClassic(e, "instituteName", "select")
              }
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <AccountBalance className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.filterContainer}
        >
          <CustomSelect
            success={this.state.userRoleState === "success"}
            error={this.state.userRoleState === "error"}
            errorMsg="User role is required."
            labelText={"Your role within the institution"}
            items={userRoles}
            onChange={e => this.change(e, "userRole", "select")}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

InstituteForm.propTypes = {
  classes: PropTypes.object
};

export default withAxios(withStyles(style)(InstituteForm));