import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import ActionMenu from "./ActionMenu";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import {TableSortLabel} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';

const SurveyName = ({ name, description }) => {
  return (
    <>
      {description ? (
        <Tooltip id="tooltip-bottom" placement="bottom" title={description}>
          <span>{name}</span>
        </Tooltip>
      ) : (
        <Tooltip id="tooltip-bottom" placement="bottom" title="No description available for this survey">
          <span>{name}</span>
        </Tooltip>
      )}
    </>
  );
};
function formattedDate(isoDate) {
  return new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date(isoDate));
}

const useStyles = makeStyles({
  tableContainer: {
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    objectFit: "contain",
  },
  tableBody : {
    color: "#3c4858"
  },
  text: {
    width: "100%",
    textAlign: "center",

  },
  header:{
    fontWeight:'600'
  },
  status:{
    display:'flex',
    flexDirection:'column',
    gap:'16px'
  },
  tableRow:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'8px'
  }
});

const SurveysTable = ({ surveyList, history, setSurveyList, axios }) => {
  const classes = useStyles();

  const [order, setOrder] = useState("asc");
  const [orderByColumn, setOrderByColumn] = useState("name");
  const sortedData = [...surveyList].sort((a, b) => {
    if (order === "asc") {
      return a[orderByColumn] > b[orderByColumn] ? 1 : -1;
    } else {
      return a[orderByColumn] < b[orderByColumn] ? 1 : -1;
    }
  });

  const handleSortRequest = (column) => {
    setOrder(
      column === orderByColumn ? (order === "asc" ? "desc" : "asc") : "asc",
    );
    setOrderByColumn(column);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              <TableSortLabel
                active={orderByColumn === "name"}
                direction={orderByColumn === "name" ? order : "asc"}
                onClick={() => handleSortRequest("name")}
              >
                Survey Name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.header} align="left">
              <TableSortLabel
                active={orderByColumn === "projectName"}
                direction={orderByColumn === "projectName" ? order : "asc"}
                onClick={() => handleSortRequest("projectName")}
              >
                Project Name
              </TableSortLabel>

            </TableCell>
            <TableCell className={classes.header} align="left">
              <TableSortLabel
                active={orderByColumn === "created_at"}
                direction={orderByColumn === "created_at" ? order : "asc"}
                onClick={() => handleSortRequest("created_at")}
              >
                Created
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.header} align="left">
              <TableSortLabel
                active={orderByColumn === "updated_at"}
                direction={orderByColumn === "updated_at" ? order : "asc"}
                onClick={() => handleSortRequest("updated_at")}
              >
                Latest update
              </TableSortLabel>

            </TableCell>
            <TableCell className={classes.header} align="center">Progress</TableCell>
            <TableCell className={classes.header} align="center">Status</TableCell>
            <TableCell className={classes.header} align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {sortedData.length ? (
            sortedData.map((quizData) => {
              const {
                id,
                name,
                created_at,
                updated_at,
                active,
                projectName,
                project,
                metadata,
                description,
                accept_submissions,
              } = quizData;
              const completion =
                metadata && metadata.percentage_completion
                  ? metadata.percentage_completion > 100
                    ? 100
                    : metadata.percentage_completion
                  : 0;


              return (
                <TableRow key={id}>
                  <TableCell align="left" className={classes.tableBody}>
                    <SurveyName name={name} description={description}/>
                  </TableCell>
                  <TableCell align="left">
                    <Link
                      href={`/projects/${project}`}
                      style={{
                        fontFamily: '"Poppins", sans-serif',
                        fontSize: ".8rem",
                        color: "#1E8449",
                      }}
                    >
                      {projectName}
                    </Link>
                  </TableCell>
                  <TableCell align="left" className={classes.tableBody}>
                    {formattedDate(created_at)}
                  </TableCell>
                  <TableCell align="left" className={classes.tableBody}>
                    {formattedDate(updated_at)}
                  </TableCell>
                  <TableCell>
                    <LinearProgress
                      variant="determinate"
                      value={typeof completion === 'string' ? parseFloat(completion) : completion}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.status}>
                    <Chip
                      label={active ? "Accessible" : "Locked"}
                      style={{ fontSize: ".7rem", color: "white", backgroundColor: active ? "#1E8449" : "grey", border: "none" }}
                      variant="outlined"
                    />
                    <Chip
                       label={accept_submissions ? "Ongoing" : "Finalized"}
                       style={{ fontSize: ".7rem", color: "white", backgroundColor: accept_submissions ? "#1E8449" : "red", border: "none" }}
                       variant="outlined"
                    />
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <ActionMenu
                      axios={axios}
                      quizData={quizData}
                      setSurveyList={setSurveyList}
                      history={history}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow key="0">
              <TableCell>No Survey Data</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SurveysTable;
