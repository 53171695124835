import { API_ROOT } from "config.js";
const SEARCH_END_POINT = `${API_ROOT}/api/search/`;

export async function searchApi(axios, payload) {
  let url = SEARCH_END_POINT;
  const response = await axios.post(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
