import { successColor } from "assets/jss/material-dashboard-react.jsx";

const dashboardStyle = {
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: "#999999",
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardCategory: {
    color: "#999999",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: "#3C4858",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  projectDashboard: {
    position: "relative",
    width: "100%",
    border: "none",
    height: "1000px"
  },
  memberavatar: {
    width: "80px !important"
  },
  contactAvatar: {
    width: "80px !important",
    aspectRatio: 1,
    overflow: "hidden",
    //display: "block",
    objectFit: "cover",
    maxWidth: "100vw",
    height: "100%",
    maxHeight: "100vh",
    borderRadius: "50%"
  },
  title: {
    backgroundColor: "#1E8449",
    color: "#FFFFFF",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
    fontWeight: "300",
    marginTop: "20px",
    marginBottom: "35px",
    fontFamily: "'Poppins', 'sans-serif'"
  },
  arrangeVertical: {
    display: "grid"
  },

  contactContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexGrow: "1",
    background: "white"
  },

};

export default dashboardStyle;
