import { API_ROOT } from "config.js";
import axios from "axios";

const INSTITUTE_DETAILS_ENDPOINT = `${API_ROOT}/api/institutes/`;

export async function searchInstitute(name) {
  let url = `${INSTITUTE_DETAILS_ENDPOINT}?name=${name}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function search(axios, name, type=undefined) {
  let url = `${INSTITUTE_DETAILS_ENDPOINT}`;
  if (name) {
    url = `${INSTITUTE_DETAILS_ENDPOINT}?name=${name}`;
  }
  if (type) {
    url = `${INSTITUTE_DETAILS_ENDPOINT}?type=${type}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getData(axios, instituteId) {
  let response = undefined;
  let url = `${INSTITUTE_DETAILS_ENDPOINT}`;
  if (instituteId) {
    url = `${INSTITUTE_DETAILS_ENDPOINT}${instituteId}`;
  }
  if (axios !== undefined) {
    response = await axios.get(url);
  } else {
    response = await fetch(url);
    return response.json();
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateData(axios, instituteId, instituteData) {
  if (instituteData.website_url) {
    let url = instituteData.website_url.toLowerCase();
    if (url.startsWith("http://") || url.startsWith("https://")) {
      instituteData.website_url = url;
    } else {
      instituteData.website_url = `http://${url}`;
    }
  }
  let typeDetailsClean = Object.entries(instituteData.type_details).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {});
  instituteData.type_details = typeDetailsClean;
  let payload = instituteData;
  if (instituteData.avatarFile) {
    payload = new FormData();
    payload.append("file", instituteData.avatarFile);
    delete instituteData["avatarFile"];

    const json = JSON.stringify(instituteData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.put(
    `${INSTITUTE_DETAILS_ENDPOINT}${instituteId}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function create(axios, user_id, userData) {
  let payload = userData;
  if (userData.avatarFile) {
    payload = new FormData();
    payload.append("file", userData.avatarFile);
    delete userData["avatarFile"];

    const json = JSON.stringify(userData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(
    `${INSTITUTE_DETAILS_ENDPOINT}`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getInstituteCoordinates(axios) {
  let url = `${INSTITUTE_DETAILS_ENDPOINT}gps`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function followInstitute(axios, instituteId, follow) {
  const response = await axios.put(
    `${INSTITUTE_DETAILS_ENDPOINT}${instituteId}/follow/`,
    {
      follow: follow
    }
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function markAsViewedInstitute(axios, instituteId, viewed) {
  const response = await axios.put(
    `${INSTITUTE_DETAILS_ENDPOINT}${instituteId}/viewed/`,
    { viewed }
  );

  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }

  return response.data;
}
