/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";

import { getEvents, updateEvent } from "services/eventsDataProvider.js";
import EventSummary from "components/EventSummary/EventSummary.jsx";
import styles from "assets/jss/material-dashboard-react/views/extendedTablesStyle.jsx";

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedData: {},
      events: []
    };
  }

  componentDidMount() {
    getEvents(this.props.axios).then(data => this.setState({ events: data }));
  }

  updateEvent = (event, going) => e => {
    let eventData = {
      attending: going
    };
    updateEvent(this.props.axios, event.id, eventData).then(data => {
      let events = this.state.events;
      events.forEach(event => {
        if (event.id === data.id) {
          event.attendees = data.attendees;
        }
      });
      this.setState({ events: events });
    });
  };

  render() {
    // const { classes } = this.props;

    return (
      <GridContainer>
        {this.state.events &&
          this.state.events.map((eventData, idx) => {
            return (
              <EventSummary
                key={`event-${idx}`}
                eventData={eventData}
                selfUser={this.props.user}
                updateEvent={this.updateEvent}
              />
            );
          })}
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Event);
