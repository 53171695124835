import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import defaultProjectAvatar from "assets/img/HEDERA-blatt.png";
import { ProjectPermissions } from "utils/roles";
import ActionMenu from "./ActionMenu";

function formattedDate(isoDate){
    return new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric', 
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }).format(new Date(isoDate));
}

const useStyles = makeStyles({
  tableContainer:{
    overflow:'auto'
  },
  table: {
    minWidth: 650,
  },
  image:{
    width:"50px",
    height:'50px',
    borderRadius:'8px',
    objectFit:'contain'
  },
});


const ProjectTableView = ({ projects, history, axios }) => {
  const classes = useStyles();
  // const userRoles=useUsersRole({projects, axios})
  // const usersCreators=useUsersCreators({projects, axios})

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Avatar</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">User Role </TableCell>
            <TableCell align="left">Created on</TableCell>
            <TableCell align="left">Created by</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map(({ avatar, name, created_at, created_by, id, user_permissions, user_roles }) => {
            const imgUrl = avatar ? avatar : defaultProjectAvatar;
            const isEdit=user_permissions &&
            user_permissions.includes(ProjectPermissions.UPDATE_PROJECT)
            const userRoleById = user_roles[0];
            const preparedRole=userRoleById ? userRoleById.replaceAll('_', ' ') : '-'
            return (
              <TableRow key={id}>
                <TableCell align='left' component="th" scope="row">
                  <img className={classes.image} src={imgUrl} alt={name} />
                </TableCell>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="left">
                  {preparedRole}
                </TableCell>
                <TableCell align="left">{formattedDate(created_at)}</TableCell>
                <TableCell align="left"> 
                  <Link href={`/member-profile/${created_by}`} style={{ color: "#c90a73" }}>
                    {`${created_by.first_name} ${created_by.last_name}`}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <ActionMenu id={id} history={history} isEdit={isEdit}/>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectTableView;
