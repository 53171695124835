import * as React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const CustomSwitcher=({label, labelPlacement, checked, onChange, name})=>{
  return(
    <FormControlLabel
      style={{margin:0, color:'#000'}}
      control={<Switch
        color="primary"
        checked={checked}
        onChange={onChange}
        name={name}
      />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default CustomSwitcher

