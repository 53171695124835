import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';


import {
  userRoles
} from "utils/choices.jsx";

const UserDetails = ({ userFormData, setUserFormData }) => {

  const { inst_role, inst_user_firstname, inst_user_lastname, inst_email, inst_pwd, inst_repwd } = userFormData;

  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onChangeForm = ({ target: { name, value } }) => {
    setUserFormData(userFormData => ({ ...userFormData, [name]: value }));
    pattern.test(inst_email)
  }

  const styles = {
    hedera: {
      fontFamily: '"Poppins", sans-serif',
    },
    hederaFooter: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "16px"
    },
    bottomBox: {
      marginBottom: "15px",
      bottom: 0,
      width: "100%",
      position: 'absolute',
    }

  }
  return (
    <React.Fragment>
      <Typography gutterBottom style={{ ...styles.hedera, fontSize: 20 }}>
        Now your personal information..
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="select-inst-roles" style={{ marginLeft: 10 }}>
              Roles
            </InputLabel>
            <Select
              labelId="inst_role"
              id="roles"
              name="inst_role"
              value={inst_role}
              onChange={onChangeForm}
              autoWidth
              label="Roles"
              variant="outlined"
              color="primary"
              margin="normal"
              aInputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
            >
              {userRoles.map((count) => {
                return (
                  <MenuItem value={count.value}>{count.text}</MenuItem>
                )
              })}
            </Select>

            <FormHelperText style={{ fontFamily: '"Poppins", sans-serif' }}>Select your Role in Institution</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="name"
            label="First Name"
            name="inst_user_firstname"
            value={inst_user_firstname}
            fullWidth
            onChange={onChangeForm}
            error={inst_user_firstname == null || inst_user_firstname.length < 3}
            helperText={inst_user_firstname == null || inst_user_firstname === ''  || inst_user_firstname.length < 3 ? ('Please enter first name'):(null) }
            variant="outlined"
            color="primary"
            InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="name"
            label="Name"
            name="inst_user_lastname"
            value={inst_user_lastname}
            fullWidth
            onChange={onChangeForm}
            error={inst_user_lastname == null || inst_user_lastname.length === 0}
            helperText={inst_user_lastname == null || inst_user_lastname === '' ? ('Please enter name'):(null) }
            variant="outlined"
            color="primary"
            margin="normal"
            InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="email"
            label="Email"
            name="inst_email"
            value={inst_email}
            onChange={onChangeForm}
            error={!pattern.test(inst_email)}
            helperText={inst_email == null || inst_email === '' || !pattern.test(inst_email) ? ('Please enter email'):(null) }
            fullWidth
            type="email"
            variant="outlined"
            color="primary"
            margin="normal"
            InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="pass1"
            name="inst_pwd"
            value={inst_pwd}
            label="Password"
            onChange={onChangeForm}
            fullWidth
            error={inst_pwd == null || inst_pwd.length <= 8}
            helperText={inst_pwd == null || inst_pwd === ''  || inst_pwd.length <= 8 ? ('Please enter password'):(null) }
            type="password"
            variant="outlined"
            color="primary"
            margin="normal"
            InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="pass2"
            name="inst_repwd"
            value={inst_repwd}
            label="Repeat Password"
            fullWidth
            onChange={onChangeForm}
            error={inst_repwd !== inst_pwd}
            helperText={inst_repwd !== inst_pwd ? ('Please enter same password'):(null) }
            type="password"
            variant="outlined"
            color="primary"
            margin="normal"
            InputLabelProps={{ style: { fontFamily: '"Poppins", sans-serif' } }}
          />
        </Grid>


      </Grid>
    </React.Fragment>
  );
}

export default UserDetails;