import { API_ROOT } from "config.js";

const SEARCH_PROFILE_DETAILS_ENDPOINT = `${API_ROOT}/api/searchprofile/`;
const MATCHED_INSTITUTE_DETAILS_ENDPOINT = `${API_ROOT}/api/searchprofile/matched`;

export async function getSearchProfiles(axios) {
  let url = `${SEARCH_PROFILE_DETAILS_ENDPOINT}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateSearchProfile(axios, profileData) {
  let payload = profileData;
  if (profileData.avatarFile) {
    payload = new FormData();
    payload.append("file", profileData.avatarFile);
    delete profileData["avatarFile"];

    const json = JSON.stringify(profileData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(SEARCH_PROFILE_DETAILS_ENDPOINT, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getMatchedInstitutes(axios) {
  let url = `${MATCHED_INSTITUTE_DETAILS_ENDPOINT}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
