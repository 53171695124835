/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import moment from "moment";
// material-ui icons
import HowToReg from "@material-ui/icons/HowToReg";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { getEventDetails, updateEvent } from "services/eventsDataProvider.js";

import styles from "assets/jss/material-dashboard-react/views/extendedTablesStyle.jsx";
import { MEDIA_ROOT } from "config.js";

class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedData: {}
    };
  }

  componentDidMount() {
    let eventId = this.props.match.params.eventId;
    getEventDetails(this.props.axios, eventId).then(data => {
      console.log("eventDetails", data);
      this.setState({ eventDetails: data });
    });
  }

  updateEvent = (event, going) => e => {
    let eventData = {
      attending: going
    };
    updateEvent(this.props.axios, event.id, eventData).then(data => {
      let events = this.state.events;
      events.forEach(event => {
        if (event.id === data.id) {
          event.attendees = data.attendees;
        }
      });
      this.setState({ events: events });
    });
  };

  simpleButtons(event) {
    const { classes } = this.props;
    let going = event.attendees.filter(
      // eslint-disable-next-line react/prop-types
      x => x.user_id === this.props.user.user_id
    );
    going = going.length > 0;
    return [
      { color: "warning", icon: Visibility, text: "Visit webpage" },
      {
        color: going === true ? "success" : "danger",
        icon: HowToReg,
        text:
          going === true
            ? "You are going. Click to change."
            : "You are not going. Click to change.",
        onClick: this.updateEvent(event, !going)
      }
      // {
      //   color: going === false ? "success" : "danger",
      //   icon: Block,
      //   text: "No, I am not going"
      // },
      // { color: "primary", icon: Close, text: "Remove from list" }
    ].map((prop, key) => {
      return (
        <Tooltip
          key={`event-${event.id}-${key}`}
          id="tooltip-top"
          title={prop.text}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.onClick}
          >
            <prop.icon className={classes.icon} />
          </Button>
        </Tooltip>
      );
    });
  }

  getEventsData() {
    if (this.state.events === undefined) {
      return [];
    }
    return this.state.events.map(event => {
      // convert data to DD/MM/YYYY format
      //var dateStart = moment(event.start).format("MMMM Do YYYY, hh:mm a");
      var dateStart = moment(event.start).format("MMMM Do YYYY");
      var dateEnd = moment(event.end).format("MMMM Do YYYY");
      return [
        event.title,
        `${event.address.city_name}, ${event.address.country_name}`,
        dateStart,
        dateEnd,
        event.description,
        this.simpleButtons(event)
      ];
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.eventDetails === undefined) {
      return null;
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img
                  src={`${MEDIA_ROOT}${this.state.eventDetails.avatar}`}
                  alt="..."
                />
              </a>
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <ArtTrack className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="success" simple justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Remove"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="danger" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  {this.state.eventDetails.title}
                </a>
              </h4>
              <p className={classes.cardProductDesciprion}>
                {this.state.eventDetails.description}
              </p>
            </CardBody>
            <CardFooter product>
              <div className={classes.price}>
                <h4>$899/night</h4>
              </div>
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Place /> Barcelona, Spain
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(EventDetail);
