import XlsxPopulate from "xlsx-populate";

export const escapeCSVText = (text) => {
  text = text.replace(/"/g, '""');

  if (/[,"\n\r]/.test(text)) {
    text = '"' + text + '"';
  }

  return text
};

export async function exportToExcel(name, data) {
  const workbook = await XlsxPopulate.fromBlankAsync();

  const sheet = workbook.sheet(0);

  data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      sheet.cell(rowIndex + 1, colIndex + 1).value(cell);
    });
  });

  const buffer = await workbook.outputAsync();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = `${name}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function exportToCSV(name, data) {
  const csvContent = data.map(row => {
    return row.map(escapeCSVText).join(',');
  }).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
