/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import UserImg from "../../assets/img/default-avatar.png";

import { MEDIA_ROOT } from "config.js";

const userStyles = {
  userContainer: {
    overflow: "hidden",
    display: "block"
  },
  userCard: {
    display: "flex",
    flexDirection: "row"
  },
  userCardHeader: {
    maxWidth: "150px",
    paddingTop: "5px"
  },
  memberAvatar: {
    width: "150px",
  },
  userNameContainer: {
    fontSize: "large",
    padding: "5px",
    color: "blueviolet"
  },
  aboutUser: {
    fontSize: "smaller",
    color: "blueviolet"
  },
  userRolesContainer: {
    display: "flex",
    flexDirection: "column"
  },
  userRole: {
    fontSize: "smaller",
    color: "blueviolet"
  },
};


class UserSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, userData } = this.props;
    return (
      <GridItem
        xs={12}
        sm={12}
        md={4}
        key={`event-${userData.id}`}
        className={classes.userContainer}
      >
        <Card className={classes.userCard}>
          <CardHeader color="primary" icon className={classes.userCardHeader}>
            <a
              href="#avatar"
              onClick={() =>
                this.props.history && this.props.history.push({
                  pathname: `/member-profile/${userData.id}`,
                  state: { member: userData }
                })}
              key={`contact_list-link-${userData.id}`}
              alt="..."
            >
              <img
                className={classes.memberAvatar}
                src={
                  userData.avatar == null
                    ? UserImg
                    : `${MEDIA_ROOT}${userData.avatar}`
                }
                alt={"..."}
              />
            </a>
            <div className={classes.userNameContainer}>
              {`${userData.first_name} ${userData.last_name}`}
            </div>
          </CardHeader>

          <CardBody className={classes.userCardBody}>
            <div className={classes.aboutUser}>
              {userData.about_me}
              <strong className={classes.userCity}>{userData.city}</strong>
            </div>
            <ul className={classes.userRolesContainer}>
              {userData.roles && userData.roles.map(roleDetails => {
                return (
                  <li className={classes.userRole} key={`user-role-${roleDetails.id}`}>
                    {`${roleDetails.role} at `}
                    <a
                      href={`/institutes/${roleDetails.institute}`}
                    >
                      {roleDetails.institute_name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </CardBody>

          <CardFooter product>
            
          </CardFooter>
        </Card>
      </GridItem>
    );
  }
}

UserSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

export default withStyles(userStyles)(UserSummary);
