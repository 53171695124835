import {
  projectTypes,
  SDGs,
  WORLD_REGION_CHOICES,
  BASIC_SERVICE_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name ",
      required: true
    },
    short_name: {
      type: "string",
      title: "Short Name "
    },
    type: {
      type: "string",
      title: "Project Type ",
      options: projectTypes,
      required: true
    },
    sustainable_development_goals: {
      type: "string",
      title: "Sustainable Development Goals ",
      options: SDGs,
      required: false
    },
    focus_areas: {
      type: "string",
      title: "Non-financial Focus Areas ",
      options: BASIC_SERVICE_CHOICES,
      required: false
    },
    regions: {
      type: "string",
      title: "Region ",
      required: true,
      options: WORLD_REGION_CHOICES
    },
    website_url: {
      type: "url",
      title: "Website (https://) ",
      required: false
    },
    description: {
      type: "string",
      title: "Project description "
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      name: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter name"
      }
    }
  },
  {
    container: "griditem",
    items: {
      short_name: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter short name"
      },
      regions: {
        "ui:widget": "multiselect",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please select regions"
      },
      type: {
        "ui:widget": "select",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please select type of project"
      }
    }
  },
  {
    container: "griditem",
    items: {
      sustainable_development_goals: {
        "ui:widget": "multiselect",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter sustainable development goals"
      },
      focus_areas: {
        "ui:widget": "multiselect",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter non-financial focus area"
      },
    }
  },
  {
    container: "griditem",
    items: {
      website_url: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter website url"
      }
    }
  },
  {
    container: "griditem",
    items: {
      description: {
        "ui:widget": "text",
        "ui:emptyValue": "",
        "ui:errorMsg": "Please enter project description"
      }
    }
  }
];

export const ProjectFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
