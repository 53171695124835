import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { getMember } from "services/userDataProvider.js";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Table from "components/Table/Table.jsx";

//icons
import Language from "@material-ui/icons/Domain";
import SendButton from "@material-ui/icons/Message";

import styles from "assets/jss/material-dashboard-react/views/userProfileStyles.jsx";

import avatar from "assets/img/default-avatar.png";
import { MEDIA_ROOT } from "config.js";
import { getAccessToken } from "../../containers/authenticated";

const useStyles = makeStyles(() => ({
  ...styles,
  cardContainer: {
    //height: "40vh"
  },
  avatarContainer: {
    height: "25vh"
  },
  imgContainer: {
    height: "100% !important"
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },

  

}));

const ShowDetails = ({ role }) => {
  const classes = useStyles();
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Language />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Institutions
            </h4>
          </CardHeader>
          <CardBody>
            <CardBody>
              <Table
                tableHead={["Name", "Role/Position"]}
                tableData={role.map(inst => {
                  return [
                    <a key={inst.id} href={`/institutes/${inst.institute}`}>
                      {inst.institute_name}
                    </a>,
                    `${inst.role}`
                  ];
                })}
              />
            </CardBody>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

function UserProfile(props) {
  const classes = useStyles();
  const memberId = window.location.pathname.split("/member-profile/")[1];
  const [member, setMember] = React.useState({});
  const [userAuthenticated, setUserAuthenticated] = React.useState(false);
  React.useEffect(() => {
    let token = getAccessToken();
    setUserAuthenticated(token !== null ? true : false);
    getMember(memberId).then(data => {
      const nonAdminRoles = data["roles"].filter(memberData => memberData["role"] !== "Admin");
      data["roles"] = nonAdminRoles;
      setMember(data);
    });
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile className={classes.cardContainer}>
            <CardAvatar profile className={classes.avatarContainer}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img
                  src={
                    member.avatar == null
                      ? avatar
                      : `${MEDIA_ROOT}${member.avatar}`
                  }
                  alt="..."
                  className={classes.imgContainer}
                />
              </a>
            </CardAvatar>
            <CardBody profile>
              {/* <ShowDetails /> */}
              {/* <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6> */}
              <h4 className={classes.cardTitle}>
                {member.first_name} {member.last_name}
              </h4>
            </CardBody>
            <CardFooter className={classes.footerContainer}>
              {userAuthenticated ? (
                <Button
                  color="info"
                  size="sm"
                  onClick={() =>
                    props.history.push({
                      pathname: "/chat",
                      state: { messagePerson: member }
                    })
                  }
                >
                  <SendButton />
                  Message
                </Button>
              ) : null}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {Object.keys(member).length && userAuthenticated ? (
        <ShowDetails role={member.roles} />
      ) : null}
    </div>
  );
}

export default UserProfile;
