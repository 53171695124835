// @material-ui/icons

import Info from "@material-ui/icons/Info";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Domain from "@material-ui/icons/Domain";
import Chat from "@material-ui/icons/Chat";
import AssignmentIcon from '@material-ui/icons/Poll';
import EventIcon from '@material-ui/icons/Event'
// import Equalizer from "@material-ui/icons/Equalizer";
import PieChart from "@material-ui/icons/PieChart";
import MyLocation from "@material-ui/icons/MyLocation";
import DateRange from "@material-ui/icons/DateRange";
import Extension from "@material-ui/icons/Extension";
// import FindReplace from "@material-ui/icons/FindReplace";
//import List from "@material-ui/icons/ListRounded";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import PostAdd from "@material-ui/icons/PostAdd";
import Notifications from "@material-ui/icons/Notifications";
import RecentActors from "@material-ui/icons/RecentActors";

// pages
// import OldProfilePage from "../views/Profile/UnusedProfile.jsx";
import ProfilePage from "../views/Profile/Profile.jsx";
import AboutPage from "../views/About/About.jsx";
import InstitutionList from "../views/UserProfile/InstitutionList.jsx";
import MemberProfile from "../views/UserProfile/MemberProfile";
import HomePage from "../views/Home/Home.jsx";
import MatchedInstitutesPage from "../views/MatchedInstitutes/MatchedInstitutes.jsx";
import SettingsPage from "../views/Settings/Settings.jsx";
import FilterAndMatchPage from "../views/FilterAndMatch/FilterAndMatch.jsx";
import ContactsPage from "../views/Contacts/Contacts.jsx";
import MembersMapPage from "../views/MembersMap/MembersMapSearch.jsx";
import MapPage from "../views/MembersMap/MembersMap.jsx";
import EventsPage from "../views/Events/Events.jsx";
import QuizList from "views/Quiz/QuizListing.jsx";
import ManageQuiz from "views/Quiz/ManageQuiz.jsx";
//import Survey from "views/Survey/Survey.jsx"; // TO DO: unused view
import CreateProject from "views/Projects/CreateProject.jsx";
import ProjectsList from "views/ProjectsList/ProjectsList.jsx";
import ProjectDetails from "views/Projects/ProjectDetails.jsx";
import ManagePg from "views/Manage/Manage.jsx";
import Notification from "../views/Notifications/Notifications";

// 2.0
import ChatPage from "../views/ChatPage/ChatPage.jsx";
import Calendar from "../views/Calendar/Calendar";
import CreateNewInstitute from "../views/Institute/CreateNewInstitute.jsx";
import EditProject from "views/Projects/EditProject.jsx";
import { withAxios } from "../containers/authenticated.js";
import InstitutionProfile from "../views/UserProfile/InstitutionProfile.jsx";
import ExploreQuizSubmissions from "../views/Quiz/ExploreQuizSubmissions.jsx";
import Surveys from "../views/Surveys/Surveys.jsx";

// Menu Links on the Sidebar
const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Home",
    navbarName: "Home",
    icon: Dashboard,
    component: withAxios(HomePage),
    searchItem: function(item) {
      return item;
    }
  },
  // {
  //   path: "/profile-2",
  //   sidebarName: "Profile",
  //   navbarName: "Profile",
  //   icon: Person,
  //   component: withAxios(OldProfilePage),
  //   hide: "forStaffOnly"
  // },
  {
    path: "/profile",
    sidebarName: "Profile",
    navbarName: "Profile",
    icon: Person,
    component: withAxios(ProfilePage)
  },
  {
    path: "/member-profile/:memberId",
    sidebarName: "Member Profile",
    navbarName: "Member Profile",
    icon: Person,
    component: withAxios(MemberProfile),
    hide: true
  },
  {
    path: "/institution",
    sidebarName: "Institution Profile",
    navbarName: "Institution Profile",
    icon: Domain,
    component: withAxios(InstitutionList),
    hide: true
  },
  {
    path: "/institutes/:instituteId",
    sidebarName: "Institution Profile",
    navbarName: "Institution Profile",
    icon: Domain,
    component: withAxios(InstitutionProfile),
    hide: true
  },
  {
    path: "/members",
    sidebarName: "Member Database",
    navbarName: "Search and Find Members",
    icon: MyLocation,
    component: withAxios(MembersMapPage),
    hide: "forStaffOnly"
  },
  {
    path: "/map",
    sidebarName: "Members Map",
    navbarName: "Members Map",
    icon: MyLocation,
    component: withAxios(MapPage),
    hide: "forStaffOnly"
  },
  {
    path: "/settings",
    sidebarName: "Filter Settings",
    navbarName: "Set your filter preferences",
    icon: SettingsApplications,
    component: withAxios(SettingsPage),
    hide: true
  },
  {
    path: "/matchedprofiles",
    sidebarName: "Matches",
    navbarName: "Your Search Matches",
    icon: Extension,
    component: withAxios(MatchedInstitutesPage),
    hide: true
  },
  {
    path: "/match",
    sidebarName: "Matching Tool",
    navbarName: "Advanced Search",
    icon: Extension,
    component: withAxios(FilterAndMatchPage)
  },
  {
    path: "/projects/:projectId/edit",
    sidebarName: "Projects",
    navbarName: "My Projects",
    icon: PostAdd,
    component: withAxios(EditProject),
    hide: true
  },
  {
    path: "/projects/:projectId/:activeTab?",
    sidebarName: "Projects",
    navbarName: "My Projects",
    icon: PostAdd,
    component: withAxios(ProjectDetails),
    hide: true
  },
  {
    path: "/projects",
    sidebarName: "Projects",
    navbarName: "My Projects",
    icon: PostAdd,
    component: withAxios(ProjectsList)
  },
  {
    path: "/surveys",
    sidebarName: "Surveys",
    navbarName: "Surveys",
    icon: AssignmentIcon,
    component: withAxios(Surveys),
  },
  {
    path: "/create-project",
    sidebarName: "Create New Project",
    navbarName: "Create New Project",
    icon: PostAdd,
    component: withAxios(CreateProject),
    hide: true
  },
  {
    path: "/events",
    sidebarName: "Events",
    navbarName: "Events",
    icon: EventIcon,
    component: withAxios(EventsPage),
    hide: "forStaffOnly"
  },
  {
    path: "/contact",
    sidebarName: "Contacts",
    navbarName: "Contacts",
    icon: RecentActors,
    component: withAxios(ContactsPage),
    hide: false
  },
  {
    path: "/chat",
    sidebarName: "Chat",
    navbarName: "Chat",
    icon: Chat,
    component: withAxios(ChatPage),
    hide: false
  },
  {
    path: "/calendar",
    sidebarName: "Calendar",
    navbarName: "Calendar",
    icon: DateRange,
    component: withAxios(Calendar),
    hide: true
  },
  /*{
    path: "/Pay",
    sidebarName: "Pay",
    navbarName: "Pay",
    icon: DateRange,
    component: withAxios(Checkout)
  },*/
  {
    path: "/quiz/manage/:quizName",
    sidebarName: "Reports",
    navbarName: "Reports",
    icon: PieChart,
    component: withAxios(ManageQuiz),
    hide: true
  },
  {
    path: "/quiz/explore-submissions/:quizName",
    sidebarName: "Reports",
    navbarName: "Reports",
    icon: PieChart,
    component: withAxios(ExploreQuizSubmissions),
    hide: true
  },
  {
    path: "/quiz/:quizName",
    sidebarName: "Reports",
    navbarName: "Reports",
    icon: PieChart,
    component: withAxios(QuizList),
    hide: true
  },
  /*
  {
    path: "/survey/:surveyName",
    sidebarName: "Surveys",
    navbarName: "FIll Social Performance Surveys",
    icon: PieChart,
    component: withAxios(Survey),
    hide: "forStaffOnly"
  },
  */
  {
    path: "/notification",
    sidebarName: "Notifications",
    navbarName: "Notifications",
    icon: Notifications,
    component: withAxios(Notification),
    hide: "forStaffOnly"
  },
  {
    path: "/manage",
    sidebarName: "MANAGE USERS",
    navbarName: "MANAGE USERS (admin)",
    icon: Dashboard,
    component: withAxios(ManagePg),
    hide: "forStaffOnly"
  },
  {
    path: "/institutes/:instituteId",
    sidebarName: "View Profile",
    navbarName: "View Profile",
    icon: Domain,
    component: withAxios(InstitutionProfile),
    hide: true
  },
  {
    path: "/about",
    sidebarName: "Home",
    navbarName: "About HEDERA connect",
    icon: Info,
    component: withAxios(AboutPage),
    hide: true
  },
  {
    path: "/create-institute",
    component: withAxios(CreateNewInstitute),
    hide: true
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
