import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";


// core components
import Table from "components/Table/Table.jsx";
import Heading from "components/Heading/Heading.jsx";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from "@material-ui/core/TextField";

import UserImg from "../../assets/img/default-avatar.png";
import { MEDIA_ROOT } from "config.js";
import {
  getContacts,
  updateRequest,
  removeConnection
} from "services/userDataProvider.js";

// icons
import MailIcon from "@material-ui/icons/Message";
import RemoveIcon from "@material-ui/icons/Remove";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
//import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ViewIcon from  "@material-ui/icons/Visibility";
//import Tooltip from "@material-ui/core/Tooltip";
import ContactsStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class Contacts extends React.Component {
  state = {
    contacts: []
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    this.fetchContacts();
  }
  fetchContacts = () => {
    getContacts(this.props.axios).then(data => {
      let allContacts = data.results;
      this.setState({ contacts: allContacts , searchedContacts: allContacts })
    });
  };

  //to accept the request
  acceptClick = (id, isAccepted) => {
    let requestData = {
      id: id,
      accepted: isAccepted
    };
    updateRequest(this.props.axios, requestData).then(data => {
      this.fetchContacts();
    });
  };

  //to remove the contact
  removeClick = id => {
    removeConnection(this.props.axios, id).then(data => {
      this.fetchContacts();
    });
  };

  handleSearchChange = e => {
    let query = e.target.value;
    let results = this.state.contacts;
    results = results.filter(contactPerson => {
      if (query === "") {
        return true;
      } else if (contactPerson.to.first_name.toLowerCase().includes(query.toLowerCase())
            || contactPerson.to.last_name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      return false;
    });
    this.setState({searchedContacts : results})
  }

  handleActionSelection = (e, contactPerson, id) => {
    switch(e){
      case "View Profile":
        this.props.history.push({
          pathname: `/member-profile/${contactPerson.id}`,
          state: { member: contactPerson }
        });
        break;
      case "Message":
        this.props.history.push({
          pathname: "/chat",
          state: { messagePerson: contactPerson }
        })
        break;
      case "Reject request":
        this.acceptClick(id, false);
        break;  
     case "Cancel request":
     case "Remove connection":
        this.removeClick(id);
        break;
      case "Accept connection":
        this.acceptClick(id, true);
        break;     
      default:
        break;
    }
  }

  renderactionButtons(el, contactPerson, id) {
    switch(el){
      case "View Profile":
        return <span>
        <Button variant="outlined" startIcon={<ViewIcon />} onClick={(e) => this.handleActionSelection(el, contactPerson, id)}>
          View profile
        </Button>
    </span>
    case "Message":
        return <span>
          <Button variant="outlined" startIcon={<MailIcon />} onClick={(e) => this.handleActionSelection(el, contactPerson, id)}>
            Message
          </Button>
      </span>
    case "Reject request":
        return <span>
          <Button variant="outlined" startIcon={<CancelIcon />} onClick={(e) => this.handleActionSelection(el, contactPerson, id)}>
            Reject request
          </Button>
      </span>
   case "Cancel request":
      return <span>
        <Button variant="outlined" startIcon={<CancelIcon />} onClick={(e) => this.handleActionSelection(el, contactPerson, id)}>
          Cancel request
        </Button>
      </span>
   case "Remove connection":
      return <span>
        <Button variant="outlined" startIcon={<RemoveIcon />} onClick={(e) => this.handleActionSelection(el, contactPerson, id)}>
          Remove
        </Button>
    </span>
    case "Accept connection":
        return <span>
          <Button variant="outlined" startIcon={<GroupAddIcon />} onClick={(e) => this.handleActionSelection(el, contactPerson, id)}>
            Accept request
          </Button>
      </span>
    default:
  }
}

  renderUserContacts() {
    let { classes } = this.props;
    let contactsList = this.state.contacts ? this.state.contacts.length : 0;
    if (contactsList === 0)
      return <Heading textAlign="center" title={"No contact yet"} />
    let tableHeader = [
      <p
        key={`contact-list-table-header-avatar`}
      >
      </p>,
      <p
        key={`contact-list-table-header-title`}
      >
      </p>,
      <p
        key={`contact-list-table-header-actions`}
      >
      </p>
    ];
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="searct-term"
              label="Search Contact"
              name="search"
              autoFocus
              onChange={this.handleSearchChange}
            />
          </GridItem>
        </GridContainer>
        <GridContainer className={ContactsStyle.contactContainer}>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderTable(classes, tableHeader)}
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  
  renderTable = (classes, tableHeader) => {
    return <Table
    tableHeaderColor="warning"
    tableHead={tableHeader}
    tableData={this.state.searchedContacts && this.state.searchedContacts.map(contactData => {
      let currentUserId = this.props.user && this.props.user.user_id;
      let contactPerson = undefined;
      let actiondropDownList = ["View Profile"]
      let cancelButtonTitle = undefined;
      let acceptButtonTitle = undefined;

      if (contactData.from.id === currentUserId) {
        // Current user had sent this request
        contactPerson = contactData.to;
        cancelButtonTitle = contactData.accepted
          ? "Remove connection"
          : "Cancel request";
      } else {
        // Current user had received this request
        contactPerson = contactData.from;
        cancelButtonTitle = contactData.accepted
          ? "Remove connection"
          : "Reject request";
        acceptButtonTitle = contactData.accepted
          ? undefined
          : "Accept connection";
      }
        cancelButtonTitle && (
        actiondropDownList.push(cancelButtonTitle)
      )
      if (acceptButtonTitle !== undefined) {
        actiondropDownList.push(acceptButtonTitle)
      } else if (cancelButtonTitle === "Remove connection") {
        actiondropDownList.push("Message")
      }
      return [
        <a
          href="#avatar"
          onClick={() =>
            this.props.history.push({
              pathname: `/member-profile/${contactPerson.id}`,
              state: { member: contactPerson }
            })}
          key={`contact_list-link-${contactPerson.id}`}
          alt="..."
          style={{ width: "100px", height: "100px" }}>
          <img
            className={classes.contactAvatar}
            src={
              contactPerson.avatar == null
                ? UserImg
                : `${MEDIA_ROOT}${contactPerson.avatar}`
            }
            alt={"..."}
            />
          </a>,
        <span style={{ fontSize: "1.5em" }}>{contactPerson.first_name + " " + contactPerson.last_name }</span>,
      /*<div className={classes.scrollArea}>
        {contactPerson.roles.map(
          role =>
            role.role !== "Follower" && role.role !== "Admin" 
              && role.role !== "Contact" && (
              <p key={role.id}>{`${role.role} at ${role.institute_name
                }`}</p>
            )
        )}
              </div>,*/
      <div style={{gap: "1rem", display: "flex"}}>
        {
          actiondropDownList.map(
            el => this.renderactionButtons(el, contactPerson, contactData.id)
          )
        }
      </div>
    ];
  })}
/>
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    return <div>{this.renderUserContacts()}</div>;
  }
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(ContactsStyle)(Contacts);
