/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Info from "@material-ui/icons/Info";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomForm from "layouts/Registration/WizardSteps/CustomForm.js";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PrimaryText from "components/Typography/Primary.jsx";

import { FinancialInstituteFormSchema } from "layouts/Search/FormSchemas/FinancialInstituteSearchFormSchema.js";
import { InvestorFormSchema } from "layouts/Search/FormSchemas/InvestorSearchFormSchema.js";
import { TechnologyProviderFormSchema } from "layouts/Search/FormSchemas/TechnologyProviderSearchFormSchema.js";
import { TechnicalAssistanceFormSchema } from "layouts/Search/FormSchemas/TechnicalAssistanceSearchFormSchema.js";
import { AcademiaFormSchema } from "layouts/Search/FormSchemas/AcademiaSearchFormSchema.js";
import { NGOFormSchema } from "layouts/Search/FormSchemas/NGOSearchFormSchema.js";

import {
  getSearchProfiles,
  updateSearchProfile
} from "services/searchProfileDataProvider.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const INSTITUTE_TYPES = {
  FINANCIAL_INSTITUTION: {
    name: "Financial Institution",
    label: "Rural Banks, Microfinance institution, Cooperatives"
  },
  INVESTOR_ASSET_MANAGER: {
    name: "Investor/Asset manager",
    label: "Funds, Development Banks, Commercial Banks, Corporations"
  },
  TECHNOLOGY_SERVICE_PROVIDER: {
    name: "Technology/Service Provider",
    label: "Companies (SMEs) providing (e.g.) energy or water technologies"
  },
  NGO: {
    name: "NGO",
    label:
      "Non-governamental organizations, networks, international initiatives"
  },
  TECHNICAL_ASSISTANCE_CONSULTANT: {
    name: "Technical assistance/Consultant",
    label: "Consulting companies, Multilateral organizations, Rating Agencies"
  },
  ACADEMIA: {
    name: "Academia",
    label: "Research Institutes, Universities, R&D Departments"
  }
};

class Settings extends React.Component {
  state = {
    institutes: {}
  };

  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    let inputData = {};
    Object.keys(this.state.institutes).forEach(key => {
      if (this.state.institutes[key] === true) {
        inputData[key] =
          this.formSchemas[key] && this.formSchemas[key].schema.data();
      }
    });
    updateSearchProfile(this.props.axios, inputData)
      .then(() => {
        this.setState({ successMessage: "Success" });
      })
      .catch(() => {
        this.setState({ errorMessage: "Failed" });
      });
  };

  checkBoxUpdated = name => e => {
    let institutes = this.state.institutes;
    institutes[name] = e.target.checked;
    this.setState({ institutes: institutes });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getSearchProfiles(this.props.axios, this.props.user.user_id).then(data => {
      let searchSettings = {};
      let institutes = {};
      data.forEach(dt => {
        searchSettings[dt.institute_type] = dt;
        institutes[dt.institute_type] = true;
      });
      this.setState({ profiles: searchSettings, institutes: institutes });
    });
  }

  renderInstituteForm(instituteType) {
    let formSchema = null;
    switch (instituteType) {
      case INSTITUTE_TYPES.FINANCIAL_INSTITUTION.name:
        formSchema = FinancialInstituteFormSchema;
        break;
      case INSTITUTE_TYPES.INVESTOR_ASSET_MANAGER.name:
        formSchema = InvestorFormSchema;
        break;
      case INSTITUTE_TYPES.TECHNOLOGY_SERVICE_PROVIDER.name:
        formSchema = TechnologyProviderFormSchema;
        break;
      case INSTITUTE_TYPES.NGO.name:
        formSchema = NGOFormSchema;
        break;
      case INSTITUTE_TYPES.TECHNICAL_ASSISTANCE_CONSULTANT.name:
        formSchema = TechnicalAssistanceFormSchema;
        break;
      case INSTITUTE_TYPES.ACADEMIA.name:
        formSchema = AcademiaFormSchema;
        break;
      default:
        formSchema = null;
    }
    if (formSchema != null) {
      let schemas = this.formSchemas || {};
      schemas[instituteType] = formSchema;
      this.formSchemas = schemas;
      return (
        <CustomForm
          formSchema={formSchema}
          formData={
            this.state.profiles[instituteType] &&
            this.state.profiles[instituteType].options
          }
        />
      );
    } else {
      return "Invalid Institute type";
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <Info />
                    </CardIcon>
                  </CardHeader>
                  <CardBody justify="center">
                    <PrimaryText>
                      Select the type of institution you are interested in.
                      Additional filters will appear. Update your search profile
                      by clicking the button below.
                    </PrimaryText>
                  </CardBody>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          {Object.keys(INSTITUTE_TYPES).map(key => {
            const instituteType = INSTITUTE_TYPES[key].name;
            const isChecked = this.state.institutes[instituteType] === true;
            // console.log("institute_type", instituteType, isChecked)
            return (
              <Card key={key}>
                <CardHeader color="info">
                  <h4>{INSTITUTE_TYPES[key].name}</h4>
                </CardHeader>
                <CardBody>
                  <GridItem xs={12} sm={12} md={2} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={this.checkBoxUpdated(instituteType)}
                        />
                      }
                      label={
                        <PrimaryText>{INSTITUTE_TYPES[key].label}</PrimaryText>
                      }
                    />
                  </GridItem>

                  {isChecked ? (
                    this.renderInstituteForm(instituteType)
                  ) : (
                    <GridItem xs={12} sm={12} md={12} lg={12} />
                  )}
                </CardBody>
              </Card>
            );
          })}

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Button
              fullWidth
              variant="contained"
              color="success"
              className={classes.submit}
              onClick={this.submitForm}
            >
              Update your Search Profile
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Settings);
