import {
  INSTITUTE_LEGAL_STATUS_CHOICES,
  BASIC_SERVICE_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    legal_status: {
      type: "string",
      title: "Legal Status",
      required: true,
      options: INSTITUTE_LEGAL_STATUS_CHOICES["Academia"]
    },
    research_areas: {
      type: "string",
      title: "Research Areas",
      options: BASIC_SERVICE_CHOICES,
      required: true
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      legal_status: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      },
      research_areas: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  }
];

export const AcademiaFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
