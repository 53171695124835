import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { withAxios } from "containers/authenticated.js";

import CustomForm from "layouts/Registration/WizardSteps/CustomForm.js";
import { ProjectFormSchema } from "./FormSchemas/ProjectFormSchema.js";
import { createProject } from "services/projectDataProvider.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  filterContainer: {
    zIndex: "9999 !important"
  }
};

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  sendState() {
    return this.state;
  }

  nextButtonClick() {
    let projectData = null;
    projectData = ProjectFormSchema.schema.data();
    return createProject(this.props.axios, projectData).then(data => {
      let projectId = null;
      if (data.roles) {
        data.roles.forEach(role => {
          projectId = role.institute;
        });
      }
      this.setState({
        projectId: projectId,
        id: data.id,
        instituteType: projectData && projectData.type
      });
      this.sendState();
    })
  }

  isValidated() {
    return ProjectFormSchema.schema.validate();
  }

  renderProjectForm() {
    return <CustomForm formSchema={ProjectFormSchema} />;
  }

  render() {
    return this.renderProjectForm();
  }
}

ProjectForm.propTypes = {
  classes: PropTypes.object,
  axios: PropTypes.func
};

export default withAxios(withStyles(style)(ProjectForm));
