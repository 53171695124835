/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import Footer from "components/Footer/Footer.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { forgotPassword, resetPassword } from "services/authProvider.js";

import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    };
  }

  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    if (this.state.acceptPassword !== true) {
      forgotPassword(this.state.email)
        .then(() => {
          this.setState({
            errorMessage: "Password reset link has been sent to you via email."
          });
        })
        .catch((e) => {
          this.setState({ errorMessage: e});
        });
    } else {
      resetPassword(
        this.state.uid,
        this.state.token,
        this.state.password,
        this.state.confirmPassword
      ).then(({success, data}) => {
        if (success) {
          window.open("/signin", "_self");
        } else {
          this.setState({
            errorMessage: data
          });
        }
        })
        .catch(({success, data}) => {
          this.setState({ errorMessage: data });
        });
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  componentDidMount() {
    let params = this.props.match.params;
    if (params) {
      let uid = params.uid64;
      let token = params.token;
      if (uid !== undefined && token !== undefined) {
        this.setState({ acceptPassword: true, uid: uid, token: token });
      }
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;

    return (
      <div className={classes.root} >
        <div className={classes.paperContainer}>
          <CssBaseline />

          <div className="logo_name" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
            <img src={require('../../assets/img/hedera-logo.png')} alt="Hedera Logo" width={150} />
          </div>
          <Box my={3} />
          <Paper elevation={3} style={{ padding: 40, maxWidth: 600, alignSelf: "center" }}>

            <Typography style={{ fontFamily: '"Poppins", sans-serif', textAlign: "center" }} variant="h5">
              Reset Password
            </Typography>
            <Box my={2} />
            {this.state.acceptPassword !== true ? (
              <p style={{ fontFamily: '"Poppins", sans-serif', textAlign: "center" }}>Confirm your email to receive reset password link.</p>
            ) : null}

            <Grid item margin="normal">
              {this.state.acceptPassword ? (
                <div>
                  <TextField
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    color="secondary"
                    name="password"
                    autoFocus
                    onChange={this.handleChange("password")}
                  />
                  <TextField
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    color="secondary"
                    id="confirmPassword"
                    label="Confirm password"
                    name="confirmPassword"
                    onChange={this.handleChange("confirmPassword")}
                  />
                </div>
              ) : (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  color="secondary"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={this.handleChange("email")}
                />
              )}
              {this.state.errorMessage ? (
                <SnackbarContent
                  message={this.state.errorMessage}
                  color="danger"
                />
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                //color="primary"
                disableElevation
                size="large"
                className={classes.registrationButtonStyle}
                onClick={this.submitForm}
              >
                Submit
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/registration" style={{ fontFamily: '"Poppins", sans-serif', fontSize: 18, color: "#c90a73" }}>
                    Sign Up
                  </Link>
                </Grid>
                <Grid item >
                  <Link href="/signin" style={{ fontFamily: '"Poppins", sans-serif', fontSize: 18, color: "#c90a73" }}>
                    Sign In
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
        <Footer style={{ backgroundColor: "#7e2f6d" }} sx={{ bgcolor: "#7e2f6d", backgroundColor: "#7e2f6d" }} />
      </div>
    );
  }
}

export default withStyles(signinStyle)(ResetPassword);