import {
  INSTITUTE_LEGAL_STATUS_CHOICES,
  INSTITUTE_REGULATED_CHOICES,
  FOCUS_AREA_CHOICES,
  FINANCIAL_SERVICE_CHOICES,
  FUNDING_INSTRUMENT_CHOICES,
  BASIC_SERVICE_CHOICES,
  IMPACT_METRIC_CHOICES,
  TRUE_FALSE_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    legal_status: {
      type: "string",
      title: "Legal Status",
      required: true,
      options: INSTITUTE_LEGAL_STATUS_CHOICES["FinancialInstitute"]
    },
    regulated: {
      type: "string",
      title: "Regulated",
      required: true,
      options: INSTITUTE_REGULATED_CHOICES["FinancialInstitute"]
    },
    focus_areas: {
      type: "string",
      title: "Focus Areas",
      options: FOCUS_AREA_CHOICES,
      required: false
    },
    mixmarket_profile: {
      type: "string",
      title: "Mix market profile url",
      required: false
    },
    services_offered: {
      type: "string",
      title: "Services Offered",
      options: FINANCIAL_SERVICE_CHOICES,
      required: false
    },
    non_financial_programs: {
      type: "string",
      title: "Non financial programs",
      required: false,
      options: BASIC_SERVICE_CHOICES
    },
    main_funding_sources: {
      type: "string",
      title: "Current Main Funding Instrument",
      options: FUNDING_INSTRUMENT_CHOICES["FinancialInstitute"]
    },
    funding_needed: {
      type: "string",
      title: "Funding Instrument Needed (if any)",
      options: FUNDING_INSTRUMENT_CHOICES["FinancialInstitute"],
      required: false
    },
    savings: {
      type: "string",
      title: "Savings",
      options: TRUE_FALSE_CHOICES,
      required: false
    },
    impact_metrics: {
      type: "string",
      title: "Relevant Impact Metrics",
      options: IMPACT_METRIC_CHOICES,
      required: false
    },
    ///@attention is there a type for money? this item does not show up in the form
    current_financial_needs: {
      type: "money",
      title: "Current financial needs"
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      legal_status: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      },
      regulated: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      services_offered: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      focus_areas: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      non_financial_programs: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      savings: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      main_funding_sources: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      funding_needed: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      mixmarket_profile: {
        "ui:widget": "text",
        "ui:emptyValue": ""
      },
      impact_metrics: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      current_financial_needs: {
        "ui:widget": "money",
        "ui:emptyValue": ""
      }
    }
  }
];

export const FinancialInstituteFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
