import { getContacts } from "services/userDataProvider.js";

export const fetchChatContact = props => {
  let chatContact = getContacts(props.axios).then(data => {
    let allContacts = data.results;
    let filterdContact = [];
    filterdContact = allContacts.filter(i => {
      if (i.accepted) {
        // show this contact
        return i;
      } else {
        return null;
      }
    });

    let chatContact = filterdContact.map(itm => {
      let customContact =
        itm.from.id === props.user && props.user.user_id ? itm.to : itm.from;
      return customContact;
    });
    return chatContact;
  });
  return chatContact;
};
