/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {
  getNotifications,
  readNotification
} from "services/userDataProvider.js";
import { getAccessToken } from "containers/authenticated.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  message: {
    color: "#6f6262a3",
    textAlign: "center"
  }
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tr: false,
      notification: [],
      notifMessage: ""
    };
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }
  showNotification(place) {
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      2000
    );
  }

  componentDidMount = () => {
    this.fetchNotification();
  };

  //to fetch notification from api
  fetchNotification = () => {
    let token = getAccessToken();
    getNotifications(token).then(data => {
      let notifRes = data.results;
      if (notifRes.length) {
        this.setState({ notification: notifRes, notifMessage: "" });
      } else {
        this.setState({ notifMessage: "No Notifications" });
      }
    });
  };

  //readNotification makes particular id read:true
  readNotification = id => {
    if (id) {
      readNotification(this.props.axios, id).then(data => {
        //readnotification api
        this.showNotification("tr");
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { notification, notifMessage } = this.state;
    //show all notification in grid
    const renderNotification = notification.map((notif, idx) => {
      return (
        <GridItem xs={12} spacing={3}>
          <SnackbarContent
            icon={AddAlert}
            message={
              <div>
                <p>
                  {" "}
                  <h4>{notif.verb}</h4>
                  {notif.description}
                </p>
                <div>
                  <Button
                    color="success"
                    onClick={() => this.readNotification(notif.id)}
                  >
                    Mark as read
                  </Button>
                </div>
              </div>
            }
          />
        </GridItem>
      );
    });
    return (
      <Card>
        <CardBody>
          <h3></h3>
          <div style={styles.message}>
            <h3>{notifMessage}</h3>
          </div>
          <GridContainer spacing={1}>{renderNotification}</GridContainer>
          <Snackbar
            place="tr"
            color="info"
            // autoHideDuration
            message="Notification is marked as read"
            open={this.state.tr}
            closeNotification={() => this.setState({ tr: false })}
            close
          />
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(styles)(Notifications);
