/* eslint-disable react/prop-types */
import React, { Component } from "react";
//import Clock from "react-digital-clock"
import Clock from "react-clock"
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
import { MadeWithLove } from "components/MadeWithLove/MadeWithLove.jsx";
import { setAccessToken } from "containers/authenticated.js";
import { verifyLogin } from "services/authProvider.js";

import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";
import { staticData } from "config.js";

const styles = {
  hedera: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: "400"
  },
  bottomBox: {
    marginBottom: "15px"
  },
  clock:{
    fontFamily: '"Poppins", sans-serif',
    fontWeight: "400",
    color: "black",
    fontSize: "20px"
  }
}

class StartPage extends Component {
  static getDerivedStateFromProps(props, state) {
    let searchPath = props.location && props.location.search;
    if (searchPath) {
      let tempList = searchPath.split("&");
      let token = tempList.length > 1 ? tempList[1] : null;
      token = token.replace("token=", "");
      // console.log("token", token)
      if (token) {
        setAccessToken({ token: token });
        verifyLogin().then(data => {
          props.history.push("/registration");
        });
      }
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      errorMessage: null
    };
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    window.open("registration", "_self");
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <div>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            alt="logo"
            className={classes.logoSmall}
            src={staticData.company.logoSmall}
          />
          <br />
          <Typography style={styles.hedera} component="h3" variant="h5">
            Welcome to HEDERA Connect
          </Typography>
          <br />
          <div className={classes.subtitle} style={styles.hedera}>
            The professional network for institutions committed to sustainable
            development.
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={styles.hedera}
            onClick={this.submitForm}
          >
            Sign Up
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            href="signin"
            style={styles.hedera}
          >
            Sign In
          </Button>
          {/*<Grid container>
            <Grid item xs>
              <Link href="signin" variant="body2">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
            <Grid item>
              <Link href="signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>*/}
        </div>
        <Box style={styles.bottomBox} mt={5}>
          <MadeWithLove />
        </Box>
        
        {/*<br />
        <Box mt={5}>
        <div>
          By registering you confirm that you have read and understood our  
          <a href="https://hedera.online/gdpr/hedera-data-protection-declaration-2019-en.pdf"> data protection declaration</a>.
          </div>
        </Box>*/}
      </Container>
      </div>
      
    );
  }

  renderWhite(){
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <div>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <div className={classes.paper}>
          
          <img
            alt="logo"
            className={classes.logoSmall}
            src={staticData.company.logoSmall}
          />
          <br />
          
          <Button
            type="submit"
            halfWidth
            variant="contained"
            color="primary"
            href="signin"
            style={styles.hedera}
          >
            IT'S TIME TO CONNECT
          </Button>
          <br />
          <br />
          <div>
            <Clock
              renderSecondHand = {false}
              size  = {80}
              value={this.state.date}
              hour12= {false}
              
            />
          </div>
          
          
        </div>
        <Box style={styles.bottomBox} mt={5}>
          <MadeWithLove />
        </Box>
        
        
      </Container>
      </div>
      
    );
  }
}

export default withStyles(signinStyle)(StartPage);
