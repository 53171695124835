import React, { Component } from "react";
import axios from "axios";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import StartPage from "layouts/StartPage/StartPage.jsx";
// import SignIn from "layouts/SignIn/SignIn.jsx";

import { verifyLogin } from "services/authProvider";
import { API_ROOT } from "config.js";

const ACCESS_TOKEN_KEY = "access-token";

class AuthenticatedComponent extends Component {
  constructor(props) {
    super(props);
    if (isValidAccessToken()) {
      this.state = {
        isLoggedIn: true,
        accessToken: getAccessToken()
      };
      this.updateRavenUserContextFromState();
    } else {
      this.state = {
        isLoggedIn: false,
        accessToken: null
      };
    }
  }

  updateRavenUserContextFromState() {
    if (this.state.isLoggedIn) {
      // this.props.raven.setUserContext({
      //   id: this.getUID()
      // });
    }
  }

  login = accessToken => {
    setAccessToken(accessToken);
    this.setState(
      {
        isLoggedIn: true,
        accessToken: accessToken
      },
      this.updateRavenUserContextFromState
    );
  };

  logout = () => {
    this.setState(
      {
        isLoggedIn: false,
        accessToken: null
      },
      this.updateRavenUserContextFromState
    );
    removeAccessToken();
  };

  createProviderValue = () => {
    return {
      accessToken: this.state.accessToken,
      authenticate: this.logout,
      axios: this.createAxiosInstance(),
      user: getUserData()
    };
  };

  createAxiosInstance = () => {
    const instance = axios.create({
      baseURL: API_ROOT,
      paramsSerializer: params =>
        JSON.stringify(params, { arrayFormat: "repeat", skipNulls: true })
    });
    instance.interceptors.request.use(request => {
      if (!isValidAccessToken()) {
        this.logout();
      }

      //For token auth
      const token = this.state.accessToken;
      request.headers.Authorization = `Token ${token}`;

      //show global loading indicator
      document.body.classList.add("loading-indicator");
      return request;
    }, Promise.reject);
    instance.interceptors.response.use(
      response => {
        // hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      error => {
        // hide global loading indicator
        document.body.classList.remove("loading-indicator");
        if (error.response && [401, 403].includes(error.response.status)) {
          this.logout();
        } else if (error.response && [400].includes(error.response.status)) {
          return error.response;
        }
        return Promise.reject(error);
      }
    );
    return instance;
  };

  render() {
    return (
      <AuthenticatedContext.Provider value={this.createProviderValue()}>
        {this.renderBody()}
      </AuthenticatedContext.Provider>
    );
  }

  renderBody() {
    if (this.state.isLoggedIn) {
      // eslint-disable-next-line react/prop-types
      return this.props.children;
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/start" render={() => <StartPage />} />
            <Redirect to={{ pathname: "/start" }} />
          </Switch>
        </BrowserRouter>
      );
    }
  }
}

export const Authenticated = AuthenticatedComponent;

const AuthenticatedContext = React.createContext(null);

export function withAxios(Component) {
  // eslint-disable-next-line react/display-name
  return props => {
    return (
      <AuthenticatedContext.Consumer>
        {({ axios, user }) => (
          <Component {...props} axios={axios} user={user} />
        )}
      </AuthenticatedContext.Consumer>
    );
  };
}

export function getAccessToken() {
  let data = localStorage.getItem(ACCESS_TOKEN_KEY);
  let token = data && JSON.parse(data).token;
  // console.log("getAccessToken", token)
  return token;
}

export function getUserData() {
  const value = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (value) {
    const data = JSON.parse(value);
    return {
      username: data.username,
      user_id: data.user_id,
      roles: data.roles,
      isStaff: data.is_staff,
      activeModules: data.active_modules
    };
  }
}

function isValidAccessToken() {
  let accessToken = getAccessToken();
  if (accessToken === null || accessToken === '' || accessToken === undefined) {
    return false;
  }
  return verifyLogin();
}

export function setAccessToken(data) {
  // console.log("setAccessToken", data);
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(data));
}

export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}
