import {
  INSTITUTE_LEGAL_STATUS_CHOICES,
  BASIC_SERVICE_CHOICES,
  IMPACT_METRIC_CHOICES,
  WORLD_REGION_CHOICES,
  SDGs
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    legal_status: {
      type: "string",
      title: "Type of NGO",
      options: INSTITUTE_LEGAL_STATUS_CHOICES["NGO"]
    },
    working_regions: {
      type: "string",
      title: "Region(s) of Intervention",
      options: WORLD_REGION_CHOICES
    },
    focus_areas: {
      type: "string",
      title: "Non-financial Focus Areas",
      options: BASIC_SERVICE_CHOICES
    },
    impact_metrics: {
      type: "string",
      title: "Relevant Impact metrics",
      options: IMPACT_METRIC_CHOICES
    },
    sustainable_development_goals: {
      type: "string",
      title: "Sustainable Development Goals",
      options: SDGs
    },
    region: {
      type: "string",
      title: "Region",
      options: WORLD_REGION_CHOICES
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      region: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      sustainable_development_goals: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      legal_status: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      focus_areas: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      working_regions: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      impact_metrics: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  }
];

export const NGOFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
