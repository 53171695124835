import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withAxios } from "containers/authenticated.js";

import TextField from "@material-ui/core/TextField";
import moment from "moment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Modal from "@material-ui/core/Modal";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ContactMail } from "@material-ui/icons";

import {
  postTopic,
  deleteTopic
} from "services/projectDataProvider.js";
import { getContacts } from "services/userDataProvider";

import customSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle.jsx";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  createTopicStyle: {
    marginTop: "10px",
    textAlign: "end"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  textField: {
    width: "100%",
    marginTop: "20px"
  },

  cssLabel: {
    color: "grey"
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "rgb(243, 197,69)"
    }
  },
  discusionTitle: {
    color: "#273746",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontSize: "30px",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "bold",
    textAlign: "center"
  },
  cssFocused: {
    "&$cssFocused": {
      color: "grey"
    }
  },
  notchedOutline: {
    borderWidth: "1px"
  },
  deleteTopicStyle: {
    marginTop: "10px",
    textAlign: "end",
    display: "flex",
    flexDirection: "column"
  },
  discussionDescription: {
    width: "100%"
  },
  ...customSelectStyle
};

class TopicStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }

  topicUpdate = fieldName => e => {
    let topicData = this.state.newTopicData || {};
    topicData[fieldName] = e.target.value;
    this.setState({ newTopicData: topicData });
  }

  createNewTopic = e => {
    let topicData = this.state.newTopicData || {};
    let topicArray = this.state.topics || [];
    topicData["project"] = this.props.allStates.project.id || 0;
    postTopic(this.props.axios, topicData).then(data => {
      topicArray.push(data);
      this.setState({ topics: topicArray, openTopicPopUp: false });
    });
  };

  handleModalClose = e => {
    e.preventDefault();
    this.setState({ openTopicPopUp: false });
  };

  handleModalOpen = e => {
    e.preventDefault();
    this.setState({ openTopicPopUp: true});
  };
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {
    return (
      <GridContainer>
        {this.renderTopics()}
      </GridContainer>
    );
  }

  renderTopics() {
    let projectTopics = this.state.topics || [];
    return (
      <div style={{width: "100%"}}>
        {this.renderCreateTopicButton()}
        { projectTopics.length>0?projectTopics.map(topic => this.renderIndividualTopics(topic)):
          this.renderNoProjectsAvailable()
        }
      </div>
    );
  }

  renderNoProjectsAvailable = e => {
    const { classes } = this.props;
    return ( 
    <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Please Create New Topics</h4>
        </GridItem>
      </GridContainer>
    )
  } 

  renderCreateTopicButton = e => {
    const { classes } = this.props;
    return (
      <div className={classes.createTopicStyle}>
        <Button color="primary" onClick={this.handleModalOpen}>
          <AddIcon fontSize="48px" aria-label="add" />
          Create New Topic
        </Button>
        {this.renderTopicModal()}
      </div>
    );
  };

  renderTopicModal() {
    const { classes } = this.props;
    const { openTopicPopUp } = this.state;
    return (
      <Modal
        style={{ margin: "30px" }}
        open={openTopicPopUp}
        onClose={this.handleModalClose}
      >
        <Card
          style={{ backgroundColor: "white", margin: "30px", width: "auto" }}>
          <CardBody>
            <GridContainer justify="center" >
              <GridItem xs={12} sm={12}>
                <h4 className={classes.discusionTitle}>Create a New Topic</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <CustomInput
                  labelText={
                    <span>
                      Title <small>(required)</small>
                    </span>
                  }
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: this.topicUpdate("name"),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <ContactMail className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <TextField
                  id="description"
                  label="Description (required)"
                  rows={4}
                  multiline
                  className={classes.textField}
                  onChange={this.topicUpdate("description")}
                  variant="outlined"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
              </GridItem>
              <GridItem style={{ marginTop: "30px", display: "flex" }} xs={12} sm={12} md={12}>
              <GridItem style={{ textAlign: "end" }} xs={6} sm={6} md={6}>
                <Button
                  type="button"
                  color="rose"
                  onClick={this.handleModalClose}
                >
                  Cancel
                </Button>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  type="button"
                  color="success"
                  onClick={this.createNewTopic}
                >
                  Create
                </Button>
              </GridItem>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Modal>
    );
  }

  deleteTopicDetails = (id) => e => {
    let topicList = this.state.topics || [];
    let index = topicList.findIndex(x => x.id === id);
    topicList.splice(index, 1);

    deleteTopic(this.props.axios, id).then(data => {
      this.setState({ state: this.state });
    });
  }

  renderIndividualTopics(topic) {
    const { classes } = this.props;
    return (
      <Card
        key={topic.id}
        style={
          {
            border: "1px solid lightgrey",
            padding: "10px",
            boxShadow: "4px 10px #efefef"
          }
        }>
        <CardBody>
          <CustomInput
            labelText="Topic Name"
            id="disabled"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              defaultValue: topic.name,
            }}
          />
          <TextField
            label="Description"
            rows={4}
            multiline
            className={classes.textField}
            defaultValue={topic.description}
            disabled={true}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused
              }
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              }
            }}
          />
        </CardBody>
        <CardFooter>
          <div style={styles.projectUpdateInfo}>
            <label>Last Update: </label>
            <large>{moment(topic.updated_at).format("MMMM Do YYYY")}</large>
          </div>
          {this.renderDeleteButton(topic.id)}
        </CardFooter>
      </Card>
    );
  }

  renderDeleteButton = id => {
    const { classes } = this.props;
    return (
      <div className={classes.deleteTopicStyle}>
        <span>
          <Tooltip
            id="tooltip-top"
            title="Delete Project"
            placement="bottom"
            classes={{ tooltip: this.props.classes.tooltip }}>
            <Button
              color="danger"
              size="sm"
              onClick={this.deleteTopicDetails(id)}>
              <DeleteIcon fontSize="48px" aria-label="delete" />
              Delete Topic
            </Button>
          </Tooltip>
        </span>
      </div>
    );
  }
  
  nextButtonClick() {
    return getContacts(this.props.axios).then(data => {
      let allContacts = data.results;
      this.setState({ contacts: allContacts });
      this.sendState();
    });
  }

}

TopicStep.propTypes = {
    axios: PropTypes.func,
    classes: PropTypes.object
};

export default withAxios(withStyles(styles)(TopicStep));
