import { API_ROOT } from "config.js";
import { getAccessToken } from "containers/authenticated.js";

const PROJECTS_LIST_ENDPOINT = `${API_ROOT}/api/projects/`;

export async function createProject(axios, projectData) {
  const response = await axios.post(`${PROJECTS_LIST_ENDPOINT}`, projectData);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getProjectsList(axios) {
  const response = await axios.get(`${PROJECTS_LIST_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getProjectDetails(axios, projectName) {
  const response = await axios.get(`${PROJECTS_LIST_ENDPOINT}${projectName}/`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function createComment(axios, discussionId, comment) {
  const response = await axios.put(
    `${PROJECTS_LIST_ENDPOINT}discussions/${discussionId}/comment/`,
    {
      comment: comment
    }
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function createDiscussion(axios, discussionData) {
  const response = await axios.post(
    `${PROJECTS_LIST_ENDPOINT}discussions/`,
    discussionData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function deleteProject(axios, projectData) {
  const response = await axios.delete(
    `${PROJECTS_LIST_ENDPOINT}${projectData}/`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function postTopic(axios, projectData) {
  const response = await axios.post(
    `${PROJECTS_LIST_ENDPOINT}topics/`,
    projectData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function postDocument(axios, projectData) {
  let payload = projectData;
  if (projectData.attachedFile) {
    payload = new FormData();
    payload.append("file", projectData.attachedFile);
    delete projectData["attachedFile"];

    const json = JSON.stringify(projectData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(
    `${PROJECTS_LIST_ENDPOINT}documents/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateTopic(axios, projectData) {
  const response = await axios.put(
    `${PROJECTS_LIST_ENDPOINT}topics/${projectData.id}/`,
    projectData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function deleteTopic(axios, id) {
  const response = await axios.delete(`${PROJECTS_LIST_ENDPOINT}topics/${id}/`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function deleteDiscussion(axios, id) {
  const response = await axios.delete(
    `${PROJECTS_LIST_ENDPOINT}discussions/${id}/`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function addRole(axios, updateData) {
  const response = await axios.put(
    `${PROJECTS_LIST_ENDPOINT}participants/`,
    updateData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function removeUser(axios, userDetails) {
  const response = await axios.delete(
    `${PROJECTS_LIST_ENDPOINT}participants/`,
    { data: userDetails }
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateProject(axios, projectData) {
  let payload = projectData;
  if (projectData.avatarFile || projectData.bannerFile) {
    payload = new FormData();
    if (projectData.avatarFile) {
      payload.append("file", projectData.avatarFile);
      delete projectData["avatarFile"];
    }
    if (projectData.bannerFile) {
      payload.append("bannerFile", projectData.bannerFile);
      delete projectData["bannerFile"];
    }

    const json = JSON.stringify(projectData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.put(
    `${PROJECTS_LIST_ENDPOINT}${projectData.id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateProjectStyle(axios, projectId, styleData) {
  const response = await axios.post(
    `${PROJECTS_LIST_ENDPOINT}${projectId}/styling_info/`,
    styleData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export function getDashboardConfigUrl(baseUrl) {
  const tempList = baseUrl.split('projectId=');
  const projectId = tempList[tempList.length - 1];
  if (projectId == null) return '';

  const encodedParams = btoa(`${projectId},${getAccessToken()}`);
  let configUrl = `${PROJECTS_LIST_ENDPOINT}dashboard_config?params=${encodedParams}`;
  return configUrl;
}
