import React from "react";
import L from "leaflet";

import { instituteTypes } from "../../utils/choices.jsx";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { getInstituteCoordinates } from "../../services/instituteDataProvider.js";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const style = {
  width: "100%",
  height: "900px"
};

class Map extends React.Component {
  componentDidMount() {
    //// crear mapa
    getInstituteCoordinates(this.props.axios).then(data => {
      let dataPoints = [];
      data.forEach(dt => {
        if (dt.address && dt.address.latitude && dt.address.longitude) {
          dt["description"] = `
          <a href="institutes/${dt.id}" target='_blank'>${dt["name"]}</a>
          <br>
          ${dt.type}
          <br>
          <small>${dt.address.city_name},${dt.address.country_name} </small>
          <br>
          <a href="${dt.website_url}" target='_blank'>Homepage</a>
          `;

          // change marker color according to type
          if (dt.type === instituteTypes[0].value) {
            dt.color = instituteTypes[0].color;
          } else if (dt.type === instituteTypes[1].value) {
            dt.color = instituteTypes[1].color;
          } else if (dt.type === instituteTypes[2].value) {
            dt.color = instituteTypes[2].color;
          } else if (dt.type === instituteTypes[3].value) {
            dt.color = instituteTypes[3].color;
          } else if (dt.type === instituteTypes[4].value) {
            dt.color = instituteTypes[4].color;
          } else if (dt.type === instituteTypes[5].value) {
            dt.color = instituteTypes[5].color;
          } else dt.color = "black";

          dataPoints.push(dt);
        }
      });

      this.map = L.map("map", {
        center: [
          dataPoints[0].address.latitude,
          dataPoints[0].address.longitude
        ],
        zoom: 3,
        layers: [
          L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution:
              '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          })
        ]
      });

      //// Agregar marcadores al mapa
      dataPoints.forEach(d => {
        //L.marker([d.address.latitude, d.address.longitude], {
        L.circleMarker(L.latLng(d.address.latitude, d.address.longitude), {
          title: d.name,
          draggable: false,
          color: d.color
        })
          .bindPopup(d.description)
          .addTo(this.map);
      });
    });
  }

  render() {
    return <div id="map" style={style} />;
  }
}

export default Map;
