import {
  INSTITUTE_LEGAL_STATUS_CHOICES,
  FUNDING_INSTRUMENT_CHOICES,
  IMPACT_METRIC_CHOICES,
  WORLD_REGION_CHOICES,
  FUNDING_CURRENCY_CHOICES,
  FUNDED_INSTITUTION_CHOICES,
  PORTFOLIO_SIZE_CHOICES
} from "utils/choices.jsx";

const schema = {
  title: "",
  description: "",
  type: "object",
  properties: {
    legal_status: {
      type: "string",
      title: "Type",
      required: true,
      options: INSTITUTE_LEGAL_STATUS_CHOICES["Investor"]
    },
    working_regions: {
      type: "string",
      title: "Region of Intervention",
      options: WORLD_REGION_CHOICES,
      required: false
    },
    funding_offered: {
      type: "string",
      title: "Funding Instruments Offered",
      options: FUNDING_INSTRUMENT_CHOICES["Investor"],
      required: false
    },
    funding_institution_type: {
      type: "string",
      title: "Type of Institution funded",
      options: FUNDED_INSTITUTION_CHOICES,
      required: false
    },
    funding_currency: {
      type: "string",
      title: "Funding Curerncy",
      options: FUNDING_CURRENCY_CHOICES,
      required: false
    },
    portfolio_size: {
      type: "string",
      title: "Portfolio Size",
      options: PORTFOLIO_SIZE_CHOICES,
      required: false
    },
    impact_metrics: {
      type: "string",
      title: "Relevant Impact Metrics & Tools",
      options: IMPACT_METRIC_CHOICES,
      required: false
    },
    funding_min: {
      type: "integer",
      title: "Minimum Amount of Funding (USD)"
    },
    funding_max: {
      type: "integer",
      title: "Maximum Amount of Funding (USD)"
    },
    term_min: {
      type: "integer",
      title: "Minimum Term (Months)"
    },
    term_max: {
      type: "integer",
      title: "Maximum Term (Months)"
    }
  }
};

const uiSchema = [
  {
    container: "griditem",
    items: {
      legal_status: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      },
      working_regions: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      funding_institution_type: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      },
      funding_offered: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      portfolio_size: {
        "ui:widget": "select",
        "ui:emptyValue": ""
      },
      funding_currency: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      funding_min: {
        "ui:widget": "text",
        "ui:emptyValue": ""
      },
      funding_max: {
        "ui:widget": "text",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      term_min: {
        "ui:widget": "text",
        "ui:emptyValue": ""
      },
      term_max: {
        "ui:widget": "text",
        "ui:emptyValue": ""
      }
    }
  },
  {
    container: "griditem",
    items: {
      impact_metrics: {
        "ui:widget": "multiselect",
        "ui:emptyValue": ""
      }
    }
  }
];

export const InvestorFormSchema = {
  schema: schema,
  uiSchema: uiSchema
};
