import React, { useState } from "react";
import { withAxios } from "containers/authenticated.js";
import Modal from "@material-ui/core/Modal";
import Button from "../../../components/CustomButtons/Button";
import { updateProjectStyle, updateProject } from "../../../services/projectDataProvider";
import CustomSwitcher from "../../../components/CustomSwitcher/CustomSwitcher";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";

const useStyles = makeStyles({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
});

const ConfigurationModal = ({ handleClose, project, axios, currentProject }) => {
  const classes = useStyles();
  const [config, setConfig] = useState({
    isMetadata: false,
    isBanner: false,
  });

  const close = () => {
    setConfig({});
    handleClose();
  };

  const handleSubmit = async () => {
    if(config.isMetadata){
    const {styling_info, ...rest} = project.metadata
      await updateProjectStyle(axios,currentProject.id,project.metadata.styling_info)
      await updateProject(axios, {id:currentProject.id,...rest})
    }
    
    close()
  };

  if (!Object.keys(project).length) {
    return <></>;
  }

  const handleChangeTableView = (event, checked, name) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: checked,
    }));
  };

  return (
    <Modal
      open={Object.keys(project).length}
      onClose={close}
      style={{ margin: "30px" }}
    >
      <Card
        style={{
          backgroundColor: "white",
          margin: "30px auto",
          maxWidth: "500px",
        }}
      >
        <CardBody>
          {project.metadata || project.banner ? (
            <>
              <div className={classes.paper}>
                <h4>Select data to import</h4>
                {project.metadata && (
                  <CustomSwitcher
                    label="Design & style configuration"
                    labelPlacement="start"
                    checked={config.isMetadata}
                    onChange={(event, checked) =>
                      handleChangeTableView(event, checked, "isMetadata")
                    }
                  />
                )}
                {project.banner && (
                  <CustomSwitcher
                    label="Project banner"
                    labelPlacement="start"
                    checked={config.isBanner}
                    onChange={(event, checked) =>
                      handleChangeTableView(event, checked, "isBanner")
                    }
                  />
                )}
                <div className={classes.buttonGroup}>
                  <Button color="grey" onClick={close}>
                    Cancel
                  </Button>
                  <Button disabled={!config.isBanner&&!config.isMetadata} color="info" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.paper}>
              <h4>Nothing to be imported</h4>
              <Button color="grey" onClick={close}>
                Close
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default withAxios(ConfigurationModal);
