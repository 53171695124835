const quizStyles = theme => ({
    quizActionsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap:'wrap',
        width: "-webkit-fill-available"
    },
    buttonWrap:{
        display: "flex",
        gap:'16px',
        alignItems: 'center'
    },
    buttonDelete:{
        display:'flex', 
        justifyContent:'flex-end',
        width:'100%'
    },
    switcherWrap:{
        marginLeft:'-12px'
    }
});

export default quizStyles;
