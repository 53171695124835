/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Place from "@material-ui/icons/Place";
import Assignment from "@material-ui/icons/Assignment";
import HowToReg from "@material-ui/icons/HowToReg";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import DateRange from "@material-ui/icons/DateRange";
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Accordion from "components/Accordion/Accordion.js";

import "./EventSummary.css";

const dashboardStyle = {
  cardTitle: {
    color: "#3C4858",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "300",
    fontSize: "20px",
    fontFamily: '"Poppins", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },

  instituteContainer: {
    overflow: "hidden",
    display: "block"
  },
  imgContainer: {
    width: "200px",
    maxHeight: "200px",
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
  img: {
    width: "100%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    border: "0"
  }
};

class EventSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRegisterButtons(event) {
    const { classes } = this.props;
    let going = event.attendees.filter(
      // eslint-disable-next-line react/prop-types
      x => x.user_id === this.props.selfUser.user_id
    );
    going = going.length > 0;
    return [
      {
        color: "warning",
        icon: Visibility,
        text: "Visit Homepage",
        onClick: () =>
          event.external_link && window.open(event.external_link, "_blank")
      },
      {
        color: going === true ? "success" : "danger",
        icon: HowToReg,
        text:
          going === true
            ? "You are going. Click to change."
            : "You are not going. Click to change.",
        onClick: this.props.updateEvent && this.props.updateEvent(event, !going)
      }
    ].map((prop, key) => {
      return (
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip
            key={`event-${event.id}-${key}`}
            id="tooltip-top"
            title={prop.text}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color={prop.color}
              simple
              className={classes.actionButton}
              key={key}
              label="going"
              onClick={prop.onClick}
            >
              <prop.icon className={classes.icon} /> {prop.text}
            </Button>
          </Tooltip>
        </GridItem>
      );
    });
  }

  renderAttendes() {
    const { eventData } = this.props;
    if (eventData) {
      return (
        <Accordion
          // active={0}
          collapses={[
            {
              title: "Attendees List",
              content: (
                <ul>
                  {eventData.attendees &&
                    eventData.attendees.map(user => {
                      if (user.first_name) {
                        return (
                          <li>
                            <strong>(Institution)</strong>{" "}
                            {`${user.first_name} ${user.last_name}`}
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>
              )
            }
          ]}
        />
      );
    }
    return null;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, eventData } = this.props;

    return (
      <GridItem
        xs={12}
        sm={12}
        md={12}
        key={`event-${eventData.id}`}
        className="eventContainer"
      >
        <Card product className={classes.cardHover}>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 style={dashboardStyle.cardTitle}>{eventData.title}</h4>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.cardHoverUnder}>
                  {eventData.description}
                </div>
              </GridItem>
              {this.renderRegisterButtons(eventData)}
            </GridContainer>
          </CardBody>

          <CardFooter product>
            <GridContainer>
              {/*<GridItem xs={12} sm={12} md={12}>
                <Public />{" "}
                {
                  <a
                    href={eventData.external_link}
                    // onClick={e => e.preventDefault()}
                  >
                    {" "}
                    <small>www</small>
                  </a>
                }
              </GridItem>*/}
              <GridItem xs={12} sm={12} md={12}>
                <DateRange />{" "}
                {
                  <small>
                    {`${moment(eventData.start).format(
                      "MMMM Do YYYY"
                    )}-${moment(eventData.end).format("MMMM Do YYYY")}`}
                  </small>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Place />{" "}
                {
                  <small>{`${eventData.address.city_name}, ${
                    eventData.address.country_name
                  }`}</small>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {this.renderAttendes()}
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </GridItem>
    );
  }
}

EventSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  eventData: PropTypes.object.isRequired,
  unfollowInstitute: PropTypes.object.isRequired,
  followInstitute: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(EventSummary);
