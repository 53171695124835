import { WEB_SOCKET_API_PATH } from "../config";
import { getAccessToken } from "../containers/authenticated";

class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connect(userId) {
    const path = `${WEB_SOCKET_API_PATH}/${userId}/`;
    this.socketRef = new WebSocket(path);

    this.socketRef.onopen = () => {
      console.log("WebSocket open");
    };
    this.socketRef.onmessage = e => {
      this.socketNewMessage(e.data);
      // console.log("ON_MESSAGE", e);
    };

    this.socketRef.onerror = e => {
      console.log("Error webcocket: ", e.message);
    };
    this.socketRef.onclose = () => {
      console.log("WebSocket closed let's reopen");
    };
  }

  // close(userId) {
  //   console.log("Want to close connection", this.socketRef);
  //   this.socketRef.close();
  // }

  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    // console.log("socketNewMessage", parsedData);
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === "messages") {
      this.callbacks[command](parsedData.messages);
    }
    if (command === "new_message") {
      this.callbacks[command](parsedData.message);
    }
  }

  initChatUser(userId) {
    this.sendMessage({
      command: "init_chat",
      userId: userId,
      token: getAccessToken()
    });
  }

  fetchMessages(userId, lastMessageId = undefined, fetchDirection = "after") {
    if (lastMessageId !== undefined) {
      this.sendMessage({
        command: "fetch_messages",
        userId: userId,
        lastMessageId: lastMessageId,
        fetchDirection: fetchDirection
      });
    } else {
      this.sendMessage({ command: "fetch_messages", userId: userId });
    }
  }

  messageSeen(lastMessageId) {
    this.sendMessage({ command: "mark_messages_as_read", lastMessageId });
  }

  newChatMessage(message) {
    this.sendMessage({
      command: "new_message",
      from: message.from,
      message: message.message
    });
  }

  closeServer() {
    this.sendMessage({
      command: "exit_chat"
    });
  }

  addCallbacks(messagesCallback, newMessageCallback) {
    this.callbacks["messages"] = messagesCallback;
    this.callbacks["new_message"] = newMessageCallback;
  }

  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    } catch (err) {
      console.log(err.message);
    }
  }

  state() {
    return this.socketRef && this.socketRef.readyState;
  }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
