/* eslint-disable react/prop-types */
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./quizStyles.jsx";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import {
  downloadCsvAllResults,
  downloadCsvLatestResults,
  downloadExcelLatestResults,
  downloadExcelAllResults
} from "../../utils/quiz";
import PropTypes from "prop-types";

const buttonColorScheme = {
  errorButton: "danger",
  createButton: "info",
  infoButton: "primary",
  actionButton: "rose"
};

class QuizExportButton extends Component {
  defaultHandlers = [
    {
      name: 'CSV(Latest)',
      handler: async () => downloadCsvLatestResults(await this.getQuizDetails()),
      isHidden: false
    },
    {
      name: 'CSV(All)',
      handler: async () => downloadCsvAllResults(await this.getQuizDetails()),
      isHidden: this.props.onlyLatestSubmissions
    },
    {
      name: 'Excel(Latest)',
      handler: async () => downloadExcelLatestResults(await this.getQuizDetails()),
      isHidden: false
    },
    {
      name: 'Excel(All)',
      handler: async () => downloadExcelAllResults(await this.getQuizDetails()),
      isHidden: this.props.onlyLatestSubmissions
    },
    {
      name: 'Export to the project',
      handler: async () => {},
      isHidden: false
    },
    {
      name: 'Explore submissions',
      handler: async () => window.location = `/quiz/explore-submissions/${(await this.getQuizDetails()).name}`,
      isHidden: this.props.noExploreSubmissions
    },
  ]

  async getQuizDetails() {
    return typeof this.props.quizDetails === 'function'
      ? await this.props.quizDetails()
      : this.props.quizDetails;
  }

  render() {
    const handlers = this.props.customHandlers || this.defaultHandlers

    return (
      <CustomDropdown
        key={`quiz-action-buttons-dropdown`}
        buttonText="Download results"
        buttonProps={{
          color: buttonColorScheme.actionButton
        }}
        onClick={(e) => {
          const foundHandler = handlers.find(({name}) => name === e)
          if (foundHandler) {
            foundHandler.handler()
          }
        }}
        dropdownList={handlers.filter(({ isHidden }) => !isHidden).map(({ name }) => name)}
      />
    )
  }
}

QuizExportButton.propTypes = {
  quizDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  customHandlers: PropTypes.array,
  noExploreSubmissions: PropTypes.bool.isRequired,
  onlyLatestSubmissions: PropTypes.bool
};

export default withStyles(styles)(QuizExportButton);
